import styled from 'styled-components';

export const AvatarContainer = styled.div<{
	size?: number;
	onClick?: () => void;
	backgroundColor?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 50%;
	height: ${({ size }) => `${size}px`};
	min-width: ${({ size }) => `${size}px`};
	max-width: ${({ size }) => `${size}px`};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
	background: ${({ theme, backgroundColor }) =>
		backgroundColor || theme.colors.neutral[50]};
`;

export const AvatarImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const InitialsTemplate = styled.div<{
	size: number;
	textColor?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: ${({ size }) => `${(2 * size) / 5}px`};
	letter-spacing: 0.6px;
	font-weight: 600;
	color: ${({ theme, textColor }) => textColor || theme.colors.neutral[400]};
	border-radius: 50%;
`;
