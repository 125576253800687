import styled from 'styled-components';

import { TextTooltip } from '../WrapperTooltip';
import { Icon } from '../../presentation/Icon';

export const HelpTextTooltip = styled(TextTooltip)<{
	height: number;
	dataTestId?: string;
}>`
	display: flex;
	height: ${({ height }) => height}px;
	cursor: pointer;
	z-index: 1;
`;

export const HelpIcon = styled(Icon)<{
	dataTestId?: string;
}>``;
