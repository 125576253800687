import styled from 'styled-components';

export const Box = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
`;

export const Div = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr); // Specifies 3 columns with equal width
	grid-gap: 10px;
`;
