import styled from 'styled-components';

export const MainContainer = styled.div`
	margin: 0;
	background: ${({ theme }) => theme.colors.system.background.system};
	align-items: center;
	max-width: 640px;
`;

export const LoadingWrapper = styled.div`
	display: flex;
	min-height: 400px;
	align-items: center;
	justify-content: center;
`;

export const Drag = styled.div`
	width: 100%;
	height: 268px;
	border: ${({ theme }) => `1px solid ${theme.colors.system.border.medium}`};
	border-radius: ${({ theme }) => theme.shapeRadius.medium};
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.medium};
	cursor: pointer;

	:hover {
		background-color: ${({ theme }) => theme.colors.system.background.medium};
	}
`;

export const DetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.medium};
`;
