import styled from 'styled-components';

export const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: ${({ theme }) => theme.spacing.medium};
`;

export const TextContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	row-gap: ${({ theme }) => theme.spacing.xs};
`;
