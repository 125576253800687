import React, { useMemo } from 'react';
import NavLinkButton, {
	NavLinkButtonProps,
} from '../NavLinkButton/NavLinkButton';

import * as S from './NavLinkWithSubMenu.styles';
import { DropResult } from 'react-beautiful-dnd';
import { DragDropContainer, DraggableContainer } from '../../layout';
import { Icon } from '../../atoms';
import { useTheme } from 'styled-components';

export type NavLinkWithSubMenuProps = {
	navLink: NavLinkButtonProps;
	isSubMenuOpen: boolean;
	isNavbarCollapsed?: boolean;
	loading: boolean;
	capitalizeTitle?: boolean;
	showMenuCount?: boolean;
	isMenuSortable?: boolean;
	onSortedMenu?: (sortedMenus: NavLinkButtonProps[]) => void;
};
function NavLinkWithSubMenu({
	navLink,
	isSubMenuOpen,
	isNavbarCollapsed,
	loading,
	capitalizeTitle = true,
	showMenuCount = false,
	isMenuSortable = false,
	onSortedMenu,
}: NavLinkWithSubMenuProps) {
	const theme = useTheme();
	const title = useMemo(() => {
		let title = navLink.text;

		if (showMenuCount) {
			title = `${title} (${navLink?.menus?.length})`;
		}

		if (capitalizeTitle) {
			title = title.toUpperCase();
		}
		return title;
	}, [navLink.text, capitalizeTitle, showMenuCount]);

	const handleDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		const items = navLink?.menus || [];
		if (!destination) return;
		// If the item is dropped back in the same position, return early
		if (source.index === destination.index) return;
		// Reorder the items array
		const updatedItems = Array.from(items);
		const [movedItem] = updatedItems.splice(source.index, 1);
		updatedItems.splice(destination.index, 0, movedItem);
		onSortedMenu && onSortedMenu(updatedItems);
	};

	return (
		<S.NavLinkWithSubMenu key={navLink.text}>
			{/* Hide the parent accordion button if the navbar is collapsed or the title is empty*/}
			{!!title && !isNavbarCollapsed && (
				<NavLinkButton
					key={`nav-link-${navLink.text}`}
					{...navLink}
					text={title}
				/>
			)}

			{(isNavbarCollapsed || isSubMenuOpen) && (
				<S.SubMenuWrapper
					isOpen={isSubMenuOpen}
					isNavbarCollapsed={isNavbarCollapsed}
					isSubMenusThreaded={navLink?.isSubMenusThreaded}
					isMenuSortable={isMenuSortable}
				>
					{!isMenuSortable && (
						<>
							{navLink?.menus?.map((menu) => {
								return (
									<NavLinkButton
										key={`nav-sublink-${navLink.text}-${menu.text}`}
										text={menu.text}
										iconName={menu.iconName}
										inactiveIconName={menu.inactiveIconName}
										count={menu.count}
										redirectTo={menu.redirectTo}
										onClick={menu.onClick}
										isDisabled={loading}
										active={menu.active}
										textBadge={menu.textBadge}
										isNavbarCollapsed={isNavbarCollapsed}
									/>
								);
							})}
						</>
					)}
					{isMenuSortable && (
						<DragDropContainer
							droppableId={`navSubMenuDrag-${title}`}
							onDragEnd={(result) => {
								handleDragEnd(result);
							}}
						>
							{navLink?.menus?.map((menu, menuIndex) => {
								return (
									<DraggableContainer
										key={`${menuIndex}-menu-item-index`}
										draggableId={`${menuIndex}-menu-item-index`}
										index={menuIndex}
										className="m-0"
										itemDraggingStyle={{
											overflow: 'visible',
											backgroundColor: theme.colors.system.background.strong,
											borderRadius: theme.shapeRadius.xs,
											height: 'auto',
										}}
									>
										<S.DraggableMenuOption isActive={menu.active}>
											<Icon
												color="neutral.icon.hover"
												iconName="dotsSixVertical"
												size="xxs"
												className="draggable-icon"
											/>
											<NavLinkButton
												key={`nav-sublink-${navLink.text}-${menu.text}`}
												text={menu.text}
												iconName={menu.iconName}
												inactiveIconName={menu.inactiveIconName}
												count={menu.count}
												redirectTo={menu.redirectTo}
												onClick={menu.onClick}
												isDisabled={loading}
												active={menu.active}
												textBadge={menu.textBadge}
												isNavbarCollapsed={isNavbarCollapsed}
												className="draggable-option"
											/>
										</S.DraggableMenuOption>
									</DraggableContainer>
								);
							})}
						</DragDropContainer>
					)}
				</S.SubMenuWrapper>
			)}
		</S.NavLinkWithSubMenu>
	);
}

export default NavLinkWithSubMenu;
