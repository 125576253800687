import styled, { css } from 'styled-components';
import { InputPropsType } from './Input';

export const InputSizes = {
	small: 36,
	medium: 40,
};

export const MainWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const LabelWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

const borderLessStyles = css`
	background: transparent;
	border: none;
	box-shadow: none;

	:hover {
		box-shadow: none;
	}
	:focus,
	:active {
		box-shadow: none;
		border: none;
	}
`;

const baseStyle = css<{
	hasIcon?: boolean;
	hasClearable?: boolean;
	hasError?: boolean;
	hasLeadingText?: boolean;
	disabled?: boolean;
	$variant?: string;
	$borderless?: boolean;
	hasEndingText?: boolean;
}>`
	width: 100%;
	padding-top: 8.5px;
	padding-bottom: 9px;
	padding-left: ${({ hasIcon }) => (hasIcon ? '40px' : '14px')};
	padding-right: ${({ hasClearable }) => (hasClearable ? '40px' : '14px')};
	font-size: ${({ theme }) => theme.fonts.sizes.small};
	line-height: ${({ theme }) => theme.fonts.lineHeight.large};
	background-color: ${({ theme, disabled, $variant }) =>
		disabled
			? theme.colors.system.background.medium
			: $variant === 'secondary'
				? theme.colors.system.background.weak
				: theme.colors.system.background.system};
	border: 1px solid
		${({ theme, hasError }) =>
			hasError
				? theme.colors.error.border.default
				: theme.colors.system.border.medium};
	border-radius: ${({ theme, hasLeadingText, hasEndingText }) =>
		hasLeadingText
			? `0 ${theme.shapeRadius.small} ${theme.shapeRadius.small} 0`
			: hasEndingText
				? `${theme.shapeRadius.small} 0 0 ${theme.shapeRadius.small}`
				: theme.shapeRadius.small};

	transition: ${({ theme }) => theme.transitions.ease01};

	box-shadow: ${({ theme, hasError }) =>
		hasError && theme.shadows.controls.error};

	&:hover {
		box-shadow: ${({ theme, hasError }) =>
			!hasError && theme.shadows.controls.hover};
	}

	&:focus,
	&:active {
		box-shadow: ${({ theme, hasError }) =>
			!hasError && theme.shadows.controls.selected};
		border: 1px solid
			${({ theme, hasError }) =>
				hasError ? 'initial' : theme.colors.primary.border.default};
		outline: none;
	}

	&::placeholder {
		color: ${({ theme }) => theme.colors.system.text.weak};
		font-size: ${({ theme }) => theme.fonts.sizes.small};
	}

	${({ $borderless }) => !!$borderless && borderLessStyles}
`;

export const InputWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
`;

export const Input = styled.input<{
	hasIcon?: boolean;
	hasClearable?: boolean;
	hasError?: boolean;
	hasLeadingText?: boolean;
	disabled?: boolean;
	$size?: InputPropsType['size'];
	$variant?: InputPropsType['variant'];
	$borderless?: boolean;
	hasEndingText?: boolean;
}>`
	height: ${({ $size }) => $size && `${InputSizes[$size]}px`};
	${baseStyle}
`;

export const TextArea = styled.textarea<{
	hasIcon?: boolean;
	hasClearable?: boolean;
	hasError?: boolean;
	disabled?: boolean;
	$variant?: InputPropsType['variant'];
	$borderless?: boolean;
	rows?: number;
}>`
	min-height: ${({ rows }) => (rows ? 'initial' : '112px')};
	resize: none;
	${baseStyle}
`;

export const IconWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 14px;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ClearWrapper = styled.div`
	position: absolute;
	top: 0;
	right: ${({ theme }) => theme.spacing.xs};
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LeadingTextWrapper = styled.div`
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	border: 1px solid ${({ theme }) => theme.colors.system.border.medium};
	border-right: none;
	min-width: 79px;
	border-top-left-radius: ${({ theme }) => theme.shapeRadius.regular};
	border-bottom-left-radius: ${({ theme }) => theme.shapeRadius.regular};
`;

export const EndingTextWrapper = styled.div`
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	border: 1px solid ${({ theme }) => theme.colors.system.border.medium};
	border-left: none;
	min-width: 110px;
	border-top-right-radius: ${({ theme }) => theme.shapeRadius.regular};
	border-bottom-right-radius: ${({ theme }) => theme.shapeRadius.regular};
`;
