import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import {
	addStorytellerToTeamTypes,
	assignPhoneToTeamType,
	createTeamTypes,
	deleteTeamTypes,
	getPhoneNumbersType,
	getTeamByIdTypes,
	getTeamsByStorytellerTypes,
	getTeamsTypes,
	removeStorytellersFromTeamTypes,
	updateStorytellerTeamsTypes,
	removeAudienceFromTeamTypes,
	updateTeamTypes,
} from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import { normalize } from 'normalizr';
import { formatPhoneNumberWithoutCountryCode } from '@app/utils/formatPhoneNumber';
import { PhoneType } from '@app/types/modules/phone';

const team = entityNormalizer('teams');
const pagination = { teams: [team] };

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			teams,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ teams, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

const initialState: GenericEntityState = {
	loading: false,
	teams: {},
	teamsByStorytellers: {},
	currentTeamInDetail: null,
	teamsCount: 0,
	totalUnreads: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: 1,
	},
	teamPhoneNumbers: {
		phoneNumberOptions: [],
		phoneNumbers: [],
		pagination: {
			page: 0,
			pageSize: 0,
			totalPages: 0,
			count: 0,
		},
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		reload: false,
		getPhoneNumbersLoading: false,
		assignPhoneToTeamLoading: false,
		removeAudienceFromTeamLoading: false,
	},
};

export type teamsStateType = typeof initialState;

function getTeamsMapper(state: teamsStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);
	const hasTeams = action.response.teams?.length > 0;

	if (hasTeams) {
		if (!action.response.page || action.response.page === 1) {
			state.teams = normalizedData.entities.teams;
		} else {
			state.teams = {
				...state.teams,
				...normalizedData.entities.teams,
			};
		}
		state.teamsCount = Object.keys(state.teams || {})?.length;

		if (state.pagination) {
			state.pagination.currentPage = action.response.page;
			state.pagination.totalPages = action.response.totalPages;
			state.pagination.count = action.response.count;
		}

		state.totalUnreads = action.response.totalUnreads;
	}
}

function getTeamByIdMapper(state: teamsStateType, action: ResponseAction) {
	state.currentTeamInDetail = action.response.team;
}

function getTeamsByStorytellerMapper(
	state: teamsStateType,
	action: ResponseAction,
) {
	const normalizedData = normalizeResponse(action);
	const storytellerId = action.payload.storytellerId;

	if (action.response.page === 1) {
		state.teamsByStorytellers[storytellerId] = normalizedData.entities.teams;
	} else {
		state.teamsByStorytellers[storytellerId] = {
			...state.teamsByStorytellers[storytellerId],
			...normalizedData.entities.teams,
		};
	}
}

function updateTeamMapper(state: teamsStateType, action: ResponseAction) {
	state.currentTeamInDetail = action.response.team;

	const updatedTeam = action.response.team;

	state.teams[updatedTeam._id] = updatedTeam;
}

function addStorytellerToTeamMapper(
	state: teamsStateType,
	action: ResponseAction,
) {
	const addedToTeam = action.response.team;
	const storytellerId: string = action.payload.storytellerId;
	const teamsByStorytellersCopy = { ...state.teamsByStorytellers };

	state.teamsByStorytellers[storytellerId] = {
		...teamsByStorytellersCopy[storytellerId],
		[addedToTeam._id]: addedToTeam,
	};

	//Add to current team in detail if it's the same team
	if (state.currentTeamInDetail?._id === addedToTeam._id) {
		state.currentTeamInDetail = addedToTeam;
	}
}

function removeStorytellersFromTeamMapper(
	state: teamsStateType,
	action: ResponseAction,
) {
	const removedFromTeam = action.response.team;
	const storytellersId: string[] = action.payload.storytellersId;
	const teamsByStorytellersCopy = { ...state.teamsByStorytellers };

	storytellersId?.forEach((storytellerId) => {
		const copiedStorytellerTeams = teamsByStorytellersCopy[storytellerId];

		if (!!copiedStorytellerTeams) {
			//TODO: Fix this state mutation
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete copiedStorytellerTeams[removedFromTeam._id];
		}

		teamsByStorytellersCopy[storytellerId] = copiedStorytellerTeams;
	});

	state.teamsByStorytellers = teamsByStorytellersCopy;
}

function getPhoneNumbersMapper(state: teamsStateType, action: ResponseAction) {
	state.teamPhoneNumbers.phoneNumbers = action.response.numbers;
	state.teamPhoneNumbers.pagination.page = action.response.page;
	state.teamPhoneNumbers.pagination.pageSize = action.response.pageSize;
	state.teamPhoneNumbers.pagination.totalPages = action.response.totalPages;
	state.teamPhoneNumbers.pagination.count = action.response.count;
	state.teamPhoneNumbers.phoneNumberOptions = action.response.numbers?.map(
		(number: PhoneType) => ({
			label: formatPhoneNumberWithoutCountryCode(number?.phoneNumber),
			value: number,
			icon: number?.supportWhatsapp ? 'whatsapp-active' : undefined,
			iconWeight: 'fill',
		}),
	);
}

function updateStorytellerTeamsMapper(
	state: teamsStateType,
	action: ResponseAction,
) {
	const { teams } = action.response;
	const { storytellerId } = action.payload;

	state.teamsByStorytellers[storytellerId] = teams;
}

function removeAudienceFromTeamMapper(
	state: teamsStateType,
	action: ResponseAction,
) {
	const { team } = action.response;
	const teamId = team?._id;
	const teamAudiences = team?.audiences;

	if (state.teams[teamId]) {
		state.teams[teamId].audiences = teamAudiences;
	}

	if (state.currentTeamInDetail?._id === teamId) {
		state.currentTeamInDetail.audiences = teamAudiences;
	}
}

function assignPhoneToTeamMapper(
	state: teamsStateType,
	action: ResponseAction,
) {
	const { team } = action.response;

	const teamId = team?._id;
	const phone = team?.phoneNumber;

	if (state.teams[teamId]) {
		state.teams[teamId].phoneNumber = phone;
	}

	if (state.currentTeamInDetail?._id === teamId) {
		state.currentTeamInDetail.phoneNumber = phone;
	}
}

const teamsSlice = createSlice({
	name: 'teams',
	initialState,
	reducers: {
		RESET_TEAMS(state: teamsStateType) {
			state.teams = {};
			state.teamsCount = 0;
		},

		CLEAN_TEAM_IN_DETAIL: (state) => {
			state.currentTeamInDetail = null;
		},
		UPDATE_TEAM_UNREAD_COUNT: (state, action) => {
			const { teamId, unreads } = action.payload;

			if (state.teams[teamId]) {
				state.teams[teamId].unreadConversations = unreads;
			}
		},
		UPDATE_TOTAL_UNREADS_COUNT: (state, action) => {
			state.totalUnreads = action.payload;
		},
	},
	extraReducers: {
		...createFetchReducer(getTeamsTypes, getTeamsMapper),
		...createFetchReducer(getTeamByIdTypes, getTeamByIdMapper),
		...createFetchReducer(
			getTeamsByStorytellerTypes,
			getTeamsByStorytellerMapper,
		),
		...createFetchReducer(createTeamTypes),
		...createFetchReducer(deleteTeamTypes),
		...createFetchReducer(updateTeamTypes, updateTeamMapper),
		...createFetchReducer(
			addStorytellerToTeamTypes,
			addStorytellerToTeamMapper,
		),
		...createFetchReducer(
			removeStorytellersFromTeamTypes,
			removeStorytellersFromTeamMapper,
		),
		...createFetchReducer(
			updateStorytellerTeamsTypes,
			updateStorytellerTeamsMapper,
		),
		...createFetchReducer(
			removeAudienceFromTeamTypes,
			removeAudienceFromTeamMapper,
		),
		...createFetchReducer(getPhoneNumbersType, getPhoneNumbersMapper),
		...createFetchReducer(assignPhoneToTeamType, assignPhoneToTeamMapper),
	},
});

export default teamsSlice.reducer;
