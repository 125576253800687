import React from 'react';

import * as S from './CardButton.styles';
import {
	DisplayIcon,
	Icon,
	Text,
} from '@common/design-system/components/atoms';
import { IconWeight } from '@phosphor-icons/react';
import { IconProps } from '../../atoms/Icon/Icon';

type PropsType = {
	onClick: () => void;
	icon?: string;
	useDisplayIcon?: boolean;
	centered?: boolean;
	text?: string;
	subText?: string;
	active?: boolean;
	children?: React.ReactNode;
	disabled?: boolean;
	variant?: 'horizontal' | 'vertical';
	withArrow?: boolean;
	size?: 'small' | 'medium' | 'large' | 'xl';
	iconWeight?: IconWeight;
	iconColor?: IconProps['color'];
	iconSize?: IconProps['size'];
	activeWhite?: boolean;
	className?: string;
	textAlignment?: string;
};

function CardButton({
	onClick,
	icon,
	iconWeight = 'regular',
	iconColor,
	iconSize,
	useDisplayIcon = false,
	centered = true,
	text,
	subText,
	active,
	children,
	disabled,
	variant = 'vertical',
	withArrow,
	size = 'small',
	activeWhite,
	className,
	textAlignment,
}: PropsType) {
	const isVertical = variant === 'vertical';

	//TODO: Change horizontal to use DisplayIcon
	const IconComponent = () =>
		icon && useDisplayIcon ? (
			<DisplayIcon iconName={icon} variant="system" />
		) : icon ? (
			<Icon
				iconName={icon}
				size={iconSize ? iconSize : isVertical ? 'large' : 'medium'}
				color={
					disabled
						? 'system.text.weak'
						: active
							? 'primary.text.hover'
							: iconColor || 'neutral.icon.hover'
				}
				weight={iconWeight}
			/>
		) : null;

	const textColor = disabled
		? 'system.text.weak'
		: active
			? 'primary.text.hover'
			: 'system.text.default';
	const subTextColor = disabled ? 'system.text.weak' : 'system.text.medium';

	return (
		<S.CardButton
			onClick={onClick}
			active={active}
			disabled={disabled}
			variant={variant}
			size={size}
			centered={centered}
			activeWhite={activeWhite}
			className={className}
		>
			{!!icon && <IconComponent />}

			{!!children && <>{children}</>}

			{(!!text || !!subText) && !children && (
				<S.TextContainer
					textAlignment={textAlignment}
					className="texts-container"
				>
					{!!text && !children && (
						<Text
							size="small"
							color={textColor}
							lineHeight="large"
							fontWeight="medium"
						>
							{text}
						</Text>
					)}

					{!!subText && !children && (
						<Text size="small" color={subTextColor} lineHeight="large">
							{subText}
						</Text>
					)}
				</S.TextContainer>
			)}

			{!isVertical && withArrow && (
				<Icon iconName="caretRight" color="neutral.icon.default" />
			)}
		</S.CardButton>
	);
}

export default CardButton;
