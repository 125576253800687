import { colorRegex } from '@app/constants/regex';

export const isValidColorFormat = (color: string) => {
	if (!color || typeof color !== 'string') return false;

	if (color.charAt(0) === '#') {
		return colorRegex.test(color);
	} else {
		return /^(rgb|hsl)a?\((\d+%?(deg|rad|grad|turn)?[,\s]+){2,3}[\s\/]*[\d\.]+%?\)$/i.test(
			color,
		);
	}
};
