import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants/index';
import { fetchAction } from '../types';
import { FilterOptions } from '@app/types/index';
import axios from '@global/axios';
import { replyStates } from './constants';

const mainType = 'REPLY';

export const filterRepliesTypes = createFetchTypes(
	`${mainType}_FILTER_REPLIES`,
);

export const getSingleReplyTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_REPLY`,
);

export const setReplyStateTypes = createFetchTypes(
	`${mainType}_SET_REPLY_STATE`,
);

export const bulkUpdateRepliesTypes = createFetchTypes(
	`${mainType}_BULK_UPDATE`,
);

function filterReplies(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(FilterOptions);

	return {
		type: filterRepliesTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/reply${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'filterReplies',
		},
	};
}

function getSingleReply(replyId: string, FilterOptions: FilterOptions) {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getSingleReplyTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/reply/${replyId}${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			errorMessage: ToastMessage.errorReplyNotFound,
		},
	};
}

function setReplyState(replyId: string, state: string) {
	return {
		type: setReplyStateTypes,
		callAPI: () => axios.patch(`${apiUrl}/reply/${replyId}`, { state }),
		payload: {
			successMessage: state !== replyStates.seen && ToastMessage.updatedReply,
			custom: 'updateReplyState',
			replyId,
			state,
		},
	};
}

function bulkUpdateReplies(replyIds: string[], update: any) {
	let sucessMessage = ToastMessage.updatedReplies;
	if (update.deleted) {
		sucessMessage =
			replyIds.length > 1
				? ToastMessage.severalReplyStoriesDeleted
				: ToastMessage.singleReplyStoryDeleted;
	}
	return {
		type: bulkUpdateRepliesTypes,
		callAPI: () => axios.patch(`${apiUrl}/reply/bulk`, { replyIds, update }),
		payload: {
			successMessage: sucessMessage,
			custom: 'bulkUpdateReplies',
			replyIds,
			update,
		},
	};
}

export { filterReplies, getSingleReply, setReplyState, bulkUpdateReplies };
