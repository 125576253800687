import { SpacingType } from '@common/design-system/global/types';
import styled from 'styled-components';

export const Wrapper = styled.div<{
	onClick?: () => void;
	gapSize?: SpacingType;
	verticalMode?: boolean;
	centered?: boolean;
}>`
	display: inline-flex;
	flex-direction: ${({ verticalMode }) => (verticalMode ? 'column' : 'row')};
	align-items: ${({ centered, verticalMode }) =>
		centered || !verticalMode ? 'center' : 'start'};
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	column-gap: ${({ theme, gapSize }) =>
		gapSize ? theme.spacing[gapSize] : theme.spacing.small};
	cursor: ${({ onClick }) => !!onClick && 'pointer'};
`;

export const AvatarWrapper = styled.div``;

export const InfoWrapper = styled.div<{ centered?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: ${({ centered }) => (centered ? 'center' : 'start')};
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	row-gap: ${({ theme }) => theme.spacing.xs};
`;

export const MainInfoWrapper = styled.div<{
	centered?: boolean;
}>`
	display: flex;
	flex-direction: column;
	justify-content: start;
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	column-gap: ${({ theme }) => theme.spacing.xs};
	row-gap: ${({ theme }) => theme.spacing.xs};
`;
