import styled from 'styled-components';

import { CollapsableContainer } from '@common/design-system/components/layout/CollapsableContainer';

export const NavLinksGroup = styled.div`
	display: flex;
	padding: 0px ${({ theme }) => theme.spacing.small};
	flex-direction: column;
	row-gap: ${({ theme }) => theme.spacing.xxs};
	position: relative;
`;

export const NavLinkBottomGroup = styled(NavLinksGroup)`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.small}`};
	border-top: ${({ theme }) => `1px solid ${theme.colors.system.border.weak}`};
	background-color: ${({ theme }) => theme.colors.system.background.xWeak};
`;
export const LogoContainer = styled.div`
	cursor: pointer;
	width: 90%;
	display: flex;

	&:hover {
		opacity: 0.7;
		transition: ${({ theme }) => theme.transitions.fade};
	}
`;
export const CollapseCloseButton = styled.div`
	display: flex;
	width: 10%;
	cursor: pointer;
`;
export const CollapseOpenButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	transition: ${({ theme }) => theme.transitions.transform};
	padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.xxs} 0`};
	cursor: pointer;
	margin-bottom: ${({ theme }) => theme.spacing.medium};

	&:hover {
		color: ${({ theme }) => theme.colors.system.text.weak} !important;
	}
`;

export const NavigationBarContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	overflow: auto;
	flex: 1;
`;

export const NavigationLinksWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	overflow: auto;
	flex: 1;
	padding-bottom: ${({ theme }) => theme.spacing.large};
	max-height: calc(100vh - 52px - 62px);

	/* Hide scrollbar */
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
`;

export const NavigationBarContainer = styled(CollapsableContainer)`
	position: fixed;
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 100vh;
	box-shadow: ${({ theme }) => theme.shadows.elevation.base};
	z-index: ${({ theme }) => theme.zIndex.fixed};
	background-color: ${({ theme }) => theme.colors.system.background.xWeak};
	min-width: 72px;
`;

export const NavigationBarWrapper = styled(CollapsableContainer)`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	z-index: ${({ theme }) => theme.zIndex.fixed};
	min-width: 72px;
`;

export const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.medium};
	margin-bottom: ${({ theme }) => theme.spacing.medium};
	padding: ${({ theme }) =>
		`${theme.spacing.medium} ${theme.spacing.medium} 0`};

	transition: ${({ theme }) => theme.transitions.transform};
`;
