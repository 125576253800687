import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
	  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
`;

export const PreviewMainContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	min-height: 100vh;
	overflow-y: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxxl}`};
	z-index: ${({ theme }) => theme.zIndex.popover};
	animation: ${fadeIn} 0.15s linear normal;

	#floating-container {
		z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
	}

	.close-btn {
		position: absolute;
		top: 20px; // No theme spacing for this
		right: ${({ theme }) => theme.spacing.large};
		z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
	}
`;

export const PreviewBackground = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => `${theme.colors.system.background.modal}`};
	opacity: 0.35;
	z-index: ${({ theme }) => theme.zIndex.fixed};
`;
