import styled from 'styled-components';

import { DataTestType } from '../../../../app/types';

export const HeaderWrapper = styled.div<DataTestType>`
	position: sticky;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 32px;
	max-height: 64px;
	min-height: 64px;
	background: ${({ theme }) => theme.colors.neutral[0]};
	z-index: ${({ theme }) => theme.zIndex.mid};

	@media (max-width: 1600px) {
		padding: 0 24px;
	}
`;
