import { AvailableMergeTags } from '@app/constants/mergeTags';
import { OptionsType } from '../../DropdownInput/types';

export function cleanTextWithSpaceRemoval(text: string): string {
	return text
		.replace(/&nbsp;/g, ' ')
		.replace(/&amp;/g, '&')
		.replace(/&gt;/g, '>')
		.replace(/&lt;/g, '<');
}

export function clearTagFormatFromEditorText(text: string) {
	return cleanTextWithSpaceRemoval(
		text
			.replace(new RegExp(/\{\{\{/gi), '')
			.replace(new RegExp(/\}\}\}/gi), ''),
		// .replace(new RegExp(/\{\{\{(.*)\}\}\}/))
	);
}

export function addCustomAttributesToMergeTagOptions(
	options: OptionsType[],
	workspace: any,
) {
	const customAttributesArray = workspace?.customAttributes || [];
	if (!customAttributesArray.length) {
		return options;
	}
	const customAttributesOptionsArray: { label: string; value: string }[] = [];
	customAttributesArray.length &&
		customAttributesArray.map((attribute: any) => {
			customAttributesOptionsArray.push({
				label: `${attribute.displayName}`,
				value: `contact.customAttributes.${attribute.name}`,
			});
		});

	return [...options, ...customAttributesOptionsArray];
}

export function replaceMergeToBraceFormat(text: string, workspace: any = []) {
	let editedText = text || '';

	const customAttributes =
		workspace?.customAttributes?.map(
			(customAttribute: { displayName: string; name: string }) => {
				return {
					value: `contact.customAttributes.${customAttribute?.name}`,
					label: customAttribute?.displayName,
				};
			},
		) || [];

	const mergeTags = [
		...AvailableMergeTags.emailSubjectTitle,
		...AvailableMergeTags.action,
		...customAttributes,
	];

	mergeTags.forEach(({ value, label }) => {
		editedText = editedText.replace(
			new RegExp(`{{{${value}}}}`, 'ig'),
			`[${label}]`,
		);
	});

	return editedText;
}

export function checkMergeTagsHasSpacingWarning(text: string) {
	const doubleMergeTagsRegex = /}}}{{{/;
	const prefixRegex = /[a-zA-z]{{{/;
	const suffixRegex = /}}}[a-zA-Z\d]/;

	const doubleTagsMatches = text.match(doubleMergeTagsRegex);
	const charBeforeTagsMatches = text.match(prefixRegex);
	const charAfterTagsMatches = text.match(suffixRegex);

	return (
		!!doubleTagsMatches || !!charBeforeTagsMatches || !!charAfterTagsMatches
	);
}
