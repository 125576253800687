import styled from 'styled-components';

export const IconSelectorContainer = styled.div`
	display: flex;
	width: fit-content;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.medium};
	position: relative;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const IconContainer = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	cursor: pointer;

	:hover {
		background-color: ${({ theme }) => theme.colors.system.background.medium};
	}
`;

export const PickerContainer = styled.div`
	z-index: ${({ theme }) => theme.zIndex.popover};
`;
