import styled from 'styled-components';

const getTextColor = (theme: any, type: string, mode?: string) => {
	const getTextColorByModes = (theme: any, mode?: string) => {
		switch (mode) {
			case 'failed':
				return theme.colors.system.text.default;
			default:
				return theme.colors.system.text.default;
		}
	};

	switch (type) {
		case 'outbound':
			return getTextColorByModes(theme, mode);
		case 'inbound':
			return theme.colors.system.text.default;
		default:
			return theme.colors.system.text.default;
	}
};

const getBoxStylesByType = (theme: any, type: string, mode?: string) => {
	const getBoxColorByModes = (theme: any, mode?: string) => {
		switch (mode) {
			case 'scheduled':
				return theme.colors.system.background.medium;
			case 'failed':
				return theme.colors.warning.background.default;
			case 'reply':
				return theme.colors.accent.background.default;
			case 'received':
				return theme.colors.system.background.system;
			default:
				return theme.colors.primary.background.defaultHover;
		}
	};

	const getBoxBorderByModes = (theme: any, mode?: string) => {
		switch (mode) {
			case 'scheduled':
				return theme.colors.system.border.weak;
			case 'failed':
				return theme.colors.warning.border.weak;
			case 'reply':
				return theme.colors.accent.background.defaultHover;
			case 'received':
				return theme.colors.system.border.weak;
			default:
				return theme.colors.primary.border.weak;
		}
	};

	switch (type) {
		case 'outbound':
			return `
				background-color: ${getBoxColorByModes(theme, mode)};
				border: 1px solid ${getBoxBorderByModes(theme, mode)};
            `;
		case 'inbound':
			return `
                background-color: ${getBoxColorByModes(theme, mode)};
				border: 1px solid ${getBoxBorderByModes(theme, mode)};
            `;
		default:
			return `
                background-color: ${getBoxColorByModes(theme, mode)};
            `;
	}
};

export const MessageBalloonWrapper = styled.div<{
	$type: string;
	$mode?: string;
	$bigImage?: boolean;
}>`
	display: flex;
	width: 100%;
	justify-content: ${({ $type }) =>
		$type === 'outbound' ? 'flex-end' : 'flex-start'};

	.chained-message-container {
		position: relative;
		margin-left: ${({ theme }) => theme.spacing.large};
		margin-top: ${({ theme }) => theme.spacing.xxs};

		.rectangle-corner-wrapper {
			position: absolute;
			top: -9px;
			left: -13px;
		}
	}

	.message-box {
		margin-right: 0;
		max-width: 320px;
		min-width: auto;
		padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
		box-shadow: none;
		margin: 0;
		border-radius: ${({ theme }) => theme.shapeRadius.regular} !important;
		${({ theme, $type, $mode }) => getBoxStylesByType(theme, $type, $mode)};

		&.chained {
			${({ theme, $type, $mode }) =>
				getBoxStylesByType(theme, $type, 'received')};

			.message-text-wrapper {
				.message-title-wrapper {
					font-style: italic;
				}
			}
		}

		.message-body {
			display: flex;
			flex-direction: ${({ $bigImage }) => ($bigImage ? 'column' : 'row')};
			gap: ${({ theme }) => theme.spacing.small};
			align-items: center;

			.message-image-wrapper {
				flex: 0 0 ${({ $bigImage }) => ($bigImage ? '288px' : '48px')};
				height: ${({ $bigImage }) => ($bigImage ? '288px' : '48px')};
				width: ${({ $bigImage }) => ($bigImage ? '288px' : '48px')};
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				border-radius: ${({ theme }) => theme.shapeRadius.xxs};

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			.message-text-wrapper {
				display: flex;
				flex-direction: column;
				gap: ${({ theme }) => theme.spacing.xs};
				width: 100%;

				.message-title-wrapper {
					display: flex;
					align-items: center;
					gap: ${({ theme }) => theme.spacing.xxs};
				}

				.message-header-wrapper {
					display: flex;
					gap: ${({ theme }) => theme.spacing.small};
					align-items: center;

					.message-header-text-wrapper {
						display: flex;
						flex-direction: column;
						row-gap: ${({ theme }) => theme.spacing.xs};
					}
				}

				.message-text {
					font-size: ${({ theme }) => theme.fonts.sizes.small};
					line-height: ${({ theme }) => theme.fonts.lineHeight.large};
					font-weight: ${({ theme }) => theme.fonts.weights.regular};
					color: ${({ theme, $type, $mode }) =>
						getTextColor(theme, $type, $mode)};
					word-break: break-word;
					white-space: pre-line;
				}
			}
		}
	}
`;

export const MessageLeftWrapper = styled.div<{ $type: string }>`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: ${({ $type }) =>
		$type === 'outbound' ? 'flex-end' : 'flex-start'};
	padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
	max-width: 600px;
	row-gap: ${({ theme }) => theme.spacing.xxs};

	.badge-button-wrapper {
		border: none;
		padding: 0;
		margin: 0;
		background-color: transparent;
		appearance: none;
		cursor: pointer;
	}
	.badge-button {
		cursor: pointer;
	}

	.failed-status-info-wrapper {
		display: flex;
		align-items: center;
		gap: ${({ theme }) => theme.spacing.xxs};
	}
`;

export const MessageRightWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
`;

export const MessageDateWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xxs};
`;
