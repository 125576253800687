import styled from 'styled-components';

export const Wrapper = styled.div`
	display: inline-flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.medium};
`;

export const IconWrapper = styled.div``;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${({ theme }) => theme.spacing.xs};
`;
