import { FilterOptions } from '@app/types';
import {
	ConversationCategoryEnum,
	ConversationsType,
} from '@app/types/modules/conversations';

export enum InboxTab {
	INBOX = 'inbox',
	ARCHIVED = 'archived',
	OPTED_OUT = 'opted-out',
	SENT = 'sent',
}

export type getTeamConversationsServiceType = {
	teamId: string;
	filterOptions?: FilterOptions;
	tab?: InboxTab;
};

export type handleConversationArchivedInListServiceType = {
	conversationId: string;
	isArchived: boolean;
	tab: InboxTab;
	conversation: Partial<ConversationsType>;
};

export type handleConversationUnsubscribedServiceType = {
	conversationId: string;
	tab: InboxTab;
	conversation: Partial<ConversationsType>;
};

type BaseMessage = {
	contact: string;
	team: string;
	sendNow: boolean;
	scheduleDate?: string;
	category: ConversationCategoryEnum;
	content?: {
		medias?: {
			url: string;
			type: string;
			status: string;
		}[];
	};
};

type WhatsappTemplateMessage = BaseMessage & {
	content?: never;
	whatsappTemplateId: string;
	category: ConversationCategoryEnum.WHATSAPP;
};

type WhatsappContentMessage = BaseMessage & {
	content: { text: string; useMergeTags?: boolean };
	whatsappTemplateId?: never;
	category: ConversationCategoryEnum.WHATSAPP;
};

export type SMSMessageType = BaseMessage & {
	content: { text: string; useMergeTags?: boolean };
	category: ConversationCategoryEnum.SMS;
};

export type WhatsAppMessageType =
	| WhatsappContentMessage
	| WhatsappTemplateMessage;

export type PreviewSMSMessageType = SMSMessageType & {
	contact?: string;
	toPhoneNumbers: string[];
	sendNow?: never;
};

export type PreviewWhatsappMessageType = WhatsappTemplateMessage & {
	contact?: string;
	toPhoneNumbers: string[];
	sendNow?: never;
};
