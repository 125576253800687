import styled from 'styled-components';

import { CheckboxTypes, CheckboxSizeType } from './types';

function handleCheckboxSize(size: CheckboxSizeType): string {
	switch (size) {
		case 'large':
			return '24px';

		case 'regular':
			return '20px';

		case 'small':
			return '16px';
	}
}

export const CheckboxContainer = styled.div<CheckboxTypes>`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	min-width: ${({ size }) => size && handleCheckboxSize(size)};
	width: ${({ size }) => size && handleCheckboxSize(size)};
	min-height: ${({ size }) => size && handleCheckboxSize(size)};
	height: ${({ size }) => size && handleCheckboxSize(size)};
	transition: ${({ theme }) => theme.transition?.hover};
	border-radius: ${({ theme }) => theme.borderRadius?.smooth};

	box-shadow: ${({ theme, checked, disabled, partiallyChecked }) =>
		!disabled &&
		(checked || partiallyChecked) &&
		`0 0 0 2px ${theme.colors.primary[100]}`};

	border: ${({ theme, disabled, checked, partiallyChecked }) =>
		disabled
			? `1px solid ${theme.colors.neutral[100]}`
			: checked || partiallyChecked
				? `1px solid ${theme.colors.primary[700]}`
				: `1px solid ${theme.colors.neutral[200]}`};

	background: ${({ theme, disabled, checked }) =>
		checked
			? theme.colors.primary[700]
			: disabled
				? theme.colors.neutral[100]
				: theme.colors.neutral[0]};

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&:hover {
		border: ${({ theme, disabled, checked, partiallyChecked }) =>
			!disabled &&
			!checked &&
			!partiallyChecked &&
			`1px solid ${theme.colors.neutral[400]}`};
		box-shadow: ${({ theme, disabled, checked, partiallyChecked }) =>
			!disabled &&
			!checked &&
			!partiallyChecked &&
			`0 0 0 2px ${theme.colors.neutral[100]}`};
	}
`;
