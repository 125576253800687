import styled from 'styled-components';

type PageNumberProps = {
	active: boolean;
};

type ContainerType = {
	display: boolean;
	endOfScreen?: boolean;
};

export const Container = styled.div<ContainerType>`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const PageNumber = styled.div<PageNumberProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme, active }) =>
		active ? theme.colors.primary.text.default : theme.colors.system.text.weak};
	font-weight: ${({ theme }) => theme.fonts.weights.regular};
	font-size: ${({ theme }) => theme.fonts.sizes.xs};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
	cursor: pointer;

	background-color: ${({ theme, active }) =>
		active && theme.colors.primary.background.defaultHover};

	/* & + & {
		margin-left: 4px;
	} */

	&:hover {
		color: ${({ theme }) => theme.colors.primary.text.default};
	}
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: ${({ theme }) => `0 ${theme.spacing.xs}`};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	font-size: ${({ theme }) => theme.fonts.sizes.xs};
	padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
	color: ${({ theme }) => theme.colors.system.text.weak};

	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) =>
			theme.colors.primary.background.defaultHover};
	}
`;

export const Ellipsis = styled.span`
	color: ${({ theme }) => theme.colors.system.text.weak};
	margin: ${({ theme }) => `0 ${theme.spacing.xxs}`};
`;
