import React from 'react';
import { TableMapperType } from '../../types';
import { DisplayIcon } from '@common/design-system/components/atoms';
import styled from 'styled-components';

export const CircleIconCellWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.medium};
`;

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

function CircleIconCell({ mapperField, item }: PropsType) {
	return (
		<CircleIconCellWrapper>
			{item[mapperField.key] &&
				item[mapperField.key].map(
					(icon: { id: string; iconName: string }, index: number) =>
						icon.iconName ? (
							<DisplayIcon
								key={index}
								iconName={icon.iconName}
								iconColor="system.background.system"
								bgColor="primary.background.strong"
								round
							/>
						) : (
							<></>
						),
				)}
		</CircleIconCellWrapper>
	);
}

export default CircleIconCell;
