import { orderTypes } from '../../constants';

export const FIRST_PAGE = 1;

const pagination = {
	page: 1,
	pageSize: 500,
};

export const CampaignDropdownFilter = {
	filter: [
		{
			field: 'state',
			value: 'active',
		},
	],
	order: { field: 'createdAt', type: orderTypes.descending },
	pagination,
	select: ['name', 'state', 'personalization'],
};

export const CTADropdownFilter = {
	pagination,
	order: { field: 'createdAt', type: orderTypes.descending },
	select: ['title'],
};

export const StorytellerDropdownFilter = {
	pagination,
	select: ['fullName', 'firstName', 'lastName', 'profilePicture'],
};
export const TagDropdownFilter = {
	pagination,
};
export const AudienceDropdownFilter = {
	pagination,
};

export const storyPopulateObject = [
	{ field: 'assignedTo', select: ['firstName', 'lastName', 'fullName'] },
	{ field: 'callToAction', select: ['title'] },
	{
		field: 'video',
		select: [
			'streamingUrl',
			'thumbnail',
			'captions',
			'duration',
			'createdAt',
			'createdBy',
			'topics',
			'labels',
			'emotions',
			'sentiments',
			'languages',
			'azureId',
			'uuid',
			'status',
			'mediaVersion',
			'location',
			'moderation',
			'hideCaptions',
		],
	},
	{ field: 'createdBy', select: ['firstName', 'lastName', 'fullName'] },
	{
		field: 'contacts',
		select: ['firstName', 'lastName', 'fullName', 'stats', 'email', 'tags'],
	},
	{ field: 'campaign', select: ['name', 'noInsights'] },
];
