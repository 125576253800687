import { SizeType } from '@app/types';
import styled from 'styled-components';

const getButtonDimensions = (size: SizeType): string => {
	switch (size) {
		case 'large':
			return '28px';

		case 'small':
			return '18px';

		default:
		case 'medium':
			return '24px';
	}
};

export const CloseButtonContainer = styled.button<{
	size: SizeType;
	color: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	border-radius: ${({ theme }) => theme.borderRadius?.fullRounded};
	width: ${({ size }) => getButtonDimensions(size)};
	height: ${({ size }) => getButtonDimensions(size)};
	transition: ${({ theme }) => theme.transition?.all};
	background: ${({ color }) => color};
	padding: 0;

	&:hover {
		filter: brightness(90%);
	}
`;
