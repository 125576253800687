import { schema, Schema } from 'normalizr';
import dateFormatterStrategy from './date-formatter';

function entityNormalizer(
	entity: string | symbol,
	definition: Schema = {},
	{
		idAttribute,
		processStrategy = null,
		mergeStrategy,
		excludeTime = false,
	}: {
		idAttribute?: any;
		mergeStrategy?: any;
		processStrategy?: any;
		excludeTime?: boolean;
	} = {},
) {
	return new schema.Entity(entity, definition, {
		processStrategy: (value: any, parent: any, key: string) => {
			if (processStrategy) {
				return {
					...processStrategy(value, parent, key),
					...dateFormatterStrategy(value, parent, key, excludeTime),
				};
			}
			return {
				...value,
				...dateFormatterStrategy(value, parent, key, excludeTime),
			};
		},
		mergeStrategy: mergeStrategy || undefined,
		idAttribute: idAttribute || '_id',
	});
}

export default entityNormalizer;
