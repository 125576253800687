import React from 'react';
import { LogoImage } from './Logo.styles';
import { IconEnum } from '@global/icons/customIconsConstant';

export type LogoType = {
	noText?: boolean;
};

const Logo = ({ noText }: LogoType) => {
	return (
		<LogoImage
			src={noText ? IconEnum['gklogo'] : IconEnum['goodkindlogo']}
			alt="Goodkind logo"
			noText={noText}
		/>
	);
};

export default Logo;
