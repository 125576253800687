import searchImage from './search.svg';
import boxImage from './box.svg';
import sendImage from './send.svg';
import errorImage from './error.svg';
import warningImage from './warning.svg';
import mailImage from './mail.svg';
import rocketLaunch from './rocket-launch.svg';

export type PropTypes = {
	search?: string;
	box?: string;
	send?: string;
	error?: string;
	warning?: string;
	mail?: string;
	rocketLaunch?: string;
};

const Images: PropTypes = {
	box: boxImage,
	error: errorImage,
	search: searchImage,
	send: sendImage,
	warning: warningImage,
	mail: mailImage,
	rocketLaunch: rocketLaunch,
};

export default Images;

export type { PropTypes as ImageType };
