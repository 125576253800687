import React, { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import styled, { useTheme } from 'styled-components';

const DraggableContent = styled.div`
	.drag-icon {
		cursor: grab;
	}
`;

export type DraggableContainerProps = {
	children: React.ReactNode;
	draggableId: string;
	index: number;
	className?: string;
	style?: React.CSSProperties;
	itemDraggingStyle?: React.CSSProperties;
};

export default function DraggableContainer({
	children,
	draggableId,
	index,
	className,
	style,
	itemDraggingStyle,
}: DraggableContainerProps) {
	const theme = useTheme();

	const getItemStyle = useCallback(
		(isDragging: any, draggableStyle: any) => ({
			// some basic styles to make the items look a bit nicer
			userSelect: 'none',
			cursor: 'default',
			marginBottom: theme.spacing?.medium || 'none',

			// styles we need to apply on draggables
			...draggableStyle,
			// overide initial draggable styles
			...style,

			// external styles to apply on draggables when dragging
			...(isDragging && itemDraggingStyle),
		}),
		[itemDraggingStyle],
	);

	return (
		<Draggable draggableId={draggableId} index={index}>
			{(provided, snapshot) => (
				<DraggableContent
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={className}
					style={getItemStyle(
						snapshot.isDragging,
						provided.draggableProps.style,
					)}
				>
					{children}
				</DraggableContent>
			)}
		</Draggable>
	);
}
