import styled from 'styled-components';

import { Icon } from '../../presentation/Icon';

export const StyledAnchor = styled.a`
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const IntegrationIcon = styled(Icon)`
	opacity: 0.7;

	&:hover {
		opacity: 1;
	}
`;
