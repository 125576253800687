import { createSlice, Action } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '@app/utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	editStoriesTypes,
	getApprovalsTypes,
	bulkApproveTypes,
	bulkDeleteTypes,
	bulkRejectTypes,
	getSingleStoryTypes,
} from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import { CaptionsType } from '@common/components/presentation/VideoCard/types';

const story = entityNormalizer('stories', {}, { excludeTime: true });

const pagination = { stories: [story] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	stories: {},
	singleStory: null,
	storiesCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		reload: false,
	},
};

export type storiesStateType = typeof initialState;

function paginationMapper(stories: any, state: storiesStateType) {
	state.stories = { ...stories };
}

function filterMapper(stories: any, state: storiesStateType) {
	if (state.pagination && state.pagination.currentPage === 1) {
		return paginationMapper(stories, state);
	}
	state.stories = { ...state.stories, ...stories };
}

function approvalPaginationMapper(
	state: storiesStateType,
	action: ResponseAction,
	cb: (stories: any, state: storiesStateType) => void,
) {
	const {
		response: {
			stories,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const normalizedData = normalize(
		{ stories, page, pageSize, totalPages, count },
		pagination,
	);

	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.stories;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count =
			Object.keys(normalizedData.entities.stories || {}).length *
			action.response.page;
	}

	state.storiesCount = action.response.count;

	if (state.custom) state.custom.reload = false;
	cb(normalizedData.entities.stories, state);
}

function bulkActionSuccessMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const {
		response: { stories },
	} = action;
	stories.map((story: string) => {
		const { [story]: omittedStory, ...storiesRest } = state.stories;
		state.stories = storiesRest;
	});

	if (state.custom) state.custom.reload = true;
}

function getSingleStoryMapper(state: storiesStateType, action: ResponseAction) {
	const newStory = action.response.story;

	if (newStory?.assignedTo && !newStory.assignedTo.fullName) {
		newStory.assignedTo.fullName = `${newStory.assignedTo.firstName} ${newStory.assignedTo.lastName}`;
	}

	state.singleStory = newStory;
}
const approvalSlice = createSlice({
	name: 'approval',
	initialState,
	reducers: {
		UPDATE_VIDEO_CAPTIONS(
			state: storiesStateType,
			action: {
				payload: { approvalVideoId: string; captions: CaptionsType[] };
			},
		) {
			const { approvalVideoId, captions } = action.payload;
			const [approvalId]: any = Object.entries(state.stories).find(
				([approvalId, approval]: [string, any]) =>
					approval.video._id === approvalVideoId,
			);
			state.stories[approvalId].video.captions = captions;
		},
		UPDATE_VIDEO_FIELD(
			state: storiesStateType,
			action: { payload: { approvalId: string; field: string; value: any } },
		) {
			const { approvalId, field, value } = action.payload;
			if (state?.stories[approvalId]?.video) {
				state.stories[approvalId].video[field] = value;
				state.stories = { ...state.stories };
			}
		},
		CLEAR_APPROVALS(state: storiesStateType) {
			state.stories = {};
			state.storiesCount = 0;
			state.pagination = {
				pages: {},
				currentPage: 1,
				totalPages: null,
			};
		},
		CLEAR_SINGLE_STORY(state: storiesStateType) {
			state.singleStory = null;
		},
	},
	extraReducers: {
		...createFetchReducer(editStoriesTypes),
		...createFetchReducer(
			getApprovalsTypes,
			(state: storiesStateType, action) =>
				approvalPaginationMapper(state, action, filterMapper),
		),
		STORY_CLEAR_ERROR: (state: storiesStateType, action: Action) => {
			state.error = null;
		},
		...createFetchReducer(bulkApproveTypes, bulkActionSuccessMapper),
		...createFetchReducer(bulkDeleteTypes, bulkActionSuccessMapper),
		...createFetchReducer(bulkRejectTypes, bulkActionSuccessMapper),
		...createFetchReducer(getSingleStoryTypes, getSingleStoryMapper),
	},
});

export default approvalSlice.reducer;
