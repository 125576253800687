function dateFormatterStrategy(
	value: any,
	parent: any,
	key: string,
	excludeTime: boolean,
) {
	const createdAtDate = new Date(value.createdAt);

	const formattedCreatedAt = `${createdAtDate.toLocaleString('default', {
		month: 'long',
	})} ${createdAtDate.getDate()}, ${createdAtDate.getFullYear()} ${
		excludeTime ? '' : createdAtDate.toLocaleTimeString()
	}`.trim();

	const updatedAtDate = new Date(value.updatedAt);

	const formattedUpdatedAt = value.updatedAt
		? `${updatedAtDate.toLocaleString('default', {
				month: 'long',
			})} ${updatedAtDate.getDate()}, ${updatedAtDate.getFullYear()} ${
				excludeTime ? '' : updatedAtDate.toLocaleTimeString()
			}`.trim()
		: undefined;

	return { ...value, formattedUpdatedAt, formattedCreatedAt };
}

export default dateFormatterStrategy;
