import styled from 'styled-components';

import { InputContainer, StyledInput } from './Input.styles';

import { TextAreaProps } from './types';

export const TextAreaContainer = styled(InputContainer)`
	position: relative;
	height: auto;
`;

export const SearchIconContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 12px;

	svg {
		transition: none;
	}
`;

export const StyledTextArea = styled(StyledInput).attrs({
	as: 'textarea',
})<TextAreaProps>`
	padding: 12px;
	line-height: 24px;
	min-height: ${({ height }) => height || '120px'};
	resize: none;
	font-family: ${({ theme }) => theme.fonts.default};

	&:focus,
	&:not(:placeholder-shown):not(:focus) {
		padding-top: ${({ hasLabel }) => hasLabel && '20px'};
	}

	&::placeholder {
		font-size: 14px;
	}

	&::-webkit-scrollbar {
		display: none;
	}
`;
