import styled, { css } from 'styled-components';
import { Text } from '@common/design-system/components/atoms';

export const ListTitleWrapper = styled.div`
	height: 32px;
	flex: 0 0 32px;
	width: 100%;
	display: flex;
	align-items: center;
	padding: ${({ theme }) =>
		`${theme.spacing.xxs} 14px 0`}; //TODO: review 14px sizing using on theme
`;
export const MenuWrapper = styled.div<{
	minHeight?: string;
	minWidth?: string;
	maxWidth?: string;
	maxHeight?: string;
}>`
	width: 100%;
	max-width: ${({ maxWidth }) => maxWidth || '288px'};
	min-width: ${({ minWidth }) => minWidth || '200px'};
	min-height: ${({ minHeight }) => minHeight || 'auto'};
	max-height: ${({ maxHeight }) => maxHeight || '300px'};
	overflow-y: auto;
	box-shadow: ${({ theme }) => theme.shadows.elevation.medium};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	z-index: ${({ theme }) => theme.zIndex.popover};
	position: relative;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxxs};
`;

export const SearchWrapper = styled.div`
	width: 100%;
	display: flex;
	padding: ${({ theme }) => theme.spacing.xxs};
	flex-direction: column;
`;

export const LoaderWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${({ theme }) => theme.spacing.xxs};
`;

export const FeedbackWrapper = styled.div`
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${({ theme }) => theme.spacing.xxs};
`;

export const MenuListWrapper = styled.div<{ children: any }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxxs};
	overflow: auto;
	padding: ${({ theme }) => theme.spacing.xxs};
`;

export const TriggerWrapper = styled.div`
	display: block;
	width: max-content;
	cursor: pointer;
`;

export const MenuItemWrapper = styled.div<{
	disabled?: boolean;
	active?: boolean;
}>`
	width: 100%;
	min-width: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing.xs};
	padding: ${({ theme }) =>
		`${theme.spacing.xs} 10px`}; //TODO: review 10px sizing using on theme
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	cursor: pointer;
	min-height: 40px;
	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}

	:hover {
		background-color: ${({ theme }) => theme.colors.system.background.weak};
	}

	.check-icon-wrapper {
		margin-left: auto;
	}
`;

export const MenuItemListWrapper = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing.small};
	align-items: center;
`;

export const Title = styled(Text)<{ isEllipsis?: boolean }>`
	${({ isEllipsis }) =>
		isEllipsis &&
		`
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-line-clamp: 1;
	`};
`;

export const Description = styled(Text)`
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-word;
	-webkit-line-clamp: 3;
	overflow: hidden;
`;

export const IconContainer = styled.div`
	min-width: 20px;
`;
