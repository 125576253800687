import styled from 'styled-components';

import { Typography } from '../styles';

export const H2Typography = styled(Typography).attrs({
	as: 'h2',
})`
	line-height: 24px;
	font-size: ${({ theme }) => theme.fontSizes?.h2};
	font-weight: 600;
`;
