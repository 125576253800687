import styled from 'styled-components';
import { Text } from '../../atoms';

type WidgetProps = {
	show?: boolean;
	captionsOnly?: boolean;
};

export const EditorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	height: 100%;
`;

export const EditorContainer = styled.div<WidgetProps>`
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	display: flex;
	flex-direction: column;
	position: relative;
	top: 0;
	right: 0;
	width: 100%;
	transition: opacity ${({ theme }) => theme.transition?.short};
	min-height: 160px;
`;

export const SpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-top: 16px;
`;

export const StyledTiny = styled(Text)`
	color: ${({ theme }) => theme.colors.system.text.weak};
`;

export const DropdownContainer = styled.div`
	margin-left: auto;
	width: 200px;
	margin-bottom: 8px;
`;

export const BottomBar = styled.div`
	height: 35px;
	width: 100%;
	border-top: 1px solid ${({ theme }) => theme.colors.system.border.weak};
	border-radius: ${({ theme: { borderRadius: { smooth } = {} } }) =>
		`0 0 ${smooth} ${smooth}`};
	position: absolute;
	background: ${({ theme }) => theme.colors.system.background.system};
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CaptionsSavedMessageContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const CaptionsSavedMessage = styled(Text)`
	color: ${({ theme: { colors } }) => colors.neutral[50]};
	font-size: 14px;
`;

export const EmptyContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 96px;
	width: 100%;
	margin-top: 8px;
	font-size: 12px;
	font-weight: 500;
	color: ${({ theme: { colors } }) => colors.neutral[700]};
	background: #f2f2f2;
	border-radius: ${({ theme: { borderRadius } }) => borderRadius?.default};
`;

export const ProfilePicture = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-right: 12px;

	img {
		width: 100%;
	}
`;

export const LabelContainer = styled.div``;

export const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 16px;
`;

export const LabelWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;
