export const COUNTRY_REGION_MAPPING: { [countryCode: string]: string } = {
	AF: 'ireland',
	AL: 'ireland',
	DZ: 'ireland',
	AS: 'virginia',
	AD: 'ireland',
	AO: 'ireland',
	AI: 'virginia',
	AQ: 'virginia',
	AG: 'virginia',
	AR: 'virginia',
	AM: 'ireland',
	AW: 'virginia',
	AU: 'ireland',
	'AU-ACT': 'virginia',
	'AU-NSW': 'virginia',
	'AU-NT': 'ireland',
	'AU-QLD': 'virginia',
	'AU-SA': 'ireland',
	'AU-TAS': 'virginia',
	'AU-VIC': 'virginia',
	'AU-WA': 'ireland',
	AT: 'ireland',
	AZ: 'ireland',
	BS: 'virginia',
	BH: 'ireland',
	'BH-13': 'ireland',
	'BH-15': 'ireland',
	'BH-17': 'ireland',
	'BH-19': 'ireland',
	BD: 'ireland',
	BB: 'virginia',
	BY: 'ireland',
	BE: 'ireland',
	'BE-BRU': 'ireland',
	'BE-VLG': 'ireland',
	'BE-WAL': 'ireland',
	BZ: 'virginia',
	BJ: 'ireland',
	BM: 'virginia',
	BT: 'ireland',
	BO: 'virginia',
	BQ: 'virginia',
	BA: 'ireland',
	BW: 'ireland',
	BV: 'ireland',
	BR: 'virginia',
	'BR-AC': 'virginia',
	'BR-AL': 'virginia',
	'BR-AM': 'virginia',
	'BR-AP': 'virginia',
	'BR-BA': 'virginia',
	'BR-CE': 'virginia',
	'BR-DF': 'virginia',
	'BR-ES': 'virginia',
	'BR-GO': 'virginia',
	'BR-MA': 'virginia',
	'BR-MG': 'virginia',
	'BR-MS': 'virginia',
	'BR-MT': 'virginia',
	'BR-PA': 'virginia',
	'BR-PB': 'virginia',
	'BR-PE': 'virginia',
	'BR-PI': 'virginia',
	'BR-PR': 'virginia',
	'BR-RJ': 'virginia',
	'BR-RN': 'virginia',
	'BR-RO': 'virginia',
	'BR-RR': 'virginia',
	'BR-RS': 'virginia',
	'BR-SC': 'virginia',
	'BR-SE': 'virginia',
	'BR-SP': 'virginia',
	'BR-TO': 'virginia',
	IO: 'ireland',
	BN: 'ireland',
	BG: 'ireland',
	BF: 'ireland',
	BI: 'ireland',
	CV: 'ireland',
	KH: 'ireland',
	CM: 'ireland',
	CA: 'virginia',
	'CA-AB': 'virginia',
	'CA-BC': 'virginia',
	'CA-MB': 'virginia',
	'CA-NB': 'virginia',
	'CA-NL': 'virginia',
	'CA-NS': 'virginia',
	'CA-NT': 'virginia',
	'CA-NU': 'virginia',
	'CA-ON': 'virginia',
	'CA-PE': 'virginia',
	'CA-QC': 'virginia',
	'CA-SK': 'virginia',
	'CA-YT': 'virginia',
	KY: 'virginia',
	CF: 'ireland',
	TD: 'ireland',
	CL: 'virginia',
	CN: 'ireland',
	'CN-11': 'ireland',
	'CN-12': 'ireland',
	'CN-13': 'ireland',
	'CN-14': 'ireland',
	'CN-15': 'ireland',
	'CN-21': 'ireland',
	'CN-22': 'ireland',
	'CN-23': 'ireland',
	'CN-31': 'ireland',
	'CN-32': 'ireland',
	'CN-33': 'ireland',
	'CN-34': 'ireland',
	'CN-35': 'ireland',
	'CN-36': 'ireland',
	'CN-37': 'ireland',
	'CN-41': 'ireland',
	'CN-42': 'ireland',
	'CN-43': 'ireland',
	'CN-44': 'ireland',
	'CN-45': 'ireland',
	'CN-46': 'ireland',
	'CN-50': 'ireland',
	'CN-51': 'ireland',
	'CN-52': 'ireland',
	'CN-53': 'ireland',
	'CN-54': 'ireland',
	'CN-61': 'ireland',
	'CN-62': 'ireland',
	'CN-63': 'ireland',
	'CN-64': 'ireland',
	'CN-65': 'ireland',
	CX: 'ireland',
	Keeling: 'ireland',
	CO: 'virginia',
	KM: 'ireland',
	CG: 'ireland',
	CD: 'ireland',
	CK: 'virginia',
	CR: 'virginia',
	HR: 'ireland',
	CU: 'virginia',
	CW: 'virginia',
	CY: 'ireland',
	CZ: 'ireland',
	CI: 'ireland',
	DK: 'ireland',
	DJ: 'ireland',
	DM: 'virginia',
	DO: 'virginia',
	EC: 'virginia',
	EG: 'ireland',
	SV: 'virginia',
	GQ: 'ireland',
	ER: 'ireland',
	EE: 'ireland',
	ET: 'ireland',
	Malvinas: 'virginia',
	FO: 'ireland',
	FJ: 'virginia',
	FI: 'ireland',
	'FI-02': 'ireland',
	'FI-03': 'ireland',
	'FI-04': 'ireland',
	'FI-05': 'ireland',
	'FI-06': 'ireland',
	'FI-07': 'ireland',
	'FI-08': 'ireland',
	'FI-09': 'ireland',
	'FI-10': 'ireland',
	'FI-11': 'ireland',
	'FI-12': 'ireland',
	'FI-13': 'ireland',
	'FI-14': 'ireland',
	'FI-15': 'ireland',
	'FI-16': 'ireland',
	'FI-17': 'ireland',
	'FI-18': 'ireland',
	'FI-19': 'ireland',
	FR: 'ireland',
	'FR-ARA': 'ireland',
	'FR-BFC': 'ireland',
	'FR-E': 'ireland',
	'FR-F': 'ireland',
	'FR-GES': 'ireland',
	'FR-H': 'ireland',
	'FR-HDF': 'ireland',
	'FR-J': 'ireland',
	'FR-NAQ': 'ireland',
	'FR-NOR': 'ireland',
	'FR-OCC': 'ireland',
	'FR-R': 'ireland',
	'FR-U': 'ireland',
	GF: 'virginia',
	PF: 'virginia',
	TF: 'ireland',
	GA: 'ireland',
	GM: 'ireland',
	GE: 'ireland',
	DE: 'ireland',
	'DE-BB': 'ireland',
	'DE-BE': 'ireland',
	'DE-BW': 'ireland',
	'DE-BY': 'ireland',
	'DE-HB': 'ireland',
	'DE-HE': 'ireland',
	'DE-HH': 'ireland',
	'DE-MV': 'ireland',
	'DE-NI': 'ireland',
	'DE-NW': 'ireland',
	'DE-RP': 'ireland',
	'DE-SH': 'ireland',
	'DE-SL': 'ireland',
	'DE-SN': 'ireland',
	'DE-ST': 'ireland',
	'DE-TH': 'ireland',
	GH: 'ireland',
	GI: 'ireland',
	GR: 'ireland',
	GL: 'ireland',
	GD: 'virginia',
	GP: 'virginia',
	GU: 'ireland',
	GT: 'virginia',
	GG: 'ireland',
	GN: 'ireland',
	GW: 'ireland',
	GY: 'virginia',
	HT: 'virginia',
	HM: 'ireland',
	VA: 'ireland',
	HN: 'virginia',
	HK: 'ireland',
	HU: 'ireland',
	IS: 'ireland',
	IN: 'ireland',
	'IN-AN': 'ireland',
	'IN-AP': 'ireland',
	'IN-AR': 'ireland',
	'IN-AS': 'ireland',
	'IN-BR': 'ireland',
	'IN-CH': 'ireland',
	'IN-CT': 'ireland',
	'IN-DD': 'ireland',
	'IN-DL': 'ireland',
	'IN-DN': 'ireland',
	'IN-GA': 'ireland',
	'IN-GJ': 'ireland',
	'IN-HP': 'ireland',
	'IN-HR': 'ireland',
	'IN-JH': 'ireland',
	'IN-JK': 'ireland',
	'IN-KA': 'ireland',
	'IN-KL': 'ireland',
	'IN-LD': 'ireland',
	'IN-MH': 'ireland',
	'IN-ML': 'ireland',
	'IN-MN': 'ireland',
	'IN-MP': 'ireland',
	'IN-MZ': 'ireland',
	'IN-NL': 'ireland',
	'IN-OR': 'ireland',
	'IN-PB': 'ireland',
	'IN-PY': 'ireland',
	'IN-RJ': 'ireland',
	'IN-SK': 'ireland',
	'IN-TG': 'ireland',
	'IN-TN': 'ireland',
	'IN-TR': 'ireland',
	'IN-UL': 'ireland',
	'IN-UP': 'ireland',
	'IN-WB': 'ireland',
	ID: 'ireland',
	'ID-AC': 'ireland',
	'ID-BA': 'ireland',
	'ID-BB': 'ireland',
	'ID-BE': 'ireland',
	'ID-BT': 'ireland',
	'ID-GO': 'ireland',
	'ID-JA': 'ireland',
	'ID-JB': 'ireland',
	'ID-JI': 'ireland',
	'ID-JK': 'ireland',
	'ID-JT': 'ireland',
	'ID-KB': 'ireland',
	'ID-KI': 'ireland',
	'ID-KR': 'ireland',
	'ID-KS': 'ireland',
	'ID-KT': 'ireland',
	'ID-KU': 'ireland',
	'ID-LA': 'ireland',
	'ID-MA': 'ireland',
	'ID-MU': 'ireland',
	'ID-NB': 'ireland',
	'ID-NT': 'ireland',
	'ID-PA': 'ireland',
	'ID-RI': 'ireland',
	'ID-SA': 'ireland',
	'ID-SB': 'ireland',
	'ID-SG': 'ireland',
	'ID-SN': 'ireland',
	'ID-SR': 'ireland',
	'ID-SS': 'ireland',
	'ID-ST': 'ireland',
	'ID-SU': 'ireland',
	'ID-YO': 'ireland',
	IR: 'ireland',
	IQ: 'ireland',
	IE: 'ireland',
	'IE-C': 'ireland',
	'IE-L': 'ireland',
	'IE-M': 'ireland',
	'IE-U': 'ireland',
	IM: 'ireland',
	IL: 'ireland',
	IT: 'ireland',
	'IT-21': 'ireland',
	'IT-23': 'ireland',
	'IT-25': 'ireland',
	'IT-32': 'ireland',
	'IT-34': 'ireland',
	'IT-36': 'ireland',
	'IT-42': 'ireland',
	'IT-45': 'ireland',
	'IT-52': 'ireland',
	'IT-55': 'ireland',
	'IT-57': 'ireland',
	'IT-62': 'ireland',
	'IT-65': 'ireland',
	'IT-67': 'ireland',
	'IT-72': 'ireland',
	'IT-75': 'ireland',
	'IT-77': 'ireland',
	'IT-78': 'ireland',
	'IT-82': 'ireland',
	'IT-88': 'ireland',
	JM: 'virginia',
	JP: 'ireland',
	'JP-01': 'ireland',
	'JP-02': 'ireland',
	'JP-03': 'ireland',
	'JP-04': 'ireland',
	'JP-05': 'ireland',
	'JP-06': 'ireland',
	'JP-07': 'ireland',
	'JP-08': 'ireland',
	'JP-09': 'ireland',
	'JP-10': 'ireland',
	'JP-11': 'ireland',
	'JP-12': 'ireland',
	'JP-13': 'ireland',
	'JP-14': 'ireland',
	'JP-15': 'ireland',
	'JP-16': 'ireland',
	'JP-17': 'ireland',
	'JP-18': 'ireland',
	'JP-19': 'ireland',
	'JP-20': 'ireland',
	'JP-21': 'ireland',
	'JP-22': 'ireland',
	'JP-23': 'ireland',
	'JP-24': 'ireland',
	'JP-25': 'ireland',
	'JP-26': 'ireland',
	'JP-27': 'ireland',
	'JP-28': 'ireland',
	'JP-29': 'ireland',
	'JP-30': 'ireland',
	'JP-31': 'ireland',
	'JP-32': 'ireland',
	'JP-33': 'ireland',
	'JP-34': 'ireland',
	'JP-35': 'ireland',
	'JP-36': 'ireland',
	'JP-37': 'ireland',
	'JP-38': 'ireland',
	'JP-39': 'ireland',
	'JP-40': 'ireland',
	'JP-41': 'ireland',
	'JP-42': 'ireland',
	'JP-43': 'ireland',
	'JP-44': 'ireland',
	'JP-45': 'ireland',
	'JP-46': 'ireland',
	'JP-47': 'ireland',
	JE: 'ireland',
	JO: 'ireland',
	KZ: 'ireland',
	KE: 'ireland',
	KI: 'virginia',
	KP: 'ireland',
	KR: 'ireland',
	'KR-11': 'ireland',
	'KR-22': 'ireland',
	'KR-26': 'ireland',
	'KR-27': 'ireland',
	'KR-28': 'ireland',
	'KR-29': 'ireland',
	'KR-30': 'ireland',
	'KR-31': 'ireland',
	'KR-41': 'ireland',
	'KR-42': 'ireland',
	'KR-43': 'ireland',
	'KR-44': 'ireland',
	'KR-45': 'ireland',
	'KR-46': 'ireland',
	'KR-47': 'ireland',
	'KR-48': 'ireland',
	'KR-49': 'ireland',
	KW: 'ireland',
	KG: 'ireland',
	LA: 'ireland',
	LV: 'ireland',
	LB: 'ireland',
	LS: 'ireland',
	LR: 'ireland',
	LY: 'ireland',
	LI: 'ireland',
	LT: 'ireland',
	LU: 'ireland',
	MO: 'ireland',
	MK: 'ireland',
	MG: 'ireland',
	MW: 'ireland',
	MY: 'ireland',
	MV: 'ireland',
	ML: 'ireland',
	MT: 'ireland',
	MH: 'virginia',
	MQ: 'virginia',
	MR: 'ireland',
	MU: 'ireland',
	YT: 'ireland',
	MX: 'virginia',
	FM: 'virginia',
	MD: 'ireland',
	MC: 'ireland',
	MN: 'ireland',
	ME: 'ireland',
	MS: 'virginia',
	MA: 'ireland',
	MZ: 'ireland',
	MM: 'ireland',
	NA: 'ireland',
	NR: 'virginia',
	NP: 'ireland',
	NL: 'ireland',
	'NL-DR': 'ireland',
	'NL-FL': 'ireland',
	'NL-FR': 'ireland',
	'NL-GE': 'ireland',
	'NL-GR': 'ireland',
	'NL-LI': 'ireland',
	'NL-NB': 'ireland',
	'NL-NH': 'ireland',
	'NL-OV': 'ireland',
	'NL-UT': 'ireland',
	'NL-ZE': 'ireland',
	'NL-ZH': 'ireland',
	NC: 'virginia',
	NZ: 'virginia',
	NI: 'virginia',
	NE: 'ireland',
	NG: 'ireland',
	NU: 'virginia',
	NF: 'virginia',
	MP: 'ireland',
	NO: 'ireland',
	'NO-01': 'ireland',
	'NO-02': 'ireland',
	'NO-03': 'ireland',
	'NO-04': 'ireland',
	'NO-05': 'ireland',
	'NO-06': 'ireland',
	'NO-07': 'ireland',
	'NO-08': 'ireland',
	'NO-09': 'ireland',
	'NO-10': 'ireland',
	'NO-11': 'ireland',
	'NO-12': 'ireland',
	'NO-14': 'ireland',
	'NO-15': 'ireland',
	'NO-16': 'ireland',
	'NO-17': 'ireland',
	'NO-18': 'ireland',
	'NO-19': 'ireland',
	'NO-20': 'ireland',
	OM: 'ireland',
	PK: 'ireland',
	PW: 'ireland',
	PS: 'ireland',
	PA: 'virginia',
	PG: 'ireland',
	PY: 'virginia',
	PE: 'virginia',
	PH: 'ireland',
	PN: 'virginia',
	PL: 'ireland',
	PT: 'ireland',
	PR: 'virginia',
	QA: 'ireland',
	RO: 'ireland',
	RU: 'ireland',
	RW: 'ireland',
	RE: 'ireland',
	BL: 'virginia',
	SH: 'ireland',
	KN: 'virginia',
	LC: 'virginia',
	'French part': 'virginia',
	PM: 'virginia',
	VC: 'virginia',
	WS: 'virginia',
	SM: 'ireland',
	ST: 'ireland',
	SA: 'ireland',
	SN: 'ireland',
	RS: 'ireland',
	SC: 'ireland',
	SL: 'ireland',
	SG: 'ireland',
	'SG-01': 'ireland',
	'SG-02': 'ireland',
	'SG-03': 'ireland',
	'SG-04': 'ireland',
	'SG-05': 'ireland',
	'Dutch part': 'virginia',
	SK: 'ireland',
	SI: 'ireland',
	SB: 'virginia',
	SO: 'ireland',
	ZA: 'ireland',
	'ZA-EC': 'ireland',
	'ZA-FS': 'ireland',
	'ZA-GT': 'ireland',
	'ZA-LP': 'ireland',
	'ZA-MP': 'ireland',
	'ZA-NC': 'ireland',
	'ZA-NW': 'ireland',
	'ZA-WC': 'ireland',
	'ZA-ZN': 'ireland',
	GS: 'virginia',
	SS: 'ireland',
	ES: 'ireland',
	LK: 'ireland',
	SD: 'ireland',
	SR: 'virginia',
	SJ: 'ireland',
	SZ: 'ireland',
	SE: 'ireland',
	'SE-AB': 'ireland',
	'SE-AC': 'ireland',
	'SE-BD': 'ireland',
	'SE-C': 'ireland',
	'SE-D': 'ireland',
	'SE-E': 'ireland',
	'SE-F': 'ireland',
	'SE-G': 'ireland',
	'SE-H': 'ireland',
	'SE-I': 'ireland',
	'SE-K': 'ireland',
	'SE-M': 'ireland',
	'SE-N': 'ireland',
	'SE-O': 'ireland',
	'SE-S': 'ireland',
	'SE-T': 'ireland',
	'SE-U': 'ireland',
	'SE-W': 'ireland',
	'SE-X': 'ireland',
	'SE-Y': 'ireland',
	'SE-Z': 'ireland',
	CH: 'ireland',
	'CH-AG': 'ireland',
	'CH-AI': 'ireland',
	'CH-AR': 'ireland',
	'CH-BE': 'ireland',
	'CH-BL': 'ireland',
	'CH-BS': 'ireland',
	'CH-FR': 'ireland',
	'CH-GE': 'ireland',
	'CH-GL': 'ireland',
	'CH-GR': 'ireland',
	'CH-JU': 'ireland',
	'CH-LU': 'ireland',
	'CH-NE': 'ireland',
	'CH-NW': 'ireland',
	'CH-OW': 'ireland',
	'CH-SG': 'ireland',
	'CH-SH': 'ireland',
	'CH-SO': 'ireland',
	'CH-SZ': 'ireland',
	'CH-TG': 'ireland',
	'CH-TI': 'ireland',
	'CH-UR': 'ireland',
	'CH-VD': 'ireland',
	'CH-VS': 'ireland',
	'CH-ZG': 'ireland',
	'CH-ZH': 'ireland',
	SY: 'ireland',
	TW: 'ireland',
	'TW-CHA': 'ireland',
	'TW-CYI': 'ireland',
	'TW-CYQ': 'ireland',
	'TW-HSQ': 'ireland',
	'TW-HSZ': 'ireland',
	'TW-HUA': 'ireland',
	'TW-ILA': 'ireland',
	'TW-KEE': 'ireland',
	'TW-KHH': 'ireland',
	'TW-KIN': 'ireland',
	'TW-LIE': 'ireland',
	'TW-MIA': 'ireland',
	'TW-NAN': 'ireland',
	'TW-NWT': 'ireland',
	'TW-PEN': 'ireland',
	'TW-PIF': 'ireland',
	'TW-TAO': 'ireland',
	'TW-TNN': 'ireland',
	'TW-TPE': 'ireland',
	'TW-TTT': 'ireland',
	'TW-TXG': 'ireland',
	'TW-YUN': 'ireland',
	TJ: 'ireland',
	TZ: 'ireland',
	TH: 'ireland',
	TL: 'ireland',
	TG: 'ireland',
	TK: 'virginia',
	TO: 'virginia',
	TT: 'virginia',
	TN: 'ireland',
	TR: 'ireland',
	TM: 'ireland',
	TC: 'virginia',
	TV: 'virginia',
	UG: 'ireland',
	UA: 'ireland',
	AE: 'ireland',
	'AE-AJ': 'ireland',
	'AE-AZ': 'ireland',
	'AE-DU': 'ireland',
	'AE-FU': 'ireland',
	'AE-RK': 'ireland',
	'AE-SH': 'ireland',
	'AE-UQ': 'ireland',
	GB: 'ireland',
	'GB-ENG': 'ireland',
	'GB-NIR': 'ireland',
	'GB-SCT': 'ireland',
	'GB-WLS': 'ireland',
	UM: 'virginia',
	US: 'virginia',
	'US-AK': 'virginia',
	'US-AL': 'virginia',
	'US-AR': 'virginia',
	'US-AZ': 'virginia',
	'US-CA': 'virginia',
	'US-CO': 'virginia',
	'US-CT': 'virginia',
	'US-DC': 'virginia',
	'US-DE': 'virginia',
	'US-FL': 'virginia',
	'US-GA': 'virginia',
	'US-HI': 'virginia',
	'US-IA': 'virginia',
	'US-ID': 'virginia',
	'US-IL': 'virginia',
	'US-IN': 'virginia',
	'US-KS': 'virginia',
	'US-KY': 'virginia',
	'US-LA': 'virginia',
	'US-MA': 'virginia',
	'US-MD': 'virginia',
	'US-ME': 'virginia',
	'US-MI': 'virginia',
	'US-MN': 'virginia',
	'US-MO': 'virginia',
	'US-MS': 'virginia',
	'US-MT': 'virginia',
	'US-NC': 'virginia',
	'US-ND': 'virginia',
	'US-NE': 'virginia',
	'US-NH': 'virginia',
	'US-NJ': 'virginia',
	'US-NM': 'virginia',
	'US-NV': 'virginia',
	'US-NY': 'virginia',
	'US-OH': 'virginia',
	'US-OK': 'virginia',
	'US-OR': 'virginia',
	'US-PA': 'virginia',
	'US-RI': 'virginia',
	'US-SC': 'virginia',
	'US-SD': 'virginia',
	'US-TN': 'virginia',
	'US-TX': 'virginia',
	'US-UT': 'virginia',
	'US-VA': 'virginia',
	'US-VT': 'virginia',
	'US-WA': 'virginia',
	'US-WI': 'virginia',
	'US-WV': 'virginia',
	'US-WY': 'virginia',
	UY: 'virginia',
	UZ: 'ireland',
	VU: 'virginia',
	VE: 'virginia',
	VN: 'ireland',
	VG: 'virginia',
	VI: 'virginia',
	WF: 'virginia',
	EH: 'ireland',
	YE: 'ireland',
	ZM: 'ireland',
	ZW: 'ireland',
	AX: 'ireland',
};

export const COUNTRY_ISO_CODE = [
	{ name: 'United States of America', alpha2: 'US', country_code: '840' },
	{ name: 'Canada', alpha2: 'CA', country_code: '124' },
	{ name: 'Afghanistan', alpha2: 'AF', country_code: '004' },
	{ name: 'Åland Islands', alpha2: 'AX', country_code: '248' },
	{ name: 'Albania', alpha2: 'AL', country_code: '008' },
	{ name: 'Algeria', alpha2: 'DZ', country_code: '012' },
	{ name: 'American Samoa', alpha2: 'AS', country_code: '016' },
	{ name: 'Andorra', alpha2: 'AD', country_code: '020' },
	{ name: 'Angola', alpha2: 'AO', country_code: '024' },
	{ name: 'Anguilla', alpha2: 'AI', country_code: '660' },
	{ name: 'Antarctica', alpha2: 'AQ', country_code: '010' },
	{ name: 'Antigua and Barbuda', alpha2: 'AG', country_code: '028' },
	{ name: 'Argentina', alpha2: 'AR', country_code: '032' },
	{ name: 'Armenia', alpha2: 'AM', country_code: '051' },
	{ name: 'Aruba', alpha2: 'AW', country_code: '533' },
	{ name: 'Australia', alpha2: 'AU', country_code: '036' },
	{ name: 'Austria', alpha2: 'AT', country_code: '040' },
	{ name: 'Azerbaijan', alpha2: 'AZ', country_code: '031' },
	{ name: 'Bahamas', alpha2: 'BS', country_code: '044' },
	{ name: 'Bahrain', alpha2: 'BH', country_code: '048' },
	{ name: 'Bangladesh', alpha2: 'BD', country_code: '050' },
	{ name: 'Barbados', alpha2: 'BB', country_code: '052' },
	{ name: 'Belarus', alpha2: 'BY', country_code: '112' },
	{ name: 'Belgium', alpha2: 'BE', country_code: '056' },
	{ name: 'Belize', alpha2: 'BZ', country_code: '084' },
	{ name: 'Benin', alpha2: 'BJ', country_code: '204' },
	{ name: 'Bermuda', alpha2: 'BM', country_code: '060' },
	{ name: 'Bhutan', alpha2: 'BT', country_code: '064' },
	{
		name: 'Bolivia (Plurinational State of)',
		alpha2: 'BO',
		country_code: '068',
	},
	{
		name: 'Bonaire, Sint Eustatius and Saba',
		alpha2: 'BQ',
		country_code: '535',
	},
	{ name: 'Bosnia and Herzegovina', alpha2: 'BA', country_code: '070' },
	{ name: 'Botswana', alpha2: 'BW', country_code: '072' },
	{ name: 'Bouvet Island', alpha2: 'BV', country_code: '074' },
	{ name: 'Brazil', alpha2: 'BR', country_code: '076' },
	{ name: 'British Indian Ocean Territory', alpha2: 'IO', country_code: '086' },
	{ name: 'Brunei Darussalam', alpha2: 'BN', country_code: '096' },
	{ name: 'Bulgaria', alpha2: 'BG', country_code: '100' },
	{ name: 'Burkina Faso', alpha2: 'BF', country_code: '854' },
	{ name: 'Burundi', alpha2: 'BI', country_code: '108' },
	{ name: 'Cabo Verde', alpha2: 'CVda', country_code: '132' },
	{ name: 'Cambodia', alpha2: 'KH', country_code: '116' },
	{ name: 'Cameroon', alpha2: 'CM', country_code: '120' },
	{ name: 'Canada', alpha2: 'CA', country_code: '124' },
	{ name: 'Cayman Islands', alpha2: 'KY', country_code: '136' },
	{ name: 'Central African Republic', alpha2: 'CF', country_code: '140' },
	{ name: 'Chad', alpha2: 'TD', country_code: '148' },
	{ name: 'Chile', alpha2: 'CL', country_code: '152' },
	{ name: 'China', alpha2: 'CN', country_code: '156' },
	{ name: 'Christmas Island', alpha2: 'CX', country_code: '162' },
	{ name: 'Cocos (Keeling) Islands', alpha2: 'CC', country_code: '166' },
	{ name: 'Colombia', alpha2: 'CO', country_code: '170' },
	{ name: 'Comoros', alpha2: 'KM', country_code: '174' },
	{ name: 'Congo', alpha2: 'CG', country_code: '178' },
	{
		name: 'Congo, Democratic Republic of the',
		alpha2: 'CD',
		country_code: '180',
	},
	{ name: 'Cook Islands', alpha2: 'CK', country_code: '184' },
	{ name: 'Costa Rica', alpha2: 'CR', country_code: '188' },
	{ name: "Côte d'Ivoire", alpha2: 'CI', country_code: '384' },
	{ name: 'Croatia', alpha2: 'HR', country_code: '191' },
	{ name: 'Cuba', alpha2: 'CU', country_code: '192' },
	{ name: 'Curaçao', alpha2: 'CW', country_code: '531' },
	{ name: 'Cyprus', alpha2: 'CY', country_code: '196' },
	{ name: 'Czechia', alpha2: 'CZ', country_code: '203' },
	{ name: 'Denmark', alpha2: 'DK', country_code: '208' },
	{ name: 'Djibouti', alpha2: 'DJ', country_code: '262' },
	{ name: 'Dominica', alpha2: 'DM', country_code: '212' },
	{ name: 'Dominican Republic', alpha2: 'DO', country_code: '214' },
	{ name: 'Ecuador', alpha2: 'EC', country_code: '218' },
	{ name: 'Egypt', alpha2: 'EG', country_code: '818' },
	{ name: 'El Salvador', alpha2: 'SV', country_code: '222' },
	{ name: 'Equatorial Guinea', alpha2: 'GQ', country_code: '226' },
	{ name: 'Eritrea', alpha2: 'ER', country_code: '232' },
	{ name: 'Estonia', alpha2: 'EE', country_code: '233' },
	{ name: 'Eswatini', alpha2: 'SZ', country_code: '748' },
	{ name: 'Ethiopia', alpha2: 'ET', country_code: '231' },
	{ name: 'Falkland Islands (Malvinas)', alpha2: 'FK', country_code: '238' },
	{ name: 'Faroe Islands', alpha2: 'FO', country_code: '234' },
	{ name: 'Fiji', alpha2: 'FJ', country_code: '242' },
	{ name: 'Finland', alpha2: 'FI', country_code: '246' },
	{ name: 'France', alpha2: 'FR', country_code: '250' },
	{ name: 'French Guiana', alpha2: 'GF', country_code: '254' },
	{ name: 'French Polynesia', alpha2: 'PF', country_code: '258' },
	{ name: 'French Southern Territories', alpha2: 'TF', country_code: '260' },
	{ name: 'Gabon', alpha2: 'GA', country_code: '266' },
	{ name: 'Gambia', alpha2: 'GM', country_code: '270' },
	{ name: 'Georgia', alpha2: 'GE', country_code: '268' },
	{ name: 'Germany', alpha2: 'DE', country_code: '276' },
	{ name: 'Ghana', alpha2: 'GH', country_code: '288' },
	{ name: 'Gibraltar', alpha2: 'GI', country_code: '292' },
	{ name: 'Greece', alpha2: 'GR', country_code: '300' },
	{ name: 'Greenland', alpha2: 'GL', country_code: '304' },
	{ name: 'Grenada', alpha2: 'GD', country_code: '308' },
	{ name: 'Guadeloupe', alpha2: 'GP', country_code: '312' },
	{ name: 'Guam', alpha2: 'GU', country_code: '316' },
	{ name: 'Guatemala', alpha2: 'GT', country_code: '320' },
	{ name: 'Guernsey', alpha2: 'GG', country_code: '831' },
	{ name: 'Guinea', alpha2: 'GN', country_code: '324' },
	{ name: 'Guinea-Bissau', alpha2: 'GW', country_code: '624' },
	{ name: 'Guyana', alpha2: 'GY', country_code: '328' },
	{ name: 'Haiti', alpha2: 'HT', country_code: '332' },
	{
		name: 'Heard Island and McDonald Islands',
		alpha2: 'HM',
		country_code: '334',
	},
	{ name: 'Holy See', alpha2: 'VA', country_code: '336' },
	{ name: 'Honduras', alpha2: 'HN', country_code: '340' },
	{ name: 'Hong Kong', alpha2: 'HK', country_code: '344' },
	{ name: 'Hungary', alpha2: 'HU', country_code: '348' },
	{ name: 'Iceland', alpha2: 'IS', country_code: '352' },
	{ name: 'India', alpha2: 'IN', country_code: '356' },
	{ name: 'Indonesia', alpha2: 'ID', country_code: '360' },
	{ name: 'Iran (Islamic Republic of)', alpha2: 'IR', country_code: '364' },
	{ name: 'Iraq', alpha2: 'IQ', country_code: '368' },
	{ name: 'Ireland', alpha2: 'IE', country_code: '372' },
	{ name: 'Isle of Man', alpha2: 'IM', country_code: '833' },
	{ name: 'Israel', alpha2: 'IL', country_code: '376' },
	{ name: 'Italy', alpha2: 'IT', country_code: '380' },
	{ name: 'Jamaica', alpha2: 'JM', country_code: '388' },
	{ name: 'Japan', alpha2: 'JP', country_code: '392' },
	{ name: 'Jersey', alpha2: 'JE', country_code: '832' },
	{ name: 'Jordan', alpha2: 'JO', country_code: '400' },
	{ name: 'Kazakhstan', alpha2: 'KZ', country_code: '398' },
	{ name: 'Kenya', alpha2: 'KE', country_code: '404' },
	{ name: 'Kiribati', alpha2: 'KI', country_code: '296' },
	{
		name: "Korea (Democratic People's Republic of)",
		alpha2: 'KP',
		country_code: '408',
	},
	{ name: 'Korea, Republic of', alpha2: 'KR', country_code: '410' },
	{ name: 'Kuwait', alpha2: 'KW', country_code: '414' },
	{ name: 'Kyrgyzstan', alpha2: 'KG', country_code: '417' },
	{
		name: "Lao People's Democratic Republic",
		alpha2: 'LA',
		country_code: '418',
	},
	{ name: 'Latvia', alpha2: 'LV', country_code: '428' },
	{ name: 'Lebanon', alpha2: 'LB', country_code: '422' },
	{ name: 'Lesotho', alpha2: 'LS', country_code: '426' },
	{ name: 'Liberia', alpha2: 'LR', country_code: '430' },
	{ name: 'Libya', alpha2: 'LY', country_code: '434' },
	{ name: 'Liechtenstein', alpha2: 'LI', country_code: '438' },
	{ name: 'Lithuania', alpha2: 'LT', country_code: '440' },
	{ name: 'Luxembourg', alpha2: 'LU', country_code: '442' },
	{ name: 'Macao', alpha2: 'MO', country_code: '446' },
	{ name: 'Madagascar', alpha2: 'MG', country_code: '450' },
	{ name: 'Malawi', alpha2: 'MW', country_code: '454' },
	{ name: 'Malaysia', alpha2: 'MY', country_code: '458' },
	{ name: 'Maldives', alpha2: 'MV', country_code: '462' },
	{ name: 'Mali', alpha2: 'ML', country_code: '466' },
	{ name: 'Malta', alpha2: 'MT', country_code: '470' },
	{ name: 'Marshall Islands', alpha2: 'MH', country_code: '584' },
	{ name: 'Martinique', alpha2: 'MQ', country_code: '474' },
	{ name: 'Mauritania', alpha2: 'MR', country_code: '478' },
	{ name: 'Mauritius', alpha2: 'MU', country_code: '480' },
	{ name: 'Mayotte', alpha2: 'YT', country_code: '175' },
	{ name: 'Mexico', alpha2: 'MX', country_code: '484' },
	{
		name: 'Micronesia (Federated States of)',
		alpha2: 'FM',
		country_code: '583',
	},
	{ name: 'Moldova, Republic of', alpha2: 'MD', country_code: '498' },
	{ name: 'Monaco', alpha2: 'MC', country_code: '492' },
	{ name: 'Mongolia', alpha2: 'MN', country_code: '496' },
	{ name: 'Montenegro', alpha2: 'ME', country_code: '499' },
	{ name: 'Montserrat', alpha2: 'MS', country_code: '500' },
	{ name: 'Morocco', alpha2: 'MA', country_code: '504' },
	{ name: 'Mozambique', alpha2: 'MZ', country_code: '508' },
	{ name: 'Myanmar', alpha2: 'MM', country_code: '104' },
	{ name: 'Namibia', alpha2: 'NA', country_code: '516' },
	{ name: 'Nauru', alpha2: 'NR', country_code: '520' },
	{ name: 'Nepal', alpha2: 'NP', country_code: '524' },
	{ name: 'Netherlands', alpha2: 'NL', country_code: '528' },
	{ name: 'New Caledonia', alpha2: 'NC', country_code: '540' },
	{ name: 'New Zealand', alpha2: 'NZ', country_code: '554' },
	{ name: 'Nicaragua', alpha2: 'NI', country_code: '558' },
	{ name: 'Niger', alpha2: 'NE', country_code: '562' },
	{ name: 'Nigeria', alpha2: 'NG', country_code: '566' },
	{ name: 'Niue', alpha2: 'NU', country_code: '570' },
	{ name: 'Norfolk Island', alpha2: 'NF', country_code: '574' },
	{ name: 'North Macedonia', alpha2: 'MK', country_code: '807' },
	{ name: 'Northern Mariana Islands', alpha2: 'MP', country_code: '580' },
	{ name: 'Norway', alpha2: 'NO', country_code: '578' },
	{ name: 'Oman', alpha2: 'OM', country_code: '512' },
	{ name: 'Pakistan', alpha2: 'PK', country_code: '586' },
	{ name: 'Palau', alpha2: 'PW', country_code: '585' },
	{ name: 'Palestine, State of', alpha2: 'PS', country_code: '275' },
	{ name: 'Panama', alpha2: 'PA', country_code: '591' },
	{ name: 'Papua New Guinea', alpha2: 'PG', country_code: '598' },
	{ name: 'Paraguay', alpha2: 'PY', country_code: '600' },
	{ name: 'Peru', alpha2: 'PE', country_code: '604' },
	{ name: 'Philippines', alpha2: 'PH', country_code: '608' },
	{ name: 'Pitcairn', alpha2: 'PN', country_code: '612' },
	{ name: 'Poland', alpha2: 'PL', country_code: '616' },
	{ name: 'Portugal', alpha2: 'PT', country_code: '620' },
	{ name: 'Puerto Rico', alpha2: 'PR', country_code: '630' },
	{ name: 'Qatar', alpha2: 'QA', country_code: '634' },
	{ name: 'Réunion', alpha2: 'RE', country_code: '638' },
	{ name: 'Romania', alpha2: 'RO', country_code: '642' },
	{ name: 'Russian Federation', alpha2: 'RU', country_code: '643' },
	{ name: 'Rwanda', alpha2: 'RW', country_code: '646' },
	{ name: 'Saint Barthélemy', alpha2: 'BL', country_code: '652' },
	{
		name: 'Saint Helena, Ascension and Tristan da Cunha',
		alpha2: 'SH',
		country_code: '654',
	},
	{ name: 'Saint Kitts and Nevis', alpha2: 'KN', country_code: '659' },
	{ name: 'Saint Lucia', alpha2: 'LC', country_code: '662' },
	{ name: 'Saint Martin (French part)', alpha2: 'MF', country_code: '663' },
	{ name: 'Saint Pierre and Miquelon', alpha2: 'PM', country_code: '666' },
	{
		name: 'Saint Vincent and the Grenadines',
		alpha2: 'VC',
		country_code: '670',
	},
	{ name: 'Samoa', alpha2: 'WS', country_code: '882' },
	{ name: 'San Marino', alpha2: 'SM', country_code: '674' },
	{ name: 'Sao Tome and Principe', alpha2: 'ST', country_code: '678' },
	{ name: 'Saudi Arabia', alpha2: 'SA', country_code: '682' },
	{ name: 'Senegal', alpha2: 'SN', country_code: '686' },
	{ name: 'Serbia', alpha2: 'RS', country_code: '688' },
	{ name: 'Seychelles', alpha2: 'SC', country_code: '690' },
	{ name: 'Sierra Leone', alpha2: 'SL', country_code: '694' },
	{ name: 'Singapore', alpha2: 'SG', country_code: '702' },
	{ name: 'Sint Maarten (Dutch part)', alpha2: 'SX', country_code: '534' },
	{ name: 'Slovakia', alpha2: 'SK', country_code: '703' },
	{ name: 'Slovenia', alpha2: 'SI', country_code: '705' },
	{ name: 'Solomon Islands', alpha2: 'SB', country_code: '090' },
	{ name: 'Somalia', alpha2: 'SO', country_code: '706' },
	{ name: 'South Africa', alpha2: 'ZA', country_code: '710' },
	{
		name: 'South Georgia and the South Sandwich Islands',
		alpha2: 'GS',
		country_code: '239',
	},
	{ name: 'South Sudan', alpha2: 'SS', country_code: '728' },
	{ name: 'Spain', alpha2: 'ES', country_code: '724' },
	{ name: 'Sri Lanka', alpha2: 'LK', country_code: '144' },
	{ name: 'Sudan', alpha2: 'SD', country_code: '729' },
	{ name: 'Suriname', alpha2: 'SR', country_code: '740' },
	{ name: 'Svalbard and Jan Mayen', alpha2: 'SJ', country_code: '744' },
	{ name: 'Sweden', alpha2: 'SE', country_code: '752' },
	{ name: 'Switzerland', alpha2: 'CH', country_code: '756' },
	{ name: 'Syrian Arab Republic', alpha2: 'SY', country_code: '760' },
	{ name: 'Taiwan, Province of China', alpha2: 'TW', country_code: '158' },
	{ name: 'Tajikistan', alpha2: 'TJ', country_code: '762' },
	{ name: 'Tanzania, United Republic of', alpha2: 'TZ', country_code: '834' },
	{ name: 'Thailand', alpha2: 'TH', country_code: '764' },
	{ name: 'Timor-Leste', alpha2: 'TL', country_code: '626' },
	{ name: 'Togo', alpha2: 'TG', country_code: '768' },
	{ name: 'Tokelau', alpha2: 'TK', country_code: '772' },
	{ name: 'Tonga', alpha2: 'TO', country_code: '776' },
	{ name: 'Trinidad and Tobago', alpha2: 'TT', country_code: '780' },
	{ name: 'Tunisia', alpha2: 'TN', country_code: '788' },
	{ name: 'Turkey', alpha2: 'TR', country_code: '792' },
	{ name: 'Turkmenistan', alpha2: 'TM', country_code: '795' },
	{ name: 'Turks and Caicos Islands', alpha2: 'TC', country_code: '796' },
	{ name: 'Tuvalu', alpha2: 'TV', country_code: '798' },
	{ name: 'Uganda', alpha2: 'UG', country_code: '800' },
	{ name: 'Ukraine', alpha2: 'UA', country_code: '804' },
	{ name: 'United Arab Emirates', alpha2: 'AE', country_code: '784' },
	{
		name: 'United Kingdom of Great Britain and Northern Ireland',
		alpha2: 'GB',
		country_code: '826',
	},
	{ name: 'United States of America', alpha2: 'US', country_code: '840' },
	{
		name: 'United States Minor Outlying Islands',
		alpha2: 'UM',
		country_code: '581',
	},
	{ name: 'Uruguay', alpha2: 'UY', country_code: '858' },
	{ name: 'Uzbekistan', alpha2: 'UZ', country_code: '860' },
	{ name: 'Vanuatu', alpha2: 'VU', country_code: '548' },
	{
		name: 'Venezuela (Bolivarian Republic of)',
		alpha2: 'VE',
		country_code: '862',
	},
	{ name: 'Viet Nam', alpha2: 'VN', country_code: '704' },
	{ name: 'Virgin Islands (British)', alpha2: 'VG', country_code: '092' },
	{ name: 'Virgin Islands (U.S.)', alpha2: 'VI', country_code: '850' },
	{ name: 'Wallis and Futuna', alpha2: 'WF', country_code: '876' },
	{ name: 'Western Sahara', alpha2: 'EH', country_code: '732' },
	{ name: 'Yemen', alpha2: 'YE', country_code: '887' },
	{ name: 'Zambia', alpha2: 'ZM', country_code: '894' },
	{ name: 'Zimbabwe', alpha2: 'ZW', country_code: '716' },
];

export const COUNTRY_PHONE_PREFIX = [
	{ code: 'AF', phoneCode: '+93' },
	{ code: 'AL', phoneCode: '+355' },
	{ code: 'DZ', phoneCode: '+213' },
	{ code: 'AS', phoneCode: '+1684' },
	{ code: 'AD', phoneCode: '+376' },
	{ code: 'AO', phoneCode: '+244' },
	{ code: 'AI', phoneCode: '+1264' },
	{ code: 'AR', phoneCode: '+54' },
	{ code: 'AM', phoneCode: '+374' },
	{ code: 'AW', phoneCode: '+297' },
	{ code: 'AU', phoneCode: '+61' },
	{ code: 'AT', phoneCode: '+43' },
	{ code: 'AZ', phoneCode: '+994' },
	{ code: 'BH', phoneCode: '+973' },
	{ code: 'BD', phoneCode: '+880' },
	{ code: 'BB', phoneCode: '+1246' },
	{ code: 'BY', phoneCode: '+375' },
	{ code: 'BE', phoneCode: '+32' },
	{ code: 'BZ', phoneCode: '+501' },
	{ code: 'BJ', phoneCode: '+229' },
	{ code: 'BM', phoneCode: '+1441' },
	{ code: 'BT', phoneCode: '+975' },
	{ code: 'BO', phoneCode: '+591' },
	{ code: 'BW', phoneCode: '+267' },
	{ code: 'BR', phoneCode: '+55' },
	{ code: 'IO', phoneCode: '+246' },
	{ code: 'BN', phoneCode: '+673' },
	{ code: 'BG', phoneCode: '+359' },
	{ code: 'BF', phoneCode: '+226' },
	{ code: 'BI', phoneCode: '+257' },
	{ code: 'KH', phoneCode: '+855' },
	{ code: 'CM', phoneCode: '+237' },
	{ code: 'CA', phoneCode: '+1' },
	{ code: 'CV', phoneCode: '+238' },
	{ code: 'KY', phoneCode: '+1345' },
	{ code: 'CF', phoneCode: '+236' },
	{ code: 'TD', phoneCode: '+235' },
	{ code: 'CL', phoneCode: '+56' },
	{ code: 'CN', phoneCode: '+86' },
	{ code: 'CX', phoneCode: '+61' },
	{ code: 'CC', phoneCode: '+672' },
	{ code: 'CO', phoneCode: '+57' },
	{ code: 'KM', phoneCode: '+269' },
	{ code: 'CK', phoneCode: '+682' },
	{ code: 'CR', phoneCode: '+506' },
	{ code: 'CU', phoneCode: '+53' },
	{ code: 'CY', phoneCode: '+357' },
	{ code: 'DK', phoneCode: '+45' },
	{ code: 'DJ', phoneCode: '+253' },
	{ code: 'DM', phoneCode: '+1767' },
	{ code: 'DO', phoneCode: '+1809' },
	{ code: 'EC', phoneCode: '+593' },
	{ code: 'EG', phoneCode: '+20' },
	{ code: 'SV', phoneCode: '+503' },
	{ code: 'GQ', phoneCode: '+240' },
	{ code: 'ER', phoneCode: '+291' },
	{ code: 'EE', phoneCode: '+372' },
	{ code: 'ET', phoneCode: '+251' },
	{ code: 'FK', phoneCode: '+500' },
	{ code: 'FO', phoneCode: '+298' },
	{ code: 'FI', phoneCode: '+358' },
	{ code: 'FR', phoneCode: '+33' },
	{ code: 'GF', phoneCode: '+594' },
	{ code: 'PF', phoneCode: '+689' },
	{ code: 'GA', phoneCode: '+241' },
	{ code: 'GE', phoneCode: '+995' },
	{ code: 'DE', phoneCode: '+49' },
	{ code: 'GH', phoneCode: '+233' },
	{ code: 'GI', phoneCode: '+350' },
	{ code: 'GR', phoneCode: '+30' },
	{ code: 'GL', phoneCode: '+299' },
	{ code: 'GD', phoneCode: '+1473' },
	{ code: 'GP', phoneCode: '+590' },
	{ code: 'GU', phoneCode: '+1671' },
	{ code: 'GT', phoneCode: '+502' },
	{ code: 'GN', phoneCode: '+224' },
	{ code: 'GW', phoneCode: '+245' },
	{ code: 'GY', phoneCode: '+592' },
	{ code: 'HT', phoneCode: '+509' },
	{ code: 'HN', phoneCode: '+504' },
	{ code: 'HU', phoneCode: '+36' },
	{ code: 'IS', phoneCode: '+354' },
	{ code: 'IN', phoneCode: '+91' },
	{ code: 'ID', phoneCode: '+62' },
	{ code: 'IR', phoneCode: '+98' },
	{ code: 'IQ', phoneCode: '+964' },
	{ code: 'IE', phoneCode: '+353' },
	{ code: 'IL', phoneCode: '+972' },
	{ code: 'IT', phoneCode: '+39' },
	{ code: 'JM', phoneCode: '+1876' },
	{ code: 'JP', phoneCode: '+81' },
	{ code: 'JO', phoneCode: '+962' },
	{ code: 'KZ', phoneCode: '+7' },
	{ code: 'KE', phoneCode: '+254' },
	{ code: 'KI', phoneCode: '+686' },
	{ code: 'KW', phoneCode: '+965' },
	{ code: 'KG', phoneCode: '+996' },
	{ code: 'LA', phoneCode: '+856' },
	{ code: 'LV', phoneCode: '+371' },
	{ code: 'LB', phoneCode: '+961' },
	{ code: 'LS', phoneCode: '+266' },
	{ code: 'LR', phoneCode: '+231' },
	{ code: 'LY', phoneCode: '+218' },
	{ code: 'LI', phoneCode: '+423' },
	{ code: 'LT', phoneCode: '+370' },
	{ code: 'LU', phoneCode: '+352' },
	{ code: 'MK', phoneCode: '+389' },
	{ code: 'MG', phoneCode: '+261' },
	{ code: 'MW', phoneCode: '+265' },
	{ code: 'MY', phoneCode: '+60' },
	{ code: 'MV', phoneCode: '+960' },
	{ code: 'ML', phoneCode: '+223' },
	{ code: 'MT', phoneCode: '+356' },
	{ code: 'MH', phoneCode: '+692' },
	{ code: 'MQ', phoneCode: '+596' },
	{ code: 'MR', phoneCode: '+222' },
	{ code: 'MU', phoneCode: '+230' },
	{ code: 'YT', phoneCode: '+269' },
	{ code: 'MX', phoneCode: '+52' },
	{ code: 'FM', phoneCode: '+691' },
	{ code: 'MD', phoneCode: '+373' },
	{ code: 'MC', phoneCode: '+377' },
	{ code: 'MN', phoneCode: '+976' },
	{ code: 'MS', phoneCode: '+1664' },
	{ code: 'MA', phoneCode: '+212' },
	{ code: 'MZ', phoneCode: '+258' },
	{ code: 'NA', phoneCode: '+264' },
	{ code: 'NR', phoneCode: '+674' },
	{ code: 'NP', phoneCode: '+977' },
	{ code: 'NC', phoneCode: '+687' },
	{ code: 'NZ', phoneCode: '+64' },
	{ code: 'NI', phoneCode: '+505' },
	{ code: 'NE', phoneCode: '+227' },
	{ code: 'NG', phoneCode: '+234' },
	{ code: 'NU', phoneCode: '+683' },
	{ code: 'NF', phoneCode: '+672' },
	{ code: 'MP', phoneCode: '+1670' },
	{ code: 'NO', phoneCode: '+47' },
	{ code: 'OM', phoneCode: '+968' },
	{ code: 'PK', phoneCode: '+92' },
	{ code: 'PW', phoneCode: '+680' },
	{ code: 'PA', phoneCode: '+507' },
	{ code: 'PY', phoneCode: '+595' },
	{ code: 'PE', phoneCode: '+51' },
	{ code: 'PH', phoneCode: '+63' },
	{ code: 'PL', phoneCode: '+48' },
	{ code: 'PT', phoneCode: '+351' },
	{ code: 'PR', phoneCode: '+1787' },
	{ code: 'QA', phoneCode: '+974' },
	{ code: 'RO', phoneCode: '+40' },
	{ code: 'RU', phoneCode: '+70' },
	{ code: 'RW', phoneCode: '+250' },
	{ code: 'WS', phoneCode: '+684' },
	{ code: 'SM', phoneCode: '+378' },
	{ code: 'SA', phoneCode: '+966' },
	{ code: 'SN', phoneCode: '+221' },
	{ code: 'RS', phoneCode: '+381' },
	{ code: 'SC', phoneCode: '+248' },
	{ code: 'SL', phoneCode: '+232' },
	{ code: 'SG', phoneCode: '+65' },
	{ code: 'SK', phoneCode: '+421' },
	{ code: 'SI', phoneCode: '+386' },
	{ code: 'SB', phoneCode: '+677' },
	{ code: 'SO', phoneCode: '+252' },
	{ code: 'ZA', phoneCode: '+27' },
	{ code: 'SS', phoneCode: '+211' },
	{ code: 'ES', phoneCode: '+34' },
	{ code: 'LK', phoneCode: '+94' },
	{ code: 'SD', phoneCode: '+249' },
	{ code: 'SR', phoneCode: '+597' },
	{ code: 'SZ', phoneCode: '+268' },
	{ code: 'SE', phoneCode: '+46' },
	{ code: 'CH', phoneCode: '+41' },
	{ code: 'SY', phoneCode: '+963' },
	{ code: 'TW', phoneCode: '+886' },
	{ code: 'TJ', phoneCode: '+992' },
	{ code: 'TZ', phoneCode: '+255' },
	{ code: 'TH', phoneCode: '+66' },
	{ code: 'TG', phoneCode: '+228' },
	{ code: 'TK', phoneCode: '+690' },
	{ code: 'TO', phoneCode: '+676' },
	{ code: 'TN', phoneCode: '+216' },
	{ code: 'TR', phoneCode: '+90' },
	{ code: 'TM', phoneCode: '+7370' },
	{ code: 'TV', phoneCode: '+688' },
	{ code: 'UG', phoneCode: '+256' },
	{ code: 'UA', phoneCode: '+380' },
	{ code: 'AE', phoneCode: '+971' },
	{ code: 'GB', phoneCode: '+44' },
	{ code: 'US', phoneCode: '+1' },
	{ code: 'UY', phoneCode: '+598' },
	{ code: 'UZ', phoneCode: '+998' },
	{ code: 'VU', phoneCode: '+678' },
	{ code: 'VE', phoneCode: '+58' },
	{ code: 'VN', phoneCode: '+84' },
	{ code: 'EH', phoneCode: '+212' },
	{ code: 'YE', phoneCode: '+967' },
	{ code: 'ZM', phoneCode: '+260' },
	{ code: 'ZW', phoneCode: '+26' },
];

export const VIDEO_LANGUAGE_CODES = [
	{ name: 'Serbian (Cyrillic)', countryCode: 'sr-Cyrl-RS' },
	{ name: 'Serbian (Latin)', countryCode: 'sr-Latn-RS' },
	{ name: 'Bosnian', countryCode: 'bs-Latn' },
	{ name: 'Chinese (Simplified)', countryCode: 'zh-Hans' },
	{ name: 'Chinese (Traditional)', countryCode: 'zh-Hant' },
	{ name: 'Filipino', countryCode: 'fil-PH' },
	{ name: 'English (US)', countryCode: 'en-US' },
	{ name: 'English (UK)', countryCode: 'en-GB' },
	{ name: 'English (AU)', countryCode: 'en-AU' },
	{ name: 'Fijian', countryCode: 'en-FJ' },
	{ name: 'Samoan', countryCode: 'en-WS' },
	{ name: 'Greek', countryCode: 'el-GR' },
	{ name: 'Spanish', countryCode: 'es-ES' },
	{ name: 'Spanish (MX)', countryCode: 'es-MX' },
	{ name: 'Estonian', countryCode: 'et-EE' },
	{ name: 'Persian', countryCode: 'fa-IR' },
	{ name: 'Finnish', countryCode: 'fi-FI' },
	{ name: 'French', countryCode: 'fr-FR' },
	{ name: 'Haitian', countryCode: 'fr-HT' },
	{ name: 'Afrikaans', countryCode: 'af-ZA' },
	{ name: 'Arabic Syrian Arab Republic', countryCode: 'ar-SY' },
	{ name: 'Arabic Egypt', countryCode: 'ar-EG' },
	{ name: 'Danish', countryCode: 'da-DK' },
	{ name: 'German', countryCode: 'de-DE' },
	{ name: 'Bulgarian', countryCode: 'bg-BG' },
	{ name: 'Bangla', countryCode: 'bn-BD' },
	{ name: 'Malagasy', countryCode: 'mg-MG' },
	{ name: 'Malay', countryCode: 'ms-MY' },
	{ name: 'Maltese', countryCode: 'mt-MT' },
	{ name: 'Catalan', countryCode: 'ca-ES' },
	{ name: 'Czech', countryCode: 'cs-CZ' },
	{ name: 'Dutch', countryCode: 'nl-NL' },
	{ name: 'Norwegian', countryCode: 'nb-NO' },
	{ name: 'Indonesian', countryCode: 'id-ID' },
	{ name: 'Italian', countryCode: 'it-IT' },
	{ name: 'Lithuanian', countryCode: 'lt-LT' },
	{ name: 'Latvian', countryCode: 'lv-LV' },
	{ name: 'Japanese', countryCode: 'ja-JP' },
	{ name: 'Urdu', countryCode: 'ur-PK' },
	{ name: 'Ukrainian', countryCode: 'uk-UA' },
	{ name: 'Hindi', countryCode: 'hi-IN' },
	{ name: 'Hebrew', countryCode: 'he-IL' },
	{ name: 'Hungarian', countryCode: 'hu-HU' },
	{ name: 'Croatian', countryCode: 'hr-HR' },
	{ name: 'Korean', countryCode: 'ko-KR' },
	{ name: 'Vietnamese', countryCode: 'vi-VN' },
	{ name: 'Turkish', countryCode: 'tr-TR' },
	{ name: 'Tamil', countryCode: 'ta-IN' },
	{ name: 'Thai', countryCode: 'th-TH' },
	{ name: 'Tongan', countryCode: 'to-TO' },
	{ name: 'Russian', countryCode: 'ru-RU' },
	{ name: 'Romanian', countryCode: 'ro-RO' },
	{ name: 'Portuguese', countryCode: 'pt-BR' },
	{ name: 'Polish', countryCode: 'pl-PL' },
	{ name: 'Swedish', countryCode: 'sv-SE' },
	{ name: 'Kiswahili', countryCode: 'sw-KE' },
	{ name: 'Slovenian', countryCode: 'sl-SI' },
	{ name: 'Slovak', countryCode: 'sk-SK' },
];

export const COUNTRY_DROPDOWN_OPTIONS = [
	{
		label: 'United States of America',
		value: 'US',
	},
	{
		label: 'Canada',
		value: 'CA',
	},
	{
		label: 'Afghanistan',
		value: 'AF',
	},
	{
		label: 'Åland Islands',
		value: 'AX',
	},
	{
		label: 'Albania',
		value: 'AL',
	},
	{
		label: 'Algeria',
		value: 'DZ',
	},
	{
		label: 'American Samoa',
		value: 'AS',
	},
	{
		label: 'Andorra',
		value: 'AD',
	},
	{
		label: 'Angola',
		value: 'AO',
	},
	{
		label: 'Anguilla',
		value: 'AI',
	},
	{
		label: 'Antarctica',
		value: 'AQ',
	},
	{
		label: 'Antigua and Barbuda',
		value: 'AG',
	},
	{
		label: 'Argentina',
		value: 'AR',
	},
	{
		label: 'Armenia',
		value: 'AM',
	},
	{
		label: 'Aruba',
		value: 'AW',
	},
	{
		label: 'Australia',
		value: 'AU',
	},
	{
		label: 'Austria',
		value: 'AT',
	},
	{
		label: 'Azerbaijan',
		value: 'AZ',
	},
	{
		label: 'Bahamas',
		value: 'BS',
	},
	{
		label: 'Bahrain',
		value: 'BH',
	},
	{
		label: 'Bangladesh',
		value: 'BD',
	},
	{
		label: 'Barbados',
		value: 'BB',
	},
	{
		label: 'Belarus',
		value: 'BY',
	},
	{
		label: 'Belgium',
		value: 'BE',
	},
	{
		label: 'Belize',
		value: 'BZ',
	},
	{
		label: 'Benin',
		value: 'BJ',
	},
	{
		label: 'Bermuda',
		value: 'BM',
	},
	{
		label: 'Bhutan',
		value: 'BT',
	},
	{
		label: 'Bolivia (Plurinational State of)',
		value: 'BO',
	},
	{
		label: 'Bonaire, Sint Eustatius and Saba',
		value: 'BQ',
	},
	{
		label: 'Bosnia and Herzegovina',
		value: 'BA',
	},
	{
		label: 'Botswana',
		value: 'BW',
	},
	{
		label: 'Bouvet Island',
		value: 'BV',
	},
	{
		label: 'Brazil',
		value: 'BR',
	},
	{
		label: 'British Indian Ocean Territory',
		value: 'IO',
	},
	{
		label: 'Brunei Darussalam',
		value: 'BN',
	},
	{
		label: 'Bulgaria',
		value: 'BG',
	},
	{
		label: 'Burkina Faso',
		value: 'BF',
	},
	{
		label: 'Burundi',
		value: 'BI',
	},
	{
		label: 'Cabo Verde',
		value: 'CVda',
	},
	{
		label: 'Cambodia',
		value: 'KH',
	},
	{
		label: 'Cameroon',
		value: 'CM',
	},
	{
		label: 'Canada',
		value: 'CA',
	},
	{
		label: 'Cayman Islands',
		value: 'KY',
	},
	{
		label: 'Central African Republic',
		value: 'CF',
	},
	{
		label: 'Chad',
		value: 'TD',
	},
	{
		label: 'Chile',
		value: 'CL',
	},
	{
		label: 'China',
		value: 'CN',
	},
	{
		label: 'Christmas Island',
		value: 'CX',
	},
	{
		label: 'Cocos (Keeling) Islands',
		value: 'CC',
	},
	{
		label: 'Colombia',
		value: 'CO',
	},
	{
		label: 'Comoros',
		value: 'KM',
	},
	{
		label: 'Congo',
		value: 'CG',
	},
	{
		label: 'Congo, Democratic Republic of the',
		value: 'CD',
	},
	{
		label: 'Cook Islands',
		value: 'CK',
	},
	{
		label: 'Costa Rica',
		value: 'CR',
	},
	{
		label: "Côte d'Ivoire",
		value: 'CI',
	},
	{
		label: 'Croatia',
		value: 'HR',
	},
	{
		label: 'Cuba',
		value: 'CU',
	},
	{
		label: 'Curaçao',
		value: 'CW',
	},
	{
		label: 'Cyprus',
		value: 'CY',
	},
	{
		label: 'Czechia',
		value: 'CZ',
	},
	{
		label: 'Denmark',
		value: 'DK',
	},
	{
		label: 'Djibouti',
		value: 'DJ',
	},
	{
		label: 'Dominica',
		value: 'DM',
	},
	{
		label: 'Dominican Republic',
		value: 'DO',
	},
	{
		label: 'Ecuador',
		value: 'EC',
	},
	{
		label: 'Egypt',
		value: 'EG',
	},
	{
		label: 'El Salvador',
		value: 'SV',
	},
	{
		label: 'Equatorial Guinea',
		value: 'GQ',
	},
	{
		label: 'Eritrea',
		value: 'ER',
	},
	{
		label: 'Estonia',
		value: 'EE',
	},
	{
		label: 'Eswatini',
		value: 'SZ',
	},
	{
		label: 'Ethiopia',
		value: 'ET',
	},
	{
		label: 'Falkland Islands (Malvinas)',
		value: 'FK',
	},
	{
		label: 'Faroe Islands',
		value: 'FO',
	},
	{
		label: 'Fiji',
		value: 'FJ',
	},
	{
		label: 'Finland',
		value: 'FI',
	},
	{
		label: 'France',
		value: 'FR',
	},
	{
		label: 'French Guiana',
		value: 'GF',
	},
	{
		label: 'French Polynesia',
		value: 'PF',
	},
	{
		label: 'French Southern Territories',
		value: 'TF',
	},
	{
		label: 'Gabon',
		value: 'GA',
	},
	{
		label: 'Gambia',
		value: 'GM',
	},
	{
		label: 'Georgia',
		value: 'GE',
	},
	{
		label: 'Germany',
		value: 'DE',
	},
	{
		label: 'Ghana',
		value: 'GH',
	},
	{
		label: 'Gibraltar',
		value: 'GI',
	},
	{
		label: 'Greece',
		value: 'GR',
	},
	{
		label: 'Greenland',
		value: 'GL',
	},
	{
		label: 'Grenada',
		value: 'GD',
	},
	{
		label: 'Guadeloupe',
		value: 'GP',
	},
	{
		label: 'Guam',
		value: 'GU',
	},
	{
		label: 'Guatemala',
		value: 'GT',
	},
	{
		label: 'Guernsey',
		value: 'GG',
	},
	{
		label: 'Guinea',
		value: 'GN',
	},
	{
		label: 'Guinea-Bissau',
		value: 'GW',
	},
	{
		label: 'Guyana',
		value: 'GY',
	},
	{
		label: 'Haiti',
		value: 'HT',
	},
	{
		label: 'Heard Island and McDonald Islands',
		value: 'HM',
	},
	{
		label: 'Holy See',
		value: 'VA',
	},
	{
		label: 'Honduras',
		value: 'HN',
	},
	{
		label: 'Hong Kong',
		value: 'HK',
	},
	{
		label: 'Hungary',
		value: 'HU',
	},
	{
		label: 'Iceland',
		value: 'IS',
	},
	{
		label: 'India',
		value: 'IN',
	},
	{
		label: 'Indonesia',
		value: 'ID',
	},
	{
		label: 'Iran (Islamic Republic of)',
		value: 'IR',
	},
	{
		label: 'Iraq',
		value: 'IQ',
	},
	{
		label: 'Ireland',
		value: 'IE',
	},
	{
		label: 'Isle of Man',
		value: 'IM',
	},
	{
		label: 'Israel',
		value: 'IL',
	},
	{
		label: 'Italy',
		value: 'IT',
	},
	{
		label: 'Jamaica',
		value: 'JM',
	},
	{
		label: 'Japan',
		value: 'JP',
	},
	{
		label: 'Jersey',
		value: 'JE',
	},
	{
		label: 'Jordan',
		value: 'JO',
	},
	{
		label: 'Kazakhstan',
		value: 'KZ',
	},
	{
		label: 'Kenya',
		value: 'KE',
	},
	{
		label: 'Kiribati',
		value: 'KI',
	},
	{
		label: "Korea (Democratic People's Republic of)",
		value: 'KP',
	},
	{
		label: 'Korea, Republic of',
		value: 'KR',
	},
	{
		label: 'Kuwait',
		value: 'KW',
	},
	{
		label: 'Kyrgyzstan',
		value: 'KG',
	},
	{
		label: "Lao People's Democratic Republic",
		value: 'LA',
	},
	{
		label: 'Latvia',
		value: 'LV',
	},
	{
		label: 'Lebanon',
		value: 'LB',
	},
	{
		label: 'Lesotho',
		value: 'LS',
	},
	{
		label: 'Liberia',
		value: 'LR',
	},
	{
		label: 'Libya',
		value: 'LY',
	},
	{
		label: 'Liechtenstein',
		value: 'LI',
	},
	{
		label: 'Lithuania',
		value: 'LT',
	},
	{
		label: 'Luxembourg',
		value: 'LU',
	},
	{
		label: 'Macao',
		value: 'MO',
	},
	{
		label: 'Madagascar',
		value: 'MG',
	},
	{
		label: 'Malawi',
		value: 'MW',
	},
	{
		label: 'Malaysia',
		value: 'MY',
	},
	{
		label: 'Maldives',
		value: 'MV',
	},
	{
		label: 'Mali',
		value: 'ML',
	},
	{
		label: 'Malta',
		value: 'MT',
	},
	{
		label: 'Marshall Islands',
		value: 'MH',
	},
	{
		label: 'Martinique',
		value: 'MQ',
	},
	{
		label: 'Mauritania',
		value: 'MR',
	},
	{
		label: 'Mauritius',
		value: 'MU',
	},
	{
		label: 'Mayotte',
		value: 'YT',
	},
	{
		label: 'Mexico',
		value: 'MX',
	},
	{
		label: 'Micronesia (Federated States of)',
		value: 'FM',
	},
	{
		label: 'Moldova, Republic of',
		value: 'MD',
	},
	{
		label: 'Monaco',
		value: 'MC',
	},
	{
		label: 'Mongolia',
		value: 'MN',
	},
	{
		label: 'Montenegro',
		value: 'ME',
	},
	{
		label: 'Montserrat',
		value: 'MS',
	},
	{
		label: 'Morocco',
		value: 'MA',
	},
	{
		label: 'Mozambique',
		value: 'MZ',
	},
	{
		label: 'Myanmar',
		value: 'MM',
	},
	{
		label: 'Namibia',
		value: 'NA',
	},
	{
		label: 'Nauru',
		value: 'NR',
	},
	{
		label: 'Nepal',
		value: 'NP',
	},
	{
		label: 'Netherlands',
		value: 'NL',
	},
	{
		label: 'New Caledonia',
		value: 'NC',
	},
	{
		label: 'New Zealand',
		value: 'NZ',
	},
	{
		label: 'Nicaragua',
		value: 'NI',
	},
	{
		label: 'Niger',
		value: 'NE',
	},
	{
		label: 'Nigeria',
		value: 'NG',
	},
	{
		label: 'Niue',
		value: 'NU',
	},
	{
		label: 'Norfolk Island',
		value: 'NF',
	},
	{
		label: 'North Macedonia',
		value: 'MK',
	},
	{
		label: 'Northern Mariana Islands',
		value: 'MP',
	},
	{
		label: 'Norway',
		value: 'NO',
	},
	{
		label: 'Oman',
		value: 'OM',
	},
	{
		label: 'Pakistan',
		value: 'PK',
	},
	{
		label: 'Palau',
		value: 'PW',
	},
	{
		label: 'Palestine, State of',
		value: 'PS',
	},
	{
		label: 'Panama',
		value: 'PA',
	},
	{
		label: 'Papua New Guinea',
		value: 'PG',
	},
	{
		label: 'Paraguay',
		value: 'PY',
	},
	{
		label: 'Peru',
		value: 'PE',
	},
	{
		label: 'Philippines',
		value: 'PH',
	},
	{
		label: 'Pitcairn',
		value: 'PN',
	},
	{
		label: 'Poland',
		value: 'PL',
	},
	{
		label: 'Portugal',
		value: 'PT',
	},
	{
		label: 'Puerto Rico',
		value: 'PR',
	},
	{
		label: 'Qatar',
		value: 'QA',
	},
	{
		label: 'Réunion',
		value: 'RE',
	},
	{
		label: 'Romania',
		value: 'RO',
	},
	{
		label: 'Russian Federation',
		value: 'RU',
	},
	{
		label: 'Rwanda',
		value: 'RW',
	},
	{
		label: 'Saint Barthélemy',
		value: 'BL',
	},
	{
		label: 'Saint Helena, Ascension and Tristan da Cunha',
		value: 'SH',
	},
	{
		label: 'Saint Kitts and Nevis',
		value: 'KN',
	},
	{
		label: 'Saint Lucia',
		value: 'LC',
	},
	{
		label: 'Saint Martin (French part)',
		value: 'MF',
	},
	{
		label: 'Saint Pierre and Miquelon',
		value: 'PM',
	},
	{
		label: 'Saint Vincent and the Grenadines',
		value: 'VC',
	},
	{
		label: 'Samoa',
		value: 'WS',
	},
	{
		label: 'San Marino',
		value: 'SM',
	},
	{
		label: 'Sao Tome and Principe',
		value: 'ST',
	},
	{
		label: 'Saudi Arabia',
		value: 'SA',
	},
	{
		label: 'Senegal',
		value: 'SN',
	},
	{
		label: 'Serbia',
		value: 'RS',
	},
	{
		label: 'Seychelles',
		value: 'SC',
	},
	{
		label: 'Sierra Leone',
		value: 'SL',
	},
	{
		label: 'Singapore',
		value: 'SG',
	},
	{
		label: 'Sint Maarten (Dutch part)',
		value: 'SX',
	},
	{
		label: 'Slovakia',
		value: 'SK',
	},
	{
		label: 'Slovenia',
		value: 'SI',
	},
	{
		label: 'Solomon Islands',
		value: 'SB',
	},
	{
		label: 'Somalia',
		value: 'SO',
	},
	{
		label: 'South Africa',
		value: 'ZA',
	},
	{
		label: 'South Georgia and the South Sandwich Islands',
		value: 'GS',
	},
	{
		label: 'South Sudan',
		value: 'SS',
	},
	{
		label: 'Spain',
		value: 'ES',
	},
	{
		label: 'Sri Lanka',
		value: 'LK',
	},
	{
		label: 'Sudan',
		value: 'SD',
	},
	{
		label: 'Suriname',
		value: 'SR',
	},
	{
		label: 'Svalbard and Jan Mayen',
		value: 'SJ',
	},
	{
		label: 'Sweden',
		value: 'SE',
	},
	{
		label: 'Switzerland',
		value: 'CH',
	},
	{
		label: 'Syrian Arab Republic',
		value: 'SY',
	},
	{
		label: 'Taiwan, Province of China',
		value: 'TW',
	},
	{
		label: 'Tajikistan',
		value: 'TJ',
	},
	{
		label: 'Tanzania, United Republic of',
		value: 'TZ',
	},
	{
		label: 'Thailand',
		value: 'TH',
	},
	{
		label: 'Timor-Leste',
		value: 'TL',
	},
	{
		label: 'Togo',
		value: 'TG',
	},
	{
		label: 'Tokelau',
		value: 'TK',
	},
	{
		label: 'Tonga',
		value: 'TO',
	},
	{
		label: 'Trinidad and Tobago',
		value: 'TT',
	},
	{
		label: 'Tunisia',
		value: 'TN',
	},
	{
		label: 'Turkey',
		value: 'TR',
	},
	{
		label: 'Turkmenistan',
		value: 'TM',
	},
	{
		label: 'Turks and Caicos Islands',
		value: 'TC',
	},
	{
		label: 'Tuvalu',
		value: 'TV',
	},
	{
		label: 'Uganda',
		value: 'UG',
	},
	{
		label: 'Ukraine',
		value: 'UA',
	},
	{
		label: 'United Arab Emirates',
		value: 'AE',
	},
	{
		label: 'United Kingdom of Great Britain and Northern Ireland',
		value: 'GB',
	},
	{
		label: 'United States of America',
		value: 'US',
	},
	{
		label: 'United States Minor Outlying Islands',
		value: 'UM',
	},
	{
		label: 'Uruguay',
		value: 'UY',
	},
	{
		label: 'Uzbekistan',
		value: 'UZ',
	},
	{
		label: 'Vanuatu',
		value: 'VU',
	},
	{
		label: 'Venezuela (Bolivarian Republic of)',
		value: 'VE',
	},
	{
		label: 'Viet Nam',
		value: 'VN',
	},
	{
		label: 'Virgin Islands (British)',
		value: 'VG',
	},
	{
		label: 'Virgin Islands (U.S.)',
		value: 'VI',
	},
	{
		label: 'Wallis and Futuna',
		value: 'WF',
	},
	{
		label: 'Western Sahara',
		value: 'EH',
	},
	{
		label: 'Yemen',
		value: 'YE',
	},
	{
		label: 'Zambia',
		value: 'ZM',
	},
	{
		label: 'Zimbabwe',
		value: 'ZW',
	},
];
