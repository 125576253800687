import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { devtools } from 'zustand/middleware';
import * as yup from 'yup';

import { createWithEqualityFn } from 'zustand/traditional';
import { StorytellerType } from '@app/redux/storyteller/types';
import { VideoType } from '@app/types/modules/video';
import { CreationMethod, PersonalizationEnum } from '../../constants';
import { VideoTypeEnum } from '@app/containers/CampaignCreationV2/constants/video';
import { normalizeYupErrors } from '../../utils/saveStoreUtils';
import { CampaignType } from '@app/redux/campaign/types';
import { Contact } from '@app/redux/contact/@types';
import {
	checkIsOneToMany,
	checkIsOneToOne,
	checkIsPost,
	checkIsReels,
} from '@app/utils/modules/campaigns';
import { sortStoriesByStoryOrderAndIncludeType } from './utils';
import { hoursPerDay } from '@app/constants';

type StoreCampaignGroupType = {
	name: string;
	storytellers: string[];
	value: string;
};

type StoreType = {
	campaign: {
		name?: string;
		currentStep?: string;
		video?: any;
		storytellers?: any[];
		personalization?: PersonalizationEnum | string;
		creationMethod?: CreationMethod | string;
		videoType?: VideoTypeEnum;
		groups?: StoreCampaignGroupType[];
		assignmentType?: 'evenDistribution' | string;
		storiesPerStorytellersPercentage?: any[];
		dueDate?: string | null;
		instructions?: string;
		recordingScript?: string;
		strictApproval?: boolean | number;
		campaignFallback?: boolean;
		reelsTitle?: string;
		settings?: CampaignType['settings'];
		automationData?: CampaignType['automationData'];
		contacts?: Contact[];
		assignedStories?: any[];
		completedStories?: any[];
	};
	stories: {
		id?: string;
		_id?: string;
		storeId?: string;
		type?: VideoTypeEnum;
		title?: string;
		assignedTo?: string[] | StorytellerType[]; //if assignToDo
		video?: string | VideoType; // if video from library
		instructions?: string;
		recordingScript?: string;
		storyOrder?: number; // must be unique
	}[];
	errors: Record<string, string>;
	addStory: (
		story: StoreType['stories'][number],
		shouldValidate?: boolean,
	) => void;
	removeStory: (id: string | number) => void;
	setStories: (stories: StoreType['stories']) => void;
	setStoryDataById: (
		id: string | number,
		data: Partial<StoreType['stories'][number]>,
	) => void;
	getStoryDataById: (id: string | number) => StoreType['stories'][0];
	setCampaignData: (
		data: Partial<StoreType['campaign']>,
		shouldValidate?: boolean,
	) => void;
	validateStoreStories: (
		stories: StoreType['stories'],
		stealthMode?: boolean,
	) => Promise<boolean>;
	validateOneToMany: (
		campaign: StoreType['campaign'],
		stealthMode?: boolean,
	) => Promise<boolean>;
	validateOneToOne: (
		campaign: StoreType['campaign'],
		stealthMode?: boolean,
	) => Promise<boolean>;
	validateReelsCampaign: (
		campaign: StoreType['campaign'],
		stealthMode?: boolean,
	) => Promise<boolean>;
	validatePostCampaign: (
		campaign: StoreType['campaign'],
		stealthMode?: boolean,
	) => Promise<boolean>;
	campaignValidationTimer: any;
	storiesValidationTimer: any;
	isStoreValid: boolean;
	//Assignment group
	addAssignmentGroup: (group: StoreCampaignGroupType, setAll?: boolean) => void;
	editAssignmentGroup: ({
		groupSelected,
		editedGroup,
	}: {
		groupSelected: StoreCampaignGroupType;
		editedGroup: StoreCampaignGroupType;
	}) => void;
	removeAssignmentGroup: (group: StoreCampaignGroupType) => void;
	validateVideoStep: (
		campaign?: StoreType['campaign'],
		config?: { validateStories?: boolean },
	) => Promise<boolean>;
	reset: () => Promise<void>;
	setInitialValues: (data: Partial<StoreType['campaign']>) => void;
};

const StoriesValidationSchema = yup
	.array()
	.of(
		yup.object().shape({
			id: yup.string(),
			storeId: yup.string(),
			type: yup.mixed().oneOf(['assignToDo', 'videoLibrary', 'videoUpload']),
			title: yup.string().required('Title is required'),
			assignedTo: yup.mixed().nullable(),
			video: yup.mixed().nullable(),
			instructions: yup.string().nullable(),
			recordingScript: yup.string().nullable(),
			storyOrder: yup.number(),
		}),
	)
	.test(
		'has-video-or-assignedTo',
		'Each story must have either a video or recorder assigned.',
		(value) => {
			return value?.every(
				(story) => story.video !== undefined || story.assignedTo !== undefined,
			);
		},
	);

const ReelsCampaignValidationSchema = yup.object().shape({
	reelsTitle: yup.string().required('Title is required'),
});

const OneToManyCampaignValidationSchema = yup
	.object()
	.shape({
		video: yup.mixed().nullable(),
		storytellers: yup.array().nullable(),
	})
	.test(
		'has-video-or-recorder',
		'Campaign must have either a video or recorder assigned',
		(value) => {
			const hasVideo = !!value?.video;
			const hasStorytellers =
				Number((value?.storytellers || [])?.length || 0) > 0;

			return hasVideo || hasStorytellers;
		},
	);

const OneToOneCampaignValidationSchema = yup
	.object()
	.shape({
		groups: yup.array().nullable(),
		storytellers: yup.array().nullable(),
		videoType: yup
			.mixed()
			.oneOf([VideoTypeEnum.ASSIGNMENT_GROUP, VideoTypeEnum.ASSIGN_TO_DO]),
	})
	.test(
		'has-groups-or-storytellers',
		'Campaign must have either a group or recorder(s) assigned',
		(value) => {
			const isAssignmentGroupType =
				value?.videoType === VideoTypeEnum.ASSIGNMENT_GROUP;

			const hasGroups = !!value?.groups?.length;
			const hasStorytellers =
				Number((value?.storytellers || [])?.length || 0) > 0;

			const allGroupsHaveStorytellers = value?.groups?.every(
				(group) => group.storytellers?.length,
			);

			return isAssignmentGroupType
				? hasGroups && allGroupsHaveStorytellers
				: hasStorytellers;
		},
	);

const PostCampaignValidationSchema = yup
	.object()
	.shape({
		video: yup.mixed().nullable(),
		storytellers: yup.array().nullable(),
	})
	.test('has-video', 'Campaign must have a video', (value) => {
		const hasVideo = !!value?.video;

		return !!hasVideo;
	});

const useVideoSaveStore = createWithEqualityFn(
	devtools(
		immer((set, get) => ({
			stories: [],
			campaign: {},
			errors: {},
			campaignValidationTimer: null,
			storiesValidationTimer: null,
			isStoreValid: false,
			setStories: async (stories: StoreType['stories']) => {
				set(
					(state: StoreType) => {
						state.stories = stories;
					},
					false,
					'videoSaveStore/setStories',
				);

				const currentStore = get() as StoreType;

				// Validate the new stories array
				await currentStore.validateStoreStories(stories);
			},
			setStoryDataById: async (
				id: string,
				data: Partial<StoreType['stories'][number]>,
			) => {
				set(
					(state: StoreType) => {
						const index = state.stories.findIndex(
							(story) => (story.id || story.storeId) === id,
						);

						if (index === -1) return;

						state.stories[index] = {
							...state.stories[index],
							...data,
						};
					},
					false,
					'videoSaveStore/setStorieDataById',
				);

				const currentStore = get() as StoreType;

				// Validate the new stories array

				//Debounce the validation
				if (currentStore.storiesValidationTimer) {
					clearTimeout(currentStore.storiesValidationTimer);
				}

				set(
					(state: StoreType) => {
						state.storiesValidationTimer = setTimeout(async () => {
							currentStore.validateStoreStories(currentStore.stories);
						}, 600);
					},
					false,
					'videoSaveStore/setStoryDataByIdValidationTimerSet',
				);
			},
			getStoryDataById: (id: string) => {
				const currentStore = get() as StoreType;

				return currentStore.stories.find(
					(story) => (story.id || story.storeId) === id,
				);
			},
			addStory: async (
				story: StoreType['stories'][number],
				shouldValidate = true,
			) => {
				set(
					(state: StoreType) => {
						state.stories.push(story);
					},
					false,
					'videoSaveStore/addStory',
				);

				const currentStore = get() as StoreType;

				if (shouldValidate) {
					// Validate the new stories array
					await currentStore.validateStoreStories([
						...currentStore.stories,
						story,
					]);
				}
			},
			removeStory: async (id: string | number) => {
				set(
					(state: StoreType) => {
						const index = state.stories.findIndex(
							(story) => (story.id || story.storeId) === id,
						);

						if (index === -1) return;

						state.stories.splice(index, 1);
					},
					false,
					'videoSaveStore/removeStory',
				);

				const currentStore = get() as StoreType;

				// Validate existing stories array
				await currentStore.validateStoreStories([...currentStore.stories]);
			},
			setCampaignData: async (
				data: Partial<StoreType['campaign']>,
				shouldValidate = true,
			) => {
				const campaignData = data;

				set(
					(state: StoreType) => {
						state.campaign = {
							...state.campaign,
							...campaignData,
						};
					},
					false,
					'videoSaveStore/setCampaignData',
				);

				if (shouldValidate) {
					const currentStore = get() as StoreType;

					// Validate the campaign fields

					//Debounce the validation

					if (currentStore.campaignValidationTimer) {
						clearTimeout(currentStore.campaignValidationTimer);
					}

					set(
						(state: StoreType) => {
							state.campaignValidationTimer = setTimeout(async () => {
								currentStore.validateVideoStep(
									{
										...currentStore.campaign,
										...campaignData,
									},
									{
										validateStories: false,
									},
								);
							}, 600);
						},
						false,
						'videoSaveStore/setCampaignValidationTimer',
					);
				}
			},

			addAssignmentGroup: (group: StoreCampaignGroupType, setAll?: boolean) => {
				const currentStore = get() as StoreType;

				currentStore.setCampaignData({
					...currentStore.campaign,
					groups: setAll
						? [group]
						: [...(currentStore.campaign.groups || []), group],
				});
			},

			editAssignmentGroup: ({
				groupSelected,
				editedGroup,
			}: {
				groupSelected: StoreCampaignGroupType;
				editedGroup: StoreCampaignGroupType;
			}) => {
				/**
				 * Gets the current groups array and replaces the group to be edited
				 * */
				const handleGroupReplacement = (
					groups: StoreType['campaign']['groups'],
					groupToBeAdded: StoreCampaignGroupType,
				) => {
					const groupIndex: number = groups?.findIndex(
						(group) => group.value === groupSelected?.value,
					) as number;

					const groupsCopy = groups ? [...groups] : [];

					if (groupIndex !== -1 && groupsCopy) {
						groupsCopy[groupIndex] = groupToBeAdded;
					}

					return groupsCopy;
				};

				const currentStore = get() as StoreType;

				currentStore.setCampaignData({
					...currentStore.campaign,
					groups: handleGroupReplacement(
						currentStore.campaign.groups,
						editedGroup,
					),
				});
			},
			removeAssignmentGroup: (group: StoreCampaignGroupType) => {
				const currentStore = get() as StoreType;

				currentStore.setCampaignData({
					...currentStore.campaign,
					groups: (currentStore.campaign.groups || []).filter(
						(g) => g.value !== group.value,
					),
				});
			},
			setInitialValues: async (campaign: Partial<StoreType['campaign']>) => {
				const campaignData = {
					name: campaign?.name,
					video: campaign?.video,
					storytellers: campaign?.storytellers,
					personalization: campaign?.personalization,
					creationMethod: campaign?.creationMethod,
					groups: campaign?.groups,
					dueDate: campaign?.dueDate,
					instructions: campaign?.instructions,
					recordingScript: campaign?.recordingScript,
					strictApproval: campaign?.strictApproval,
					campaignFallback: campaign?.campaignFallback,
					reelsTitle: campaign?.reelsTitle,
					settings: campaign?.settings,
					contacts: campaign?.contacts,
					assignedStories: campaign?.assignedStories,
					completedStories: campaign?.completedStories,
				} as unknown as CampaignType;

				const isPost = checkIsPost(campaignData?.personalization as string);
				const isReels = checkIsReels(campaignData?.personalization as string);
				const isOneToMany = checkIsOneToMany(
					campaignData?.personalization as string,
				);
				const isOneToOne = checkIsOneToOne(campaignData);

				const currentStore = get() as StoreType;

				if (isReels) {
					// Concatenate the stories from the campaign - assigned and completed (selected video)
					const allStories = [
						...(campaignData.assignedStories &&
						campaignData.assignedStories.length
							? campaignData.assignedStories
							: []),
						...(campaignData.completedStories &&
						campaignData.completedStories.length
							? campaignData.completedStories
							: []),
					];

					// Then transform and sort the result
					const storiesWithType =
						sortStoriesByStoryOrderAndIncludeType(allStories);

					currentStore.setStories(storiesWithType);
					currentStore.setCampaignData(campaignData, false);

					// If there are no stories, add one
					if (!storiesWithType || storiesWithType?.length === 0) {
						const storiesSizing = currentStore.stories?.length ?? 0;
						const newStoryId = `${storiesSizing}-${Date.now()}`;

						const newStory: StoreType['stories'][number] = {
							storeId: newStoryId,
							title: `Stop`,
						};

						currentStore.addStory(newStory, false);
					}
				}

				if (isOneToOne) {
					currentStore.setCampaignData(
						{
							...campaignData,
							assignmentType: 'evenDistribution',
							videoType: campaignData?.groups?.length
								? VideoTypeEnum.ASSIGNMENT_GROUP
								: campaignData?.storytellers?.length
									? VideoTypeEnum.ASSIGN_TO_DO
									: undefined,
							automationData: {
								active: !!campaignData?.automation,
								campaignId: campaignData?.automation?.targetCampaign,
								targetAssignmentHours:
									campaignData?.automation?.metadata?.targetAssignmentHours ||
									hoursPerDay,
							},
						},
						!!campaignData?.groups?.length ||
							!!campaignData?.storytellers?.length,
					);
				}

				if (isOneToMany) {
					currentStore.setCampaignData(
						{
							...campaignData,
							videoType: campaignData?.video
								? VideoTypeEnum.VIDEO_LIBRARY
								: campaignData?.storytellers?.length
									? VideoTypeEnum.ASSIGN_TO_DO
									: undefined,
						},
						!!campaignData?.storytellers?.length || !!campaignData?.video,
					);
				}

				if (isPost) {
					currentStore.setCampaignData(
						{
							...campaignData,
							videoType: campaignData?.video
								? VideoTypeEnum.VIDEO_LIBRARY
								: undefined,
						},
						!!campaignData?.storytellers?.length || !!campaignData?.video,
					);
				}
			},
			validateStoreStories: async (
				stories: StoreType['stories'],
				stealthMode?: boolean,
			) => {
				try {
					await StoriesValidationSchema.validate(stories, {
						abortEarly: false,
					});

					set(
						(state: StoreType) => {
							state.errors = {};
							state.isStoreValid = true;
						},
						false,
						'videoSaveStore/validateStoreStories',
					);

					return true;
				} catch (error: any) {
					if (stealthMode) {
						// If in stealth mode, don't update the 'errors' object
						set((state: StoreType) => {
							state.isStoreValid = false;
						});

						return false;
					}
					// If validation fails, update the 'errors' object with validation errors
					const validationErrors = normalizeYupErrors(error);

					set((state: StoreType) => {
						state.errors = validationErrors;
						state.isStoreValid = false;
					});

					return false; // Validation failed
				}
			},
			validateOneToMany: async (
				campaign: StoreType['campaign'],
				stealthMode?: boolean,
			) => {
				try {
					await OneToManyCampaignValidationSchema.validate(campaign, {
						abortEarly: false,
					});

					set(
						(state: StoreType) => {
							state.errors = {};
							state.isStoreValid = true;
						},
						false,
						'videoSaveStore/validateOneToMany',
					);

					return true;
				} catch (error: any) {
					if (stealthMode) {
						// If in stealth mode, don't update the 'errors' object
						set((state: StoreType) => {
							state.isStoreValid = false;
						});

						return false;
					}

					// If validation fails, update the 'errors' object with validation errors
					const validationErrors = normalizeYupErrors(error);

					set((state: StoreType) => {
						state.errors = validationErrors;
						state.isStoreValid = false;
					});
					return false; // Validation failed
				}
			},
			validateOneToOne: async (
				campaign: StoreType['campaign'],
				stealthMode?: boolean,
			) => {
				try {
					await OneToOneCampaignValidationSchema.validate(campaign, {
						abortEarly: false,
					});

					set(
						(state: StoreType) => {
							state.errors = {};
							state.isStoreValid = true;
						},
						false,
						'videoSaveStore/validateOneToOne',
					);

					return true;
				} catch (error: any) {
					if (stealthMode) {
						// If in stealth mode, don't update the 'errors' object
						set((state: StoreType) => {
							state.isStoreValid = false;
						});

						return false;
					}

					// If validation fails, update the 'errors' object with validation errors
					const validationErrors = normalizeYupErrors(error);

					set((state: StoreType) => {
						state.errors = validationErrors;
						state.isStoreValid = false;
					});
					return false; // Validation failed
				}
			},
			validateReelsCampaign: async (
				campaign: StoreType['campaign'],
				stealthMode?: boolean,
			) => {
				try {
					await ReelsCampaignValidationSchema.validate(campaign, {
						abortEarly: false,
					});

					set(
						(state: StoreType) => {
							state.errors = {};
							state.isStoreValid = true;
						},
						false,
						'videoSaveStore/validateReelsCampaign',
					);

					return true;
				} catch (error: any) {
					if (stealthMode) {
						// If in stealth mode, don't update the 'errors' object
						set((state: StoreType) => {
							state.isStoreValid = false;
						});

						return false;
					}

					// If validation fails, update the 'errors' object with validation errors
					const validationErrors = normalizeYupErrors(error);

					set((state: StoreType) => {
						state.errors = validationErrors;
						state.isStoreValid = false;
					});
					return false; // Validation failed
				}
			},
			validatePostCampaign: async (
				campaign: StoreType['campaign'],
				stealthMode?: boolean,
			) => {
				try {
					await PostCampaignValidationSchema.validate(campaign, {
						abortEarly: false,
					});

					set(
						(state: StoreType) => {
							state.errors = {};
							state.isStoreValid = true;
						},
						false,
						'videoSaveStore/validatePostCampaign',
					);

					return true;
				} catch (error: any) {
					if (stealthMode) {
						// If in stealth mode, don't update the 'errors' object
						set((state: StoreType) => {
							state.isStoreValid = false;
						});

						return false;
					}

					// If validation fails, update the 'errors' object with validation errors
					const validationErrors = normalizeYupErrors(error);

					set((state: StoreType) => {
						state.errors = validationErrors;
						state.isStoreValid = false;
					});
					return false; // Validation failed
				}
			},
			validateVideoStep: async (
				campaign?: StoreType['campaign'],
				config?: { validateStories?: boolean; stealthMode?: boolean },
			) => {
				const { validateStories, stealthMode } = config || {
					validateStories: true,
					stealthMode: false,
				};

				const currentStore = get() as StoreType;

				const campaignData = campaign || currentStore.campaign;

				const { personalization } = campaignData;

				const isPost = personalization === PersonalizationEnum.post;
				const isReels = personalization === PersonalizationEnum.reels;
				const isOneToOne = personalization === PersonalizationEnum.oneToOne;
				const isOneToMany = personalization === PersonalizationEnum.oneToAll;

				if (isOneToMany) {
					return await currentStore.validateOneToMany(
						campaignData,
						stealthMode,
					);
				}

				if (isReels && currentStore.stories) {
					if (validateStories) {
						return (
							(await currentStore.validateReelsCampaign(
								campaignData,
								stealthMode,
							)) &&
							(await currentStore.validateStoreStories(
								currentStore.stories,
								stealthMode,
							))
						);
					}

					return await currentStore.validateReelsCampaign(
						campaignData,
						stealthMode,
					);
				}

				if (isOneToOne) {
					return await currentStore.validateOneToOne(campaignData, stealthMode);
				}

				if (isPost) {
					return await currentStore.validatePostCampaign(
						campaignData,
						stealthMode,
					);
				}

				return false;
			},
			reset: async () => {
				set(
					(state: StoreType) => {
						state.stories = [];
						state.campaign = {};
						state.errors = {};
					},
					true,
					'videoSaveStore/reset',
				);
			},
		})),
		{ name: 'videoSaveStore' },
	),
	shallow,
);

export default useVideoSaveStore;
export type { StoreType as VideoSaveStoreType, StoreCampaignGroupType };
