export function checkShouldSetUser(
	workspaceId: string,
	storytellerId: string,
	currentWorkspaceId: string,
	currentStorytellerId: string,
): boolean {
	const isDifferentWorkspace = workspaceId !== currentWorkspaceId;
	const isDifferentStoryteller = storytellerId !== currentStorytellerId;

	return isDifferentWorkspace || isDifferentStoryteller;
}
