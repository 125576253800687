import * as R from 'ramda';

function moveToTopById(
	id: string | number,
	array: Record<string | number, any>[],
) {
	const index = R.findIndex(R.compose(R.equals(id), R.prop('id')))(array);

	if (index === -1) {
		// Handle the case where the ID doesn't exist in the array.
		return array;
	}

	// Use Ramda's move function to reorder the array.
	const reorderedArray = R.move(index, 0, array);

	return reorderedArray;
}

export default moveToTopById;
