import styled from 'styled-components';

import { Paragraph } from '@common/components/presentation/NewTypography';

export const TableContainer = styled.div<{ isScrollable: boolean }>`
	position: relative;
	display: flex;
	flex: 1;
	overflow-y: ${({ isScrollable }) => isScrollable && 'auto'};

	&::-webkit-scrollbar {
		width: 16px;
		background: ${({ theme }) => theme.colors.neutral[0]};
		border-radius: ${({ theme }) => theme.borderRadius?.rounded};
	}
	&::-webkit-scrollbar-track {
		background: ${({ theme }) => theme.colors.neutral[0]};
		background-clip: padding-box;
		border: 5px solid ${({ theme }) => theme.colors.neutral[0]};
		border-radius: ${({ theme }) => theme.borderRadius?.rounded};
	}
	&::-webkit-scrollbar-thumb {
		background: ${({ theme }) => theme.colors.neutral[100]};
		background-clip: padding-box;
		border: 5px solid ${({ theme }) => theme.colors.neutral[0]};
		border-radius: ${({ theme }) => theme.borderRadius?.rounded};
	}
	&::-webkit-scrollbar-thumb:hover {
		background: ${({ theme }) => theme.colors.neutral[200]};
	}
`;

export const StyledTable = styled.table<{
	$loading: boolean;
	hasRoundRows: boolean;
}>`
	position: relative;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	border: none;
	visibility: ${({ $loading }) => $loading && 'hidden'};
	height: fit-content;

	/** Makes header and rows round */
	td,
	th {
		&:first-child {
			border-radius: ${({ theme, hasRoundRows }) =>
				hasRoundRows &&
				`${theme.borderRadius?.components.large} 0 0 ${theme.borderRadius?.components.large}`};
		}

		&:last-child {
			border-radius: ${({ theme, hasRoundRows }) =>
				hasRoundRows &&
				`0 ${theme.borderRadius?.components.large} ${theme.borderRadius?.components.large} 0`};
		}
	}
`;

export const Heading = styled.thead`
	position: sticky;
	top: 0;
	background: ${({ theme }) => theme.colors.background};
	z-index: ${({ theme }) => theme.zIndex.min};
`;

export const TableRow = styled.tr<{ onClick?: () => void }>`
	height: 48px;
	max-height: 48px;
	cursor: ${({ onClick }) => onClick && 'pointer'};

	&:nth-child(even) {
		background: ${({ theme }) => theme.colors.background};
	}
	&:nth-child(odd) {
		background: ${({ theme }) => theme.colors.neutral[0]};
	}

	&:hover {
		background: ${({ theme, onClick }) => onClick && theme.colors.lightestBlue};
	}
`;

export const HeadingRow = styled.tr`
	height: 40px;
	max-height: 40px;
`;

export const TableHeader = styled.th<{ centered?: boolean }>`
	padding: 0 8px;
	height: 40px;
	margin: 0;
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	font-weight: 600;
	white-space: nowrap;
	vertical-align: middle;
`;

export const CheckboxTableHeader = styled(TableHeader)`
	padding: 0 12px;
	width: 50px;
`;

export const TableCell = styled.td<{ centered?: boolean }>`
	padding: 0 8px;
	margin: 0;
	height: 48px;
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	vertical-align: middle;

	> * {
		margin: ${({ centered }) => centered && '0 auto'};
	}
`;

export const CheckboxTableCell = styled(TableCell)`
	padding: 0 12px;
	width: 50px;
`;

export const TableParagraph = styled(Paragraph)``;

export const Flex = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
`;

export const TableLoaderContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;
