import Joi from '@hapi/joi';
import { CTAValidatorMessages } from '@app/containers/CampaignCreation/containers/Templates/constant';
import { MergeTagSpaceAroundRegex, UrlRegex } from '@app/constants/regex';

export const stringWithRegex = (
	regex: RegExp,
	messages: { required: string; empty: string; invalid: string },
) => {
	return Joi.string()
		.pattern(regex)
		.required()
		.messages({
			'any.required': `${messages.required}`,
			'string.empty': `${messages.empty}`,
			'string.pattern.base': `${messages.invalid}`,
		});
};

const baseCTASchemaValues = {
	name: Joi.string()
		.trim()
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.internalActionNameRequired}`,
			'string.empty': `${CTAValidatorMessages.internalActionNameRequired}`,
		}),
	archived: Joi.boolean(),
	cueCard: Joi.array(),
	deleted: Joi.boolean(),
	image: Joi.string().allow(null, ''),
	id: Joi.string(),
	_id: Joi.string(),
	__v: Joi.number(),
	workspace: Joi.string(),
	createdAt: Joi.string(),
	updatedAt: Joi.string(),
	formattedUpdatedAt: Joi.string(),
	formattedCreatedAt: Joi.string(),
	location: Joi.string(),
	preview: Joi.boolean(),
	type: Joi.string(),
	description: Joi.string().allow(null, ''),
	buttonText: Joi.string().allow(null, ''),
	title: Joi.string().allow(null, ''),
};

export const doubleLinkCTAValidatorSchema = Joi.object({
	...baseCTASchemaValues,
	primaryLinkTitle: Joi.string()
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.primaryLinkTitleRequired}`,
			'string.empty': `${CTAValidatorMessages.primaryLinkTitleRequired}`,
		}),
	primaryLinkUrl: Joi.alternatives().try(
		stringWithRegex(UrlRegex, {
			required: CTAValidatorMessages.primaryLinkUrlRequired,
			empty: CTAValidatorMessages.primaryLinkUrlRequired,
			invalid: CTAValidatorMessages.primaryLinkUrlInvalid,
		}),
		stringWithRegex(MergeTagSpaceAroundRegex, {
			required: CTAValidatorMessages.primaryLinkUrlRequired,
			empty: CTAValidatorMessages.primaryLinkUrlRequired,
			invalid: CTAValidatorMessages.primaryLinkUrlInvalid,
		}),
	),
	secondaryLinkTitle: Joi.string()
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.secondaryLinkTitleRequired}`,
			'string.empty': `${CTAValidatorMessages.secondaryLinkTitleRequired}`,
		}),
	secondaryLinkUrl: Joi.alternatives().try(
		stringWithRegex(UrlRegex, {
			required: CTAValidatorMessages.secondaryLinkUrlRequired,
			empty: CTAValidatorMessages.secondaryLinkUrlRequired,
			invalid: CTAValidatorMessages.secondaryLinkUrlInvalid,
		}),
		stringWithRegex(MergeTagSpaceAroundRegex, {
			required: CTAValidatorMessages.secondaryLinkUrlRequired,
			empty: CTAValidatorMessages.secondaryLinkUrlRequired,
			invalid: CTAValidatorMessages.secondaryLinkUrlInvalid,
		}),
	),
});

export const linkCTAValidatorSchema = Joi.object({
	...baseCTASchemaValues,
	linkButtonTitle: Joi.string()
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.buttonTitleRequired}`,
			'string.empty': `${CTAValidatorMessages.buttonTitleRequired}`,
		}),
	linkUrl: Joi.alternatives().try(
		stringWithRegex(UrlRegex, {
			required: CTAValidatorMessages.linkUrlRequired,
			empty: CTAValidatorMessages.linkUrlRequired,
			invalid: CTAValidatorMessages.linkUrlInvalid,
		}),
		stringWithRegex(MergeTagSpaceAroundRegex, {
			required: CTAValidatorMessages.linkUrlRequired,
			empty: CTAValidatorMessages.linkUrlRequired,
			invalid: CTAValidatorMessages.linkUrlInvalid,
		}),
	),
});

export const codeCTAValidatorSchema = Joi.object({
	...baseCTASchemaValues,
	script: Joi.string(),
	buttonText: Joi.string()
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.buttonTitleRequired}`,
			'string.empty': `${CTAValidatorMessages.buttonTitleRequired}`,
		}),
});

export const suggestedReplyValidatorSchema = Joi.object({
	...baseCTASchemaValues,
	name: Joi.string()
		.trim()
		.max(30)
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.internalRepliesNameRequired}`,
			'string.empty': `${CTAValidatorMessages.internalRepliesNameRequired}`,
			'string.max': `${CTAValidatorMessages.internalRepliesNameMax} 30 chars allowed.`,
		}),
	textReplies: Joi.array().items(Joi.string()),
	emojiReplies: Joi.array().items(Joi.string()),
});

export const eventCTAValidatorSchema = Joi.object({
	...baseCTASchemaValues,
	eventTitle: Joi.string()
		.trim()
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.eventTitleRequired}`,
			'string.empty': `${CTAValidatorMessages.eventTitleRequired}`,
		}),
	eventDate: Joi.date()
		.required()
		.min(new Date())
		.messages({
			'any.required': `${CTAValidatorMessages.eventTitleRequired}`,
			'string.empty': `${CTAValidatorMessages.eventTitleRequired}`,
			'date.min': `${CTAValidatorMessages.eventDateInvalid}`,
		}),
	isAllDayEvent: Joi.boolean(),
	startTime: Joi.date().allow(null, ''),
	endTime: Joi.date().allow(null, ''),
	timezone: Joi.string(),
	linkUrl: Joi.alternatives().try(
		stringWithRegex(UrlRegex, {
			required: CTAValidatorMessages.linkUrlRequired,
			empty: CTAValidatorMessages.linkUrlRequired,
			invalid: CTAValidatorMessages.linkUrlInvalid,
		}),
		stringWithRegex(MergeTagSpaceAroundRegex, {
			required: CTAValidatorMessages.linkUrlRequired,
			empty: CTAValidatorMessages.linkUrlRequired,
			invalid: CTAValidatorMessages.linkUrlInvalid,
		}),
	),
	linkButtonTitle: Joi.string()
		.required()
		.messages({
			'any.required': `${CTAValidatorMessages.buttonTitleRequired}`,
			'string.empty': `${CTAValidatorMessages.buttonTitleRequired}`,
		}),
});
