import React from 'react';

import {
	AvatarList,
	Button,
	Checkbox,
	Text,
} from '@common/design-system/components/atoms';

import objectNestedKeys from '@app/utils/objectNestedKey';

import { TableItemType, TableMapperType } from '../../types';

import * as S from '../../Table.styles';
import {
	BadgeCell,
	BadgeFloatingMenuCell,
	CheckBoxCell,
	IconButtonCell,
	PinButtonCell,
	StatusIconCell,
	TextCell,
	UserDisplayCell,
	CircleIconCell,
	MultiBadgeCell,
	LinkButtonCell,
} from '../TableCells';

export type TableRowProps = {
	item: TableItemType;
	mappers: TableMapperType[];
	isChecked: boolean;
	onClickRow?: (item: any) => void;
	onCheckRow?: () => void;
	heightSize: number;
	firstColumnFixed?: boolean;
	lastColumnFixed?: boolean;
};

function TableRow({
	item,
	mappers,
	isChecked,
	onClickRow,
	onCheckRow,
	heightSize,
	firstColumnFixed,
	lastColumnFixed,
}: TableRowProps) {
	//TODO: Split into cell components

	const renderCell = (type: string, mapperField: TableMapperType) => {
		switch (type) {
			case 'text':
				return <TextCell mapperField={mapperField} item={item} />;
			case 'user':
				return <UserDisplayCell mapperField={mapperField} item={item} />;
			case 'userList':
				return (
					<AvatarList displayAmount={3} avatars={item[mapperField.key] || []} />
				);
			case 'badge':
				return <BadgeCell mapperField={mapperField} item={item} />;
			case 'badgeFloatingMenu':
				return <BadgeFloatingMenuCell item={item} mapperField={mapperField} />;
			case 'multiBadge':
				return <MultiBadgeCell item={item} mapperField={mapperField} />;
			case 'statusIcon':
				return <StatusIconCell item={item} mapperField={mapperField} />;
			case 'circleIcons':
				return <CircleIconCell item={item} mapperField={mapperField} />;

			case 'linkButton':
				return <LinkButtonCell item={item} mapperField={mapperField} />;
			case 'button':
				return (
					mapperField.onButtonClick && (
						<Button
							variant="tertiary"
							{...mapperField?.buttonProps}
							onClick={() =>
								mapperField.onButtonClick && mapperField.onButtonClick(item)
							}
						>
							{mapperField.buttonText}
						</Button>
					)
				);
			case 'buttonGroup':
				return (
					!!mapperField.buttonGroup?.length && (
						<S.ButtonGroupWrapper>
							{mapperField.buttonGroup.map((button, index) => (
								<Button
									key={index}
									variant="tertiary"
									{...button}
									onClick={() => button.onClick && button.onClick(item)}
								>
									{button.text}
								</Button>
							))}
						</S.ButtonGroupWrapper>
					)
				);
			case 'iconButton':
				return <IconButtonCell item={item} mapperField={mapperField} />;
			case 'checkbox':
				return <CheckBoxCell item={item} mapperField={mapperField} />;
			case 'pinButton':
				return <PinButtonCell item={item} mapperField={mapperField} />;
			default:
				return <Text>{objectNestedKeys(item, mapperField.key)}</Text>;
		}
	};

	const multiRenderCell = (
		mapperField: TableMapperType,
		mapperIndex?: number,
	) => {
		const CellCheckBox = () => {
			return (
				<>
					{!!onCheckRow && (
						<S.CheckboxTableCell onClick={(e) => e.stopPropagation()}>
							<Checkbox
								ariaLabel="Select row"
								checked={isChecked}
								onChange={() => onCheckRow()}
							/>
						</S.CheckboxTableCell>
					)}
				</>
			);
		};

		const typeData =
			typeof mapperField.type === 'function'
				? mapperField.type(item)
				: mapperField.type;

		const multiCellSettings =
			typeof mapperField.multiCellSettings === 'function'
				? mapperField.multiCellSettings(item)
				: mapperField.multiCellSettings;

		const multiCellSettingsClasses = {
			spaceBetweenCells: 'multi-cell-space-between',
		};

		if (Array.isArray(typeData)) {
			return (
				<div
					className={`table-cell-inner-container multi-cell-inner-container ${multiCellSettings?.spaceBetweenCells ? `${multiCellSettingsClasses.spaceBetweenCells}` : ''}`}
				>
					{typeData.map((type, cellTypeIndex) => {
						return (
							<span key={cellTypeIndex} className="d-flex align-items-center">
								<>
									{mapperIndex === 0 && cellTypeIndex === 0 && (
										<>{!!onCheckRow && <CellCheckBox />}</>
									)}
									{renderCell(type, mapperField)}
								</>
							</span>
						);
					})}
				</div>
			);
		}

		return (
			<div className="table-cell-inner-container">
				{mapperIndex === 0 && <>{!!onCheckRow && <CellCheckBox />}</>}
				{renderCell(typeData, mapperField)}
			</div>
		);
	};

	const getIsMaxContentWidth = (mapperField: TableMapperType) => {
		//This is used to tell which cells should have max-content width
		const maxContentCells = [
			'user',
			'userList',
			'button',
			'buttonGroup',
			'text',
			'iconButton',
			'badge',
		];

		const typeToCheck =
			typeof mapperField.type === 'function'
				? mapperField.type(item)
				: mapperField.type;

		return Array.isArray(typeToCheck)
			? typeToCheck.some((type) => maxContentCells.includes(type))
			: maxContentCells.includes(typeToCheck);
	};

	return (
		<S.StyledTableRow
			onClick={onClickRow ? () => onClickRow(item) : undefined}
			heightSize={heightSize}
			firstColumnFixed={firstColumnFixed}
			lastColumnFixed={lastColumnFixed}
		>
			<>
				{mappers.map((mapperField: TableMapperType, index: number) => (
					<S.TableCell
						key={index}
						centered={mapperField.centered}
						heightSize={heightSize}
						cellWidth={mapperField.cellWidth}
						maxContentWidth={getIsMaxContentWidth(mapperField)}
					>
						{multiRenderCell(mapperField, index)}
					</S.TableCell>
				))}
			</>
		</S.StyledTableRow>
	);
}

export default React.memo(TableRow);
