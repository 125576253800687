import styled, { css } from 'styled-components';

export const StyledButton = styled.div<{
	disabled?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	pointer-events: ${({ disabled = false }) => {
		return disabled ? 'none' : 'auto';
	}};
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
	background-color: ${({ theme }) => theme.colors.system.text.default};
	color: ${({ theme }) => theme.colors.system.background.system};
	border: none;
	width: 20px;
	height: 20px;

	:hover {
		opacity: 0.8;
	}

	${({ disabled }) => {
		if (disabled) {
			return css`
				opacity: 0.45;
				cursor: not-allowed;
			`;
		}
	}}
`;
