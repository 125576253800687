import styled from 'styled-components';

export const MenuWrapper = styled.div`
	z-index: ${({ theme }) => theme.zIndex.fixed};
	position: relative;
	display: flex;
	width: 280px;
	flex-direction: column;
	align-items: flex-start;
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	background: ${({ theme }) => theme.colors.system.background.system};
	box-shadow: ${({ theme }) => theme.shadows.elevation.xl};
`;

export const UserInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
`;

export const UserInfoWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xs};
	padding: ${({ theme }) => `${theme.spacing.xs} 10px`};
`;

export const UserInfoDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const NavListContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
	padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
`;

export const BottomContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: ${({ theme }) => theme.spacing.xxs};
	padding: ${({ theme }) =>
		`${theme.spacing.xxs} ${theme.spacing.xs} ${theme.spacing.xs}`};
	align-self: stretch;
`;

export const VersonContainer = styled.div`
	display: flex;
	padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
	justify-content: center;
	align-items: center;
	gap: 6px;
	align-self: stretch;
`;
