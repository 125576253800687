import { OptionsType } from '@app/types';

type ObjectToOptionsReturnType = OptionsType[];

export function denormalizedObjectToOptions(
	objArray: any[],
	field: string,
	text?: string,
): ObjectToOptionsReturnType {
	return objArray?.map(
		(obj: { [key: string]: any }) =>
			({
				value: obj[field],
				label: text,
			}) as OptionsType,
	);
}

function ObjectToOptions(
	NormalizedObject: { [key: string]: any },
	field: string,
	text?: string,
	textFormat?: (value: string | boolean) => string,
): ObjectToOptionsReturnType {
	return (
		NormalizedObject &&
		Object.entries(NormalizedObject)?.map(([id, value]) => ({
			value: value[field],
			label: textFormat
				? textFormat(text ? value[text] || value[field] : value[field])
				: value[text as string] || field,
			profilePicture: value?.profilePicture,
		}))
	);
}

export default ObjectToOptions;
