import React, {
	useState,
	useEffect,
	useMemo,
	useCallback,
	CSSProperties,
} from 'react';
import Picker from 'emoji-picker-react';

import * as S from './TagsInputTooltip.styles';

import { InputTabNames, NOT_FOUND } from './constants';

import { OptionsType } from '../../types';

import { Tabs, Input, Text } from '@common/design-system/components/atoms';
import { INITIAL_HEIGHT } from '../../constants';

export type TagsInputTooltipProps = {
	tagOptions: OptionsType[];
	tooltipRef: any;
	onSelect: (tag: OptionsType, isEmoji?: boolean) => void;
	style: CSSProperties;
	hideTabs?: boolean;
};

export default function TagsInputTooltip({
	tagOptions,
	tooltipRef,
	onSelect,
	style,
	hideTabs = false,
}: TagsInputTooltipProps) {
	const [height, setHeight] = useState<number>(INITIAL_HEIGHT);
	const [search, setSearch] = useState<string>('');
	const [activeTab, setActiveTab] = useState<string>(InputTabNames.mergeTag);

	useEffect(() => {
		if (tooltipRef?.current) setHeight(tooltipRef.current.offsetHeight);
	}, [tooltipRef]);

	const tabs = [
		{
			isActive: activeTab === InputTabNames.mergeTag,
			onSelect: () => setActiveTab(InputTabNames.mergeTag),
			iconName: 'personAdd',
			text: 'Tags',
		},
		{
			isActive: activeTab === InputTabNames.emoji,
			onSelect: () => setActiveTab(InputTabNames.emoji),
			iconName: 'smiley',
			text: 'Emoji',
		},
	];

	const onEmojiClick = (_event: any, emojiObject: any) => {
		onSelect(emojiObject.emoji, true);
	};

	const filteredTagsInput = useMemo(() => {
		const filteredTags = tagOptions.filter((option) =>
			option.label.toLowerCase().includes(search.toLowerCase()),
		);
		if (filteredTags.length === 0) filteredTags.push(NOT_FOUND);

		return filteredTags;
	}, [search]);

	const handleOptionsToRender = useCallback(
		() => (search.length > 1 ? filteredTagsInput : tagOptions),
		[filteredTagsInput],
	);

	return (
		<S.TagsInputTooltipContainer
			ref={tooltipRef}
			id="merge-tags-tooltip"
			height={height}
			style={style}
		>
			<S.EmojiAndOptionsWrapper>
				{!hideTabs && <Tabs tabs={tabs} />}
				{activeTab === InputTabNames.emoji && (
					<Picker
						onEmojiClick={onEmojiClick}
						groupNames={{ smileys_people: 'PEOPLE' }}
						native
					/>
				)}

				{activeTab === InputTabNames.mergeTag && (
					<>
						<Input
							id="search-merge-tags"
							name="search-merge-tags"
							value={search}
							onChange={(e: any) => setSearch(`${e.target.value}`)}
							placeholder="Search by name"
						/>
						<S.OptionsContainer>
							{handleOptionsToRender().map((option, index) => (
								<S.Option
									key={`${option}-${index}`}
									onClick={() =>
										option.value !== NOT_FOUND.value && onSelect(option)
									}
								>
									<Text
										size="small"
										color="system.text.default"
										lineHeight="large"
									>
										{option.label}
									</Text>
								</S.Option>
							))}
						</S.OptionsContainer>
					</>
				)}
			</S.EmojiAndOptionsWrapper>
		</S.TagsInputTooltipContainer>
	);
}
