import { createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '@app/utils/createFetchReducer';
import { GenericEntityState, ResponseAction } from '@app/redux/types';

import {
	getContactAttributesTypes,
	getAttributeValuesTypes,
	createContactAttributeTypes,
	editContactAttributeTypes,
	bulkUpdateContactAttributesTypes,
} from './action';
import { ContactAttributeType } from '@app/redux/contactAttributes/types';

const initialState: GenericEntityState = {
	loading: false,
	contactAttributes: [],
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		createContactAttributeLoading: false,
		editContactAttributeLoading: false,
		getAttributeValuesLoading: false,
	},
};

export type contactAttributesType = typeof initialState;

function getContactAttributesMapper(
	state: contactAttributesType,
	action: ResponseAction,
) {
	state.contactAttributes = action.response.contactAttributes;
}

function upsertContactAttributesMapper(
	state: contactAttributesType,
	action: ResponseAction,
) {
	const customAttributeEdited = action.response.contactAttribute;
	state.contactAttributes = state.contactAttributes.map(
		(attribute: ContactAttributeType) =>
			attribute.id === customAttributeEdited.id
				? customAttributeEdited
				: attribute,
	);
}

const contactAttributesSlice = createSlice({
	name: 'contactAttributes',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(
			getContactAttributesTypes,
			getContactAttributesMapper,
		),
		...createFetchReducer(getAttributeValuesTypes),
		...createFetchReducer(createContactAttributeTypes),
		...createFetchReducer(
			editContactAttributeTypes,
			upsertContactAttributesMapper,
		),
		...createFetchReducer(bulkUpdateContactAttributesTypes),
	},
});

export default contactAttributesSlice.reducer;
