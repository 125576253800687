import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import { FilterOptions } from '@app/types/index';
import axios from '@global/axios';
import { EditStoryOptionsTypes, StoryTellerBasicInfo } from '../stories/types';
import { storyStatus } from '../stories/constants';

const mainType = 'ASSIGNED';

export const getAssignedStoriesTypes = createFetchTypes(
	`${mainType}_GET_ASSIGNED_STORIES`,
);

export const editStoriesTypes = createFetchTypes(`${mainType}_EDIT_STORIES`);

export const bulkDeleteTypes = createFetchTypes(`${mainType}_BULK_DELETE`);
export const getSingleStoryTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_STORY`,
);

function getAssignedStories(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify({
		...FilterOptions,
		filter: [
			...(FilterOptions.filter ?? []),
			{ field: 'status', value: storyStatus.assigned },
		],
	});

	return {
		type: getAssignedStoriesTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'getAssignedStories',
		},
	};
}

function getSingleStory(storyId: string, FilterOptions: FilterOptions) {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getSingleStoryTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories/${storyId}${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			custom: 'getSingleStory',
			errorMessage: ToastMessage.errorStoryNotFound,
		},
	};
}

function editStories(
	EditOptions: EditStoryOptionsTypes,
	storyteller: StoryTellerBasicInfo,
	storytellerFilter?: string,
): fetchAction {
	return {
		type: editStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/edit`, {
				...EditOptions,
				storytellerId: storyteller.id,
			}),
		payload: {
			EditOptions,
			storyteller,
			storytellerFilter,
			custom: 'editStories',
			successMessage: ToastMessage.editedAssignedStory,
			errorMessage: ToastMessage.error,
		},
	};
}

function bulkDelete(storiesId: string[]) {
	return {
		type: bulkDeleteTypes,
		callAPI: () =>
			axios.delete(`${apiUrl}/stories/bulk`, { data: { storiesId } }),
		payload: {
			custom: 'bulkDelete',
			successMessage:
				storiesId.length > 1
					? ToastMessage.severalAssignedStoriesDeleted
					: ToastMessage.singleAssignedStoryDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

export { getAssignedStories, editStories, bulkDelete, getSingleStory };
