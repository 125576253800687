import React from 'react';
import {
	Background,
	MainContainer,
	TitleContainer,
} from '@app/containers/Error/Error.styles';
import { ERROR_DESCRIPTION, ERROR_TITLE } from '@app/constants/error';
import { env } from '@app/env';
import { Icon, LinkButton, Text } from '@common/design-system/components/atoms';

type ErrorProps = {
	errorMessage: string;
	onResetError: () => void;
};
const Error = ({ errorMessage, onResetError }: ErrorProps) => {
	const isDevOrQa =
		env.NODE_ENV === 'development' ||
		env.NODE_ENV === 'dev' ||
		env.NODE_ENV === 'qa';

	return (
		<Background>
			<MainContainer>
				<TitleContainer>
					<Icon iconName="errorTriangle" size="xl" />
					<Text fontWeight="semiBold" size="large">
						{ERROR_TITLE}
					</Text>
				</TitleContainer>
				<Text lineHeight="large" size="small">
					{ERROR_DESCRIPTION}
				</Text>

				{isDevOrQa && (
					<div className="my-2">
						<Text color="error.text.default" lineHeight="large" size="small">
							{errorMessage}
						</Text>
					</div>
				)}

				<LinkButton onClick={onResetError}>Go back</LinkButton>
			</MainContainer>
		</Background>
	);
};

export default Error;
