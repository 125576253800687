import styled from 'styled-components';

export const LoaderContainer = styled.div`
	position: fixed;
	top: 0;
	z-index: 10;
	height: calc(100vh);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
