import styled from 'styled-components';

export const StyledLabel = styled.label`
	margin-bottom: 12px;
	font-family: ${({ theme }) => theme.fonts.default};
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.23px;
	color: ${({ theme }) => theme.colors.neutral[700]};
	display: block;
`;
