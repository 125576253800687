import styled from 'styled-components';

export const NoSignalImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const TextContainer = styled.div`
	position: absolute;
	padding: 12px 16px;
	background-color: ${({ theme }) => theme.colors.neutral[700]};
	border-radius: 5px;
`;

export const Text = styled.span`
	font-family: ${({ theme }) => theme.fonts.default};
	color: white;
	font-weight: 500;
`;
