import { CTAListEnum, CTANameListEnum } from '@app/constants/cta';
import { operators, orderTypes } from '@app/constants';
import { FilterOptions, filterObjectArray, orderObject } from '@app/types';
import { CTATypeEnum, CTA_TYPES } from '@app/constants/modules/ctas';
import { CTA } from '@app/redux/cta/types';
import { getTimeFromDate } from '@app/utils/getTimeFromDate';

type getCTAFilterProps = {
	page: number;
	debouncedSearchQuery: string;
	CTAFilterType?: CTAListEnum[];
	isFilteringUnarchived?: boolean;
	sort?: orderObject;
	isReply?: boolean;
	disabledCtaTypes?: CTATypeEnum[];
};

export function checkIsClickAction(callToActions: any[]): boolean {
	if (!callToActions || !callToActions.length) return false;

	const allActionsTypes = callToActions.map((cta) => cta.type);

	return (
		allActionsTypes.includes(CTAListEnum.link) ||
		allActionsTypes.includes(CTAListEnum.doubleLink) ||
		allActionsTypes.includes(CTAListEnum.event)
	);
}

export function getCTAURL(cta: CTA, isSecondary?: boolean) {
	if (!cta) return '';

	let url;

	switch (cta.type) {
		case CTATypeEnum.LINK:
		case CTATypeEnum.EVENT:
			url = cta.linkUrl;
			break;

		case CTATypeEnum.DOUBLE_LINK:
			url = isSecondary ? cta.secondaryLinkUrl : cta.primaryLinkUrl;
			break;

		default:
			break;
	}

	return url;
}

export function getCTAButtonTitle(cta: CTA, isSecondary?: boolean) {
	if (!cta) return 'No cta passed';

	let buttonTitle = cta.title || undefined;

	switch (cta.type) {
		case CTATypeEnum.LINK:
		case CTATypeEnum.EVENT:
			buttonTitle = cta.linkButtonTitle;
			break;

		case CTATypeEnum.CODE:
			buttonTitle = cta.buttonText;
			break;

		case CTATypeEnum.DOUBLE_LINK:
			buttonTitle = isSecondary ? cta.secondaryLinkTitle : cta.primaryLinkTitle;
			break;

		default:
			break;
	}

	return buttonTitle || 'Button title not found';
}

export function handleActionName(actionType: string, isV2?: boolean): string {
	switch (actionType) {
		case CTATypeEnum.LINK:
			return CTANameListEnum.linkCta;

		case CTATypeEnum.TEXT:
			return CTANameListEnum.textCta;

		case CTATypeEnum.CODE:
			return CTANameListEnum.codeCta;

		case CTATypeEnum.EVENT:
			return CTANameListEnum.eventCta;

		case CTATypeEnum.DOUBLE_LINK:
			return isV2 ? '2 Links' : CTANameListEnum.doubleLinkCta;

		case CTATypeEnum.SUGGESTED_REPLY:
			return 'New set';

		default:
			return '';
	}
}

export function getCTAIconByType(type: CTAListEnum | string) {
	switch (type) {
		case CTAListEnum.link:
			return 'link';
		case CTAListEnum.text:
			return 'text';
		case CTAListEnum.event:
			return 'calendarDate';
		case CTAListEnum.code:
			return 'codeSimple';
		default:
			return type;
	}
}

export function getCTAIconColorByType(type: CTAListEnum | string) {
	switch (type) {
		default:
		case CTAListEnum.link:
			return 'primary.icon.default';
		case CTAListEnum.doubleLink:
			return 'warning.border.hover';
		case CTAListEnum.code:
			return 'accent.icon.default';
		case CTAListEnum.event:
			return 'error.icon.default';
	}
}

export function getIconBackgroundByType(type: CTATypeEnum) {
	switch (type) {
		default:
		case CTATypeEnum.LINK:
			return 'primary.background.defaultHover';
		case CTATypeEnum.DOUBLE_LINK:
			return 'warning.background.defaultHover';
		case CTATypeEnum.CODE:
			return 'accent.background.default';
		case CTATypeEnum.EVENT:
			return 'error.background.default';
	}
}

export const getCTAFilter = ({
	page = 1,
	debouncedSearchQuery,
	CTAFilterType,
	sort,
	isFilteringUnarchived = false,
	isReply,
	disabledCtaTypes,
}: getCTAFilterProps): FilterOptions => {
	// Filter out hidden CTAs
	const visibleCTAFilter = CTA_TYPES?.filter(
		(cta) => !disabledCtaTypes?.includes(cta),
	);

	const pagination = {
		page,
		pageSize: 20,
	};

	const order = sort ?? { field: 'createdAt', type: orderTypes.descending };

	const search = {
		fields: ['name'],
		value: debouncedSearchQuery,
	};

	const filter: filterObjectArray = [
		{ field: 'preview', value: true, operator: operators.not },
		{ field: 'archived', value: isFilteringUnarchived },
		{
			field: 'type',
			value: isReply ? CTAListEnum.suggestedReply : visibleCTAFilter,
		},
	];

	//Filter for the CTA type
	if (!!CTAFilterType?.length) {
		filter.push({
			field: 'type',
			value: CTAFilterType,
		});
	}

	return {
		pagination,
		order,
		search,
		filter,
	};
};

/** Given an array of call to actions is going filter the suggestion
 * and return an action */

export const getActionFromCallToActions = (ctas: CTA[]) => {
	const CTAs = ctas?.filter(
		(cta: CTA) => cta?.type !== CTATypeEnum.SUGGESTED_REPLY,
	);
	if (CTAs?.length) {
		return CTAs[0];
	}
	return null;
};

export function checkIsDoubleLinkCta(type: CTATypeEnum) {
	return type === CTATypeEnum.DOUBLE_LINK;
}

export function checkIsTextCta(actionType: CTATypeEnum) {
	return actionType === CTATypeEnum.TEXT;
}

export function checkIsEventCta(actionTye: CTATypeEnum) {
	return actionTye === CTATypeEnum.EVENT;
}

export function getEventCTADetails(callToAction: CTA) {
	const eventDate = new Date(callToAction?.eventDate as string);

	const eventDay = eventDate.getDate();
	const eventMonth = eventDate.toLocaleString('default', { month: 'short' });

	const startTime =
		callToAction?.startTime &&
		getTimeFromDate(callToAction?.startTime as string);

	const endTime =
		callToAction?.endTime && getTimeFromDate(callToAction?.endTime as string);
	const isAllDayEvent = !!callToAction?.isAllDayEvent;

	const timezone = callToAction?.timezone;

	return {
		eventDay,
		eventMonth,
		startTime,
		endTime,
		isAllDayEvent,
		timezone,
	};
}
