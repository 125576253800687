import styled from 'styled-components';

export const PopupButtonWrapper = styled.div<{
	$isNavbarCollapsed?: boolean;
	$isFocused: boolean;
}>`
	display: flex;
	align-items: center;
	padding: ${({ theme }) => `${theme.spacing.xxs} 6px`};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	width: ${({ $isNavbarCollapsed }) => ($isNavbarCollapsed ? '40px' : 'auto')};
	min-height: 40px;
	margin: ${({ $isNavbarCollapsed }) =>
		$isNavbarCollapsed ? '0px auto' : '0px'};
	gap: ${({ theme }) => theme.spacing.xs};
	background-color: ${({ $isFocused, theme }) =>
		theme.colors.system.background[$isFocused ? 'medium' : 'xWeak']};
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: ${({ theme }) => theme.colors.system.background.medium};
	}

	justify-content: ${({ $isNavbarCollapsed }) =>
		$isNavbarCollapsed && 'center'};
`;
