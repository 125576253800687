import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	H1,
	Textbase,
} from '../../../../../common/components/presentation/NewTypography';
import { LinkButton } from '../../../../../common/components/interaction/LinkButton';

import { CheckYourEmailText } from './constants';

import { GoodkindTheme } from '../../../../../global/theme';
import {
	CheckYourEmailBanner,
	GoodkindLogo,
	SendIconContainer,
	SendIcon,
	DescriptionContainer,
	DescriptionText,
	ResendContainer,
} from './CheckYourEmail.styles';

export default function CheckYourEmail() {
	const navigate = useNavigate();

	return (
		<CheckYourEmailBanner>
			<GoodkindLogo iconName="goodkindlogo" width="240px" height="57px" />

			<SendIconContainer>
				<SendIcon iconName="sendIcon" size={240} />
			</SendIconContainer>

			<DescriptionContainer>
				<H1 color={GoodkindTheme.colors.dark[700]}>
					{CheckYourEmailText.HEADING}
				</H1>

				<DescriptionText size="large">
					{CheckYourEmailText.DESCRIPTION}
				</DescriptionText>

				<ResendContainer>
					<Textbase color={GoodkindTheme.colors.dark[700]} size="large">
						{CheckYourEmailText.FAILED_RECEIVING}
					</Textbase>

					<LinkButton
						text={CheckYourEmailText.RESEND}
						textSize="large"
						onClick={() => navigate('/?login_hint=signup')}
					/>
				</ResendContainer>
			</DescriptionContainer>
		</CheckYourEmailBanner>
	);
}
