import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import axios from '@global/axios';
import { FilterOptions } from '@app/types';
import { GroupTypes } from './types';

const mainType = 'group';
export const getGroupsTypes = createFetchTypes(`${mainType}_GET_GROUPS`);
export const createGroupTypes = createFetchTypes(`${mainType}_CREATE_GROUP`);
export const getSingleGroupTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_GROUP`,
);
export const deleteGroupTypes = createFetchTypes(`${mainType}_DELETE_GROUP`);

function getSingleGroup(groupId: string): fetchAction {
	return {
		type: getSingleGroupTypes,
		callAPI: () => axios.get(`${apiUrl}/group/${groupId}`),
		payload: {
			errorMessage: ToastMessage.groupNotFound,
		},
	};
}

function getGroups(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify({
		...FilterOptions,
		filter: [...(FilterOptions.filter ?? [])],
	});

	return {
		type: getGroupsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/group${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'getGroups',
		},
	};
}

function createGroup(group: GroupTypes): fetchAction {
	return {
		type: createGroupTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/group`, {
				...group,
			}),
		payload: {
			custom: 'createGroup',
			successMessage: ToastMessage.createGroup,
			errorMessage: ToastMessage.error,
		},
	};
}

function deleteGroup(id: string): fetchAction {
	return {
		type: deleteGroupTypes,
		callAPI: () => axios.delete(`${apiUrl}/group/${id}`),
		payload: {
			errorMessage: ToastMessage.error,
		},
	};
}

export { getSingleGroup, getGroups, createGroup, deleteGroup };
