import { SizeType } from '@common/components/presentation/NewTypography/types';
import { LinkButtonVariantType } from '../types';

import { GoodkindTheme } from '@global/theme';

export function handleVariantColor(variant: LinkButtonVariantType): string {
	switch (variant) {
		case 'primary':
			return GoodkindTheme.colors.primary[700];

		case 'alert':
			return GoodkindTheme.colors.alert[700];

		case 'neutral':
			return GoodkindTheme.colors.neutral[500];

		default:
			return GoodkindTheme.colors.primary[700];
	}
}

export function handleVariantHoverColor(
	variant: LinkButtonVariantType,
): string {
	switch (variant) {
		case 'primary':
			return GoodkindTheme.colors.primary[900];

		case 'alert':
			return GoodkindTheme.colors.alert[900];

		case 'neutral':
			return GoodkindTheme.colors.neutral[700];

		default:
			return GoodkindTheme.colors.primary[900];
	}
}

export function handleIconByTextSize(textSize: SizeType) {
	switch (textSize) {
		case 'large':
			return 18;

		case 'regular':
			return 16;

		case 'small':
			return 12;

		default:
			return 16;
	}
}

export function handleGapSize(textSize: SizeType) {
	switch (textSize) {
		case 'large':
		case 'regular':
			return '8px';

		case 'small':
			return '4px';

		default:
			return '8px';
	}
}
