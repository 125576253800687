import React, { forwardRef, useCallback } from 'react';
import * as S from './LinkButton.styles';
import { Icon } from '@common/design-system/components/atoms';

import {
	FontLineHeightsType,
	FontWeightsType,
} from '@common/design-system/global/types';

//TODO: Improve to work with router - to prop

type PropTypes = {
	children: React.ReactNode;
	onClick?: () => void;
	href?: string;
	className?: string;
	size?: 'small' | 'medium' | 'large';
	icon?: string;
	iconPosition?: 'left' | 'right';
	disabled?: boolean;
	openInNewTab?: boolean;
	color?: string;
	weight?: FontWeightsType;
	lineHeight?: FontLineHeightsType;
};

const LinkButton = forwardRef<HTMLAnchorElement, PropTypes>(function Link(
	{
		children,
		onClick,
		href,
		className,
		size = 'medium',
		icon,
		iconPosition = 'left',
		disabled = false,
		openInNewTab = false,
		color,
		weight = 'medium',
		lineHeight = 'regular',
		...rest
	},
	ref,
) {
	const isIconLeft = icon && iconPosition === 'left';
	const isIconRight = icon && iconPosition === 'right';

	const iconColor = disabled
		? `system.text.medium`
		: color ?? `primary.text.default`;

	const getIconSizing = useCallback(() => {
		switch (size) {
			case 'small':
				return 'xs';
			case 'medium':
				return 'small';
			case 'large':
				return 'medium';
		}
	}, [size]);

	return (
		<S.StyledLink
			className={className}
			size={size}
			onClick={onClick}
			disabled={disabled}
			href={href}
			color={color}
			weight={weight}
			lineHeight={lineHeight}
			target={openInNewTab ? '_blank' : undefined}
			ref={ref}
			{...rest}
		>
			{isIconLeft && (
				<Icon iconName={icon} size={getIconSizing()} color={iconColor} />
			)}
			{children}
			{isIconRight && (
				<Icon iconName={icon} size={getIconSizing()} color={iconColor} />
			)}
		</S.StyledLink>
	);
});

export default LinkButton;

export { PropTypes as LinkButtonProps };
