import * as React from 'react';

import Error from '@app/containers/Error';

import * as S from './ErrorBoundary.styles';

import { useRouteError } from 'react-router-dom';
import GoodkindDSProvider from '@common/design-system/global/provider';

type ErrorBoundaryProps = {
	handleReset?: () => void;
};

export const ErrorBoundary = ({ handleReset }: ErrorBoundaryProps) => {
	const error: any = useRouteError();

	const onReset = () => {
		handleReset && handleReset();
	};

	if (error) {
		return (
			<GoodkindDSProvider>
				<S.Container>
					<Error
						errorMessage={error?.message as string}
						onResetError={onReset}
					/>
				</S.Container>
			</GoodkindDSProvider>
		);
	}

	return <></>;
};

export default ErrorBoundary;
