import React from 'react';

import { DATA_TEST_ID } from './constants';

import { HelpTooltipProps } from './types';

import { GoodkindTheme } from '@global/theme';
import { HelpTextTooltip, HelpIcon } from './HelpTooltip.styles';
export default function HelpTooltip({
	tooltipProps,
	size = 16,
	color,
	dataTestId = DATA_TEST_ID,
	className,
}: HelpTooltipProps) {
	return (
		<HelpTextTooltip
			{...tooltipProps}
			height={size}
			dataTestId={`${dataTestId}-tooltip`}
			className={className}
		>
			<HelpIcon
				iconName="info"
				size={size || 16}
				weight="regular"
				color={color || GoodkindTheme.colors.primary[700]}
				dataTestId={`${dataTestId}-icon`}
			/>
		</HelpTextTooltip>
	);
}
