import { TIMEZONE_DEFAULT_VALUE } from '@app/constants/timezone';

export const getInitialStateEndDateEventCTA = (startDate: Date) => {
	const today = new Date(startDate);
	today.setTime(today.getTime() + 60 * 60 * 1000);
	return today;
};

export enum CTATypeDisplayNameEnum {
	LINK = 'Link',
	TEXT = 'Text',
	CODE = 'Code',
	DOUBLE_LINK = 'Double Link',
}

export enum CTATypeEnum {
	LINK = 'linkCta',
	TEXT = 'textCta',
	CODE = 'codeCta',
	DOUBLE_LINK = 'doubleLinkCta',
	EVENT = 'eventCta',
	SUGGESTED_REPLY = 'suggestedReplyCta',
}

export const CTA_TYPES = [
	CTATypeEnum.LINK,
	CTATypeEnum.DOUBLE_LINK,
	CTATypeEnum.CODE,
	CTATypeEnum.EVENT,
];

export const callToActionFieldKey = {
	NAME: 'name',
};

export const doubleLinkCtaFieldKey = {
	...callToActionFieldKey,
	PRIMARY_TITLE: 'primaryLinkTitle',
	PRIMARY_URL: 'primaryLinkUrl',
	SECONDARY_TITLE: 'secondaryLinkTitle',
	SECONDARY_URL: 'secondaryLinkUrl',
};

export const codeCtaFieldKey = {
	...callToActionFieldKey,
	TITLE: 'buttonText',
	CODE: 'script',
};

export const linkCtaFieldKey = {
	...callToActionFieldKey,
	TITLE: 'linkButtonTitle',
	URL: 'linkUrl',
};

export const textCtaFieldKey = {
	TITLE: 'title',
};

export const suggestedReplyCtaFieldKey = {
	...callToActionFieldKey,
	TEXT_REPLIES: 'textReplies',
	EMOJI_REPLIES: 'emojiReplies',
};

export const eventCTAFieldKey = {
	...callToActionFieldKey,
	EVENT_TITLE: 'eventTitle',
	EVENT_DATE: 'eventDate',
	IS_ALL_DAY_EVENT: 'isAllDayEvent',
	START_TIME: 'startTime',
	END_TIME: 'endTime',
	LINK_URL: 'linkUrl',
	LINK_BUTTON_TITLE: 'linkButtonTitle',
	TIMEZONE: 'timezone',
};

export const ctaInitialFields: {
	[key in CTATypeEnum]: { [key: string]: string | boolean | [] };
} = {
	[CTATypeEnum.LINK]: {
		[callToActionFieldKey.NAME]: '',
		[linkCtaFieldKey.TITLE]: '',
		[linkCtaFieldKey.URL]: '',
		type: CTATypeEnum.LINK,
	},
	[CTATypeEnum.TEXT]: {
		[textCtaFieldKey.TITLE]: '',
		type: CTATypeEnum.TEXT,
	},
	[CTATypeEnum.CODE]: {
		[callToActionFieldKey.NAME]: '',
		[codeCtaFieldKey.TITLE]: '',
		[codeCtaFieldKey.CODE]: '',
		type: CTATypeEnum.CODE,
	},
	[CTATypeEnum.DOUBLE_LINK]: {
		[callToActionFieldKey.NAME]: '',
		[doubleLinkCtaFieldKey.PRIMARY_TITLE]: '',
		[doubleLinkCtaFieldKey.PRIMARY_URL]: '',
		[doubleLinkCtaFieldKey.SECONDARY_TITLE]: '',
		[doubleLinkCtaFieldKey.SECONDARY_URL]: '',
		type: CTATypeEnum.DOUBLE_LINK,
	},
	[CTATypeEnum.EVENT]: {
		type: CTATypeEnum.EVENT,
		[callToActionFieldKey.NAME]: '',
		[eventCTAFieldKey.EVENT_TITLE]: '',
		[eventCTAFieldKey.EVENT_DATE]: String(new Date()),
		[eventCTAFieldKey.IS_ALL_DAY_EVENT]: false,
		[eventCTAFieldKey.START_TIME]: '',
		[eventCTAFieldKey.END_TIME]: '',
		[eventCTAFieldKey.LINK_URL]: '',
		[eventCTAFieldKey.LINK_BUTTON_TITLE]: '',
		[eventCTAFieldKey.TIMEZONE]: TIMEZONE_DEFAULT_VALUE,
	},
	[CTATypeEnum.SUGGESTED_REPLY]: {
		[callToActionFieldKey.NAME]: '',
		[suggestedReplyCtaFieldKey.TEXT_REPLIES]: [],
		[suggestedReplyCtaFieldKey.EMOJI_REPLIES]: [],
		type: CTATypeEnum.SUGGESTED_REPLY,
	},
};
