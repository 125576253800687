import React from 'react';

import { TextbaseTypographyProps } from '../types';

import { TextbaseTypography } from './Textbase.styles';

export default function Textbase({
	children,
	position = 'left',
	color,
	size = 'regular',
	className,
	lineJump,
	fontWeight,
}: TextbaseTypographyProps) {
	return (
		<TextbaseTypography
			color={color}
			position={position}
			size={size}
			lineJump={lineJump}
			fontWeight={fontWeight}
			className={className}
		>
			{children}
		</TextbaseTypography>
	);
}
