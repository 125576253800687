import styled from 'styled-components';

const ControlGroupRow = styled.div<{
	noMargin?: boolean;
	autoInfoSize?: boolean;
	autoItemSize?: boolean;
	fullFlexInfoWidth?: boolean;
	singleItem?: boolean;
}>`
	display: flex;
	margin: 0;

	> :first-child {
		margin-right: ${({ theme, noMargin }) =>
			noMargin ? 0 : theme.spacing.xxxl};
		flex: ${({ fullFlexInfoWidth }) => fullFlexInfoWidth && '1'};
		width: ${({ autoInfoSize }) => (autoInfoSize ? 'auto' : '50%')};
	}

	> :last-child {
		width: ${({ singleItem, autoItemSize }) =>
			autoItemSize ? 'auto' : singleItem ? '100%' : '50%'};
	}
`;

export default ControlGroupRow;
