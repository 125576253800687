import React from 'react';
import Images, { ImageType } from '@common/design-system/global/images';
import * as S from './Illustration.styles';

type PropTypes = {
	name?: keyof ImageType;
	size?: 'small' | 'regular' | 'medium' | 'large';
	width?: string;
	height?: string;
	alt: string;
	isSquare?: boolean;
};

const Illustration = ({
	name = 'search',
	size,
	isSquare = true,
	alt,
	width,
	height,
}: PropTypes) => {
	return (
		<S.StyledIllustration
			draggable="false"
			isSquare={isSquare}
			src={!!Images[name] ? Images[name] : Images['search']}
			alt={alt}
			size={size}
			width={width}
			height={height}
		/>
	);
};

export default Illustration;
