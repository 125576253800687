import styled from 'styled-components';

import { Icon } from '@common/components/presentation/Icon';

export const PinIcon = styled(Icon)<{ pinned: boolean; loading: boolean }>`
	color: ${({ theme, pinned, loading }) =>
		loading || pinned
			? theme.colors.dark[700]
			: theme.colors.neutral[300]} !important;
`;

export const PinButtonContainer = styled.button<{
	pinned: boolean;
}>`
	background: none;
	border: 0;

	&:hover {
		${PinIcon} {
			color: ${({ theme }) => theme.colors.dark[700]} !important;
		}
	}
`;
