import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from { 
		opacity: 0;
	 }

  to { 
		opacity: 1; 
	}
`;

export const TooltipBlock = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	border-radius: ${({ theme }) => theme.borderRadius?.smooth};
	background: ${({ theme }) => theme.colors.neutral[0]};
	box-shadow: ${({ theme }) => theme.boxShadow?.medium};
`;

export const TooltipContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	z-index: ${({ theme }) => theme.zIndex.overlay};
	animation-name: ${fade};
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
`;

export const TooltipWrapper = styled.div<{ fillParent?: boolean }>`
	position: relative;
	display: inline-flex;
	flex-direction: column;
	width: fit-content;
	max-width: 100%;
	align-items: center;
	justify-content: center;
	z-index: 3;
	${({ fillParent }) =>
		fillParent && 'width: 100%; height: 100%; display: flex;'}
`;
