import * as R from 'ramda';

import { UserTemplateType } from './types';

function getUserTemplatesOrdered(
	templates: UserTemplateType[],
	updatedTemplate?: UserTemplateType,
) {
	const updatedIsDefault = !!updatedTemplate?.default;

	const updatedTemplates = R.map((template) => {
		if (template.id === updatedTemplate?.id) {
			return updatedTemplate;
		}
		if (updatedIsDefault) {
			return R.assoc('default', false, template);
		}
		return template;
	}, templates);

	return R.sortWith(
		[
			R.descend(R.prop('default')), // Bring `default: true` to the top
			R.descend(R.prop('createdAt')), // Order remaining by `createdAt`
		],
		updatedTemplates,
	);
}

export { getUserTemplatesOrdered };
