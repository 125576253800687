import React from 'react';
import {
	FloatingArrow,
	FloatingPortal,
	Placement,
	Strategy,
} from '@floating-ui/react';

import useFloatingUi from '@app/hooks/useFloatingUi';

import * as S from './Popover.styles';
import { useTheme } from 'styled-components';
import { SquareIconButton } from '../../atoms';

type PropsType = {
	children?: React.ReactNode;
	closeOnOutsideClick?: boolean;
	popOverContent:
		| React.ReactNode
		| ((
				menuIsOpen: boolean,
				setIsOpen: (value: boolean) => void,
		  ) => React.ReactNode);
	trigger?: React.ReactNode | ((menuIsOpen: boolean) => React.ReactNode);
	popUpStrategy?: Strategy;
	popUpPlacement?: Placement;
	positionOffset?: number;
	floatingConfig?: any;
	onMenuOpen?: (event?: any, reason?: string) => void;
	onMenuClose?: (event?: any, reason?: string) => void;
	disabled?: boolean;
	openOnHover?: boolean;
	withArrow?: boolean;
	withCloseButton?: boolean;
	withNoPadding?: boolean;
	maxWidth?: string;
	zIndex?: number;
	initialOpen?: boolean;
};

const ARROW_WIDTH = 12;
const ARROW_HEIGHT = 6;

function Popover({
	children,
	trigger,
	closeOnOutsideClick,
	popOverContent,
	popUpPlacement,
	popUpStrategy,
	positionOffset = 0, // Position offset to be set on floating UI, this offset takes effect in all position directions
	floatingConfig, //External Floating UI config for controlled cases - useFloatingUi
	onMenuOpen,
	onMenuClose,
	disabled,
	openOnHover = false,
	withArrow,
	withCloseButton = false,
	withNoPadding = false,
	maxWidth = '320px',
	zIndex,
	initialOpen = false,
}: PropsType) {
	const theme = useTheme();

	const {
		x: floatingX,
		y: floatingY,
		openingReference,
		floatingReference,
		strategy,
		internalRefs,
		middlewareData,
		getReferenceProps,
		getFloatingProps,
		isOpen,
		setIsOpen,
		placement,
		context,
		arrowRef,
	} = floatingConfig ||
	useFloatingUi({
		closeOnOutsideClick: closeOnOutsideClick ?? true,
		preferredStrategy: popUpStrategy ?? 'absolute',
		preferredPlacement: popUpPlacement ?? 'top',
		clickDisabled: disabled,
		onMenuOpen: onMenuOpen,
		onMenuClose: onMenuClose,
		offset: positionOffset,
		hoverDisabled: disabled ?? !openOnHover,
		initialOpen: initialOpen,
	});

	const isEdgeAligned = placement.includes('-');

	return (
		<>
			<S.TriggerWrapper
				ref={openingReference}
				role="button"
				tabIndex={0}
				{...getReferenceProps()}
				onClick={(e) => {
					e.stopPropagation();
					getReferenceProps().onClick(e);
				}}
			>
				{trigger
					? typeof trigger === 'function'
						? trigger(isOpen)
						: trigger
					: children}
			</S.TriggerWrapper>
			<FloatingPortal>
				{isOpen && (
					<S.PopoverContainer
						ref={floatingReference}
						style={{
							position: strategy,
							top: floatingY ?? 0,
							left: floatingX ?? 0,
							zIndex: zIndex || theme.zIndex.popover,
							maxWidth: maxWidth,
							visibility: middlewareData.hide?.referenceHidden
								? 'hidden'
								: 'visible',
							backgroundColor: theme.colors.system.background.system,
						}}
						{...getFloatingProps()}
						withNoPadding={withNoPadding}
					>
						{withCloseButton && (
							<S.CloseContainer>
								<SquareIconButton
									icon="close"
									ariaLabel="close"
									variant="neutral"
									onClick={() => setIsOpen(false)}
									size="xs"
								/>
							</S.CloseContainer>
						)}

						{typeof popOverContent === 'function'
							? popOverContent(isOpen, setIsOpen)
							: popOverContent}
						{withArrow && (
							<FloatingArrow
								ref={arrowRef}
								context={context}
								fill={theme.colors.system.background.system}
								width={ARROW_WIDTH}
								height={ARROW_HEIGHT}
								staticOffset={isEdgeAligned ? '20%' : undefined}
								stroke={theme.colors.system.background.xStrong}
								strokeWidth={1}
							/>
						)}
					</S.PopoverContainer>
				)}
			</FloatingPortal>
		</>
	);
}

export default Popover;
export type { PropsType as PopoverPropsType };
