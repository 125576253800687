import authConfig from '@app/services/Auth/authConfig';

function getAuth0LocalValue() {
	try {
		const {
			auth0: { scope, clientId, audience },
		} = authConfig;
		const scopeWithRefreshToken = `${scope} offline_access`;
		const stringValue =
			localStorage.getItem(
				`@@auth0spajs@@::${clientId}::${audience}::${scopeWithRefreshToken}`,
			) || '';
		const auth0State = JSON.parse(stringValue);
		// console.log({ auth0State });
		return auth0State;
	} catch (e) {
		// console.log(e);
		return null;
	}
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class TokenStorage {
	public static getAccessToken(): string {
		const token =
			getAuth0LocalValue()?.body?.access_token ||
			localStorage.getItem('accessToken');
		return token;
	}

	public static setAccessToken(accessToken: string) {
		return localStorage.setItem('accessToken', accessToken);
	}

	public static getIdentity(): string {
		return localStorage.getItem('identity') || '';
	}

	public static setIdentity(identityToken: string) {
		return localStorage.setItem('identity', identityToken);
	}
}
