import { createSlice, Action } from '@reduxjs/toolkit';
import {
	GenericEntityState,
	GenericAPIResponse,
	ResponseAction,
} from '../types';
import createFetchReducer from '@app/utils/createFetchReducer';
import { normalize } from 'normalizr';
import fileDownload from 'js-file-download';
import {
	filterStoriesTypes,
	editStoriesTypes,
	createStoriesTypes,
	sendStoriesTypes,
	getApprovalsTypes,
	approvalCountTypes,
	exportStoriesTypes,
	filterStoriesWithInfiniteTypes,
	bulkApproveTypes,
	bulkDeleteTypes,
	bulkRejectTypes,
	getSingleStoryTypes,
	getStoriesCountTypes,
} from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import { CaptionsType } from '@common/components/presentation/VideoCard/types';
import { StoriesType } from '@app/types/modules/stories';

const story = entityNormalizer('stories', {}, { excludeTime: true });
const video = entityNormalizer('videos', {}, { excludeTime: true });

const pagination = { stories: [story], videos: [video] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState & {
	[key: string]: any;
} = {
	loading: false,
	stories: {},
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	approvals: {},
	approvalPagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},

	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
	createAssignedStoryResponse: {
		status: null,
		message: null,
	},
	sendVideoResponse: {
		status: null,
		message: null,
	},
	totalStories: {},
};

export type storiesStateType = typeof initialState;

function defaultStoriesPaginationMapper(
	state: storiesStateType,
	action: ResponseAction,
	cb: (stories: any, state: storiesStateType) => void,
) {
	const {
		response: {
			stories,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const normalizedData = normalize(
		{ stories, page, pageSize, totalPages, count },
		pagination,
	);
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.stories;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}

	cb(normalizedData.entities.stories, state);
}

function paginationMapper(stories: any, state: storiesStateType) {
	state.stories = { ...stories };
	state.storiesCount = Object.keys(state.stories || {})?.length;
}

function filterMapper(stories: any, state: storiesStateType) {
	if (state.pagination && state.pagination.currentPage === 1) {
		return paginationMapper(stories, state);
	}
	state.stories = { ...state.stories, ...stories };
	state.storiesCount = Object.keys(state.stories || {})?.length;
}

function approvalPaginationMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const {
		response: {
			stories,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const videos = stories.map((story: any) => story.video);

	const normalizedData: any = normalize(
		{ stories, page, pageSize, totalPages, count, videos },
		pagination,
	);

	state.approvals = { ...normalizedData.entities.stories };
	Object.values(state.approvals).forEach((approval: any) => {
		approval.video = normalizedData.entities.videos[approval.video._id];
	});
	state.approvalPagination.pages[action.response.page] =
		normalizedData.result.stories;
	state.approvalPagination.currentPage = action.response.page;
	state.approvalPagination.totalPages = action.response.totalPages;
	state.approvalPagination.count = action.response.count;
}

function createAssignedStoryMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	state.createAssignedStoryResponse = action.response;
}

function sendVideoMapper(state: storiesStateType, action: ResponseAction) {
	state.sendVideoResponse = action.response;
}

function exportStoriesSuccessMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const { response } = action;
	fileDownload(response, 'stories.csv');
	state.type = action.type;
}

function bulkActionSuccessMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const {
		response: { stories },
	} = action;
	stories.map((story: string) => {
		const { [story]: omit, ...rest } = state.approvals;
		state.approvals = rest;
		const { [story]: omittedStory, ...storiesRest } = state.stories;
		state.stories = storiesRest;
		state.storiesCount = Object.keys(state.stories || {})?.length;
	});
}

function getSingleStoryMapper(state: storiesStateType, action: ResponseAction) {
	const storyId = action.response.story._id;
	const normalizedData = normalize(
		{ stories: { ...state.stories, [storyId]: action.response.story } },
		pagination,
	);
	state.stories = { ...normalizedData.entities.stories };
	state.storiesCount = Object.keys(state.stories || {})?.length;
}

function getStoriesCountMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	state.totalStories = action.response.totalStories;
}

const storySlice = createSlice({
	name: 'story',
	initialState,
	reducers: {
		CLEAR_STORIES(state: storiesStateType) {
			state.stories = {};
			state.storiesCount = 0;
			state.pagination = initialState.pagination;
		},
		CLEAR_RESPONSE(state: storiesStateType) {
			state.response = null as unknown as GenericAPIResponse;
		},
		UPDATE_STORY_DATA(
			state: storiesStateType,
			action: { payload: { story: Partial<StoriesType> } },
		) {
			const { story } = action.payload;

			const storyId = story?._id || story?.id;

			if (storyId) {
				state.stories[storyId] = { ...state.stories[storyId], ...story };
			}
		},
		UPDATE_APPROVAL_VIDEO_CAPTIONS(
			state: storiesStateType,
			action: {
				payload: { approvalVideoId: string; captions: CaptionsType[] };
			},
		) {
			const { approvalVideoId, captions } = action.payload;
			const [approvalId]: any = Object.entries(state.approvals).find(
				([approvalId, approval]: [string, any]) =>
					approval.video._id === approvalVideoId,
			);
			state.approvals[approvalId].video.captions = captions;
		},
		UPDATE_APPROVAL_VIDEO_FIELD(
			state: storiesStateType,
			action: { payload: { approvalId: string; field: string; value: any } },
		) {
			const { approvalId, field, value } = action.payload;
			if (state?.approvals[approvalId]?.video) {
				state.approvals[approvalId].video[field] = value;
			}
		},
	},
	extraReducers: {
		...createFetchReducer(
			filterStoriesTypes,
			(state: storiesStateType, action) =>
				defaultStoriesPaginationMapper(state, action, paginationMapper),
		),
		...createFetchReducer(
			filterStoriesWithInfiniteTypes,
			(state: storiesStateType, action) =>
				defaultStoriesPaginationMapper(state, action, filterMapper),
		),

		...createFetchReducer(editStoriesTypes),
		...createFetchReducer(createStoriesTypes, createAssignedStoryMapper),
		...createFetchReducer(sendStoriesTypes, sendVideoMapper),
		...createFetchReducer(getApprovalsTypes, approvalPaginationMapper),
		...createFetchReducer(approvalCountTypes),
		...createFetchReducer(exportStoriesTypes, exportStoriesSuccessMapper),
		STORY_CLEAR_ERROR: (state: storiesStateType, action: Action) => {
			state.error = null;
		},
		...createFetchReducer(bulkApproveTypes, bulkActionSuccessMapper),
		...createFetchReducer(bulkDeleteTypes, bulkActionSuccessMapper),
		...createFetchReducer(bulkRejectTypes, bulkActionSuccessMapper),
		...createFetchReducer(getSingleStoryTypes, getSingleStoryMapper),
		...createFetchReducer(getStoriesCountTypes, getStoriesCountMapper),
	},
});

export default storySlice.reducer;
