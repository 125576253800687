import React, {
	ForwardRefRenderFunction,
	forwardRef,
	useEffect,
	useLayoutEffect,
	useRef,
} from 'react';

import Quill from 'quill';

import Delta from 'quill-delta';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import * as S from './RichTextEditor.styles';

type PropsType = {
	id?: string;
	readOnly?: boolean;
	initialValue?: any;
	placeholder?: string;
	hasError?: boolean;
	onTextChange?: (
		value: {
			html: string;
			string: string;
			delta?: Delta;
		},
		id?: string,
		...QuillArgs: any[]
	) => void;
};

const RichTextEditor = (
	{
		readOnly,
		initialValue,
		onTextChange,
		placeholder,
		id,
		hasError,
	}: PropsType,
	ref: { current: Quill | null } | null,
) => {
	const internalQuillRef = useRef<Quill | null>(null);
	const quillRef = ref || internalQuillRef;

	const containerRef = useRef(null);
	const initialValueRef = useRef(initialValue);
	const onTextChangeRef = useRef(onTextChange);

	useLayoutEffect(() => {
		onTextChangeRef.current = onTextChange;
	});

	useEffect(() => {
		if (quillRef && quillRef.current) {
			quillRef.current?.enable(!readOnly);
		}
	}, [quillRef, readOnly]);

	useEffect(() => {
		const container = containerRef.current as unknown as HTMLElement;
		const editorContainer = container?.appendChild(
			container.ownerDocument.createElement('div'),
		);

		const quill = new Quill(editorContainer, {
			theme: 'snow',
			placeholder: placeholder,
		});

		quillRef.current = quill;

		if (initialValueRef.current) {
			const deltaConverted = quill.clipboard.convert({
				html: initialValueRef.current,
			});
			quill.setContents(deltaConverted, 'silent');
		}

		quill.on(Quill.events.TEXT_CHANGE, (...QuillArgs) => {
			onTextChangeRef.current?.(
				{
					html: quillRef?.current?.getSemanticHTML() || '',
					string: quillRef?.current?.getText() || '',
					delta: quillRef?.current?.getContents(),
				},
				id,
				...QuillArgs,
			);
		});

		return () => {
			quillRef.current = null;
			container.innerHTML = '';
		};
	}, [quillRef]);

	return (
		<S.REWrapper ref={containerRef} id={id} hasError={hasError}></S.REWrapper>
	);
};

export default forwardRef(
	RichTextEditor as ForwardRefRenderFunction<Quill, PropsType>,
);
