import styled from 'styled-components';

import { Input } from '@common/components/interaction/TextInput';
import { H3, Textbase } from '@common/components/presentation/NewTypography';
import { HeaderContainer } from '@common/components/layout/HeaderContainer';

import { DataTestType } from '../../../../app/types';

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
	column-gap: 12px;
`;

export const PageHeaderSearchInput = styled(Input)`
	max-width: 224px;
`;

export const PageTitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;
export const PageTitle = styled(H3)<DataTestType>`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 18px;
	margin-left: 16px;

	&:first-child {
		margin-left: 0;
	}
`;

export const PageSubTitle = styled(Textbase)`
	margin-left: 16px;
`;

export const PageHeaderContainer = styled(HeaderContainer)`
	column-gap: 16px;
`;
