import styled from 'styled-components';

export const CloudinaryUploadWidgetContainer = styled.div`
	width: 100%;
	display: flex;
	gap: ${({ theme }) => theme.spacing.xl};
	justify-content: space-between;
`;

export const SubSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	flex: 1;
`;

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const ImagePlaceholder = styled.div`
	width: 182px;
	height: 148px;
	border-radius: ${({ theme }) => theme.shapeRadius.medium};
	border: 1px solid ${({ theme }) => theme.colors.neutral.border.default};
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	overflow: hidden;
`;

export const PlaceholderImage = styled.img`
	width: 100%;
`;
