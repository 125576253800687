import { env } from '../../env';

const authConfig = {
	auth0: {
		domain: env.AUTH0_DOMAIN,
		clientId: env.AUTH0_CLIENT_ID,
		audience: env.AUTH0_AUDIENCE,
		scope: 'openid profile email',
	},

	propelAuth: {
		authUrl: env.PROPEL_AUTH_URL,
	},

	redirectUri: `${env.APP_URL}/callback`,
	logoutUrl: env.APP_URL,
};

export default authConfig;
