import { evalColorPath } from '@common/design-system/global/utils';
import styled, { css } from 'styled-components';

const getSizingStyle = (size?: string, round?: boolean) => {
	switch (size) {
		case 'small':
			return css`
				width: 21px;
				height: 21px;
				flex: 0 0 21px;
				border-radius: ${({ theme }) =>
					round ? theme.shapeRadius[50] : theme.shapeRadius.xxs};
			`;
		case 'large':
			return css`
				width: 48px;
				height: 48px;
				flex: 0 0 48px;
				border-radius: ${({ theme }) =>
					round ? theme.shapeRadius[50] : theme.shapeRadius.xs};
			`;
		case 'medium':
		default:
			return css`
				width: 40px;
				height: 40px;
				flex: 0 0 40px;
				border-radius: ${({ theme }) =>
					round ? theme.shapeRadius[50] : theme.shapeRadius.xs};
			`;
	}
};

export const DisplayIconWrapper = styled.div<{
	color?: string;
	size?: string;
	round?: boolean;
}>`
	background-color: ${({ theme, color }) =>
		color
			? evalColorPath(theme.colors, color)
			: theme.colors.primary.background.default};
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: ${({ theme, round }) =>
		round ? theme.shapeRadius[50] : theme.shapeRadius.xs};

	${({ size, round }) => getSizingStyle(size, round)};
`;
