import { PageNameEnum, PageRouteEnum } from '../constants/pages';

// Returns page name relative to path
export function getPageNameFromPath(path: string): string {
	const pagePath = path.split('/').splice(1)[0].toLowerCase();

	switch (pagePath) {
		case PageRouteEnum.home:
			return PageNameEnum.HOME;

		case PageRouteEnum.activity:
			return PageNameEnum.ACTIVITY;

		case PageRouteEnum.campaigns:
			return PageNameEnum.CAMPAIGNS;

		case PageRouteEnum.campaign:
			return PageNameEnum.CAMPAIGN;

		case PageRouteEnum.videos:
			return PageNameEnum.VIDEOS;

		case PageRouteEnum.video:
			return PageNameEnum.VIDEO;

		case PageRouteEnum.settings:
			return PageNameEnum.SETTINGS;

		case PageRouteEnum.audiences:
			return PageNameEnum.AUDIENCES;

		case PageRouteEnum.campaignCreation:
		case PageRouteEnum.campaignCreationFallback:
			return PageNameEnum.CAMPAIGN_CREATION;

		case PageRouteEnum.successPlan:
			return PageNameEnum.SUCCESS_PLAN;
		case PageRouteEnum.chatbot:
			return PageNameEnum.CHATBOT;

		case PageRouteEnum.inbox:
			return PageNameEnum.INBOX;

		case PageRouteEnum.teams:
			return PageNameEnum.TEAMS;

		default:
			return '';
	}
}
