import styled from 'styled-components';

export const Container = styled.div<{ width?: string; height?: string }>`
	position: relative;
	width: ${({ width }) => (width ? width : '192px')};
	height: ${({ height }) => (height ? height : '120px')};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.system.background.strong};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	overflow: hidden;
`;

export const Image = styled.div<{ image?: string }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(${({ image }) => image});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

export const CloseIcon = styled.div`
	position: absolute;
	top: 0;
	right: 0;
`;
