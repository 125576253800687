import styled from 'styled-components';

import { CollapsableComponentProps } from './types';

export const CollapsableWrapper = styled.div<CollapsableComponentProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: ${({ open, maxWidth, minWidth }) => `${open ? maxWidth : minWidth}px`};
	height: ${({ open, maxHeight, minHeight }) =>
		`${open ? maxHeight : minHeight}px`};
	transition: ${({ theme }) => theme.transitions.expand};
`;
