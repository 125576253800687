import styled from 'styled-components';

export const CheckboxContainer = styled.div<{
	checked?: boolean;
	disabled?: boolean;
	partiallyChecked?: boolean;
}>`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	width: 16px;
	height: 16px;
	flex: 0 0 16px;
	transition: ${({ theme }) => theme.transitions.hover};
	border-radius: ${({ theme }) => theme.shapeRadius.xxs};
	font-size: 12px;
	color: ${({ theme }) => theme.colors.system.background.system};

	border: ${({ theme, disabled, checked, partiallyChecked }) =>
		disabled
			? `1px solid ${theme.colors.neutral.border.default}`
			: checked || partiallyChecked
				? `1px solid ${theme.colors.primary.icon.default}`
				: `1px solid ${theme.colors.neutral.border.default}`};

	background: ${({ theme, disabled, checked, partiallyChecked }) =>
		disabled
			? theme.colors.system.background.strong
			: checked || partiallyChecked
				? theme.colors.primary.icon.default
				: theme.colors.system.background.system};

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	overflow: hidden;

	&:hover {
		box-shadow: ${({ theme }) => theme.shadows.controls.hover};
		color: ${({ theme }) => theme.colors.neutral.background.hover};
		background-color: ${({ theme, disabled, checked, partiallyChecked }) =>
			!disabled &&
			!checked &&
			!partiallyChecked &&
			theme.colors.neutral.background.hover};
	}

	&:focus {
		box-shadow: ${({ theme }) => theme.shadows.controls.selected};
	}
`;
