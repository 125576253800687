import styled from 'styled-components';

export const BulletStepperContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
`;

export const BulletStepperItem = styled.div<{
	active?: boolean;
	disabled?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5px;
	height: 5px;
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
	background-color: ${({ theme, active, disabled }) =>
		disabled
			? theme.colors.system.background.strong
			: active
				? theme.colors.system.text.default
				: theme.colors.system.border.strong};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
