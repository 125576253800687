import styled from 'styled-components';
import { Icon } from '@common/design-system/components/atoms/Icon';

export const ParentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const TextContainer = styled.div`
	margin-top: ${({ theme }) => theme.spacing?.xl};
	gap: ${({ theme }) => theme.spacing?.large};
	text-align: center;
	display: flex;
	flex-direction: column;
	text-align: center;
`;

export const GoodkindLogo = styled(Icon)`
	width: 240px;
	height: 57px;
`;
