import React from 'react';

import { Avatar, Text } from '@common/design-system/components/atoms';

import * as S from './PopupButton.styles';

export type PopupButtonProps = {
	isFocused: boolean;
	userFullName: string;
	userProfilePicture: string;
	isNavbarCollapsed?: boolean;
};

export default function PopupButton({
	isFocused,
	userFullName,
	userProfilePicture,
	isNavbarCollapsed,
}: PopupButtonProps) {
	return (
		<>
			<S.PopupButtonWrapper
				$isNavbarCollapsed={isNavbarCollapsed}
				$isFocused={isFocused}
			>
				<Avatar name={userFullName} image={userProfilePicture} size="medium" />

				{!isNavbarCollapsed && (
					<div>
						<Text
							size="small"
							lineHeight="regular"
							noWrap
							lineClamp={1}
							className="workspace-name"
						>
							{userFullName || 'Loading...'}
						</Text>
					</div>
				)}
			</S.PopupButtonWrapper>
		</>
	);
}
