import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';

import GoodkindDSProvider from '@common/design-system/global/provider';

import * as S from './EmailCheck.styles';
import {
	Button,
	Input,
	Logo,
	Title,
} from '@common/design-system/components/atoms';
import { emailRegex } from '@app/constants/regex';
import { useAppDispatch } from '@app/redux/store';
import { emailCheck } from '@app/redux/auth/action';
import { showToast } from '@common/design-system/components/molecules';
import { useSelector } from 'react-redux';
import { RootState } from '@app/redux/types';
import LocalStorage from '@app/utils/LocalStorage';

type PropsType = {
	email: string;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
};

function EmailCheck({ email, setEmail }: PropsType) {
	const dispatch = useAppDispatch();

	const [error, setError] = useState<string | null>(null);
	const [isEmailValid, setIsEmailValid] = useState(false);

	const isEmailCheckLoading = useSelector(
		({ auth }: RootState) => auth.custom.emailCheckLoading,
	);

	const handleChange = (event: any) => {
		setError(null);

		const value = event.target.value as string;
		setEmail(value);
		validateEmail(value);
	};

	const validateEmail = (email: string) => {
		const isValid = emailRegex.test(email);

		if (!isValid) {
			setIsEmailValid(false);
		} else {
			setIsEmailValid(true);
		}
	};

	const handleBlur = () => {
		if (!isEmailValid) {
			setError('Invalid email');
		} else {
			setError(null);
		}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		const encryptedEmail = CryptoJS.AES.encrypt(
			email,
			'4xt!yo2hQgf9.CjoNyc3o@zzx.p!2iH-9Ax7.mr@W*um6pFh-gNV9jT@eJ6PaQPw!-sZ-DDkMHyKHHMeJ7',
		).toString();

		try {
			await dispatch(emailCheck(encryptedEmail));
		} catch (error: any) {
			const notFound = error?.message === 'Request failed with status code 404';

			showToast({
				type: 'error',
				message: notFound
					? 'Email not found'
					: 'An error occurred. Please try again',
			});
		}
	};

	useEffect(() => {
		LocalStorage.clear();
	}, []);

	return (
		<GoodkindDSProvider>
			<S.EmailCheckContainer>
				<S.FormContainer onSubmit={handleSubmit}>
					<div className="title-wrapper">
						<Logo noText />

						<Title size="large">Log in to Goodkind</Title>
					</div>

					<div className="form-wrapper">
						<Input
							label="Email"
							name="email"
							type="email"
							placeholder="your@email.com"
							onChange={handleChange}
							onBlur={handleBlur}
							value={email}
							hasError={error}
							message={error ? error : null}
						/>

						<Button
							className="w-100"
							variant="primary"
							type="submit"
							size="large"
							disabled={
								!!error ||
								!email ||
								email === '' ||
								isEmailCheckLoading ||
								!isEmailValid
							}
						>
							Continue
						</Button>
					</div>
				</S.FormContainer>
			</S.EmailCheckContainer>
		</GoodkindDSProvider>
	);
}

export default EmailCheck;
