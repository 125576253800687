import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '@app/utils/createFetchReducer';
import {
	getScheduledActivityTypes,
	getSentActivityTypes,
	removeActivityTypes,
	scheduleBulkSendNowTypes,
} from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import { FIRST_PAGE } from '../constants';

const campaign = entityNormalizer('campaigns', {}, { excludeTime: true });

const pagination = { campaigns: [campaign] };

const initialState: GenericEntityState = {
	loading: false,
	scheduleActivity: {
		workspaceActivities: [],
		activitiesCount: 0,
		requireApprovalCount: 0,
		pageSize: 25,
		pagination: {
			pages: {},
			currentPage: null,
			totalPages: null,
		},
	},
	sentActivity: {
		workspaceActivities: [],
		activitiesCount: 0,
		pageSize: 25,
		pagination: {
			pages: {},
			currentPage: null,
			totalPages: null,
		},
	},

	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		getSentActivityLoading: false,
		getScheduledActivityLoading: false,
		removeActivityLoading: false,
		scheduleBulkSendNowLoading: false,
	},
};
export type activityStateType = typeof initialState;

function getScheduleActivityMapper(
	state: activityStateType,
	action: ResponseAction,
) {
	if (state.scheduleActivity.pagination) {
		state.scheduleActivity.pagination.currentPage = action.response.page;
		state.scheduleActivity.pagination.totalPages = action.response.totalPages;
		state.scheduleActivity.pagination.count = action.response.count;
		state.scheduleActivity.requireApprovalCount =
			action.response.campaignsWithPendingContactsCount;
	}

	if (action.response.page === FIRST_PAGE) {
		state.scheduleActivity.workspaceActivities =
			action.response.workspaceActivities;
	} else {
		state.scheduleActivity.workspaceActivities = [
			...state.scheduleActivity.workspaceActivities,
			...action.response.workspaceActivities,
		];
	}

	state.scheduleActivity.activitiesCount =
		state.scheduleActivity.workspaceActivities?.length || 0;
}

function getSentActivityMapper(
	state: activityStateType,
	action: ResponseAction,
) {
	if (action.response.page === FIRST_PAGE) {
		state.sentActivity.workspaceActivities =
			action.response.workspaceActivities;
	} else {
		state.sentActivity.workspaceActivities = [
			...state.sentActivity.workspaceActivities,
			...action.response.workspaceActivities,
		];
	}

	if (state.sentActivity.pagination) {
		state.sentActivity.pagination.currentPage = action.response.page;
		state.sentActivity.pagination.totalPages = action.response.totalPages;
		state.sentActivity.pagination.count = action.response.count;
	}

	state.sentActivity.activitiesCount =
		state.sentActivity.workspaceActivities?.length || 0;
}

function bulkSendScheduledActivity(
	state: activityStateType,
	action: ResponseAction,
) {
	const { workspaceActivities } = state.scheduleActivity;
	const updatedActivities = workspaceActivities.filter(
		(activity: any) => !action.payload.selectedIds.includes(activity.id),
	);
	state.scheduleActivity.workspaceActivities = updatedActivities;

	if (
		state.scheduleActivity.pagination &&
		state.scheduleActivity.pagination.count
	) {
		state.scheduleActivity.pagination.count =
			state.scheduleActivity.pagination.count > 0
				? state.scheduleActivity.pagination.count - 1
				: 0;
	}
}

function removeActivityMapper(
	state: activityStateType,
	action: ResponseAction,
) {
	const { workspaceActivities } = state.scheduleActivity;
	const updatedActivities = workspaceActivities.filter(
		(activity: any) => !action.payload.selectedIds.includes(activity.id),
	);
	state.scheduleActivity.workspaceActivities = updatedActivities;

	if (
		state.scheduleActivity.pagination &&
		state.scheduleActivity.pagination.count
	) {
		state.scheduleActivity.pagination.count =
			state.scheduleActivity.pagination.count > 0
				? state.scheduleActivity.pagination.count - 1
				: 0;
	}
}

const activitySlice = createSlice({
	name: 'activity',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(getScheduledActivityTypes, getScheduleActivityMapper),
		...createFetchReducer(getSentActivityTypes, getSentActivityMapper),
		...createFetchReducer(scheduleBulkSendNowTypes, bulkSendScheduledActivity),
		...createFetchReducer(removeActivityTypes, removeActivityMapper),
	},
});

export default activitySlice.reducer;
