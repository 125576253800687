export enum UserTemplateTypeEnum {
	INBOX = 'inbox',
	VIDEO_MESSAGE = 'videoMessage',
}

export enum UserTemplateChannelsEnum {
	SMS = 'sms',
	WHATSAPP = 'whatsapp',
	POSTCARD = 'post',
}

export enum UserTemplateStatusEnum {
	APPROVED = 'approved',
	REJECTED = 'rejected',
	UNSUBMITTED = 'unsubmitted',
	RECEIVED = 'received',
	PENDING = 'pending',
	PAUSED = 'paused',
	DISABLED = 'disabled',
}
