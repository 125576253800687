export enum PageRouteEnum {
	callback = 'callback',
	changingWorkspace = 'Changing-workspace',
	changingWorkspaceFallback = 'ChangingWorkspace',
	home = 'home',
	campaigns = 'campaigns',
	campaign = 'campaign',
	audiences = 'audiences',
	settings = 'settings',
	campaignCreation = 'campaign-creation',
	campaignCreationV2 = 'campaign-creation-v2',
	campaignCreationFallback = 'CampaignCreation',
	videos = 'videos',
	video = 'video',
	activity = 'activity',
	teams = 'teams',
	newTeam = 'new-team',
	profile = 'profile',
	inbox = 'inbox',
	recorders = 'recorders',
	successPlan = 'success-plan',
	chatbot = 'chatbot',
	mobileAppRedirect = 'mobile-app-redirect',
}

export enum PageNameEnum {
	HOME = 'Home',
	ACTIVITY = 'Activity',
	CAMPAIGNS = 'Campaigns',
	CAMPAIGN_CREATION = 'Campaign Creation',
	CALL_TO_ACTIONS = 'Actions',
	CAMPAIGN = 'Campaign',
	VIDEOS = 'Video Library',
	VIDEO = 'Video',
	SETTINGS = 'Settings',
	AUDIENCES = 'Audiences',
	RECORDERS = 'Recorders',
	SUCCESS_PLAN = 'Success Plan',
	CHATBOT = 'Chatbot',
	INBOX = 'Inbox',
	TEAMS = 'Teams',
}

export enum ActivityRoute {
	ASSIGNED = 'assigned',
	APPROVAL = 'to-approve',
	SCHEDULED = 'scheduled',
	REPLIED = 'replied',
	SENT = 'sent',
}
