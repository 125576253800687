export enum VideoStepEnum {
	uploading = 'uploading',
	uploaded = 'uploaded',
	encoding = 'encoding',
	encoded = 'encoded',
	analyzingAudio = 'analyzingAudio',
	audioAnalyzed = 'audioAnalyzed',
	done = 'done',
}

export enum videoStatusEnum {
	processing = 'Processing',
	staticPlay = 'StaticPlay',
	processed = 'Processed',
}
