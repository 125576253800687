import styled from 'styled-components';

const ILLUSTRATION_SIZES: { [key: string]: string } = {
	small: '3rem',
	regular: '3.5rem',
	medium: '4rem',
	large: '5rem',
};

export const StyledIllustration = styled.img<{
	size?: string;
	isSquare?: boolean;
	width?: string;
	height?: string;
}>`
	width: ${({ size, width }) => (size ? ILLUSTRATION_SIZES[size] : width)};
	height: ${({ size, height, isSquare }) =>
		isSquare && size ? ILLUSTRATION_SIZES[size] : height};
`;
