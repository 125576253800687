import styled from 'styled-components';

import { Typography } from '../styles';

export const H4Typography = styled(Typography).attrs({
	as: 'h4',
})`
	line-height: 16px;
	font-size: ${({ theme }) => theme.fontSizes?.regular};
`;
