import styled from 'styled-components';
import { ExplainerProps } from './types';

const StyledP = styled.p<ExplainerProps>`
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[400]};
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	margin: 0;
`;

export default StyledP;
