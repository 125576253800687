import { useEffect } from 'react';
import { GrowthBook } from '@growthbook/growthbook-react';
import { env } from '@app/env';

/** These attributes are previously created in grothbook admin dashboard **/
type AttributesType = {
	workspaceId: string;
	storytellerId: string;
};

type useGrowthBookFeaturesProps = {
	attributes?: AttributesType;
};

const useGrowthBookFeatures = ({
	attributes,
}: useGrowthBookFeaturesProps = {}) => {
	const isDevOrQa =
		env.NODE_ENV === 'development' ||
		env.NODE_ENV === 'qa' ||
		env.NODE_ENV === 'dev';

	const GrowthBookInstance = new GrowthBook({
		enableDevMode: !!isDevOrQa,
	});

	useEffect(() => {
		// Load feature definitions from GrowthBook API
		fetch(`https://cdn.growthbook.io/api/features/${env.GROWTHBOOK_API_KEY}`)
			.then((res) => res.json())
			.then((parsed) => {
				GrowthBookInstance.setFeatures(parsed.features);
			})
			.then(() => {
				// Set user attributes for targeting in experiment
				if (attributes) {
					GrowthBookInstance.setAttributes(attributes);
				}
			});
	}, []);

	return GrowthBookInstance;
};

export default useGrowthBookFeatures;
