import styled from 'styled-components';
import { Icon } from '../../atoms';

export const ArrowIcon = styled(Icon)<{ disabled?: boolean }>`
	color: ${({ disabled, theme }) =>
		disabled
			? theme.colors.system.text.weak
			: theme.colors.system.text.default} !important;
`;

export const ArrowIconContainer = styled.div`
	display: flex;
	margin-left: auto;
	transition: ${({ theme }) => theme.transitions.transform};
`;

export const TitleIconWrapper = styled.div`
	width: ${({ theme }) => theme.spacing.xl};
	height: ${({ theme }) => theme.spacing.xl};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
	background: ${({ theme }) => theme.colors.system.text.default};
`;
export const AccordionTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.medium};
`;

export const AccordionSummary = styled.summary<{
	disabled?: boolean;
	hasHorizontalPadding?: boolean;
}>`
	display: flex;
	align-items: center;
	min-height: ${({ theme }) => theme.spacing.xxxl};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	background: ${({ disabled, theme }) =>
		disabled && theme.colors.system.background.weak};
	padding: ${({ theme, hasHorizontalPadding }) => {
		if (hasHorizontalPadding) {
			return `0 ${theme.spacing.large}`;
		}
		return `0`;
	}};
`;

export const AccordionWrapper = styled.details`
	display: flex;
	flex-direction: column;
	width: 100%;

	&[open] {
		padding-bottom: ${({ theme }) => theme.spacing.xs};

		${AccordionSummary} {
			margin-bottom: ${({ theme }) => theme.spacing.medium};
		}
		${ArrowIconContainer} {
			transform: rotate(180deg);
		}
	}

	& + & {
		border-top: 1px solid ${({ theme }) => theme.colors.system.border.weak};
	}
`;

export const AccordionContent = styled.div<{ disabled?: boolean }>`
	display: flex;
	width: 100%;
	height: 100%;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	background-color: red;
`;
