import GoodkindTheme from '@global/theme';
import { InputInfoType } from '../types';

export const handleTextColor = (type?: InputInfoType | null) => {
	switch (type) {
		case 'informative':
			return GoodkindTheme.colors.primary[700];
		case 'warning':
			return GoodkindTheme.colors.warning[700];
		case 'alert':
			return GoodkindTheme.colors.alert[700];
		default:
			return GoodkindTheme.colors.alert[700];
	}
};

export const handleBorderColor = (type: InputInfoType | null) => {
	switch (type) {
		case 'informative':
			return GoodkindTheme.colors.primary[700];
		case 'warning':
			return GoodkindTheme.colors.warning[700];
		case 'alert':
			return GoodkindTheme.colors.alert[700];
		default:
			return GoodkindTheme.colors.alert[700];
	}
};

export const handleBackgroundColor = (type: InputInfoType | null) => {
	switch (type) {
		case 'informative':
			return GoodkindTheme.colors.primary[50];
		case 'warning':
			return GoodkindTheme.colors.warning[50];
		case 'alert':
			return GoodkindTheme.colors.alert[50];
		default:
			return GoodkindTheme.colors.alert[50];
	}
};
