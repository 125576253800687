import styled from 'styled-components';

export const Wrapper = styled.div<{
	active?: boolean;
	onClick?: () => void;
	disabled?: boolean;
	minHeight?: string;
}>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	width: 100%;
	border: 1px solid
		${({ theme, active, onClick }) =>
			active
				? theme.colors.primary.border.default
				: !!onClick
					? theme.colors.system.border.weak
					: theme.colors.system.border.medium};
	border-radius: ${({ theme }) => theme.shapeRadius.medium};
	box-shadow: ${({ theme }) => theme.shadows.elevation.container};
	cursor: ${({ onClick, disabled }) => !!onClick && !disabled && 'pointer'};
	transition: ${({ theme }) => theme.transitions.ease01};
	min-height: ${({ minHeight }) => minHeight};

	&:hover {
		border-color: ${({ theme, active }) =>
			active
				? theme.colors.primary.border.default
				: theme.colors.system.border.medium};
		box-shadow: ${({ theme, disabled }) =>
			!disabled && theme.shadows.elevation.small};
	}
`;

export const HeaderWrapper = styled.div<{
	$withHeaderDivider?: boolean;
	$isCollapsedMemo?: boolean;
}>`
	width: 100%;
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.small};
	padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.large}`};
	border-bottom: ${({ theme, $withHeaderDivider, $isCollapsedMemo }) =>
		$withHeaderDivider &&
		!$isCollapsedMemo &&
		`1px solid ${theme.colors.system.border.weak}`};

	.buttons-wrapper {
		display: flex;
		column-gap: ${({ theme }) => theme.spacing.small};
		margin-left: auto;
	}

	.texts-wrapper {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xs};

		.title-wrapper {
			display: flex;
			align-items: center;
			gap: ${({ theme }) => theme.spacing.xs};
		}
	}
`;
