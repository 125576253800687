export enum ContactImportIntegrationNameEnum {
	SFTP = 'sftp',
	HUBSPOT = 'hubspot',
	HUBSPOT_BETA = 'hubspot-beta',
	SALESFORCE = 'salesforce',
	DYNAMICS = 'dynamics',
	SALESFORCE_SANDBOX = 'salesforce:sandbox',
	BLACKBAUD = 'blackbaud',
	ELLUCIAN_RECRUIT = 'ellucian-recruit',
}

export enum ContactImportIntegrationDisplayNameEnum {
	SFTP = 'SFTP',
	HUBSPOT = 'Hubspot',
	HUBSPOT_BETA = 'Hubspot',
	SALESFORCE = 'Salesforce',
	DYNAMICS = 'Dynamics',
	SALESFORCE_SANDBOX = 'Salesforce Sandbox',
	BLACKBAUD = 'Blackbaud',
	ELLUCIAN_RECRUIT = 'Ellucian Recruit',
}

export const weekdays = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const hourOptions = [
	{ label: '12:00 AM', value: 0 },
	{ label: '01:00 AM', value: 1 },
	{ label: '02:00 AM', value: 2 },
	{ label: '03:00 AM', value: 3 },
	{ label: '04:00 AM', value: 4 },
	{ label: '05:00 AM', value: 5 },
	{ label: '06:00 AM', value: 6 },
	{ label: '07:00 AM', value: 7 },
	{ label: '08:00 AM', value: 8 },
	{ label: '09:00 AM', value: 9 },
	{ label: '10:00 AM', value: 10 },
	{ label: '11:00 AM', value: 11 },

	{ label: '12:00 PM', value: 12 },
	{ label: '01:00 PM', value: 13 },
	{ label: '02:00 PM', value: 14 },
	{ label: '03:00 PM', value: 15 },
	{ label: '04:00 PM', value: 16 },
	{ label: '05:00 PM', value: 17 },
	{ label: '06:00 PM', value: 18 },
	{ label: '07:00 PM', value: 19 },
	{ label: '08:00 PM', value: 20 },
	{ label: '09:00 PM', value: 21 },
	{ label: '10:00 PM', value: 22 },
	{ label: '11:00 PM', value: 23 },
];

export enum WorkspacePlansEnum {
	VIDEO_MESSAGING = 'video-messaging',
	CHATBOT = 'chatbot',
	REELS = 'reels',
	SMS = 'sms',
	POST = 'post',
}

export enum WorkspaceUniqueIdentifiersEnum {
	MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
	EMAIL = 'email',
}
