import { createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '../../utils/createFetchReducer';
import { ResponseAction } from '../types';
import { contactsStateType } from './@types';
import {
	filterContactsTypes,
	uploadFlatfileContactsTypes,
	initialState,
	complexFilterContactstypes,
	updateContactTagTypes,
	deleteContactTagTypes,
	softDeleteContactsTypes,
	exportContactsTypes,
	exportContactsPerCampaignTypes,
	filterRepliesActivityTypes,
	filterStoriesActivityTypes,
	getContactsByCampaignIdTypes,
	getSingleContactTypes,
	unsubscribeContactType,
	getTeamContactsTypes,
	updateContactTypes,
} from './constants';
import {
	paginationSuccessMapper,
	complexSuccessMapper,
	updateContactsSuccessMapper,
	deleteContactsTagSucessMaper,
	softDeleteContactsSuccessMapper,
	exportContactsSuccessMapper,
	repliesActivityMapper,
	storiesActivityMapper,
	getSingleContactMapper,
	getTeamContactsMapper,
	unsubscribeContactMapper,
} from './mappers';

export default createSlice({
	name: 'contact',
	initialState,
	reducers: {
		NEW_CONTACT_TAGS(state: contactsStateType, action: ResponseAction) {
			action.payload.forEach((contact: any) => {
				if (state?.contacts[contact._id]) {
					state.contacts[contact._id].tags = contact.tags;
				}
			});
		},
		RESET(state: contactsStateType) {
			state.contacts = {};
			state.contactsCount = 0;
			state.pagination = {
				pages: {},
				currentPage: 1,
			};
		},
		RESET_UPLOAD_FLATFILE_CONTACTS(state: contactsStateType) {
			state.custom = {
				...state.custom,
				uploadFlatfileContactsLoading: false,
				uploadFlatfileContactsResponse: null,
				uploadFlatfileContactsError: null,
			};
		},
	},
	extraReducers: {
		...createFetchReducer(filterContactsTypes, paginationSuccessMapper),
		...createFetchReducer(updateContactTypes),
		...createFetchReducer(uploadFlatfileContactsTypes),
		...createFetchReducer(complexFilterContactstypes, complexSuccessMapper),
		...createFetchReducer(updateContactTagTypes, updateContactsSuccessMapper),
		...createFetchReducer(deleteContactTagTypes, deleteContactsTagSucessMaper),
		...createFetchReducer(
			softDeleteContactsTypes,
			softDeleteContactsSuccessMapper,
		),
		...createFetchReducer(exportContactsTypes, exportContactsSuccessMapper),
		...createFetchReducer(
			exportContactsPerCampaignTypes,
			exportContactsSuccessMapper,
		),
		...createFetchReducer(filterRepliesActivityTypes, repliesActivityMapper),
		...createFetchReducer(filterStoriesActivityTypes, storiesActivityMapper),
		...createFetchReducer(
			getContactsByCampaignIdTypes,
			paginationSuccessMapper,
		),
		...createFetchReducer(getSingleContactTypes, getSingleContactMapper),
		...createFetchReducer(unsubscribeContactType, unsubscribeContactMapper),
		...createFetchReducer(getTeamContactsTypes, getTeamContactsMapper),
	},
}).reducer;
