import React, { useRef } from 'react';

import { TabsProps, TabType } from './types';

import * as S from './Tabs.styles';

import useScroll from '@common/design-system/global/hooks/useScroll';

import { BadgeCount, Icon, Text } from '@common/design-system/components/atoms';

/**
 * Tab component:
 *
 * @param tabs Array of objects containing the tab specifications.
 *
 * @returns A sequence of tabs, according to the array supplied.
 */

export default function Tabs({
	tabs,
	className,
	badgeCountVariant = 'filled',
}: TabsProps) {
	const tabsWrapperRef = useRef(null);

	const {
		hasScroll,
		startScrollRight,
		stopScrollRight,
		startScrollLeft,
		stopScrollLeft,
	} = useScroll(tabsWrapperRef);

	return (
		<S.TabsContainer>
			{hasScroll && (
				<S.ScrollButton
					type="button"
					onMouseDown={() => startScrollLeft()}
					onMouseUp={() => stopScrollLeft()}
				>
					<Icon iconName="chevronLeft" weight="regular" size="large" />
				</S.ScrollButton>
			)}

			<S.TabsWrapper ref={tabsWrapperRef} className={className}>
				{tabs.map(
					(tab: TabType) =>
						tab && (
							<S.Tab
								key={tab.text}
								disabled={tab.disabled}
								active={tab.isActive}
								onClick={tab.onSelect}
							>
								<S.TextContainer active={tab.isActive} disabled={tab.disabled}>
									{tab.iconName && (
										<Icon
											iconName={tab.iconName}
											weight={tab.isActive ? 'fill' : 'regular'}
											size="xs"
										/>
									)}
									<Text
										size="small"
										fontWeight="medium"
										lineHeight="large"
										className="tab-text"
									>
										{tab.text}
									</Text>

									{tab.count != null && (
										<BadgeCount
											count={tab.count}
											variant={tab.isActive ? badgeCountVariant : 'neutral'}
											size="small"
										/>
									)}
								</S.TextContainer>
							</S.Tab>
						),
				)}
			</S.TabsWrapper>

			{hasScroll && (
				<S.ScrollButton
					type="button"
					onMouseDown={() => startScrollRight()}
					onMouseUp={() => stopScrollRight()}
				>
					<Icon iconName="chevronRight" weight="regular" size="large" />
				</S.ScrollButton>
			)}
		</S.TabsContainer>
	);
}
