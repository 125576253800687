// Template thumbnails
import BodyTextImagePNG from '@global/images/typography-thumbnails/body.png';
import HeaderTextImagePNG from '@global/images/typography-thumbnails/header.png';
import SubHeaderTextImagePNG from '@global/images/typography-thumbnails/sub-header.png';
import CongradulationsPNG from '@global/images/typography-thumbnails/congradulations.png';
import MarkYourCalendarPNG from '@global/images/typography-thumbnails/mark-your-calendar.png';
import TakeTheNextStepPNG from '@global/images/typography-thumbnails/take-the-next-step.png';
import YouAreInvitedPNG from '@global/images/typography-thumbnails/you-are-invited.png';
import YourTimeIsNowPNG from '@global/images/typography-thumbnails/your-time-is-now.png';
// Template jsons
import BodyTextImageJSON from '@global/json/typography-jsons/body.json';
import HeaderTextImageJSON from '@global/json/typography-jsons/header.json';
import SubHeaderTextImageJSON from '@global/json/typography-jsons/sub-header.json';
import CongradulationsJSON from '@global/json/typography-jsons/congradulations.json';
import MarkYourCalendarJSON from '@global/json/typography-jsons/mark-your-calendar.json';
import TakeTheNextStepJSON from '@global/json/typography-jsons/take-the-next-step.json';
import YouAreInvitedJSON from '@global/json/typography-jsons/you-are-invited.json';
import YourTimeIsNowJSON from '@global/json/typography-jsons/your-time-is-now.json';

export const postcardImageUrls = {
	qrCode:
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723678634/post-templates/ltg6dyhjoalrwrmh6qxs.svg',
	videoThumbnail:
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723678634/post-templates/ozgiognnvftehoihh31e.svg',
	workspaceLogo:
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723678634/post-templates/wiymnicngxxdcivsx8kb.svg',

	qrCodePNG:
		'https://res.cloudinary.com/dt4bove91/image/upload/v1724962437/post-templates/merge-images/qrCode_NotRounded_j6tpmp.png',
	videoThumbnailPNG:
		'https://res.cloudinary.com/dt4bove91/image/upload/v1724962437/post-templates/merge-images/videoThumbnail_NotRounded_g5blpz.png',
	workspaceLogoPNG:
		'https://res.cloudinary.com/dt4bove91/image/upload/v1724962437/post-templates/merge-images/workspaceLogo_NotRounded_rvpfbm.png',
	workspaceBgPNG:
		'https://res.cloudinary.com/dt4bove91/image/upload/v1725292252/post-templates/merge-images/Background_NotRounded_uxpocn.png',

	'4x6_overlay_front':
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723052810/post-templates/4x6_overlay_front_ygcmed.png',
	'6x9_overlay_front':
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723052811/post-templates/6x9_overlay_front_rxqsme.png',
	'6x11_overlay_front':
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723052811/post-templates/6x11_overlay_front_oycjrt.png',
	'4x6_overlay_back':
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723052752/post-templates/4x6_overlay_back_igs81c.png',
	'6x9_overlay_back':
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723052752/post-templates/6x9_overlay_back_fvrmzv.png',
	'6x11_overlay_back':
		'https://res.cloudinary.com/dt4bove91/image/upload/v1723052752/post-templates/6x11_overlay_back_s8iyps.png',
};

export enum UserRolesEnum {
	ADMIN = 'admin',
	USER = 'user',
}

export enum PostcardSizeEnum {
	FOUR_BY_SIX = '4x6',
	SIX_BY_NINE = '6x9',
	SIX_BY_ELEVEN = '6x11',
}
export enum PostcardVariableNames {
	QR_CODE = 'qr-code',
	WORKSPACE_LOGO = 'workspace-logo',
	WORKSPACE_BG = 'workspace-bg',
	VIDEO_THUMBNAIL = 'video-thumbnail',
}

export const postCardSizeOptions = [
	{
		label: '4x6 inches',
		value: PostcardSizeEnum.FOUR_BY_SIX,
	},
	{
		label: '6x9 inches',
		value: PostcardSizeEnum.SIX_BY_NINE,
	},
	{
		label: '6x11 inches',
		value: PostcardSizeEnum.SIX_BY_ELEVEN,
	},
];

export const postcardSizeMap = {
	[PostcardSizeEnum.FOUR_BY_SIX]: {
		width: 1871,
		height: 1271,
	},
	[PostcardSizeEnum.SIX_BY_NINE]: {
		width: 2771,
		height: 1871,
	},
	[PostcardSizeEnum.SIX_BY_ELEVEN]: {
		width: 3371,
		height: 1871,
	},
};

export const POSTCARD_BORDER_RADIUS = {
	[PostcardVariableNames.QR_CODE]: {
		round: 16,
		square: 0,
	},
	[PostcardVariableNames.WORKSPACE_LOGO]: {
		round: 1000,
		square: 0,
	},
	[PostcardVariableNames.WORKSPACE_BG]: {
		round: 0,
		square: 0,
	},
	[PostcardVariableNames.VIDEO_THUMBNAIL]: {
		round: 16,
		square: 0,
	},
};

export const TYPOGRAPHY_TEMPLATES = [
	{
		json: HeaderTextImageJSON,
		image: HeaderTextImagePNG,
		type: 'basic',
	},
	{
		json: SubHeaderTextImageJSON,
		image: SubHeaderTextImagePNG,
		type: 'basic',
	},
	{
		json: BodyTextImageJSON,
		image: BodyTextImagePNG,
		type: 'basic',
	},
	{
		json: YourTimeIsNowJSON,
		image: YourTimeIsNowPNG,
		type: 'custom',
	},
	{
		json: YouAreInvitedJSON,
		image: YouAreInvitedPNG,
		type: 'custom',
	},
	{
		json: TakeTheNextStepJSON,
		image: TakeTheNextStepPNG,
		type: 'custom',
	},
	{
		json: MarkYourCalendarJSON,
		image: MarkYourCalendarPNG,
		type: 'custom',
	},
	{
		json: CongradulationsJSON,
		image: CongradulationsPNG,
		type: 'custom',
	},
];

export enum PolotnoElementTypeEnum {
	GROUP = 'group',
	TEXT = 'text',
	IMAGE = 'image',
	SVG = 'svg',
	LINE = 'line',
	FIGURE = 'figure',
	VIDEO = 'video',
}

export enum PostcardActivityStatusEnum {
	POSTCARD_PENDING = 'postcard_pending',
	POSTCARD_RECEIVED = 'postcard_received',
	POSTCARD_PRODUCING = 'postcard_producing',
	POSTCARD_DELIVERED = 'postcard_delivered',
	POSTCARD_IN_TRANSIT = 'postcard_in_transit',
	POSTCARD_CANCELLED = 'postcard_cancelled',
	POSTCARD_RETURNED = 'postcard_returned',
	POSTCARD_FAILED = 'postcard_failed',
}
