import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.neutral.background.default};
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	padding: 24px;
	margin-top: -8px;

	> * + * {
		margin-top: 8px;
	}
`;
