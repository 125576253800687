import { PostcardSizeEnum } from '@app/constants/modules/postcard';
import { WorkspaceType } from '@app/redux/workspace/types';
import { StoreType } from 'polotno/model/store';
import React from 'react';
import { CreativeEditorProps } from '../CreativeEditor';

type ContextValueType = {
	store: StoreType;
	workspaceInfo?: WorkspaceType;
	customMergeImages?: CreativeEditorProps['customMergeImages'];
	handleLoadTemplate?: (template: JSON, store: StoreType) => void;
	handleAddOverlay?: (
		frontPage: StoreType['pages'][number],
		backPage: StoreType['pages'][number],
		postcardSize: PostcardSizeEnum,
	) => void;
};

export const CreativeEditorContext =
	React.createContext<ContextValueType | null>(null);

type PropsType = {
	children: React.ReactNode;
	customMergeImages: ContextValueType['customMergeImages'];
	store: ContextValueType['store'];
	workspaceInfo?: ContextValueType['workspaceInfo'];
	handleLoadTemplate?: ContextValueType['handleLoadTemplate'];
	handleAddOverlay?: ContextValueType['handleAddOverlay'];
};

function CreativeEditorProvider({
	children,
	store,
	customMergeImages,
	workspaceInfo,
	handleLoadTemplate,
	handleAddOverlay,
}: PropsType) {
	return (
		<CreativeEditorContext.Provider
			value={{
				store,
				customMergeImages,
				workspaceInfo,
				handleLoadTemplate,
				handleAddOverlay,
			}}
		>
			{children}
		</CreativeEditorContext.Provider>
	);
}

export const useCreativeEditorContext = () => {
	const context = React.useContext(CreativeEditorContext);

	if (context == null) {
		throw new Error(
			'Creative Editor components must be wrapped in <CreativeEditorProvider>',
		);
	}

	return context;
};
export default CreativeEditorProvider;
