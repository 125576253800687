import styled from 'styled-components';

export const BuildPageWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;
export const SwitchContainer = styled.div`
	position: absolute;
	top: 24px;
	right: 24px;
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	z-index: ${({ theme }) => theme.zIndex.modal};
	.switch-container {
		display: flex;
		width: 100%;
		border-radius: ${({ theme }) => theme.shapeRadius.small};
		box-shadow: ${({ theme }) => theme.shadows.elevation.base};
	}
`;

export const SectionContainer = styled.section`
	margin-bottom: 16px;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	padding: 0 ${({ theme }) => theme.spacing.large};
`;

export const ToggleButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${({ theme }) => theme.spacing.xs} 0px;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;
