import React, { useEffect } from 'react';
import Loader from '@common/design-system/components/atoms/Loader/Loader';
import { RedirectStatusMessage } from './components';
import * as S from './MobileAppRedirect.styles';
import { env } from '@app/env';
import GoodkindDSProvider from '@common/design-system/global/provider';

function MobileAppRedirect() {
	let mobileAppRedirectUrl;
	//ios uses environment so app.goodkind.{env}:// and so it will redirect to the correct environment
	//android is always just goodkind:// and on android it will show another popup if you have different environments of the app installed
	const isAndroid = /Android/i.test(navigator.userAgent);
	if (isAndroid) {
		mobileAppRedirectUrl = 'goodkind://';
	} else {
		mobileAppRedirectUrl = `app.goodkind.${env.NODE_ENV}://`;
	}

	const isOnMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent,
		);

	useEffect(() => {
		window.location.replace(mobileAppRedirectUrl);
	}, []);

	return (
		<GoodkindDSProvider withGlobalStyle={false}>
			<S.MobileAppRedirectContainer>
				<RedirectStatusMessage currentUserIsOnMobile={isOnMobile} />
				{isOnMobile && <Loader loading />}
			</S.MobileAppRedirectContainer>
		</GoodkindDSProvider>
	);
}

export default MobileAppRedirect;
