import React, { useMemo } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Icon, Input } from '..';

import * as S from './PhoneNumberInput.styles';
import { InputPropsType } from '../Input/Input';

type PhoneNumberInputProps = {
	placeholder?: string;
	value: string | null;
	onChange: (value: any) => void;
	hasError?: boolean;
	message?: string;
	size?: InputPropsType['size'];
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
	placeholder,
	value,
	onChange,
	hasError,
	message,
	size = 'medium',
}) => {
	const isShowingErrorMessage = useMemo(() => {
		return hasError && !!message;
	}, [hasError, message]);

	return (
		<S.PhoneNumberInputContainer $size={size} $hasError={hasError}>
			<PhoneInput
				onChange={onChange}
				value={(value as any) || ''}
				defaultCountry="US"
				countrySelectProps={{
					arrowComponent: () => (
						<Icon iconName="chevronDown" color="system.text.weak" />
					),
				}}
				placeholder={placeholder || null}
				hasError={isShowingErrorMessage}
				message={isShowingErrorMessage && message}
				inputComponent={InputComponentWithRef}
			/>
		</S.PhoneNumberInputContainer>
	);
};

// Separate the input component to fix losing focus on typing a character
// https://github.com/catamphetamine/react-phone-number-input/issues/345
const InputComponent = (
	props: any,
	ref: React.ForwardedRef<HTMLInputElement>,
) => {
	return <Input ref={ref} {...props} />;
};

const InputComponentWithRef = React.forwardRef<HTMLInputElement, any>(
	InputComponent,
);

export default PhoneNumberInput;
