import styled, { keyframes } from 'styled-components';

type SkeletonStyleProps = {
	height?: string;
};

const load = keyframes`
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
`;

export const SkeletonContainer = styled.div<SkeletonStyleProps>`
	display: block;
	background-color: #f2f2f2;
	height: ${({ height }) => (height ? height : '40px')};
	box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
	border-radius: 4px;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		position: absolute;
		left: -150px;
		top: 0;
		height: 100%;
		width: 150px;
		background: linear-gradient(
			to right,
			transparent 0%,
			#e8e8e8 50%,
			transparent 100%
		);
		animation: ${load} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
	}
`;
