import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@app/redux/types';
import {
	StyledGrid,
	StyledColumn,
	LoginLoaderContainer,
} from './LoginScreen.styles';
import Loader from '@common/components/presentation/Loader';
import { ToastMessage } from '@app/constants';
import { PageRouteEnum } from '@app/constants/pages';
import { env } from '@app/env';
import { showToast } from '@common/design-system/components/molecules';
import { useAuth } from '@app/services/Auth/useAuth';
import authConfig from '@app/services/Auth/authConfig';
import EmailCheck from '../EmailCheck/EmailCheck';

function Login() {
	const navigate = useNavigate();
	const location = useLocation();
	const { authProvider, selectedAuthProviderName } = useAuth();

	const [email, setEmail] = useState('');

	const { emailCheckResponse } = useSelector(({ auth }: RootState) => ({
		emailCheckResponse: auth.custom?.emailCheckResponse,
	}));

	useEffect(() => {
		if (location.search.includes('unauthorized=true')) {
			showToast({ message: ToastMessage.errorPermissionDenied, type: 'error' });
		}
	}, []);

	useEffect(() => {
		const locationState = location.state as any;
		const condition = locationState ? locationState.fromOther || false : false;

		if (condition) {
			showToast({ message: ToastMessage.errorLoginFirst, type: 'error' });
			navigate(location.pathname, {
				state: { ...locationState, fromOther: false },
			});
		}
	}, []);

	useEffect(() => {
		if (authProvider?.isAuthenticated && selectedAuthProviderName) {
			navigate(`/${PageRouteEnum.home}`);
		}
	}, [authProvider?.isAuthenticated, selectedAuthProviderName]);

	useEffect(() => {
		if (!authProvider?.isAuthenticated && selectedAuthProviderName) {
			if (authProvider?.name === 'auth0') {
				const params = new URLSearchParams(location.search);
				authProvider?.loginWithRedirect({
					screen_hint: params?.get('screen_hint') || 'login',
					login_hint: email,
					env: env.NODE_ENV,
				});
			}

			if (authProvider?.name === 'propelAuth') {
				if (emailCheckResponse?.ssoLoginPage) {
					window.location.href =
						emailCheckResponse?.ssoLoginPage + `?login_hint=${email}`;
				} else {
					const encryptedEmail = btoa(email);
					authProvider?.loginWithRedirect({
						postLoginRedirectUrl: authConfig.redirectUri,
						userSignupQueryParameters: {
							e: encryptedEmail,
						},
					});
				}
			}
		}
	}, [authProvider?.isAuthenticated, selectedAuthProviderName]);

	return (
		<div>
			<div>
				{!selectedAuthProviderName ? (
					<EmailCheck email={email} setEmail={setEmail} />
				) : (
					<>
						{!authProvider?.isLoading &&
							!authProvider?.isAuthenticated &&
							selectedAuthProviderName && (
								<StyledGrid>
									<StyledColumn>
										<LoginLoaderContainer>
											<Loader loading width="75px" />
										</LoginLoaderContainer>
									</StyledColumn>
								</StyledGrid>
							)}
					</>
				)}
			</div>
		</div>
	);
}

export default Login;
