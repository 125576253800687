import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import axios from '@global/axios';
import { FilterOptions } from '@app/types';
import { AutomationTypes } from './types';

const mainType = 'automation';
export const getAutomationsTypes = createFetchTypes(
	`${mainType}_GET_AUTOMATIONS`,
);
export const editAutomationTypes = createFetchTypes(
	`${mainType}_EDIT_AUTOMATION`,
);
export const createAutomationTypes = createFetchTypes(
	`${mainType}_CREATE_AUTOMATION`,
);
export const getSingleAutomationTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_AUTOMATION`,
);
export const deleteAutomationTypes = createFetchTypes(
	`${mainType}_DELETE_AUTOMATION`,
);

function getSingleAutomation(
	automationId: string,
	FilterOptions: FilterOptions,
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getSingleAutomationTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/automation/${automationId}${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			errorMessage: ToastMessage.automationNotFound,
		},
	};
}

function getAutomations(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify({
		...FilterOptions,
		filter: [...(FilterOptions.filter ?? [])],
	});

	return {
		type: getAutomationsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/automation${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'getAutomations',
		},
	};
}

function createAutomation(automation: AutomationTypes): fetchAction {
	return {
		type: createAutomationTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/automation`, {
				...automation,
			}),
		payload: {
			custom: 'createAutomation',
			successMessage: ToastMessage.createAutomation,
			errorMessage: ToastMessage.error,
		},
	};
}

function editAutomation(automation: AutomationTypes): fetchAction {
	return {
		type: editAutomationTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/automation`, {
				...automation,
			}),
		payload: {
			custom: 'editAutomation',
			successMessage: ToastMessage.editAutomation,
			errorMessage: ToastMessage.error,
		},
	};
}

function deleteAutomation(id: string): fetchAction {
	return {
		type: deleteAutomationTypes,
		callAPI: () => axios.delete(`${apiUrl}/automation/${id}`),
		payload: {
			errorMessage: ToastMessage.error,
		},
	};
}

export {
	getSingleAutomation,
	getAutomations,
	createAutomation,
	editAutomation,
	deleteAutomation,
};
