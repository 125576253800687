import styled from 'styled-components';

import Button from '../Button';

import { ButtonProps, ButtonSizeType } from '../Button/types';

function handleIconMargin(size: ButtonSizeType) {
	switch (size) {
		case 'regular':
			return '8px';

		case 'large':
			return '10px';

		default:
			return '8px';
	}
}

export const StyledIconButton = styled(Button)<ButtonProps>`
	padding: ${({ theme, size }) =>
		size && theme.iconButtonPadding && (theme.iconButtonPadding as any)[size]};

	> svg + * {
		margin-left: ${({ size, loading }) =>
			size && !loading && handleIconMargin(size)};
	}

	> * + svg {
		margin-left: ${({ size }) => size && handleIconMargin(size)};
	}
`;

export const ChildrenWrapper = styled.div<{ size: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit; // inherit color from the styled Button
`;
