import styled, { css } from 'styled-components';

export const SubMenuWrapper = styled.div<{
	children: any;
	isOpen?: boolean;
	isNavbarCollapsed?: boolean;
	isSubMenusThreaded?: boolean;
	isMenuSortable?: boolean;
}>`
	width: auto;
	max-height: ${({ isOpen, isNavbarCollapsed }) =>
		isOpen || isNavbarCollapsed ? '100%' : '0'};
	overflow: ${({ isMenuSortable }) => (isMenuSortable ? `visible` : `hidden`)};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};

	${({ isSubMenusThreaded }) => {
		if (isSubMenusThreaded) {
			return css`
				padding-left: ${({ theme }) => theme.spacing.xs};
				margin-left: ${({ theme }) => theme.spacing.medium};
				border-left: 1px solid ${({ theme }) => theme.colors.system.border.weak};
			`;
		}
	}}
`;

export const NavLinkWithSubMenu = styled.div``;

export const DraggableMenuOption = styled.div<{ isActive?: boolean }>`
	position: relative;
	overflow: hidden;

	.draggable-icon {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-12px, 10px);
	}

	&:hover,
	&:active {
		overflow: visible;
		transition: overflow ${({ theme }) => theme.transition?.short};
	}
	${({ isActive }) =>
		isActive
			? css`
					&:active .draggable-option {
						background-color: ${({ theme }) =>
							theme.colors.primary.background.defaultActive};
					}
				`
			: ''}
`;
