import {
	IField,
	ITheme,
	IValidator,
} from '@flatfile/adapter/build/main/interfaces';
import GoodkindTheme from '@global/theme';
import * as R from 'ramda';
import { contactRecordChange, storytellerRecordChange } from '../utils';

export const flatFileKey = '3f39d564-2c19-49e6-ac61-b014459d6cf2';

export const getLocationFields = (required?: boolean): IField[] => {
	return [
		{
			label: 'Address',
			key: 'address',
			validators: required ? [{ validate: 'required' }] : [],
		},
		{
			label: 'City',
			key: 'city',
			validators: required ? [{ validate: 'required' }] : [],
		},
		{
			label: 'State',
			key: 'state',
			validators: required ? [{ validate: 'required' }] : [],
		},
		{
			label: 'Country',
			key: 'country',
			validators: required ? [{ validate: 'required' }] : [],
		},
		{
			label: 'Postal code/Zip',
			key: 'postCode',
			type: 'string',
			validators: required ? [{ validate: 'required' }] : [],
		},
	];
};

export const getcontactFields = ({
	isPhoneNumberRequired,
}: {
	isPhoneNumberRequired?: boolean;
}): IField[] => {
	return [
		{
			label: 'First Name',
			key: 'firstName',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Last Name',
			key: 'lastName',
			validators: [{ validate: 'required' }],
		},
		{
			label: 'Email Address',
			key: 'email',
			validators: [
				{ validate: 'required' },
				{
					validate: 'regex_matches',
					regex: '^([A-Za-z0-9_\\-.])+@([A-Za-z0-9_\\-.])+\\.([A-Za-z]{2,4})$',
					error: 'Please enter a valid email address.',
				},
			],
			description: 'Our default method to send videos.',
		},
		{
			label: 'Sending Method',
			type: 'select',
			key: 'communicationPreference',
			options: [
				{ value: 'email', label: 'Email' },
				{ value: 'sms', label: 'SMS' },
				{ value: 'both', label: 'Both' },
			],
			validators: [{ validate: 'required' }],
			description: 'Enter either email, sms or both.',
		},
		{
			label: 'Phone Number',
			key: 'mobilePhoneNumber',
			validators: [
				...(isPhoneNumberRequired
					? [
							{
								validate: 'required',
								error: 'Please enter a phone number.',
							} as IValidator,
						]
					: []),
				{
					validate: 'regex_matches',
					regex: '^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$',
					error: 'Please enter a valid phone number.',
				},
			],
			description: !isPhoneNumberRequired
				? 'Optional. We use this to SMS your contacts.'
				: '',
		},
		{
			label: 'CRM ID',
			key: 'crmId',
		},
		{
			label: 'Owner (Storyteller ID)',
			key: 'owner',
			description:
				"The owner field is a reference to a storyteller. It can be either a custom ID that's unique to your company or the storyteller's ID.",
			validators: [
				{
					validate: 'regex_matches',
					regex: '^[0-9a-fA-F]{24}$',
					error: 'Not a valid storyteller id',
				},
			],
		},
		{ label: 'Caption Language', key: 'prefferedLanguageCode' },
		{
			label: 'Campaign IDs',
			key: 'campaigns',
			description:
				'Contacts will be added to campaigns upon import. Separate campaign ids with commas.',
		},
	];
};

export const storytellersFields: IField[] = [
	{
		label: 'First Name',
		key: 'firstName',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Last Name',
		key: 'lastName',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Email Address',
		key: 'email',
		validators: [
			{ validate: 'required' },
			{ validate: 'unique' },
			{
				validate: 'regex_matches',
				regex: '[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}',
				error: 'Please enter a valid email address.',
			},
		],
		description: 'Work email preferred.',
	},
	{
		label: 'Role',
		key: 'role',
		type: 'select',
		options: [
			{ value: 'admin', label: 'Admin' },
			{ value: 'recorder', label: 'Recorder' },
		],
		validators: [{ validate: 'required' }],
		description: "Type 'admin' or 'recorder'",
	},
	{
		label: 'Title',
		key: 'title',
		description: 'Ex. Volunteer or Alumni',
	},
	{
		label: 'Phone Number',
		key: 'phoneNumber',
		validators: [
			{
				validate: 'regex_matches',
				regex: '^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$',
				error: 'Please enter a valid phone number.',
			},
		],
	},
	{
		label: 'Invite Message',
		key: 'message',
	},
	{
		label: 'External ID',
		key: 'externalId',
	},
];

export const flatFileTheme: ITheme = {
	global: {
		fontFamily: GoodkindTheme.fonts.default,
	},
	buttons: {
		primary: {
			backgroundColor: GoodkindTheme.colors.primary[700],
			borderRadius: GoodkindTheme.borderRadius?.default,
			boxShadow: `0 2px 1px 0 ${GoodkindTheme.colors.primary[700]}`,
			border: 'none',
		},
	},
};

export const fields: Record<string, IField[]> = {
	contacts: R.insertAll(
		5,
		[...getLocationFields()],
		[...getcontactFields({ isPhoneNumberRequired: false })],
	),
	contactsWithRequiredPhoneNumber: R.insertAll(
		5,
		[...getLocationFields()],
		[...getcontactFields({ isPhoneNumberRequired: true })],
	),
	postcardContacts: R.insertAll(
		5,
		[...getLocationFields(true)],
		[...getcontactFields({ isPhoneNumberRequired: false })],
	),
	postcardContactsWithRequiredPhoneNumber: R.insertAll(
		5,
		[...getLocationFields(true)],
		[...getcontactFields({ isPhoneNumberRequired: true })],
	),
	recorders: storytellersFields,
};

export const recordHooks = {
	contacts: contactRecordChange,
	contactsWithRequiredPhoneNumber: contactRecordChange,
	postcardContacts: contactRecordChange,
	postcardContactsWithRequiredPhoneNumber: contactRecordChange,
	recorders: storytellerRecordChange,
};
