const action = [
	{ label: 'First Name', value: 'contact.firstName' },
	{ label: 'Last Name', value: 'contact.lastName' },
	{ label: 'Recorder First Name', value: 'storyteller.firstName' },
	{ label: 'Recorder Last Name', value: 'storyteller.lastName' },
	{ label: 'Recorder Email', value: 'storyteller.email' },
	{ label: 'Org Name', value: 'workspace.organizationName' },
];

const actionNoContact = [
	{ label: 'Recorder First Name', value: 'storyteller.firstName' },
	{ label: 'Recorder Last Name', value: 'storyteller.lastName' },
	{ label: 'Recorder Email', value: 'storyteller.email' },
	{ label: 'Org Name', value: 'workspace.organizationName' },
];

const emailSubjectTitle = [
	...action,
	{ label: 'CRM ID', value: 'contact.crmId' },
	{ label: 'Action Title', value: 'ctaTitle' },
	{ label: 'Video Url', value: 'storyUrl' },
	{ label: 'Org URL', value: 'workspace.organizationUrl' },
];

const inbox = [
	{ label: 'First name', value: 'contact.firstName' },
	{ label: 'Last name', value: 'contact.lastName' },
	{ label: 'Sender first name', value: 'storyteller.firstName' },
	{ label: 'Sender last name', value: 'storyteller.lastName' },
	{ label: 'Sender email', value: 'storyteller.email' },
	{ label: 'Org name', value: 'workspace.organizationName' },
];
export const AvailableMergeTags = {
	emailSubjectTitle,
	action,
	actionNoContact,
	inbox,
};
