import styled from 'styled-components';

import { CheckboxButtonComponentProps } from './types';

import { Textbase } from '../../../presentation/NewTypography';

export const StyledButton = styled.button<CheckboxButtonComponentProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 12px 0 8px;
	box-sizing: border-box;
	min-height: ${({ theme }) => theme.buttonHeight?.small};
	background: ${({ theme }) => theme.colors.neutral[0]};
	box-shadow: ${({ checked }) =>
		!checked && ' 0 1px 4px 0 rgba(46, 60, 74, 0.16)'};
	border: ${({ theme, checked }) =>
		checked ? `1px solid ${theme.colors.primary[700]}` : 'none'};
	border-radius: ${({ theme }) => theme.borderRadius?.default};
	cursor: pointer;

	> * + * {
		margin-left: 8px;
	}

	&:hover {
		p {
			color: ${({ theme, checked }) =>
				checked ? theme.colors.primary[900] : theme.colors.neutral[400]};
		}
	}
`;

export const StyledTextbase = styled(Textbase)<{ checked: boolean }>`
	font-weight: 700;
	color: ${({ theme, checked }) =>
		checked ? theme.colors.primary[700] : theme.colors.neutral[300]};
	white-space: nowrap;
`;
