import React from 'react';

import * as S from './Avatar.styles';

import { getNameInitials } from '@common/design-system/global/utils';
import { Icon } from '../Icon';

export type AvatarSizeType =
	| 'xxxl'
	| 'xxl'
	| 'xl'
	| 'large'
	| 'medium'
	| 'small'
	| 'xs';

type PropsType = {
	id?: string;
	_id?: string;
	name: string;
	image?: string;
	size?: AvatarSizeType;
	onClick?: () => void;
	withBorder?: boolean;
	className?: string;
	status?: 'active' | 'inactive' | 'optedOut';
};

export default function Avatar({
	name,
	image,
	size = 'medium',
	className,
	onClick,
	withBorder = false,
	status,
	...rest
}: PropsType) {
	const sizingMap = {
		xxxl: 80,
		xxl: 48,
		xl: 40,
		large: 32,
		medium: 28,
		small: 24,
		xs: 20,
	};

	const statusSizeMap = {
		xxxl: 18,
		xxl: 16,
		xl: 14,
		large: 12,
		medium: 10,
		small: 8,
		xs: 6,
	};

	const statusBulletSizeMap = {
		xxxl: 13.5,
		xxl: 11.5,
		xl: 10,
		large: 8.57,
		medium: 7.15,
		small: 5.75,
		xs: 4.5,
	};

	const closeIconSizeMap = {
		xxxl: 8.5,
		xxl: 7.4,
		xl: 6.5,
		large: 5.57,
		medium: 4.64,
		small: 3.7,
		xs: 2.75,
	};

	return (
		<S.AvatarWrapper>
			<S.AvatarContainer
				size={sizingMap[size]}
				onClick={onClick}
				className={className}
				withBorder={withBorder}
				{...rest}
			>
				<>
					{image ? (
						<S.AvatarImage alt={name} src={image} />
					) : (
						<S.InitialsTemplate size={sizingMap[size]}>
							{getNameInitials(name)?.toUpperCase() || '?'}
						</S.InitialsTemplate>
					)}
				</>
			</S.AvatarContainer>

			{status && (
				<S.StatusContainer size={statusSizeMap[size]}>
					<S.StatusBullet size={statusBulletSizeMap[size]} status={status}>
						{status === 'optedOut' && (
							<Icon
								iconName="close"
								customNumberSize={closeIconSizeMap[size]}
								weight="regular"
								color="error.text.hover"
							/>
						)}
					</S.StatusBullet>
				</S.StatusContainer>
			)}
		</S.AvatarWrapper>
	);
}

export type { PropsType as AvatarProps };
