import { NavLinkButtonProps } from '@common/design-system/components/molecules/NavLinkButton/NavLinkButton';
import { NavBarState, NavLinkText } from '../constants';
import { GrowthBook } from '@growthbook/growthbook-react';
import { InboxOrder } from '@app/redux/storyteller/types';
import { TeamWithStorytellerInfoType } from '@app/types/modules/teams';

export function getAvatarUrl(userPicture?: string, auth0Picture?: string) {
	if (auth0Picture?.includes('gravatar')) return userPicture;

	return userPicture || auth0Picture;
}

export const getNavBarInitialState = (storageState: NavBarState) => {
	return storageState || NavBarState.OPEN;
};

export const filterNavLinks = (
	navLinks: NavLinkButtonProps[],
	conditions: {
		isWorkspaceAdmin?: boolean;
		hasAnyTeamInboxPermission?: boolean;
		showSuccessPlanMenu?: boolean;
		showBugReportBugMenu?: boolean;
		showChatbotMenu?: boolean;
		hasVideoMessagingPlan?: boolean;
		hasSMSPlan?: boolean;
	},
	growthbook?: GrowthBook,
) =>
	navLinks.filter((navLink) => {
		const textToConditionMap: Record<string, boolean | undefined> = {
			[NavLinkText.HOME]: true,
			[NavLinkText.CAMPAIGNS]:
				conditions.isWorkspaceAdmin &&
				(conditions.hasVideoMessagingPlan || conditions.hasSMSPlan),
			[NavLinkText.ACTIVITY]:
				conditions.isWorkspaceAdmin && conditions.hasVideoMessagingPlan,
			[NavLinkText.VIDEOS]:
				conditions.isWorkspaceAdmin && conditions.hasVideoMessagingPlan,
			[NavLinkText.AUDIENCES]:
				conditions.isWorkspaceAdmin &&
				(conditions.hasVideoMessagingPlan || conditions.hasSMSPlan),
			[NavLinkText.INBOX]:
				(conditions.hasSMSPlan && conditions.isWorkspaceAdmin) ||
				(conditions.hasSMSPlan && conditions.hasAnyTeamInboxPermission),
			[NavLinkText.RECORDERS]: growthbook?.ready
				? conditions.isWorkspaceAdmin && !conditions.hasSMSPlan
				: false,
			[NavLinkText.CHATBOT]: conditions.showChatbotMenu,
			[NavLinkText.SUCCESS_PLAN]: conditions.showSuccessPlanMenu,
			[NavLinkText.REPORT_A_BUG]: conditions.showBugReportBugMenu,
			[NavLinkText.TEAMS]: conditions.isWorkspaceAdmin && conditions.hasSMSPlan,
		};

		return textToConditionMap[navLink.text] ?? conditions.isWorkspaceAdmin;
	});

export const formatInboxOrder = (menuOptions: NavLinkButtonProps[]) => {
	return menuOptions.reduce((ac: InboxOrder[], menuOption, index) => {
		const inboxLinkParts = menuOption.redirectTo?.split('/');
		const inbox = inboxLinkParts?.length === 3 ? inboxLinkParts[2] : '';
		const inboxPosition = {
			inbox,
			order: index,
		};
		ac.push(inboxPosition);
		return ac;
	}, []);
};

export const sortUserTeams = (
	inboxOrder: InboxOrder[],
	userTeams: TeamWithStorytellerInfoType[],
): TeamWithStorytellerInfoType[] => {
	const orderMapping = inboxOrder.reduce((acc: any, curr) => {
		acc[curr.inbox] = curr.order;
		return acc;
	}, {});

	const sortedTeams = userTeams.sort(
		(a, b) => orderMapping[a._id] - orderMapping[b._id],
	);
	return sortedTeams;
};
