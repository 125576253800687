import styled from 'styled-components';
import { InputPropsType } from '../../atoms/Input/Input';

export const TimeInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	width: 100%;
`;

export const InputsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const TimeDropdown = styled.div<{ size: InputPropsType['size'] }>`
	width: 100%;
`;

export const LabelWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;
