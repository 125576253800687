import React, { useCallback, MouseEvent } from 'react';
import * as S from './SquareIconButton.styles';
import { Icon } from '@common/design-system/components/atoms';
import { IconWeight } from '@phosphor-icons/react';

type ButtonVariantType =
	| 'primary'
	| 'neutral'
	| 'ghost'
	| 'success'
	| 'warning';

type ButtonSizeType = 'xs' | 'small' | 'medium' | 'large';

type PropTypes = {
	icon: string;
	children?: React.ReactNode; // Support for custom children
	ariaLabel: string; // Aria label is required to enforce accessibility standards as this component has just an icon and no readable text.
	onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
	iconWeight?: IconWeight;
	iconColor?: string;
	size?: ButtonSizeType;
	disabled?: boolean;
	active?: boolean;
	pressed?: boolean;
	variant?: ButtonVariantType;
	customIconSize?: number; //Take care on using this, mostly used for DS internal composition
};

function SquareIconButton(
	{
		onClick,
		size = 'small',
		icon,
		children,
		disabled = false,
		ariaLabel,
		iconWeight = 'regular',
		active,
		pressed,
		variant = 'primary',
		iconColor,
		customIconSize = 20,
		...rest
	}: PropTypes,
	ref: any,
) {
	const getIconSizing = useCallback(() => {
		if (customIconSize) {
			return customIconSize;
		}

		switch (size) {
			case 'xs':
			case 'small':
			case 'medium':
			case 'large':
				return 20;

			default:
				return 20;
		}
	}, [size]);

	const isIconOnly = !children && !!icon;
	const isCustomChildren = !isIconOnly && !!children;
	return (
		<S.StyledButton
			size={size}
			onClick={onClick}
			disabled={disabled}
			role="button"
			aria-label={ariaLabel}
			active={active}
			pressed={pressed}
			variant={variant}
			ref={ref}
			{...rest}
		>
			{isCustomChildren && children}

			{isIconOnly && (
				<Icon
					iconName={icon}
					customNumberSize={getIconSizing()}
					weight={iconWeight}
					color={iconColor}
					className="pe-none"
				/>
			)}
		</S.StyledButton>
	);
}

export default React.forwardRef<
	HTMLDivElement,
	PropTypes & React.HTMLAttributes<HTMLDivElement>
>(SquareIconButton);
export type { ButtonVariantType, ButtonSizeType };
