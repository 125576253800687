import React from 'react';
import { LinkButton } from '@common/design-system/components/atoms';
import * as R from 'ramda';
import { Navigate } from 'react-router-dom';

import {
	FontLineHeightsType,
	FontWeightsType,
} from '@common/design-system/global/types';
import { LinkButtonProps } from '@common/design-system/components/atoms/LinkButton/LinkButton';

type parseTextWithLinksType = {
	text: string;
	clickEvents?: { [key: string]: () => void };
	fontSize?: LinkButtonProps['size'];
	fontWeight?: FontWeightsType;
	lineHeight?: FontLineHeightsType;
};
export const parseTextWithLinks = ({
	text,
	clickEvents,
	fontSize,
	fontWeight,
	lineHeight,
}: parseTextWithLinksType) => {
	const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g; // Regular expression to match Markdown links
	let currentIndex = 0;
	const parts = [];

	// Loop through matches in the description
	text.replace(linkRegex, (match, textValue, url, index) => {
		// Push text before the match into parts array
		parts.push(text.substring(currentIndex, index));

		const isExternalLink =
			url.startsWith('http') ||
			url.startsWith('www') ||
			url.startsWith('mailto') ||
			url.startsWith('tel');

		const isClickAction = url.startsWith('onClick:');

		// Push JSX link into parts array
		parts.push(
			<>
				<LinkButton
					size={fontSize}
					weight={fontWeight}
					lineHeight={lineHeight}
					className="d-inline"
					key={index}
					href={isExternalLink ? url : undefined}
					onClick={() => {
						if (isExternalLink) return;

						if (isClickAction) {
							const functionName = url.split(':')[1];
							if (clickEvents && R.is(Function, clickEvents[functionName])) {
								clickEvents[functionName]();
							}
							return;
						}
						return <Navigate to={url} />;
					}}
					openInNewTab={isExternalLink}
				>
					{textValue}
				</LinkButton>
			</>,
		);

		// Update currentIndex
		currentIndex = index + match.length;

		return match; // Return the matched string
	});

	// Push the remaining text after the last match into parts array
	parts.push(text.substring(currentIndex));

	return parts;
};
