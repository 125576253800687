import styled from 'styled-components';

import { Textbase } from '../NewTypography';

export const StatTimesText = styled(Textbase)<{ color: string }>`
	margin-left: 4px;
	color: ${({ color }) => color};
`;

export const StatTag = styled.div<{ background: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 8px;
	border-radius: ${({ theme }) => theme.borderRadius?.rounded};
	background: ${({ background }) => `${background}1A`};
`;

export const StatsBarContainer = styled.div`
	display: flex;
	align-items: center;

	> * + * {
		margin-left: 8px;
	}
`;
