import styled from 'styled-components';

import { DataTestType } from '../../../../../app/types';

export const BackButtonContainer = styled.div<DataTestType & { size: number }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ size }) => `${size}px`};
	min-width: ${({ size }) => `${size}px`};
	height: ${({ size }) => `${size}px`};
	border-radius: 50%;
	cursor: pointer;

	&:hover {
		background: ${({ theme }) => theme.colors.neutral[50]};
	}
`;
