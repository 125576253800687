import styled from 'styled-components';
import { BarPropsType } from './Bar';

const getBackgroundColor = (variant: BarPropsType['variant'], theme: any) => {
	if (variant === 'warning') {
		return theme.colors.warning.background.default;
	}

	return theme.colors.system.background.strong;
};

export const BarWrapper = styled.div<{
	position: BarPropsType['position'];
	variant: BarPropsType['variant'];
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: ${({ theme, variant }) =>
		getBackgroundColor(variant, theme)};
	height: 32px;
	column-gap: ${({ theme }) => theme.spacing.small};
	padding: ${({ theme }) => `0 ${theme.spacing.small}`};

	${({ position, theme }) => {
		if (position === 'bottom') {
			return `
					border-top-left-radius: ${theme.shapeRadius.xs};
					border-top-right-radius: ${theme.shapeRadius.xs};
			`;
		}

		if (position === 'top') {
			return `
					border-bottom-left-radius: ${theme.shapeRadius.xs};
					border-bottom-right-radius: ${theme.shapeRadius.xs};
			`;
		}
	}};
`;

export const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.small};
`;

export const BarCloseWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing.xs};
	cursor: pointer;
	pointer-events: all;
	transition: ${({ theme }) => theme.transitions.ease01};

	:hover {
		opacity: 0.8;
	}
`;
