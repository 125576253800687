import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import entityNormalizer from '../../utils/entityNormalizer';
import { normalize } from 'normalizr';

import createFetchReducer from '../../utils/createFetchReducer';
import {
	createAutomationTypes,
	deleteAutomationTypes,
	editAutomationTypes,
	getAutomationsTypes,
	getSingleAutomationTypes,
} from './action';

const initialState: GenericEntityState = {
	loading: false,
	automations: {},
	automationsCount: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
};

export type automationsStateType = typeof initialState;
const automation = entityNormalizer('automations');
const pagination = { automations: [automation] };

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			automations,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ automations, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function paginationMapper(state: automationsStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);

	if (action.response.page === 1) {
		state.automations = normalizedData.entities.automations;
	} else {
		state.automations = {
			...state.automations,
			...normalizedData.entities.automations,
		};
	}
	state.automationsCount = Object.keys(state.automations || {})?.length;

	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.automations;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
}

function createAutomationMapper(
	state: automationsStateType,
	action: ResponseAction,
) {
	const { automation } = action.response;

	const newAutomation = { [automation._id]: automation };

	state.automations = { ...newAutomation, ...state.automations };
}

function updateMapperSuccess(
	state: automationsStateType,
	action: ResponseAction,
) {
	const {
		response: {
			automation: { id, _id },
			automation,
		},
	} = action;
	const automationId = id || _id;
	state.automations[automationId] = automation;
}

function deleteMapperSuccess(
	state: automationsStateType,
	action: ResponseAction,
) {
	const {
		response: { automationId },
	} = action;
	const automationsCopy = { ...state.automations };
	const { [automationId]: deletedAutomation, ...restOfAutomations } =
		automationsCopy;
	state.automations = restOfAutomations;
}

const automationSlice = createSlice({
	name: 'automation',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(getSingleAutomationTypes),
		...createFetchReducer(getAutomationsTypes, paginationMapper),
		...createFetchReducer(createAutomationTypes, createAutomationMapper),
		...createFetchReducer(editAutomationTypes, updateMapperSuccess),
		...createFetchReducer(deleteAutomationTypes, deleteMapperSuccess),
	},
});

export default automationSlice.reducer;
