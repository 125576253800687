import * as R from 'ramda';

import { StorytellerType } from '@app/redux/storyteller/types';
import { VideoSaveStoreType } from './saveStore';
import { VideoTypeEnum } from '../../constants/video';
import { VideoType } from '@app/types/modules/video';

function sortStoriesByStoryOrderAndIncludeType(
	stories: VideoSaveStoreType['stories'],
) {
	//Type is used by the FE, but no stored in the BE
	const transformStory = (story: VideoSaveStoreType['stories'][number]) => ({
		...story,
		assignedTo: [
			story.assignedTo || (story?.video as VideoType)?.uploadedBy,
		] as unknown as StorytellerType[],
		type:
			story.assignedTo && !story.video
				? VideoTypeEnum.ASSIGN_TO_DO
				: VideoTypeEnum.VIDEO_LIBRARY,
	});

	// Then transform and sort the result
	const storiesWithType: VideoSaveStoreType['stories'] = R.pipe(
		R.map(transformStory),
		R.sortBy(R.prop('storyOrder')),
	)(stories);

	return storiesWithType;
}

export { sortStoriesByStoryOrderAndIncludeType };
