import styled from 'styled-components';
import { evalColorPath } from '@common/design-system/global/utils';

export const AnimationContainer = styled.div<{
	isLoading?: boolean;
	width?: string;
	height?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: ${(props) => (props.isLoading ? 1 : 0)};
	position: ${(props) => (props.isLoading ? 'static' : 'absolute')};
	left: ${(props) => (props.isLoading ? 'auto' : '-9999px')};
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	transition: opacity ${({ theme }) => theme.transition?.short};
	path {
		${({ color, theme }) =>
			color && `fill: ${evalColorPath(theme.colors, color)};`}
		display:${(props) => (props.isLoading ? 'block' : 'none')};
	}
`;

export const ThreeDotsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: hidden;
`;
