import styled from 'styled-components';
import { H1Props } from './types';

export const StyledH1 = styled.h1<H1Props>`
	font-size: 36px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	margin: 0;
`;
