import { useEffect, useState } from 'react';
import { env } from '@app/env';

import Pusher, { Channel, PresenceChannel } from 'pusher-js';
import TokenStorage from '@app/services/Auth/TokenStorage';

let inboxPrivateChannel: Channel | undefined;
let inboxPresenceChannel: PresenceChannel | undefined;

// Custom channel subscription data
let privateChannel: Channel | undefined;
let presenceChannel: PresenceChannel | undefined;

const usePusher = () => {
	const [pusherInstance, setPusherInstance] = useState<Pusher>();

	useEffect(() => {
		const pusher = new Pusher(env.PUSHER_APP_KEY, {
			cluster: 'mt1',
			channelAuthorization: {
				endpoint: `${env.API_URL}/pusher/channel-auth`,
				transport: 'ajax',
				params: {},
				headers: {
					Identity: TokenStorage.getIdentity(),
					Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
				},
			},
			// userAuthentication: {
			// 	endpoint: 'https://api.goodkind-dev.com/pusher/user-auth',
			// 	transport: 'ajax',
			// 	params: {},
			// 	headers: {
			// 		Identity: TokenStorage.getIdentity(),
			// 		Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
			// 	},
			// },
		});

		setPusherInstance(pusher);
		// Enable pusher logging - don't include this in production
		Pusher.logToConsole = env.NODE_ENV !== 'prod';
		// Pusher sign in code, to be used on user-centric channels/implementation
		// pusher.signin();
		// pusher.bind('pusher:signin_success', (data: any) => {
		// 	console.log('signin_success', JSON.parse(data?.user_data));
		// });
	}, []);

	// Inbox channel subscription data
	const subscribeToInboxPrivateChannel = (
		workspaceId: string,
		teamId: string,
	) => {
		inboxPrivateChannel = pusherInstance?.subscribe(
			`private-inbox-v1-${workspaceId}-${teamId}`,
		);

		inboxPrivateChannel?.bind('pusher:subscription_succeeded', (data: any) => {
			console.log('subscription_succeeded', data);
		});
	};

	const unsubscribeFromInboxPrivateChannel = () => {
		inboxPrivateChannel?.unsubscribe();
	};

	const subscribeToInboxPresenceChannel = (
		workspaceId: string,
		teamId: string,
	) => {
		inboxPresenceChannel = pusherInstance?.subscribe(
			`presence-inbox-v1-${workspaceId}-${teamId}`,
		) as PresenceChannel;

		inboxPresenceChannel?.bind('pusher:subscription_succeeded', (data: any) => {
			console.log('subscription_succeeded', data);
		});

		inboxPresenceChannel?.bind('pusher:member_added', (data: any) => {
			console.log('member_added', data);
		});
	};

	const unsubscribeFromInboxPresenceChannel = () => {
		inboxPresenceChannel?.unsubscribe();
	};
	// End of Inbox channel subscription data

	// Custom channel subscription data
	const subscribeToPrivateChannel = (
		channelName: string,
		callback?: (channel: Channel) => void,
	) => {
		if (!channelName) {
			console.error(new Error('Channel name is required'));
			return;
		}

		privateChannel = pusherInstance?.subscribe(`private-${channelName}`);

		privateChannel?.bind('pusher:subscription_succeeded', (data: any) => {
			console.log('subscription_succeeded', data);
			callback && callback(privateChannel as Channel);
		});
	};

	const unsubscribeFromPrivateChannel = () => {
		privateChannel?.unsubscribe();
	};

	const subscribeToPresenceChannel = (channelName: string) => {
		if (!channelName) {
			console.error(new Error('Channel name is required'));
			return;
		}

		presenceChannel = pusherInstance?.subscribe(
			`presence-${channelName}`,
		) as PresenceChannel;

		presenceChannel?.bind('pusher:subscription_succeeded', (data: any) => {
			console.log('subscription_succeeded', data);
		});

		presenceChannel?.bind('pusher:member_added', (data: any) => {
			console.log('member_added', data);
		});
	};

	const unsubscribeFromPresenceChannel = () => {
		presenceChannel?.unsubscribe();
	};
	// End of custom channel subscription data

	return {
		pusher: pusherInstance,
		inboxPrivateChannel,
		inboxPresenceChannel,
		subscribeToInboxPresenceChannel,
		subscribeToInboxPrivateChannel,
		unsubscribeFromInboxPresenceChannel,
		unsubscribeFromInboxPrivateChannel,

		// Custom channel subscription data
		privateChannel,
		presenceChannel,
		subscribeToPrivateChannel,
		unsubscribeFromPrivateChannel,
		subscribeToPresenceChannel,
		unsubscribeFromPresenceChannel,
	};
};

export default usePusher;

export {
	inboxPrivateChannel,
	inboxPresenceChannel,
	// Custom channel subscription data
	privateChannel,
	presenceChannel,
};
