import styled from 'styled-components';

export const RadioButtonContainer = styled.label`
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	column-gap: ${({ theme }) => theme.spacing.xs};

	& > * {
		cursor: pointer;
	}
`;

export const RadioButtonInput = styled.input.attrs({ type: 'radio' })`
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
	width: 16px;
	height: 16px;
	border: 1px solid ${({ theme }) => theme.colors.neutral.border.default};
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	cursor: pointer;

	::after {
		content: '';
		display: block;
		border-radius: ${({ theme }) => theme.shapeRadius[50]};
		width: 12px;
		height: 12px;
		margin: 1px;
	}

	:hover {
		box-shadow: ${({ theme }) => theme.shadows.controls.selected};
	}
	:focus {
		box-shadow: ${({ theme }) => theme.shadows.controls.selected};
	}
	:checked {
		border: 1px solid ${({ theme }) => theme.colors.primary.background.strong};

		::after {
			background-color: ${({ theme }) =>
				theme.colors.primary.background.strong};
		}
	}

	:disabled {
		background-color: ${({ theme }) => theme.colors.system.background.strong};
		cursor: not-allowed;

		:hover {
			box-shadow: none;
			::after {
				background-color: ${({ theme }) =>
					theme.colors.neutral.background.hover};
			}
		}
	}
`;
