import React from 'react';

import { ParagraphTypographyProps } from '../types';

import { ParagraphTypography } from './Paragraph.styles';

export default function Paragraph({
	children,
	size = 'regular',
	position = 'left',
	color,
	fontWeight,
	className,
}: ParagraphTypographyProps) {
	return (
		<ParagraphTypography
			color={color}
			position={position}
			size={size}
			fontWeight={fontWeight}
			className={className}
		>
			{children}
		</ParagraphTypography>
	);
}
