import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useDocumentTitle from '@app/hooks/useDocumentTitle';

import { getPageNameFromPath } from '@app/utils/getPageName';

export default function BrowserTitleHandler() {
	const location = useLocation();

	const { changeDocumentTitle } = useDocumentTitle();

	// Page Listener
	useEffect(() => {
		const pageName = getPageNameFromPath(location.pathname);

		const title = pageName ? `${pageName} | Goodkind` : 'Goodkind';

		changeDocumentTitle(title);
	}, [location.pathname]);

	return <></>;
}
