import { Textbase } from '@common/components/presentation/NewTypography';
import styled from 'styled-components';

export const StatusText = styled(Textbase).attrs({
	size: 'small',
})<{ status: string }>`
	font-weight: bold;
	color: ${({ theme, status }) => (theme.colors as any)[status]};
	white-space: nowrap;
`;

export const StatusTag = styled.div<{ status: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: fit-content;
	padding: 0 8px;
	background-color: ${({ theme, status }) =>
		`${(theme.colors as any)[status]}1A`};
	border-radius: 12px;

	> div {
		margin-left: 4px;
	}

	svg {
		color: ${({ theme, status }) => (theme.colors as any)[status]} !important;
		fill: ${({ theme, status }) => (theme.colors as any)[status]} !important;
	}
`;
