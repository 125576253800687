import { schema } from 'normalizr';

export const customAttributesSchema = new schema.Entity(
	'customAttributes',
	{},
	{
		idAttribute: 'name',
		processStrategy(value) {
			const newObj = { ...value };

			return newObj;
		},
	},
);
