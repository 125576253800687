import {
	FontLineHeightsType,
	FontSizesType,
} from '@common/design-system/global/types';
import React from 'react';
import { Text } from '@common/design-system/components/atoms';

type PropTypes = {
	children: React.ReactNode;
	color?: string;
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
	size?: FontSizesType;
	lineHeight?: FontLineHeightsType;
	className?: string;
};

function Title({
	children,
	color = 'system.text.default',
	as = 'h6',
	size,
	lineHeight,
	className,
	...rest
}: PropTypes) {
	return (
		<Text
			color={color}
			size={size}
			as={as}
			fontWeight={'bold'}
			lineHeight={lineHeight}
			fontFamily="title"
			className={className}
			{...rest}
		>
			{children}
		</Text>
	);
}

export default Title;
