import styled from 'styled-components';

export const AvatarWrapper = styled.div`
	display: inline-flex;
	position: relative;
`;

export const AvatarContainer = styled.div<{
	size?: number;
	onClick?: () => void;
	withBorder?: boolean;
}>`
	display: inline-flex;
	justify-content: center;
	position: relative;
	align-items: center;
	overflow: hidden;
	border-radius: 50%;
	height: ${({ size }) => `${size}px`};
	min-width: ${({ size }) => `${size}px`};
	max-width: ${({ size }) => `${size}px`};
	flex: 0 0 ${({ size }) => `${size}px`};
	cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'inherit')};
	background: ${({ theme }) => theme.colors.neutral.background.hover};

	${({ withBorder, theme }) =>
		withBorder &&
		`border: 1.5px solid ${theme.colors.neutral.background.default}`};
`;

export const AvatarImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const InitialsTemplate = styled.div<{
	size: number;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: ${({ size }) => `${(2 * size) / 5}px`};
	letter-spacing: 0.6px;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.primary.text.strongHover};
	border-radius: 50%;
`;

export const StatusContainer = styled.div<{ size?: number }>`
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	width: ${({ size }) => `${size}px`};
	height: ${({ size }) => `${size}px`};
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
	.message-card-parent:hover & {
		background-color: ${({ theme }) => theme.colors.system.background.weak};
	}
`;

export const StatusBullet = styled.div<{
	size?: number;
	status?: 'active' | 'inactive' | 'optedOut';
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({ size }) => `${size}px`};
	height: ${({ size }) => `${size}px`};
	border-radius: 50%;
	background-color: ${({ theme, status }) =>
		status === 'active'
			? theme.colors.primary.icon.hover
			: status === 'inactive'
				? theme.colors.system.background.system
				: theme.colors.error.background.defaultHover};

	border: ${({ theme, status }) =>
		status === 'inactive' &&
		`1px solid ${theme.colors.warning.border.default}`};
`;
