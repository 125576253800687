import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	row-gap: ${({ theme }) => theme.spacing.medium};
`;
export const CustomItemWrapper = styled.div<{ borderRadius?: number }>`
	flex-basis: 45%;
	aspect-ratio: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing.xs};
	cursor: pointer;

	.image-wrapper {
		width: 100%;
		height: 100%;
		border-radius: ${({ borderRadius }) => `${borderRadius || 0}px`};
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}
	}
`;
