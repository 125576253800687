import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants/index';
import { fetchAction } from '../types';
import axios from '@global/axios';
import { FilterOptions } from '@app/types';

const mainType = 'overlays';

export const getOverlaysTypes = createFetchTypes(`${mainType}_GET_OVERLAYS`);

function getOverlays(FilterOptions?: FilterOptions): fetchAction {
	const filterString = JSON.stringify(FilterOptions);

	return {
		type: getOverlaysTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/overlays${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			errorMessage: ToastMessage.error,
		},
	};
}

export { getOverlays };
