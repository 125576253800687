import { RootState, useAppDispatch } from '@app/redux/types';
import { getWorkspaceByEmail } from '@app/redux/workspace/action';
import TokenStorage from '@app/services/Auth/TokenStorage';
import { useAuth } from '@app/services/Auth/useAuth';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

function useWorkspaces(search?: string) {
	const dispatch = useAppDispatch();

	const [workspaceOptions, setWorkspaceOptions] = useState<any>([]);

	const { user } = useAuth();

	const {
		workspaceInfo,
		workspaces = [],
		loading,
	} = useSelector(({ workspace: { custom, workspaceInfo } }: RootState) => ({
		workspaceInfo,
		workspaces: custom?.getWorkspaceByEmailResponse?.workspaces,
		loading: custom?.getWorkspaceByEmailLoading,
	}));

	useEffect(() => {
		if (
			!workspaceOptions.length &&
			!workspaces?.length &&
			user?.email &&
			TokenStorage.getAccessToken() &&
			!loading
		) {
			dispatch(getWorkspaceByEmail(user?.email));
		}

		if (!!workspaces?.length && !workspaceOptions.length) {
			const mappedWorkspaces = workspaces.map((workspace: any) => {
				return {
					...workspace,
					title: workspace.organizationName || workspace.name,
					id: workspace.id,
				};
			});

			const sortedWorkspaces = mappedWorkspaces.sort((a: any, b: any) =>
				a?.title.localeCompare(b?.title),
			);

			setWorkspaceOptions(sortedWorkspaces);
		}
	}, [workspaces, user?.email, loading]);

	const filteredWorkspaces = useMemo(() => {
		if (!search) return workspaceOptions;

		return workspaceOptions.filter((workspace: any) =>
			workspace.title.toLowerCase().includes(search?.toLowerCase()),
		);
	}, [search, workspaceOptions]);

	return {
		workspaces: filteredWorkspaces,
		workspacesCount: workspaceOptions.length,
		workspaceInfo,
		loading,
	};
}

export default useWorkspaces;
