import * as React from 'react';
import { observer } from 'mobx-react-lite';
// import default tab component
import { ImagesGrid, SectionTab } from 'polotno/side-panel';

import { Icon, Text } from '@common/design-system/components/atoms';
import {
	POSTCARD_BORDER_RADIUS,
	postcardImageUrls,
	PostcardVariableNames,
} from '@app/constants/modules/postcard';

import * as SharedS from '../../CreativeEditor.styles';
import * as S from './MergeImages.styles';

import { useCreativeEditorContext } from '../../providers/CreativeEditorProvider';
import { getImageSize } from 'polotno/utils/image';

type SectionTabProps = {
	onClick: any;
	active: boolean;
};
type CustomItemProps = {
	variableName: `${string}-${string}` | (string & object);
	displayName: string;
	imageUrl: string;
	borderRadius?: number;
	handleAddElement: () => void;
};

const CustomItem = ({
	displayName,
	variableName,
	imageUrl,
	borderRadius,
	handleAddElement,
}: CustomItemProps) => {
	return (
		<S.CustomItemWrapper
			role="button"
			tabIndex={0}
			aria-hidden="true"
			onClick={handleAddElement}
			borderRadius={borderRadius}
		>
			<div className="image-wrapper">
				<img src={imageUrl} alt={variableName} />
			</div>

			<Text>{displayName}</Text>
		</S.CustomItemWrapper>
	);
};

const MergeImagesSection = {
	name: 'mergeImages',
	Tab: ({ active, onClick }: SectionTabProps) => {
		return (
			<SectionTab name="Merge Images" active={active} onClick={onClick}>
				<SharedS.CustomIconWrapper isActive={active}>
					<Icon
						iconName="mergeImagesActive"
						className="active-icon merge-images-icon"
					/>

					<Icon
						iconName="mergeImages"
						className="inactive-icon merge-images-icon"
					/>
				</SharedS.CustomIconWrapper>
			</SectionTab>
		);
	},
	// we need observer to update component automatically on any store changes
	Panel: observer(({ store }: { store: any }) => {
		const { workspaceInfo, customMergeImages } = useCreativeEditorContext();
		const isRounded = !!workspaceInfo?.theme?.borderRadius?.round;

		const borderRadiusField = isRounded ? 'round' : 'square';
		const getImageUrl = (variableName: PostcardVariableNames) => {
			let imageUrl = '';

			const customImage =
				customMergeImages && customMergeImages[variableName]
					? customMergeImages[variableName]
					: '';

			if (variableName === PostcardVariableNames.QR_CODE) {
				imageUrl = customImage || postcardImageUrls.qrCodePNG;
			}
			if (variableName === PostcardVariableNames.WORKSPACE_LOGO) {
				imageUrl = customImage || postcardImageUrls.workspaceLogoPNG;
			}
			if (variableName === PostcardVariableNames.VIDEO_THUMBNAIL) {
				imageUrl = customImage || postcardImageUrls.videoThumbnailPNG;
			}
			if (variableName === PostcardVariableNames.WORKSPACE_BG) {
				imageUrl = customImage || postcardImageUrls.workspaceBgPNG;
			}

			return imageUrl;
		};
		const handleAddElement = async (variableName: PostcardVariableNames) => {
			const imageUrl = getImageUrl(variableName);

			const borderRadius =
				POSTCARD_BORDER_RADIUS[variableName][borderRadiusField];

			// get image size
			let { width, height } = await getImageSize(imageUrl);
			const aspectRatio = width / height;

			// Scale down the image to fit the canvas
			width = 500;
			height = width / aspectRatio;

			store.activePage.addElement({
				type: 'image',
				src: imageUrl,
				// Place at the center of the canvas
				x: store.width / 2 - width / 2,
				y: store.height / 2 - height / 2,
				custom: {
					variable: variableName,
				},
				width: width,
				height: height,
				cornerRadius: borderRadius,
				// can element be moved and rotated
				draggable: true,
				// can we change content of element?
				contentEditable: true, //
				// can we change style of element?
				styleEditable: false,
				// can we resize element?
				// resizable: true,
			});
		};

		const mergeImages = React.useMemo(() => {
			return [
				{
					src: getImageUrl(PostcardVariableNames.QR_CODE),
					name: 'QR Code',
					variableName: PostcardVariableNames.QR_CODE,
				},
				{
					src: getImageUrl(PostcardVariableNames.WORKSPACE_LOGO),
					name: 'Logo',
					variableName: PostcardVariableNames.WORKSPACE_LOGO,
				},
				{
					src: getImageUrl(PostcardVariableNames.VIDEO_THUMBNAIL),
					name: 'Video Thumbnail',
					variableName: PostcardVariableNames.VIDEO_THUMBNAIL,
				},
				{
					src: getImageUrl(PostcardVariableNames.WORKSPACE_BG),
					name: 'Workspace Background',
					variableName: PostcardVariableNames.WORKSPACE_BG,
				},
			];
		}, [workspaceInfo]);

		return (
			<S.Container>
				<ImagesGrid
					images={mergeImages || []}
					getPreview={(image: (typeof mergeImages)[number]) => image.src}
					onSelect={async (image, pos, element) =>
						handleAddElement(image.variableName)
					}
					isLoading={false}
					error=""
					getCredit={(image) => <Text size="xs">{image.name}</Text>}
				/>
			</S.Container>
		);
	}),
};

export default MergeImagesSection;
