import React from 'react';

import { ImageType } from '@common/design-system/global/images';

import * as S from './SystemMessage.styles';

import {
	Illustration,
	DisplayIcon,
} from '@common/design-system/components/atoms';
import Text from '@common/design-system/components/atoms/typography/Text/Text';
import { IconWeight } from '@phosphor-icons/react';

type PropTypes = {
	title?: string;
	description?: string;
	imageName?: keyof ImageType;
	alt: string;
	descriptionColor?: string;
	iconName?: string;
	iconWeight?: IconWeight;
	titleColor?: string;
	textAlignCenter?: boolean;
};

const SystemMessage = ({
	imageName,
	iconName,
	iconWeight,
	description,
	title,
	alt,
	titleColor = 'system.text.medium',
	descriptionColor = 'system.text.medium',
	textAlignCenter,
}: PropTypes) => {
	return (
		<S.MainContainer>
			{!!imageName && !iconName && (
				<Illustration name={imageName} alt={alt} size="regular" />
			)}

			{!!iconName && !imageName && (
				<DisplayIcon
					variant="system"
					iconName={iconName}
					iconWeight={iconWeight}
				/>
			)}
			<S.TextContainer>
				{!!title && (
					<Text color={titleColor} lineHeight="large">
						{title}
					</Text>
				)}

				{!!description && (
					<Text
						color={descriptionColor}
						size="small"
						lineHeight="large"
						center={textAlignCenter}
					>
						{description}
					</Text>
				)}
			</S.TextContainer>
		</S.MainContainer>
	);
};

export default SystemMessage;
export type { PropTypes as SystemMessageProps };
