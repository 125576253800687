function objectNestedKeys(obj: any, key: string): string {
	if (!obj) {
		// console.log('error in obj nested', { obj, key });
		// throw 'error on objnested';
		return obj;
	}
	const keys = key.split('.');

	let result = 'none';

	if (keys.length === 0) {
		result = obj[key];
	}

	if (keys.length === 1) {
		result = obj[keys[0]];
	}

	if (keys.length > 1 && obj[keys[0]]) {
		result = obj[keys[0]][keys[1]];
	}

	return result?.toString() || 'none';
}

export default objectNestedKeys;
