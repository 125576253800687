import styled from 'styled-components';
import { GenericTextProps } from '../types';

export const StyledP = styled.p<GenericTextProps>`
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	margin: 0;
	${({ capitalize }) => capitalize && `text-transform: capitalize`}
`;
