import { createSlice, Action } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '@app/utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	editStoriesTypes,
	getScheduledTypes,
	bulkDeleteTypes,
	getSingleStoryTypes,
	sendNowTypes,
} from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import { CaptionsType } from '@common/components/presentation/VideoCard/types';
import { showToast } from '@common/design-system/components/molecules';
import { ToastMessage } from '@app/constants';
import isSuccessHttpStatus from '@app/utils/isSuccessHttpStatus';

const story = entityNormalizer('stories', {}, { excludeTime: true });

const pagination = { stories: [story] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	stories: {},
	storiesCount: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		reload: false,
	},
};

export type storiesStateType = typeof initialState;

function paginationMapper(stories: any, state: storiesStateType) {
	state.stories = { ...stories };
}

function filterMapper(stories: any, state: storiesStateType) {
	if (state.pagination && state.pagination.currentPage === 1) {
		return paginationMapper(stories, state);
	}
	state.stories = { ...state.stories, ...stories };
}

function scheduledPaginationMapper(
	state: storiesStateType,
	action: ResponseAction,
	cb: (stories: any, state: storiesStateType) => void,
) {
	const {
		response: {
			stories,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const normalizedData = normalize(
		{ stories, page, pageSize, totalPages, count },
		pagination,
	);
	state.response = action.response;

	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.stories;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
	state.storiesCount = action.response.count;
	if (state.custom) state.custom.reload = false;
	cb(normalizedData.entities.stories, state);
}

function bulkActionSuccessMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const {
		response: { stories },
	} = action;
	stories.map((story: string) => {
		const { [story]: omittedStory, ...storiesRest } = state.stories;
		state.stories = storiesRest;
	});
	if (state.custom) state.custom.reload = true;
}

function sendNowSuccessMapper(state: storiesStateType, action: ResponseAction) {
	if (isSuccessHttpStatus(action.response.status)) {
		const { stories } = action.payload;
		stories.map((story: string) => {
			const { [story]: omittedStory, ...storiesRest } = state.stories;
			state.stories = storiesRest;
		});
	}
}

function sendNowErrorMapper(state: storiesStateType, action: ResponseAction) {
	const errorCode = action?.error?.response?.data?.errorCode;
	const haveSharedVideos = errorCode === 14;
	if (haveSharedVideos) {
		return showToast({
			message: ToastMessage.errorScheduleSendingAlreadySendStory,
			type: 'error',
		});
	} else {
		return showToast({
			message: ToastMessage.errorSendingStory,
			type: 'error',
		});
	}
}

function getSingleStoryMapper(state: storiesStateType, action: ResponseAction) {
	const newStory = action.response.story;
	const storyId = newStory._id;

	if (newStory?.assignedTo && !newStory.assignedTo.fullName) {
		newStory.assignedTo.fullName = `${newStory.assignedTo.firstName} ${newStory.assignedTo.lastName}`;
	}

	const normalizedData = normalize(
		{ stories: { ...state.stories, [storyId]: newStory } },
		pagination,
	);
	state.stories = { ...normalizedData.entities.stories };
}

const storySlice = createSlice({
	name: 'scheduled',
	initialState,
	reducers: {
		UPDATE_VIDEO_CAPTIONS(
			state: storiesStateType,
			action: {
				payload: { scheduledVideoId: string; captions: CaptionsType[] };
			},
		) {
			const { scheduledVideoId, captions } = action.payload;
			const [storyId] = Object.entries(state.stories).find(
				([storyId, approval]: [string, any]) =>
					approval.video._id === scheduledVideoId,
			) as [string, any];
			state.stories[storyId].video.captions = captions;
		},
		UPDATE_VIDEO_FIELD(
			state: storiesStateType,
			action: { payload: { scheduledId: string; field: string; value: any } },
		) {
			const { scheduledId, field, value } = action.payload;
			if (state?.stories[scheduledId]?.video) {
				state.stories[scheduledId].video[field] = value;
				state.stories = { ...state.stories };
			}
		},
	},
	extraReducers: {
		...createFetchReducer(editStoriesTypes),
		...createFetchReducer(
			getScheduledTypes,
			(state: storiesStateType, action) =>
				scheduledPaginationMapper(state, action, filterMapper),
		),
		STORY_CLEAR_ERROR: (state: storiesStateType, action: Action) => {
			state.error = null;
		},
		...createFetchReducer(bulkDeleteTypes, bulkActionSuccessMapper),
		...createFetchReducer(getSingleStoryTypes, getSingleStoryMapper),
		...createFetchReducer(
			sendNowTypes,
			sendNowSuccessMapper,
			sendNowErrorMapper,
		),
	},
});

export default storySlice.reducer;
