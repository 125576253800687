import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '@app/utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	filterTagsTypes,
	updateTagsTypes,
	deleteTagsTypes,
	getTagOptionsTypes,
	createTagsTypes,
	createContactTagsTypes,
} from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import ObjectToOptions from '@app/utils/ObjectToOptions';
import { OptionsType } from '@common/components/interaction/DropdownInput/types';
import { updateContactTagTypes } from '../contact/constants';

const tag = entityNormalizer('tags');
const pagination = { tags: [tag] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	tags: {},
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	tagOptions: [],
	custom: {},
};

export type tagsStateType = typeof initialState;
function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			tags,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ tags, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function optionsMapper(state: tagsStateType, action: ResponseAction) {
	const normalizedData: any = normalizeResponse(action);
	const tagCopy = ObjectToOptions(normalizedData?.entities?.tags, 'id', 'text');
	tagCopy?.unshift({
		label: 'All',
		value: '',
	});
	state.tagOptions = tagCopy?.length > 1 ? tagCopy : [];
}
function paginationMapper(state: tagsStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);
	state.tags = {
		...normalizedData.entities.tags,
	};

	if (state.pagination) {
		state.pagination.pages[action.response.page] = normalizedData.result.tags;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
}

const updateTagsMapper = (state: tagsStateType, action: ResponseAction) => {
	const { payload } = action;
	const auxTag = state.tags[payload.tagId];
	const result = {
		...state.tags,
		[payload.tagId]: {
			...auxTag,
			text: payload.text,
		},
	};

	state.tags = result;
};

const deleteTagsMapper = (state: tagsStateType, action: ResponseAction) => {
	const { payload } = action;
	const auxTag = state.tags[payload.tagId];
	const result = {
		...state.tags,
		[payload.tagId]: {
			...auxTag,
			deleted: true,
		},
	};
	state.tags = result;
};

const createContactTagsMapper = (
	state: tagsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	const newTags = response.contactsUpdated[0].tags.reduce(
		(tags: OptionsType[], item: any) => {
			if (response.tagsAdded.includes(item.id)) {
				tags.push({ label: item.text, value: item.id });
			}
			return tags;
		},
		[],
	);
	state.tagOptions = state.tagOptions.concat(newTags);
};

function updateContactTagMapper(state: tagsStateType, action: ResponseAction) {
	const { response } = action;

	const newTag = response.tag;

	state.tags[newTag.id] = newTag;
}

const tagSlice = createSlice({
	name: 'tag',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(filterTagsTypes, paginationMapper),
		...createFetchReducer(updateTagsTypes, updateTagsMapper),
		...createFetchReducer(deleteTagsTypes, deleteTagsMapper),
		...createFetchReducer(createContactTagsTypes, createContactTagsMapper),
		...createFetchReducer(getTagOptionsTypes, optionsMapper),
		...createFetchReducer(createTagsTypes),
		[updateContactTagTypes[1]]: updateContactTagMapper,
	},
});

export default tagSlice.reducer;
