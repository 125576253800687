import React from 'react';
import * as S from './RedirectStatusMessage.styles';
import { RedirectStatusMessageText } from './constants';
import { RedirectStatusMessageProps } from './types';
import { Col } from 'react-bootstrap';
import { Text, Title } from '@common/design-system/components/atoms';

export default function RedirectStatusMessage({
	currentUserIsOnMobile,
}: RedirectStatusMessageProps) {
	return (
		<S.ParentContainer>
			<S.GoodkindLogo iconName="goodkindlogo" />

			{!currentUserIsOnMobile && (
				<Col xs={10} md={5}>
					<S.TextContainer>
						<Title as="h1" size="xl" color="error.text.default">
							{RedirectStatusMessageText.DESKTOP_HEADING}
						</Title>

						<Text as="p" lineHeight="large">
							{RedirectStatusMessageText.DESKTOP_DESCRIPTION}
						</Text>
					</S.TextContainer>
				</Col>
			)}

			{currentUserIsOnMobile && (
				<Col xs={10} md={5}>
					<S.TextContainer>
						<Title
							as="h2"
							size="large"
							color="system.text.default"
							lineHeight="regular"
						>
							{RedirectStatusMessageText.HEADING}
						</Title>

						<Text as="p" size="regular" lineHeight="medium">
							{RedirectStatusMessageText.DESCRIPTION}
						</Text>
					</S.TextContainer>
				</Col>
			)}
		</S.ParentContainer>
	);
}
