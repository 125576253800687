// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class LocalStorage {
	public static getFromLocalStorage(name: string) {
		return localStorage.getItem(name);
	}

	public static removeFromLocalStorage(name: string) {
		return localStorage.removeItem(name);
	}

	public static setItemInLocalStorage(name: string, value: string) {
		return localStorage.setItem(name, value);
	}

	public static clear() {
		return localStorage.clear();
	}
}
