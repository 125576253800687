import styled from 'styled-components';

import { Textbase } from '../../presentation/NewTypography';

export const TagInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	> * + * {
		margin-top: 12px;
	}
`;

export const TagsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: -4px;
`;

export const InputTag = styled.div`
	display: flex;
	align-items: center;
	padding: 0 8px;
	height: 28px;
	border-radius: ${({ theme }) => theme.borderRadius?.smooth};
	background: ${({ theme }) => theme.colors.neutral[300]};
	margin: 4px;
	max-width: 224px;
	overflow: hidden;

	> * + * {
		margin-left: 8px;
	}
`;

export const TagText = styled(Textbase).attrs({
	size: 'large',
})`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: default;
`;

export const RemoveTagContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 4px 0;
`;
