import styled from 'styled-components';

import { Textbase } from '@common/components/presentation/NewTypography';
import { Icon } from '@common/components/presentation/Icon';

import { handleVariantHoverColor, handleGapSize } from './utils';

import { SizeType } from '@common/components/presentation/NewTypography/types';
import { LinkButtonVariantType } from './types';

export const LinkButtonIcon = styled(Icon)<{
	dataTestId: string;
}>``;

export const LinkButtonText = styled(Textbase)<{
	dataTestId: string;
}>``;

export const LinkButtonContainer = styled.div<{
	disabled: boolean;
	variant: LinkButtonVariantType;
	size: SizeType;
	dataTestId: string;
}>`
	display: inline-flex;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => disabled && 0.4};
	column-gap: ${({ size }) => handleGapSize(size)};
	overflow: hidden;

	&:hover {
		${LinkButtonIcon} {
			color: ${({ variant, disabled }) =>
				!disabled && handleVariantHoverColor(variant)} !important;
		}

		${LinkButtonText} {
			color: ${({ variant, disabled }) =>
				!disabled && handleVariantHoverColor(variant)};
		}
	}
`;
