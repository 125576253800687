import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants';

import axios from '@global/axios';
import { FilterOptions, saveCampaignCustomName } from '@app/types';
import { Dispatch } from '@reduxjs/toolkit';
import { saveCampaign } from '../campaign/action';
import { CTA } from '../cta/types';
import { TemplateType } from '@app/types/modules/campaign';

const mainType = 'storyTemplate';

export const getTemplateOptionsTypes = createFetchTypes(
	`${mainType}_FETCH_STORY_TEMPLATE_OPTIONS`,
);

export const createStoryTemplateTypes = createFetchTypes(
	`${mainType}_CREATE_STORY_TEMPLATE`,
);

export const editStoryTemplateTypes = createFetchTypes(
	`${mainType}_EDIT_STORY_TEMPLATE`,
);

export const filterTemplatesTypes = createFetchTypes(
	`${mainType}_FETCH_STORY_TEMPLATE_OPTIONS`,
);

const upsertCompleteTemplateType = `${mainType}_UPSERT_COMPLETE_TEMPLATE`;

function getFormData(object: any) {
	const formData = new FormData();
	Object.keys(object).forEach((key) => formData.append(key, object[key]));
	return formData;
}

function filterTemplates(filter: FilterOptions) {
	const filterString = JSON.stringify(filter);
	return {
		type: filterTemplatesTypes,
		callAPI: () => axios.get(`${apiUrl}/templates?filter=${filterString}`),
		payload: {
			custom: '',
			errorMessage: ToastMessage.error,
		},
	};
}

function getStoryTemplateOptions() {
	const filter = JSON.stringify({
		pagination: {
			page: 1,
			pageSize: 100,
		},
	});
	return {
		type: getTemplateOptionsTypes,
		callAPI: () => axios.get(`${apiUrl}/templates?filter=${filter}`),
		payload: {
			custom: '',
			errorMessage: ToastMessage.error,
		},
	};
}

function editStoryTemplate(
	id: string,
	params: FormData,
	settings?: { successMessage?: string; errorMessage?: string },
) {
	return {
		type: editStoryTemplateTypes,
		callAPI: () => axios.put(`${apiUrl}/templates/${id}`, params),
		payload: {
			custom: 'editTemplate',
			successMessage: settings?.successMessage && settings?.successMessage,
			errorMessage: settings?.errorMessage && settings?.errorMessage,
		},
	};
}

function createStoryTemplate(params: FormData) {
	return {
		type: createStoryTemplateTypes,
		callAPI: () => axios.post(`${apiUrl}/templates`, params),
		payload: {
			custom: 'createTemplate',
			successMessage: ToastMessage.storyTemplateCreated,
		},
	};
}

function clearTemplates() {
	return {
		type: `${mainType}/CLEAR_TEMPLATES`,
	};
}

function upsertCompleteTemplate({
	isDefaultTemplate,
	templateParams,
	cta,
	campaignParams,
	saveType,
	reply,
}: {
	isDefaultTemplate: boolean;
	templateParams: TemplateType;
	cta: {
		params: Partial<CTA>;
		isComplete: boolean;
	};
	reply?: {
		params: Partial<CTA>;
		isComplete: boolean;
	};
	campaignParams: any;
	saveType: saveCampaignCustomName;
}) {
	return async (dispatch: Dispatch) => {
		const hasTemplate = templateParams.id && !isDefaultTemplate;

		try {
			const callToActions: string[] = [];

			if (cta?.params?.id) {
				callToActions.push(cta.params?.id);
			}

			if (reply?.params?.id) {
				callToActions.push(reply?.params?.id);
			}

			if (hasTemplate) {
				await dispatch(
					editStoryTemplate(templateParams.id, getFormData(templateParams), {
						errorMessage: ToastMessage.storyTemplateUpdateError,
					}),
				);

				await dispatch(
					saveCampaign(
						{
							...campaignParams,
							campaign: {
								...campaignParams.campaign,
								callToActions,
								template: templateParams.id,
							},
						},
						saveType,
						ToastMessage.campaignUpdated,
					),
				);
			} else {
				let createResponse: any;

				if (!isDefaultTemplate) {
					createResponse = await dispatch(
						createStoryTemplate(getFormData(templateParams)),
					);
				}

				dispatch(
					saveCampaign(
						{
							...campaignParams,
							campaign: {
								...campaignParams.campaign,
								callToActions,
								template: isDefaultTemplate
									? null
									: createResponse?.template?.id,
							},
						},
						saveType,
					),
				);
			}

			dispatch({
				type: `${upsertCompleteTemplateType}_SUCCESS`,
			});

			return true;
		} catch (e) {
			dispatch({
				type: `${upsertCompleteTemplateType}_FAILURE`,
				payload: e,
			});

			return false;
		}
	};
}

export {
	getStoryTemplateOptions,
	editStoryTemplate,
	createStoryTemplate,
	filterTemplates,
	clearTemplates,
	upsertCompleteTemplate,
};
