import styled from 'styled-components';

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.large};
	padding-bottom: ${({ theme }) => theme.spacing.large};

	.videoCaptionEditor {
		min-height: auto;
	}
`;

export const TrainingVideoWrapper = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing.large};

	.title-wrapper {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xxxs};
	}
`;

export const FloatingPageToastContainer = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	bottom: 0;
`;

export const PageToastsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	row-gap: ${({ theme }) => theme.spacing.xs};
	padding-bottom: 50px;
`;
