import styled from 'styled-components';
import GoodkindTheme from '@global/theme';
import { FuncToolTipButtonTypes } from './types';

export const StyledButton = styled.button`
	background: transparent;
	border: none;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0;
	position: static;
	z-index: 1;
	padding: 0;
	&:disabled {
		pointer-events: none;
	}
	&:focus {
		outline: 2px solid ${({ theme }) => theme.colors.primary[700]};
		outline-offset: 2px;
	}
`;

export const FuncStyledButton = styled.button<FuncToolTipButtonTypes>`
	background: transparent;
	border: none;
	width: 30px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0;
	position: static;
	z-index: 1;
	padding: 0;
	&:disabled {
		pointer-events: none;
	}
	& span {
		background-color: ${(props) =>
			props.open
				? `${GoodkindTheme.colors.primary[900]}`
				: `${GoodkindTheme.colors.neutral[200]}`};
	}
	&:focus {
		outline: 2px solid ${({ theme }) => theme.colors.primary[900]};
		outline-offset: 2px;
	}
	&:hover span {
		background-color: ${({ theme }) => theme.colors.primary[900]};
	}
`;

export const CommFuncStyledButton = styled(FuncStyledButton)`
	width: 20px;
	height: 20px;
	margin-top: 2px;
	span {
		background-color: transparent;
		opacity: 0.6;
		transition: opacity ${({ theme }) => theme.transition?.short};
	}
	&:hover span {
		background-color: transparent;
		opacity: 1;
	}
`;

export const Dot = styled.span`
	height: 5px;
	width: 5px;
	margin: 0 2px;
	border-radius: 50%;
	display: block;
	transition: background-color ${({ theme }) => theme.transition?.short};
`;

export const ToolTipButton = styled.button`
	min-width: 175px;
	width: 100%;
	font-size: 14px;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	background: ${({ theme }) => theme.colors.neutral[0]};
	line-height: 15px;
	letter-spacing: normal;
	cursor: pointer;
	border: none;
	appearance: none;
	outline: none;
	position: relative;
	display: flex;
	align-items: center;
	text-align: center;
	text-decoration: none;
	overflow: visible;
	padding: 6px;

	&:hover {
		color: ${({ theme }) => theme.colors.primary[900]};
	}
`;

export const CommToolTipButton = styled(ToolTipButton)`
	min-width: 125px;
`;

export const TooltipStyles = {
	tooltip: {
		backgroundColor: GoodkindTheme.colors.neutral[700],
		color: GoodkindTheme.colors.neutral[50],
		fontFamily: GoodkindTheme.fonts.default,
		maxWidth: 400,
		fontSize: 12,
		border: `1px solid ${GoodkindTheme.colors.neutral[200]}`,
		padding: '5px 10px',
	},
};

export const CommFuncTooltip = styled.div`
	background-color: ${(props) => props.theme.colors.neutral[0]} !important;
	color: ${(props) => props.theme.colors.neutral[50]};
	font-family: ${(props) => props.theme.fonts.default};
	max-width: none !important;
	font-size: 12px;
	border: none;
	padding: 0;
	box-shadow: ${(props) => props.theme.boxShadow?.strong} !important;
	border-radius: ${(props) => props.theme.borderRadius?.smooth} !important;
	transition: box-shadow ${(props) => props.theme.transition?.short};
	z-index: ${(props) => props.theme.zIndex.overlay};

	&:hover {
		box-shadow: ${(props) => props.theme.boxShadow?.hover};
	}
`;

export const TooltipArrow = styled.div`
	position: absolute;
	left: -16px;
	top: calc(50% - 8px);
	width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-right: 10px solid ${({ theme }) => theme.colors.neutral[0]};
`;
