import React, { useState, useRef } from 'react';

import { Textbase } from '../../presentation/NewTypography';

import { TextTooltipProps } from './types';

import { TooltipWrapper } from './WrapperTooltip.styles';
import { TextTooltipContainer, Tooltip } from './TextTooltip.styles';
import useFloatingUi from '@app/hooks/useFloatingUi';
import { FloatingPortal } from '@floating-ui/react';

function TextTooltip({
	children,
	orientation = 'top',
	fillParent,
	text,
	tooltipWidth,
	className,
	textSize,
	disabled,
	isActive,
}: TextTooltipProps) {
	const wrapperRef = useRef<HTMLDivElement>(null);

	const [isShowing, setIsShowing] = useState<boolean>(false);
	const [timerId, setTimerId] = useState<any>(false);

	const {
		x,
		y,
		openingReference,
		floatingReference,
		strategy,
		internalRefs,
		getFloatingProps,
		getReferenceProps,
	} = useFloatingUi({
		preferredPlacement: orientation,
	});

	const isTooltipShowing =
		!!text &&
		(isShowing || isActive) &&
		!disabled &&
		wrapperRef?.current?.matches(':hover');

	function onMouseHover() {
		if (disabled) return;

		const timer = setTimeout(() => {
			setIsShowing(true);
		}, 400);

		setTimerId(timer);
	}

	function onMouseLeave() {
		clearTimeout(timerId);

		setIsShowing(false);
	}
	return (
		<TooltipWrapper
			ref={wrapperRef}
			fillParent={fillParent}
			onMouseEnter={onMouseHover}
			onMouseLeave={onMouseLeave}
			className={className}
		>
			<FloatingPortal>
				{isTooltipShowing && (
					<TextTooltipContainer
						ref={floatingReference}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
						}}
						orientation={orientation}
						width={tooltipWidth}
						{...getFloatingProps()}
					>
						<Tooltip orientation={orientation}>
							<Textbase size={textSize}>{text}</Textbase>
						</Tooltip>
					</TextTooltipContainer>
				)}
			</FloatingPortal>

			<span ref={openingReference} {...getReferenceProps()}>
				{children}
			</span>
		</TooltipWrapper>
	);
}

export default TextTooltip;
