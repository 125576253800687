import styled from 'styled-components';

export const ScreensContainer = styled.div<{ showBuilderHeader?: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	max-height: ${({ showBuilderHeader }) =>
		showBuilderHeader ? 'calc(100% - 56px);' : '100%'};
`;

// Layout for the campaign creation templates and summary steps
export const LeftSideWrapper = styled.div`
	max-width: 487px;
	min-width: 487px;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	background: ${({ theme }) => theme.colors.system.background.system};
	// the custom 1.2rem spacing is to allign the scroll bar to be visually
	// in the middle between the scrollable section and the preview.
	padding: ${({ theme }) =>
		`${theme.spacing.large} 1.2rem ${theme.spacing.xl} ${theme.spacing.large}`};
	gap: ${({ theme }) => theme.spacing.large};

	overflow-y: auto;
`;

export const RightSideWrapper = styled.div`
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.system.background.system};
	position: relative;
	overflow: auto;
`;

export const PreviewFloatingWrapper = styled.div`
	border-radius: ${({ theme }) => theme.shapeRadius.medium};
	box-shadow: ${({ theme }) => theme.shadows.elevation.container};
	background-color: ${({ theme }) => theme.colors.system.background.medium};
	border: 1px solid ${({ theme }) => theme.colors.system.border.medium};
	overflow: hidden;
`;

export const RightSideToastContainer = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndex.fixed};
`;

export const LoaderOverlayContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.6;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	z-index: ${({ theme }) => theme.zIndex.tooltip};
`;
