import React from 'react';
import { LinkButton, Tooltip } from '@common/design-system/components/atoms';
import { TableMapperType } from '../../types';

type PropsType = {
	item: any;
	mapperField: TableMapperType;
};

function LinkButtonCell({ item, mapperField }: PropsType) {
	const linkText =
		typeof mapperField.linkText === 'function'
			? mapperField.linkText(item)
			: mapperField.linkText;

	const textTooltipText =
		typeof mapperField.textTooltipText === 'function'
			? mapperField.textTooltipText(item)
			: mapperField.textTooltipText;

	const canRenderLink = !!mapperField.onLinkClick && !!linkText;

	//Text length settings
	const textMaxLength = mapperField?.textMaxLength;

	const textLength = linkText ? linkText?.length : 0;

	const isTruncated = textMaxLength ? textLength > textMaxLength : false;

	//Truncate text in textMaxLength characters maximum, for some reason Text component noWrap and line clamp doesn't work inside the table cells
	const truncateText = (text: string) => {
		if (text && textMaxLength && text.length > textMaxLength) {
			return `${text.substring(0, textMaxLength).trim()}...`;
		}
		return text;
	};

	const getTooltipText = () => {
		if (isTruncated && !textTooltipText) {
			return linkText;
		}

		return textTooltipText || '';
	};

	return (
		canRenderLink && (
			<Tooltip content={getTooltipText()}>
				<LinkButton
					icon={mapperField.iconName}
					iconPosition={mapperField.iconPosition}
					onClick={() =>
						mapperField.onLinkClick && mapperField.onLinkClick(item)
					}
					lineHeight="large"
					weight={mapperField.linkButtonTextWeight}
				>
					{truncateText(linkText)}
				</LinkButton>
			</Tooltip>
		)
	);
}

export default LinkButtonCell;
