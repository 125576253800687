import styled from 'styled-components';

export const MessageThreadWrapper = styled.div<{ xlBottomSpace?: boolean }>`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	padding: ${({ theme }) => theme.spacing.medium};
	padding-bottom: ${({ xlBottomSpace, theme }) =>
		xlBottomSpace ? theme.spacing.xl : theme.spacing.medium};
	gap: ${({ theme }) => theme.spacing.xl};
`;

export const MessageThreadHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
