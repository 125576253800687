import styled from 'styled-components';

export const MessageCardListWrapper = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	overflow-y: auto;
	height: 100%;
`;

export const MessageCardListHeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: ${({ theme }) => theme.spacing.small};
`;

export const CheckAllWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.small};
`;
