import styled from 'styled-components';

export const StyledH4 = styled.h4`
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	margin: 0;
	transition: color ${({ theme }) => theme.transition?.short};
`;
