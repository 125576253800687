import styled from 'styled-components';

export const TooltipContentWrapper = styled.div`
	padding: ${({ theme }) => theme.spacing.small};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	box-shadow: ${({ theme }) => theme.shadows.elevation.xl};
	z-index: ${({ theme }) => theme.zIndex.tooltip};
	max-width: 320px;
	white-space: pre-wrap;
`;

export const TooltipTrigger = styled.span`
	cursor: pointer;
`;
