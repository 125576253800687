import styled from 'styled-components';

const ControlGroupItem = styled.div<{
	columnMode?: boolean;
	alignment?: 'right';
}>`
	display: flex;
	flex-direction: ${({ columnMode }) => (columnMode ? 'column' : 'row')};
	gap: ${({ theme }) => theme.spacing.small};
	padding: 0;
	justify-content: ${({ alignment }) =>
		alignment === 'right' ? 'flex-end' : 'flex-start'};
	height: 100%;

	a {
		text-align: left;
	}
`;

export default ControlGroupItem;
