import styled from 'styled-components';

export const EmailCheckContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
`;

export const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 480px;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	gap: ${({ theme }) => theme.spacing.xl};
	padding: ${({ theme }) => `${theme.spacing.xl} 40px 40px`};
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	border: 1px solid ${({ theme }) => theme.colors.system.border.weak};
	box-shadow: ${({ theme }) => theme.shadows.elevation.xl};

	.title-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: ${({ theme }) => theme.spacing.large};
	}

	.form-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: ${({ theme }) => theme.spacing.large};
		width: 100%;
	}
`;
