import GoodkindDSTheme from '@common/design-system/global/theme';
import styled from 'styled-components';

const getVariantStyles = (theme: typeof GoodkindDSTheme, variant: string) => {
	switch (variant) {
		case 'success':
			return `
                background-color: ${theme.colors.success.background.default};
                color: ${theme.colors.success.text.default};
            `;
		case 'warning':
			return `
                background-color: ${theme.colors.warning.background.default};
                color: ${theme.colors.warning.text.default};
            `;
		case 'error':
			return `
                background-color: ${theme.colors.error.background.default};
                color: ${theme.colors.error.text.default};
            `;
		case 'accent':
			return `
                background-color: ${theme.colors.accent.background.default};
                color: ${theme.colors.accent.text.default};
            `;
		case 'filled':
			return `
                background-color: ${theme.colors.primary.background.strong};
                color: ${theme.colors.system.background.system};
            `;
		case 'neutral':
			return `
                background-color: ${theme.colors.neutral.background.default};
                color: ${theme.colors.neutral.icon.hover};
            `;
		case 'outline':
			return `
                background-color: ${theme.colors.system.background.system};
                color: ${theme.colors.primary.icon.default};
            `;
		default:
			return `
                background-color: ${theme.colors.primary.background.defaultHover};
                color: ${theme.colors.primary.text.strongHover};
            `;
	}
};

const getVariantSizing = (theme: typeof GoodkindDSTheme, size: string) => {
	switch (size) {
		case 'xs':
			return `
                min-width: 20px;
                height: 20px;
            `;
		case 'small':
			return `
                min-width: 24px;
                height: 24px;
            `;
		case 'large':
			return `
                min-width: 32px;
                height: 32px;
            `;
		default:
			return `
                min-width: 28px;
                height: 28px;
            `;
	}
};

export const BadgeCountContainer = styled.div<{
	variant: string;
	size: string;
}>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	column-gap: ${({ theme }) => theme.spacing.xs};
	flex-wrap: wrap;
	background-color: ${({ theme }) => theme.colors.neutral.border.default};
	border-radius: ${({ theme }) => theme.shapeRadius.xxl};
	padding: ${({ theme }) => `0 ${theme.spacing.xxs}`};

	${({ theme, size }) => getVariantSizing(theme, size)}

	${({ theme, variant }) => getVariantStyles(theme, variant)}
`;
