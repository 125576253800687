import {
	codeCTAValidatorSchema,
	doubleLinkCTAValidatorSchema,
	eventCTAValidatorSchema,
	linkCTAValidatorSchema,
	suggestedReplyValidatorSchema,
} from '@app/utils/modules/actions/schemas';

export const TitleFallback = 'Click to learn more';

export enum CTAListEnum {
	link = 'linkCta',
	text = 'textCta',
	code = 'codeCta',
	date = 'dateCta',
	shop = 'shopifyCta',
	doubleLink = 'doubleLinkCta',
	event = 'eventCta',
	suggestedReply = 'suggestedReplyCta',
}

export enum CTANameListEnum {
	linkCta = 'Link',
	textCta = 'Text',
	codeCta = 'Code',
	shopifyCta = 'Shopify',
	doubleLinkCta = 'Double Link',
	eventCta = 'Event',
	suggestedReplyCta = 'Suggested Reply',
}

export const CTASchemas = {
	[CTAListEnum.link]: linkCTAValidatorSchema,
	[CTAListEnum.doubleLink]: doubleLinkCTAValidatorSchema,
	[CTAListEnum.code]: codeCTAValidatorSchema,
	[CTAListEnum.suggestedReply]: suggestedReplyValidatorSchema,
	[CTAListEnum.event]: eventCTAValidatorSchema,
};
