import React from 'react';
import { TableMapperType } from '../../types';
import { Checkbox } from '@common/design-system/components/atoms';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

function CheckBoxCell({ mapperField, item }: PropsType) {
	const isDisabled =
		typeof mapperField.isDisabled === 'function'
			? mapperField.isDisabled(item)
			: mapperField.isDisabled;

	return (
		<Checkbox
			ariaLabel="checkbox"
			checked={mapperField.checkedItemKey && item[mapperField.checkedItemKey]}
			onChange={(check) =>
				mapperField.onCheck && mapperField.onCheck(item, check)
			}
			disabled={isDisabled}
		/>
	);
}

export default CheckBoxCell;
