import { createGlobalStyle, css } from 'styled-components';
import GoodkindDSTheme from './theme';
import X from '@global/images/x.svg';

export const mainGlobalStyle = css`
	/* http://meyerweb.com/eric/tools/css/reset/ 
        v2.0 | 20110126
        License: none (public domain)
        */

	* {
		box-sizing: border-box;
		font-family: ${GoodkindDSTheme.fonts.family.default};
		font-size: ${GoodkindDSTheme.fonts.sizes.regular};
		font-weight: ${GoodkindDSTheme.fonts.weights.regular};
		/* transition: ${GoodkindDSTheme.transitions.all}; */
	}

	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		vertical-align: baseline;
		color: ${GoodkindDSTheme.colors.system.text.default};
		font-family: ${GoodkindDSTheme.fonts.family.default};
		font-size: ${GoodkindDSTheme.fonts.sizes.regular};
		font-weight: ${GoodkindDSTheme.fonts.weights.regular};
	}
	/* HTML5 display-role reset for older browsers */
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol,
	ul {
		list-style: none;
	}
	blockquote,
	q {
		quotes: none;
	}
	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	.img-fluid {
		max-width: 100%;
		height: auto;
	}

	input[type='search']::-webkit-search-cancel-button {
		-webkit-appearance: none;
		height: 1em;
		width: 1em;
		background: url(${X}) no-repeat 50% 50%;
		background-size: contain;
		opacity: 0;
		pointer-events: none;
		cursor: pointer;
	}

	input[type='search']:focus::-webkit-search-cancel-button {
		opacity: 1;
		pointer-events: all;
	}

	button {
		appearance: none;
		border: none;
		margin: none;
		padding: none;
		background: none;
		cursor: pointer;
	}

	strong {
		font-weight: ${GoodkindDSTheme.fonts.weights.bold};
	}

	em {
		font-style: italic;
	}

	.cursor-pointer {
		cursor: pointer !important;
	}

	a {
		text-decoration: none !important;
	}

	/* Reset styling introduced by Blueprint (used by Polotno) */
	a,
	div[role='button'],
	div[role='dialog'] {
		text-decoration: none !important;
	}

	a,
	button,
	div[role='button'],
	div[role='dialog'] {
		&:focus {
			outline: none !important;
			outline-offset: 0px;
		}
		&:active {
			outline: none !important;
		}
	}

	/* Increase z-index of popover transition container used by Polotno*/
	.bp5-popover-transition-container {
		z-index: ${GoodkindDSTheme.zIndex.popover} !important;
	}

	:focus {
		outline: none !important;
		outline-offset: 0px;
	}
	/* End of reset*/
`;

export const GlobalStyle = createGlobalStyle`
    ${mainGlobalStyle}
`;
