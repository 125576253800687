import styled from 'styled-components';

export const SplitButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const Divider = styled.div`
	height: 20px;
	width: 1px;
	background-color: ${({ theme }) => theme.colors.system.border.medium};
`;
