import styled, { css } from 'styled-components';

import { ButtonProps, ButtonVariantType, ButtonSizeType } from './types';

import { GoodkindTheme } from '@global/theme';

function handleLoaderBackground(variant: ButtonVariantType): string {
	switch (variant) {
		case 'primary':
			return GoodkindTheme.colors.primary[700];

		case 'secondary':
			return GoodkindTheme.colors.neutral[0];

		case 'tertiary':
			return GoodkindTheme.colors.secondary[50];

		case 'quaternary':
			return GoodkindTheme.colors.neutral[0];

		case 'neutral':
			return GoodkindTheme.colors.neutral[0];

		case 'alert':
			return GoodkindTheme.colors.neutral[0];

		default:
			return GoodkindTheme.colors.primary[700];
	}
}

function handleFontWeight(size: ButtonSizeType): number {
	switch (size) {
		case 'smaller':
		case 'small':
			return 500;

		case 'regular':
		case 'large':
			return 600;
	}
}

export const StyledButton = styled.button<ButtonProps>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	padding: ${({ theme, size, isCircular }) =>
		isCircular ? 0 : size && theme.buttonPadding && theme.buttonPadding[size]};
	padding-top: ${({ loading }) => loading && '0px'};
	padding-bottom: ${({ loading }) => loading && '0px'};
	width: ${({ size }) => size === 'large' && '100%'};
	min-height: ${({ theme, size }) =>
		size &&
		theme.buttonPadding &&
		theme.buttonHeight &&
		theme.buttonHeight[size]};
	max-height: ${({ theme, size }) =>
		size &&
		theme.buttonPadding &&
		theme.buttonHeight &&
		theme.buttonHeight[size]};
	font-size: ${({ theme, size }) =>
		size &&
		theme.buttonPadding &&
		theme.buttonFontSize &&
		theme.buttonFontSize[size]};
	font-family: ${({ theme }) => theme.fonts.default};
	font-weight: ${({ size }) => size && handleFontWeight(size)};
	color: ${({ theme }) => theme.colors.neutral[0]};
	border-radius: ${({ size, theme, isCircular }) =>
		isCircular ? '50%' : size && theme.borderRadius?.components[size]};
	border: none;
	cursor: pointer;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	transition: ${({ theme }) => theme.transition?.hover};

	${({ theme, size, isCircular }) =>
		isCircular &&
		size &&
		theme.buttonHeight &&
		css`
			min-width: ${theme.buttonHeight[size]};
			max-width: ${theme.buttonHeight[size]};
		`}

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}

	&:not([disabled]):active {
		top: 1px;
	}
`;

export const PrimaryButton = styled(StyledButton)`
	background: ${({ theme }) => theme.colors.primary[700]};

	&:not([disabled]):hover,
	&:not([disabled]):active {
		background: ${({ theme }) => theme.colors.primary[900]};
	}

	&:not([disabled]):focus {
		outline: 2px solid ${({ theme }) => theme.colors.primary[700]};
		outline-offset: 2px;
	}
`;

export const SecondaryButton = styled(StyledButton)`
	color: ${({ theme }) => theme.colors.primary[700]};
	border: 1.5px solid ${({ theme }) => theme.colors.primary[700]};
	background: transparent;

	&:not([disabled]):hover,
	&:not([disabled]):active {
		background-color: ${({ theme }) => theme.colors.primary[50]};
	}

	&:not([disabled]):focus {
		outline: 2px solid ${({ theme }) => theme.colors.primary[700]};
		outline-offset: 2px;
	}
`;

export const TertiaryButton = styled(StyledButton)`
	color: ${({ theme }) => theme.colors.secondary[700]};
	background: ${({ theme }) => theme.colors.secondary[50]};

	&:not([disabled]):hover,
	&:not([disabled]):active {
		color: ${({ theme }) => theme.colors.secondary[900]};
		background: ${({ theme }) => theme.colors.secondary[100]};
	}

	&:not([disabled]):focus {
		outline: 2px solid ${({ theme }) => theme.colors.secondary[700]};
		outline-offset: 2px;
	}
`;

export const QuaternaryButton = styled(StyledButton)`
	color: ${({ theme }) => theme.colors.neutral[400]};
	box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.04);
	background: ${({ theme }) => theme.colors.neutral[0]};

	&:not([disabled]):hover {
		background-color: ${({ theme }) => theme.colors.neutral[50]};
	}

	&:not([disabled]):active {
		background-color: ${({ theme }) => theme.colors.neutral[100]};
	}

	&:not([disabled]):focus {
		outline: 2px solid ${({ theme }) => theme.colors.secondary[700]};
		outline-offset: 2px;
	}
`;

export const NeutralButton = styled(StyledButton)`
	color: ${({ theme }) => theme.colors.neutral[400]};
	background: ${({ theme }) => theme.colors.neutral[0]};
	border: ${({ theme }) => `1px solid ${theme.colors.neutral[400]}`};

	&:not([disabled]):hover {
		background-color: ${({ theme }) => theme.colors.neutral[50]};
	}

	&:not([disabled]):active {
		background-color: ${({ theme }) => theme.colors.neutral[100]};
	}

	&:not([disabled]):focus {
		outline: 2px solid ${({ theme }) => theme.colors.primary[700]};
		outline-offset: 2px;
	}
`;

export const AlertButton = styled(StyledButton)`
	color: ${({ theme }) => theme.colors.alert[700]};
	background: ${({ theme }) => theme.colors.neutral[0]};
	border: 1.5px solid ${({ theme }) => theme.colors.alert[700]};

	&:not([disabled]):hover,
	&:not([disabled]):active {
		background-color: ${({ theme }) => theme.colors.alert[50]};
	}

	&:not([disabled]):focus {
		outline: 2px solid ${({ theme }) => theme.colors.secondary[700]};
		outline-offset: 2px;
	}

	&:disabled {
		opacity: 0.3;
	}
`;

export const LoaderContainer = styled.div<{ variant: ButtonVariantType }>`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ variant }) => handleLoaderBackground(variant)};
	z-index: ${({
		theme: {
			zIndex: { mid },
		},
	}) => mid};
`;
