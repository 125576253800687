import { createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '@app/utils/createFetchReducer';
import {
	uploadVideoTypes,
	filterVideoTypes,
	getStreamingUrlTypes,
	filterVideoWithInfiniteTypes,
	getSingleVideoTypes,
	downloadVideoTypes,
	archiveVideoTypes,
	updateCaptionTypes,
	getParsedCaptionTypes,
	favoriteVideoTypes,
	toggleHideCaptionTypes,
	createCaptionSuggestionTypes,
	deleteCaptionSuggestionTypes,
	getCaptionSuggestionTypes,
	updateThumbnailTypes,
	updateVideoTypes,
	getVideoFoldersTypes,
	deleteVideoTypes,
	getFolderTypes,
	createFolderTypes,
	editFolderTypes,
	deleteFolderTypes,
	removeVideoFromFolderTypes,
} from './action';
import {
	EntityPaginationObject,
	GenericEntityState,
	ResponseAction,
} from '../types';
import { normalize } from 'normalizr';
import entityNormalizer from '@app/utils/entityNormalizer';
import { ServiceResponse, ToastMessage } from '@app/constants';
import { showToast } from '@common/design-system/components/molecules';

import {
	CueData,
	SubtitleType,
} from '@common/components/interaction/CaptionEditor/types';
import { parse } from 'node-webvtt';

export type VideoFolderType = {
	id: string;
	name: string;
	size: number;
};

function videoDataByCueProcessStrategy(
	value: any,
	parent: any,
	key: string,
	excludeTime: boolean,
) {
	if (!value.transcript?.length) {
		return { ...value };
	}
	const dataByCue: { [key: string]: CueData } = {};
	if (
		!value.dataByCue ||
		value.dataByCue[value.transcript?.length - 1].confidence
	) {
		value.transcript?.forEach(
			(
				{
					id,
					text,
					confidence,
				}: {
					id: string;
					confidence: number;
					text: string;
				},
				index: number,
			) => {
				dataByCue[index] = { confidence };
			},
		);
		return { ...value, dataByCue };
	}
}
const video = entityNormalizer(
	'videos',
	{},
	{
		idAttribute: '_id',
		excludeTime: true,
		processStrategy: videoDataByCueProcessStrategy,
	},
);
const pagination = { videos: [video] };

const initialState: GenericEntityState = {
	loading: false,
	error: {},
	videos: {},
	videosCount: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: 1,
	},
	response: {
		status: null,
		message: '',
	},
	processingVideos: {
		current: [],
		currentId: [],
		completed: [],
		showProcessingWidget: false,
	},
	streaming: {},
	custom: {},
	parsedCaptions: {},
	suggestions: [],
	folders: [],
	folder: {},
	folderPagination: {
		page: 1,
		currentPage: 1,
		totalPages: 1,
		count: 1,
	},
};
export type VideoStateType = typeof initialState;

function defaultVideosPaginationMapper(
	state: VideoStateType,
	action: ResponseAction,
	cb: (videos: any, state: VideoStateType) => void,
) {
	const {
		response: {
			videos,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ videos, page, pageSize, totalPages, count },
		pagination,
	);
	if (state.pagination) {
		state.pagination.pages[action.response.page] = normalizedData.result.videos;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}

	cb(normalizedData.entities.videos, state);
}

function paginationMapper(videos: any, state: VideoStateType) {
	state.videos = { ...videos };
	state.videosCount = Object.keys(state?.videos || {})?.length;
}
function filterMapper(videos: any, state: VideoStateType) {
	if (state.pagination && state.pagination.currentPage === 1) {
		return paginationMapper(videos, state);
	}
	state.videos = { ...state.videos, ...videos };
	state.videosCount = Object.keys(state?.videos || {})?.length;
}

function uploadMapper(state: VideoStateType, action: ResponseAction) {
	state.processingVideos.showProcessingWidget = true;
	state.loading = false;
}

function getstreamingUrlMapper(state: VideoStateType, action: ResponseAction) {
	const videoId = action.payload.videoId;
	const { url, jwt, accessToken, urlWithProxy } = action.response;
	const newStreamingState = {
		[videoId]: {
			id: videoId,
			streamingUrl: url,
			streamingToken: jwt,
			accessToken,
			urlWithProxy,
		},
	};
	const newState = {
		[videoId]: {
			...state.videos[videoId],
			streamingUrl: url,
			streamingToken: jwt,
		},
	};
	state.videos = { ...state.videos, ...newState };
	state.streaming = { ...state.streaming, ...newStreamingState };
}

function getSingleVideoMapper(state: VideoStateType, action: ResponseAction) {
	const videoId = action.response?.video?._id;
	const normalizedData = normalize(
		{ videos: { ...state.stories, [videoId]: action.response.video } },
		pagination,
	);
	state.videos = { ...normalizedData.entities.videos };
}
function updateCaptionsMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { video, captions },
	} = action;

	if (state.videos[video._id]) {
		state.videos[video._id].captions = video.captions;
		state.videos[video._id].transcriptText = video.transcriptText;

		if (video.dataByCue) {
			state.videos[video._id].dataByCue = video.dataByCue;
		}
	}

	state.parsedCaptions[video._id] = parse(captions, {
		meta: true,
	}) as SubtitleType;
}

function updateThumbnailMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { updatedVideo: video },
	} = action;

	state.videos[video._id] = video;
}

function parseCaptionsMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response,
		payload: { id },
	} = action;
	state.parsedCaptions[id] = parse(response, { meta: true }) as SubtitleType;
}

function downLoadVideoMapper(state: VideoStateType, action: ResponseAction) {}

function archiveVideoMapper(state: VideoStateType, action: ResponseAction) {
	state?.response && (state.response.message = ServiceResponse.videoArchived);
}

function favoriteVideoMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { video },
	} = action;
	if (state.videos[video._id]) {
		state.videos[video._id].favorite = video.favorite;
	}
	state?.response && (state.response.message = ServiceResponse.favoriteUpdated);
}

function toggleHideCaptionsMapper(message?: string) {
	return function (state: VideoStateType, action: ResponseAction) {
		const {
			response: { video },
		} = action;

		// Discards uploadedBy
		const { uploadedBy, ...videoFromResponse } = video;

		if (state.videos[video._id]) {
			state.videos[video._id].hideCaptions = videoFromResponse.hideCaptions;
		}

		if (message) {
			state?.response && (state.response.message = message);
		}
	};
}

function updateVideoMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { video },
	} = action;

	if (state.videos[video._id]) {
		state.videos[video._id] = video;
	}
}

function getSuggestionsMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { suggestions },
	} = action;
	state.suggestions = suggestions;
}

function deleteSuggestionMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { id },
	} = action;

	const suggestionsCopy = [...state?.suggestions];

	state.suggestions = suggestionsCopy.filter(({ objectID }) => objectID !== id);
}

function getVideoFoldersMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { folders, count, page, pageSize, totalPages },
	} = action;
	state.folders = folders;
	state.folderPagination = {
		count,
		page,
		pageSize,
		totalPages,
	};
}

function deleteVideoMapper(state: VideoStateType, action: ResponseAction) {
	const {
		payload: { id },
	} = action;
	const videosCopy = { ...state?.videos };
	//TODO: Fix this state mutation
	// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
	delete videosCopy[id];
	state.videos = videosCopy;
	state.pagination = {
		...state.pagination,
		count: state.pagination?.count && state.pagination?.count - 1,
	} as EntityPaginationObject;
}

function deleteVideoErrorMapper(state: VideoStateType, action: ResponseAction) {
	const errorCode = action?.error?.response?.data?.errorCode;
	const data = action?.error?.response?.data;
	const campaigns = data?.metadata?.campaigns;

	const campaignsNames = campaigns?.map(({ name }: any) => name);

	const isVideoInUse = errorCode === 1401;
	if (isVideoInUse) {
		return showToast({
			title: `This video can't be deleted as it is being used in ${campaignsNames.length} campaigns:`,
			message: `${campaignsNames.join('\n')}`,
			type: 'error',
		});
	}
	return showToast({ message: ToastMessage.error, type: 'error' });
}

function getFolderMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { folders },
	} = action;
	state.folder = folders[0];
}

function createFoldersMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { folder },
	} = action;
	const foldersCopy = [...state.folders];
	foldersCopy.unshift(folder);
	state.folders = foldersCopy;
}

function editFoldersMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { folder },
	} = action;
	state.folder = folder;
}

function deleteFolderMapper(state: VideoStateType, action: ResponseAction) {
	const {
		payload: { id },
	} = action;
	const foldersCopy = [...state?.folders];
	const removeFolder = foldersCopy.filter((folder) => folder._id !== id);
	state.folders = removeFolder;
}

function removeVideoFromFolderMapper(
	state: VideoStateType,
	action: ResponseAction,
) {
	const {
		payload: { videoId },
	} = action;
	const videosCopy = { ...state?.videos };
	//TODO: Fix this state mutation
	// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
	delete videosCopy[videoId.videos[0]];
	state.videos = videosCopy;
	state.videosCount = Object.keys(videosCopy || {}).length;
}

function removeVideoFromFolderErrorMapper(
	state: VideoStateType,
	action: ResponseAction,
) {
	return showToast({ message: ToastMessage.error, type: 'error' });
}

const videoSlice = createSlice({
	name: 'video',
	initialState,
	reducers: {
		CLEAR_VIDEOS(state: VideoStateType) {
			state.videos = {};
			state.videosCount = 0;
		},
		CLEAR_FOLDERS(state: VideoStateType) {
			state.folders = [];
		},
	},
	extraReducers: {
		...createFetchReducer(uploadVideoTypes, uploadMapper),
		...createFetchReducer(filterVideoTypes, (state: VideoStateType, action) =>
			defaultVideosPaginationMapper(state, action, paginationMapper),
		),
		...createFetchReducer(filterVideoWithInfiniteTypes, (state, action) =>
			defaultVideosPaginationMapper(state, action, filterMapper),
		),
		...createFetchReducer(getStreamingUrlTypes, getstreamingUrlMapper),
		...createFetchReducer(getSingleVideoTypes, getSingleVideoMapper),
		...createFetchReducer(downloadVideoTypes, downLoadVideoMapper),
		...createFetchReducer(archiveVideoTypes, archiveVideoMapper),
		...createFetchReducer(updateCaptionTypes, updateCaptionsMapper),
		...createFetchReducer(updateThumbnailTypes, updateThumbnailMapper),
		...createFetchReducer(getParsedCaptionTypes, parseCaptionsMapper),
		...createFetchReducer(favoriteVideoTypes, favoriteVideoMapper),
		...createFetchReducer(
			toggleHideCaptionTypes,
			toggleHideCaptionsMapper(ServiceResponse.toggledCaptions),
		),
		...createFetchReducer(updateVideoTypes, updateVideoMapper),
		...createFetchReducer(createCaptionSuggestionTypes),
		...createFetchReducer(deleteCaptionSuggestionTypes, deleteSuggestionMapper),
		...createFetchReducer(getCaptionSuggestionTypes, getSuggestionsMapper),
		...createFetchReducer(getVideoFoldersTypes, getVideoFoldersMapper),
		...createFetchReducer(
			deleteVideoTypes,
			deleteVideoMapper,
			deleteVideoErrorMapper,
		),
		...createFetchReducer(getFolderTypes, getFolderMapper),
		...createFetchReducer(createFolderTypes, createFoldersMapper),
		...createFetchReducer(editFolderTypes, editFoldersMapper),
		...createFetchReducer(deleteFolderTypes, deleteFolderMapper),
		...createFetchReducer(
			removeVideoFromFolderTypes,
			removeVideoFromFolderMapper,
			removeVideoFromFolderErrorMapper,
		),
	},
});

export default videoSlice.reducer;
