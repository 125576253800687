import styled from 'styled-components';

export const TextBadgeContainer = styled.div<{ variant: string }>`
	display: inline-block;
	padding: 0.1rem 1rem;
	z-index: 4;
	background-color: ${({ theme, variant }) =>
		theme && (theme.colors as any)[variant][100]};
	color: ${({ theme, variant }) =>
		theme && (theme.colors as any)[variant][900]};
	border-radius: 1.5rem;
`;
