import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useFeature, useGrowthBook } from '@growthbook/growthbook-react';

import {
	GenericAPIResponse,
	RootState,
	useAppDispatch,
} from '@app/redux/types';
import { growthbookFeatures } from '@app/constants/growthbook';

import Logo from '@common/components/presentation/Logo';

import {
	filterNavLinks,
	formatInboxOrder,
	getNavBarInitialState,
	sortUserTeams,
} from './utils';

import { PageRouteEnum } from '@app/constants/pages';
import {
	NavBarState,
	NavBarDimension,
	NavLinkIconName,
	NavLinkText,
} from './constants';

import * as S from './NavigationBar.styles';
import GoodkindTheme from '@global/theme';

import { Divider, Icon } from '@common/design-system/components/atoms';
import { getTeams } from '@app/redux/teams/action';
import {
	TeamStorytellerType,
	TeamType,
	TeamWithStorytellerInfoType,
} from '@app/types/modules/teams';
import GoodkindDSProvider from '@common/design-system/global/provider';

import { NavLinkButton, NavLinkWithSubMenu } from '../../molecules';
import { NavLinkButtonProps } from '../../molecules/NavLinkButton/NavLinkButton';
import { PopupMenu, PopupButton } from './components';
import {
	checkWorkspaceHasSMSPlan,
	checkWorkspaceHasVideoMessagingPlan,
} from '@app/utils/modules/workspace';

import {
	checkIsWorkspaceAdmin,
	getStorytellerName,
} from '@app/utils/modules/storytellers';
import { useAuth } from '@app/services/Auth/useAuth';
import isEmptyValue from '@app/utils/isEmptyValue';
import { updateInboxOrder } from '@app/redux/storyteller/action';
import isSuccessHttpStatus from '@app/utils/isSuccessHttpStatus';
import { UPDATE_USER_INBOX_ORDER } from '@app/redux/auth';
import { InboxOrder } from '@app/redux/storyteller/types';

type NavLinkAccordionProps = NavLinkButtonProps & {
	showMenuCount?: boolean;
	hideOnCollapse?: boolean;
};

export default function NewNavigationBar() {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const location = useLocation();

	const growthbook = useGrowthBook();

	const isAudienceV3 = useFeature(growthbookFeatures.AUDIENCE_V3).on;

	const [navBarState, setNavBarState] = useState<string>(
		getNavBarInitialState(localStorage.getItem('navBarState') as NavBarState),
	);
	const [openedSubMenu, setOpenedSubMenu] = useState<string | null>(null);

	const [inboxOrder, setInboxOrder] = useState<InboxOrder[] | undefined>();

	const isNavbarCollapsed = navBarState === NavBarState.CLOSED;

	const { user, isAccessLoading } = useAuth();

	const isWorkspaceAdmin = checkIsWorkspaceAdmin(user);

	const { workspaceInfo } = useSelector(
		({ workspace: { workspaceInfo } }: RootState) => ({
			workspaceInfo,
		}),
	);

	const hasSMSPlan = checkWorkspaceHasSMSPlan(workspaceInfo);
	const hasVideoMessagingPlan =
		checkWorkspaceHasVideoMessagingPlan(workspaceInfo);

	const allTeams: TeamType[] = useSelector(({ teams }: RootState) =>
		Object.values(teams.teams),
	);

	const totalTeamsUnread = useSelector(
		({ teams }: RootState) => teams?.totalUnreads,
	);

	const teamsCount = useSelector(({ teams }: RootState) => teams.teamsCount);

	const teamsLoading = useSelector(({ teams }: RootState) => teams.loading);

	// Support method to map the team from the user to the complete team and merge the data
	// (team info: name, icon.. + storyteller team related info: role, permissions, notifications..)
	const mapTeamToCompleteTeam = (team: {
		_id?: string;
		id?: string;
		storytellers?: TeamStorytellerType[];
	}): TeamWithStorytellerInfoType | null => {
		const completeTeam = allTeams.find(
			(t) => t._id === team._id || t.id === team.id,
		);

		if (!completeTeam) {
			return null;
		}

		const { storytellers } = team;

		return {
			...completeTeam,
			storyteller: storytellers?.[0]?.storyteller,
			permissions: storytellers?.[0]?.permissions,
			notifications: storytellers?.[0]?.notifications,
			role: storytellers?.[0]?.role,
		};
	};

	const commonNavLinkProps = { isDisabled: isAccessLoading, isNavbarCollapsed };
	const listOfTeamsToUse = isWorkspaceAdmin ? allTeams : user?.teams;

	// User teams with the complete team info
	const userTeams = useMemo(() => {
		const filteredTeams = listOfTeamsToUse
			?.map(mapTeamToCompleteTeam)
			.filter(Boolean) as (TeamType & {
			storyteller: string;
			permissions: TeamStorytellerType['permissions'];
			notifications: TeamStorytellerType['notifications'];
		})[];
		const inboxOrder = user?.settings?.inboxOrders?.[0]?.inboxOrder || [];
		if (inboxOrder && inboxOrder?.length === filteredTeams?.length) {
			return sortUserTeams(inboxOrder, filteredTeams);
		}
		return filteredTeams;
	}, [allTeams, user, listOfTeamsToUse]);

	const totalUnreadsToShow = isWorkspaceAdmin
		? totalTeamsUnread
		: userTeams?.reduce(
				(acc, team) => acc + (team?.unreadConversations || 0),
				0,
			);

	const hasAnyTeamInboxPermission = userTeams?.some(
		(team) => team?.permissions?.teamInbox,
	);

	const formatInboxMenuOption = useCallback(
		(teams: TeamWithStorytellerInfoType[]) => {
			return teams
				?.filter((team) => isWorkspaceAdmin || team.permissions?.teamInbox)
				?.map((team: any) => {
					const isActive = window.location.pathname.includes(
						`/${PageRouteEnum.inbox}/${team.id}`,
					);
					return {
						text: team.name,
						iconName: team.icon,
						onClick: (e: any) => {
							handleRedirectInbox(team.id, e);
						},
						count: team?.unreadConversations || 0,
						redirectTo: `/${PageRouteEnum.inbox}/${team.id}`,
						active: isActive,
						type: isActive ? 'primary' : 'regular',
						...commonNavLinkProps,
					};
				});
		},
		[commonNavLinkProps],
	);

	const inboxOptionMenuSorted = useMemo(() => {
		if (!inboxOrder) {
			return formatInboxMenuOption(userTeams);
		}

		const sortedUserTeams = sortUserTeams(inboxOrder, userTeams);
		return formatInboxMenuOption(sortedUserTeams);
	}, [userTeams, formatInboxMenuOption, inboxOrder]);

	const handleSortInboxMenu = async (sortedMenus: NavLinkButtonProps[]) => {
		const prevInboxOrder = inboxOrder;
		const newInboxOrder = formatInboxOrder(sortedMenus);
		setInboxOrder(newInboxOrder);
		try {
			const response = (await dispatch(
				updateInboxOrder(newInboxOrder),
			)) as unknown as GenericAPIResponse;
			if (isSuccessHttpStatus(response.status)) {
				const { inboxOrder } = response;
				dispatch(UPDATE_USER_INBOX_ORDER({ inboxOrder }));
			} else {
				const errorInfo = response?.metadata;
				if (errorInfo) {
					// If we have controlled error from backend we are receiving the saved inboxOrder in the error metadata
					// So we set the user preference and clear the changes in the state.
					// That way we are showing the state saved in the db.
					const { inboxOrder } = errorInfo;
					dispatch(UPDATE_USER_INBOX_ORDER({ inboxOrder }));
					setInboxOrder(undefined);
				}
			}
		} catch (_) {
			setInboxOrder(prevInboxOrder);
		}
	};

	const isSuccessPlanOn = useFeature(
		growthbookFeatures.FRONTEND_SUCCESS_PLAN,
	).on;

	const isInboxesDragAndPinOn = useFeature(
		growthbookFeatures.FRONTED_INBOXES_DRAG_AND_PIN,
	).on;

	const isShowingSuccessPlanNav = useMemo(
		() =>
			!!workspaceInfo?.settings?.dockUrl &&
			!isEmptyValue(workspaceInfo?.settings?.dockUrl) &&
			isSuccessPlanOn &&
			isWorkspaceAdmin,
		[isSuccessPlanOn, workspaceInfo, isWorkspaceAdmin],
	);

	function handleNavBarStateChange() {
		if (navBarState === NavBarState.OPEN) {
			setNavBarState(NavBarState.CLOSED);
		} else if (isNavbarCollapsed) {
			setNavBarState(NavBarState.OPEN);

			// Get the active accordion link when the navbar is collapsed
			const activeAccordionLink = navLinksAccordionGroup.find((navLink) => {
				return window.location.pathname.includes(navLink?.redirectTo as string);
			});

			if (activeAccordionLink) {
				setOpenedSubMenu(activeAccordionLink.text);
			} else {
				// If there is no active accordion link, open the first accordion link by default
				setOpenedSubMenu(navLinksAccordionGroup[0].text);
			}
		}
	}
	// Listener to update localStorage on NavBar state change
	useEffect(() => {
		window.addEventListener(
			'resize',
			() => {
				if (window.innerWidth <= GoodkindTheme.breakpoint.width.m) {
					setNavBarState(NavBarState.CLOSED);
				}
			},
			true,
		);
	}, []);

	//Get teams on load
	useEffect(() => {
		if (!teamsCount && workspaceInfo?._id && !teamsLoading) {
			dispatch(getTeams());
		}
	}, [workspaceInfo?._id, teamsCount]);

	// Listener to update localStorage on NavBar state change
	useEffect(() => {
		if (navBarState === NavBarState.OPEN) {
			localStorage.setItem('navBarState', NavBarState.OPEN);
		} else if (isNavbarCollapsed) {
			localStorage.setItem('navBarState', NavBarState.CLOSED);
		}
	}, [navBarState]);

	const handleRedirectInbox = (teamId: string, e?: React.MouseEvent) => {
		e?.preventDefault();

		const search = location.search || '';
		navigate({
			pathname: `/${PageRouteEnum.inbox}/${teamId}`,
			search,
		});
	};
	const handelRedirectDock = () => {
		const dockUrl = (workspaceInfo?.settings?.dockUrl as string) || '';
		window.open(dockUrl, '_blank');
	};

	const navLinksTopGroup: NavLinkButtonProps[] = [
		{
			text: NavLinkText.HOME,
			iconName: NavLinkIconName.HOME,
			redirectTo: `/${PageRouteEnum.home}`,
			...commonNavLinkProps,
		},
		{
			text: NavLinkText.CHATBOT,
			iconName: NavLinkIconName.CHATBOT_ACTIVE,
			inactiveIconName: NavLinkIconName.CHATBOT_INACTIVE,
			redirectTo: `/${PageRouteEnum.chatbot}`,
			...commonNavLinkProps,
		},
		{
			text: NavLinkText.ACTIVITY,
			iconName: NavLinkIconName.ACTIVITY,
			redirectTo: `/${PageRouteEnum.activity}`,
			...commonNavLinkProps,
		},
		{
			text: NavLinkText.CAMPAIGNS,
			iconName: NavLinkIconName.CAMPAIGNS,
			redirectTo: `/${PageRouteEnum.campaigns}`,
			...commonNavLinkProps,
		},
		{
			text: NavLinkText.VIDEOS,
			iconName: NavLinkIconName.VIDEOS,
			redirectTo: `/${PageRouteEnum.videos}`,
			...commonNavLinkProps,
		},
		{
			text: NavLinkText.RECORDERS,
			iconName: NavLinkIconName.RECORDERS,
			redirectTo: `/${PageRouteEnum.recorders}`,
			...commonNavLinkProps,
		},

		{
			text: NavLinkText.SUCCESS_PLAN,
			iconName: NavLinkIconName.SUCCESS_PLAN,
			redirectTo: '',
			onClick: handelRedirectDock,
			redirectLinkIcon: true,
			...commonNavLinkProps,
		},
		...(!isAudienceV3
			? [
					{
						text: NavLinkText.AUDIENCES,
						iconName: NavLinkIconName.AUDIENCES,
						redirectTo: `/${PageRouteEnum.audiences}`,
						...commonNavLinkProps,
					},
				]
			: []),
	];

	const navLinksAccordionGroup: NavLinkAccordionProps[] = useMemo(() => {
		return [
			...(isAudienceV3
				? ([
						{
							showMenuCount: false,
							hideOnCollapse: true,
							text: NavLinkText.AUDIENCES,
							iconName: NavLinkIconName.AUDIENCE,
							redirectTo: `/${PageRouteEnum.audiences}`,
							onClick: () => navigate(`/${PageRouteEnum.audiences}`),
							isCollapsed: openedSubMenu === NavLinkText.AUDIENCES,
							collapseArrowDirection: 'right',
							isSubMenusThreaded: true,
							parentMenuCapitalize: false,
							parentMenuStyle: 'main' as const,
							handleIsCollapsed: () => {
								if (openedSubMenu === NavLinkText.AUDIENCES) {
									setOpenedSubMenu(null);
								} else {
									setOpenedSubMenu(NavLinkText.AUDIENCES);
								}
							},
							menus: [
								{
									text: 'All contacts',
									iconName: 'usersFour',
									redirectTo: `/${PageRouteEnum.audiences}/contacts`,
									active:
										location.pathname ===
										`/${PageRouteEnum.audiences}/contacts`,
								},
								{
									text: 'Lists',
									iconName: 'user-list',
									redirectTo: `/${PageRouteEnum.audiences}`,
									active: location.pathname === `/${PageRouteEnum.audiences}`,
								},
							],
							...commonNavLinkProps,
						},
					] as NavLinkAccordionProps[])
				: []),
			{
				showMenuCount: true,
				text: NavLinkText.INBOX,
				iconName: isNavbarCollapsed ? NavLinkIconName.INBOX : '',
				redirectTo: `/${PageRouteEnum.inbox}`,
				isCollapsed: openedSubMenu === NavLinkText.INBOX,
				count: totalUnreadsToShow || 0,
				handleIsCollapsed: () => {
					if (openedSubMenu === NavLinkText.INBOX) {
						setOpenedSubMenu(null);
					} else {
						setOpenedSubMenu(NavLinkText.INBOX);
					}
				},
				menus: inboxOptionMenuSorted,
				...commonNavLinkProps,
			},
		];
	}, [
		isAudienceV3,
		openedSubMenu,
		location.pathname,
		isNavbarCollapsed,
		totalUnreadsToShow,
		inboxOptionMenuSorted,
	]);

	const filteredTopNavLinks = useMemo(
		() =>
			filterNavLinks(
				navLinksTopGroup,
				{
					showSuccessPlanMenu: isShowingSuccessPlanNav,
					hasAnyTeamInboxPermission,
					isWorkspaceAdmin,
					showChatbotMenu: true,
					hasVideoMessagingPlan,
					hasSMSPlan,
				},
				growthbook,
			),
		[
			navLinksTopGroup,
			hasAnyTeamInboxPermission,
			isWorkspaceAdmin,
			hasVideoMessagingPlan,
			hasSMSPlan,
		],
	);

	const filteredAccordionNavLinks = useMemo(
		() =>
			filterNavLinks(navLinksAccordionGroup, {
				hasAnyTeamInboxPermission,
				isWorkspaceAdmin,
				hasVideoMessagingPlan,
				hasSMSPlan,
			}),
		[
			navLinksAccordionGroup,
			hasAnyTeamInboxPermission,
			isWorkspaceAdmin,
			hasVideoMessagingPlan,
			hasSMSPlan,
		],
	);

	useEffect(() => {
		// If there is no opened sub menu, open the first accordion link by default
		if (!openedSubMenu) {
			setOpenedSubMenu(filteredAccordionNavLinks[0]?.text);
		}
	}, [filteredAccordionNavLinks?.length]);

	return (
		<GoodkindDSProvider withGlobalStyle={false}>
			<S.NavigationBarWrapper
				isOpen={navBarState === NavBarState.OPEN}
				maxWidth={NavBarDimension.MAX_WIDTH}
				minWidth={NavBarDimension.MIN_WIDTH}
			>
				<S.NavigationBarContainer
					isOpen={navBarState === NavBarState.OPEN}
					maxWidth={NavBarDimension.MAX_WIDTH}
					minWidth={NavBarDimension.MIN_WIDTH}
				>
					<S.NavigationBarContent>
						{navBarState === NavBarState.OPEN && (
							<S.LogoWrapper>
								<S.LogoContainer
									onClick={() => navigate(`/${PageRouteEnum.home}`)}
								>
									<Logo />
								</S.LogoContainer>
								<S.CollapseCloseButton onClick={handleNavBarStateChange}>
									<Icon
										iconName="arrowLineLeft"
										size={'medium'}
										weight="regular"
										color="system.text.weak"
									/>
								</S.CollapseCloseButton>
							</S.LogoWrapper>
						)}

						{isNavbarCollapsed && (
							<S.CollapseOpenButton onClick={handleNavBarStateChange}>
								<Icon
									iconName="list"
									size={'large'}
									weight="regular"
									color="system.text.weak"
								/>
							</S.CollapseOpenButton>
						)}

						<S.NavigationLinksWrapper>
							<S.NavLinksGroup>
								<>
									{filteredTopNavLinks.map((navLink: NavLinkButtonProps) => {
										return (
											<NavLinkButton
												key={`nav-link-${navLink.text}`}
												{...navLink}
											/>
										);
									})}
								</>
							</S.NavLinksGroup>

							{isNavbarCollapsed && <Divider />}

							<S.NavLinksGroup>
								<>
									{filteredAccordionNavLinks.map(
										(navLink: NavLinkAccordionProps) => {
											const isSubMenuOpen = navLink.text === openedSubMenu;
											const isInboxMenu = navLink.text === NavLinkText.INBOX;
											const isShowingSubMenu = !(
												isNavbarCollapsed && navLink.hideOnCollapse
											);

											if (isShowingSubMenu && !!navLink?.menus?.length) {
												return (
													<NavLinkWithSubMenu
														showMenuCount={navLink?.showMenuCount}
														key={`nav-link-${navLink.text}`}
														navLink={navLink}
														isSubMenuOpen={isSubMenuOpen}
														isNavbarCollapsed={isNavbarCollapsed}
														loading={isAccessLoading}
														capitalizeTitle={navLink.parentMenuCapitalize}
														{...(isInboxMenu &&
														isShowingSubMenu &&
														!isNavbarCollapsed &&
														isInboxesDragAndPinOn
															? {
																	isMenuSortable: true,
																	onSortedMenu: handleSortInboxMenu,
																}
															: {})}
													/>
												);
											}

											return (
												<NavLinkButton
													key={`nav-link-${navLink.text}`}
													{...navLink}
												/>
											);
										},
									)}
								</>
							</S.NavLinksGroup>
						</S.NavigationLinksWrapper>

						<S.NavLinkBottomGroup>
							<PopupMenu
								trigger={(isPopupMenuOpen: boolean) => (
									<PopupButton
										isFocused={isPopupMenuOpen}
										userFullName={getStorytellerName(user)}
										userProfilePicture={user?.profilePicture}
										isNavbarCollapsed={isNavbarCollapsed}
									/>
								)}
							/>
						</S.NavLinkBottomGroup>
					</S.NavigationBarContent>
				</S.NavigationBarContainer>
			</S.NavigationBarWrapper>
		</GoodkindDSProvider>
	);
}
