export enum AudienceSourceNameEnum {
	ALL = 'All audiences',
	SALESFORCE = 'Salesforce',
	SALESFORCE_SANDBOX = 'Salesforce Sandbox',
	HUBSPOT = 'Hubspot',
	DYNAMICS = 'Dynamics',
	SFTP = 'SFTP',
	UPLOAD = 'Uploads',
	SEGMENTS = 'segments',
	LISTS = 'lists',
	BLACKBAUD = 'Blackbaud',
	ELLUCIAN_RECRUIT = 'Ellucian Recruit',
}

export enum AudienceSourceEnum {
	SALESFORCE = 'salesforce',
	SALESFORCE_SANDBOX = 'salesforce:sandbox',
	HUBSPOT_BETA = 'hubspot-beta',
	HUBSPOT = 'hubspot',
	DYNAMICS = 'dynamics',
	SFTP = 'sftp',
	UPLOAD = 'upload',
	SEGMENTS = 'segments',
	BLACKBAUD = 'blackbaud',
	ELLUCIAN_RECRUIT = 'ellucian-recruit',
	ELLUCIAN = 'ellucian',
	SLATE = 'slate',
}

export enum AudienceSyncStatusEnum {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export enum AudienceRuleOperatorEnum {
	GT = 'gt',
	LT = 'lt',
	GTE = 'gte',
	LTE = 'lte',
	EQ = 'eq',
	NEQ = 'neq',
}
