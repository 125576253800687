import BoxWrapper from '../../atoms/BoxWrapper/BoxWrapper';
import styled from 'styled-components';

export const ElevatedBoxWrapper = styled(BoxWrapper)`
	display: flex;
	flex-direction: column;
	height: auto; // Override BoxWrapper height
	box-shadow: ${({ theme }) => theme.shadows.elevation.container};
	border: ${({ theme }) => `1px solid ${theme.colors.system.border.weak}`};
	padding: ${({ theme }) =>
		`${theme.spacing.large} ${theme.spacing.xl} ${theme.spacing.xl}`};
	row-gap: ${({ theme }) => theme.spacing.xl};

	.row {
		border-bottom: ${({ theme }) =>
			`1px solid ${theme.colors.system.border.weak}`};
		padding-bottom: ${({ theme }) => theme.spacing.large};

		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
		}
	}
`;

export const ElevatedBoxTitleWrapper = styled.div<{ hasDivider?: boolean }>`
	display: flex;
	align-items: center;
	padding-bottom: ${({ theme, hasDivider }) =>
		hasDivider ? theme.spacing.large : '0'};
	border-bottom: ${({ theme, hasDivider }) =>
		hasDivider ? `1px solid ${theme.colors.system.border.weak}` : 'none'};

	.icon-wrapper {
		margin-right: ${({ theme }) => theme.spacing.small};
	}

	.texts-wrapper {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xxs};
	}

	.buttons-wrapper {
		display: flex;
		margin-left: auto;
		column-gap: ${({ theme }) => theme.spacing.medium};
	}
`;
