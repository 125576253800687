import React, { Fragment, useState, useEffect } from 'react';
import * as S from './LiveEditPreview.styles';
import { ScreenDimensionsEnum } from '../../containers/BuildStep/constant';
import { SwitchContainer } from '../../containers/BuildStep/Build.styles';
import { CubeToggle, Loader } from '@common/design-system/components/atoms';
import { VideoStatusEnum } from '../../containers/BuildStep/containers/VideoPage/constant';

type LiveEditPreviewType = {
	isLoading?: boolean;
	children: React.ReactNode;
	screenType?: VideoStatusEnum;
	onChangeScreenType?: (screenType: VideoStatusEnum) => void;
	hideResponsiveSwitch?: boolean;
	hidePlayStatesSwitch?: boolean;
	hidePreviewStateSwitch?: boolean;
};

const LiveEditPreview = ({
	isLoading,
	children,
	screenType,
	onChangeScreenType,
	hideResponsiveSwitch,
	hidePlayStatesSwitch,
	hidePreviewStateSwitch,
}: LiveEditPreviewType) => {
	const [screenDimension, setScreenDimension] = useState<ScreenDimensionsEnum>(
		ScreenDimensionsEnum.DESKTOP,
	);

	const templateDimensionWidth = {
		mobile: '400px',
		desktop: '100%',
	};
	/**
	 * when autoPlay is on then hide the preview state from the preview screen and set the screen type to play
	 * */
	const handleHidePreviewState = (autoplay: boolean) => {
		if (autoplay && onChangeScreenType) {
			onChangeScreenType(VideoStatusEnum.play);
		}
	};
	useEffect(() => {
		if (hidePreviewStateSwitch) {
			handleHidePreviewState(hidePreviewStateSwitch);
		}
	}, [hidePreviewStateSwitch]);

	const dimensionsSwitchOptions = [
		{
			iconName: 'desktop',
			isActive: screenDimension === ScreenDimensionsEnum.DESKTOP,
			onClick: () => setScreenDimension(ScreenDimensionsEnum.DESKTOP),
		},
		{
			iconName: 'mobile',
			isActive: screenDimension === ScreenDimensionsEnum.MOBILE,
			onClick: () => setScreenDimension(ScreenDimensionsEnum.MOBILE),
		},
	];

	const videoPageStateSwitchOptions = () => {
		const videoStateSwiches = [
			{
				text: 'Preview',
				isActive: screenType === VideoStatusEnum.preview,
				onClick: () =>
					onChangeScreenType && onChangeScreenType(VideoStatusEnum.preview),
			},
			{
				text: 'Play',
				isActive: screenType === VideoStatusEnum.play,
				onClick: () =>
					onChangeScreenType && onChangeScreenType(VideoStatusEnum.play),
			},
			{
				text: 'End',
				isActive: screenType === VideoStatusEnum.end,
				onClick: () =>
					onChangeScreenType && onChangeScreenType(VideoStatusEnum.end),
			},
		];
		if (hidePreviewStateSwitch) {
			videoStateSwiches.shift();
		}
		return videoStateSwiches;
	};
	return (
		<Fragment>
			<S.IframeWrapper>
				{isLoading && (
					<S.LoaderContainer>
						<Loader size="large" />
					</S.LoaderContainer>
				)}

				{!isLoading && (
					<SwitchContainer>
						{!hideResponsiveSwitch && (
							<CubeToggle switchOptions={dimensionsSwitchOptions} />
						)}

						{!!onChangeScreenType && !hidePlayStatesSwitch && (
							<div className="switch-container">
								<CubeToggle
									orientation="vertical"
									switchOptions={videoPageStateSwitchOptions()}
								/>
							</div>
						)}
					</SwitchContainer>
				)}

				<S.IframeContainer
					isMobile={screenDimension === ScreenDimensionsEnum.MOBILE}
					width={templateDimensionWidth[screenDimension]}
				>
					{children}
				</S.IframeContainer>
			</S.IframeWrapper>
		</Fragment>
	);
};

export default LiveEditPreview;
