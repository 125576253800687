function evalColorPath(colors: any, path: string) {
	if (!path || !colors) return null;

	const target = colors;
	const splittedPath = path.split('.');
	const [token, level, type] = splittedPath;

	// validate the path
	if (!target[token] || !target[token][level]) return null;

	return target[token][level][type];
}

function getNameInitials(name: string): string {
	const initials = name
		?.split(' ')
		?.map((n) => n[0])
		?.join('');

	if (initials?.length > 2) return initials?.substring(0, 2);

	return initials;
}

export { evalColorPath, getNameInitials };
