import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { getOverlaysTypes } from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import { normalize } from 'normalizr';

const overlay = entityNormalizer('overlays');
const pagination = { overlays: [overlay] };

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			overlays,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ overlays, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

const initialState: GenericEntityState = {
	loading: false,
	overlays: {},
	overlaysCount: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: null,
	},

	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		reload: false,
	},
};

export type overlaysStateType = typeof initialState;

function getOverlaysMapper(state: overlaysStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);

	if (action.response.page === 1) {
		state.overlays = normalizedData.entities.overlays;
	} else {
		state.overlays = {
			...state.overlays,
			...normalizedData.entities.overlays,
		};
	}
	state.overlaysCount = Object.keys(state.overlays || {})?.length;
	if (state.pagination) {
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
}

const overlaySlice = createSlice({
	name: 'overlays',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(getOverlaysTypes, getOverlaysMapper),
	},
});

export default overlaySlice.reducer;
