import styled from 'styled-components';

export const Background = styled.div`
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const MainContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.system.background.system};
	box-shadow: ${({ theme }) => theme.shadows.elevation.large};
	width: 618px;
	min-height: 176px;
	padding: 24px;
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	margin: auto;
	row-gap: 8px;
	display: flex;
	flex-direction: column;

	span {
		line-height: ${({ theme }) => theme.fonts.lineHeight.large};
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
	margin-bottom: 8px;
`;

export const ButtonText = styled.button`
	display: contents;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.primary.text.default};
	:hover {
		color: ${({ theme }) => theme.colors.primary.text.hover};
	}
`;
