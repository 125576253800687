import styled from 'styled-components';

export const ModalContent = styled.div`
	display: flex;
	min-height: 531px;
	max-height: 531px;
	margin: ${({ theme }) => theme.spacing.large};
	gap: 40px;
`;

export const PreviewContainer = styled.div<{ isEditingThumbnail?: boolean }>`
	width: 323.69px;
	position: absolute;
	top: 120px;
`;

export const VideoPlayerWrapper = styled.div<{
	hasVideo?: boolean;
	isEditingThumbnail?: boolean;
}>`
	width: 323.69px;
	height: ${({ isEditingThumbnail }) =>
		isEditingThumbnail ? '317px' : '495.91px'};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing.xl};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};

	border: ${({ theme, hasVideo }) =>
		hasVideo ? 'none' : `1px solid ${theme.colors.system.border.weak}`};
	background-color: 'none';
	pointer-events: ${({ isEditingThumbnail }) =>
		isEditingThumbnail ? 'none' : 'auto'};
`;

export const DetailsContainer = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	max-width: 488px;
	margin-left: auto;
	gap: ${({ theme }) => theme.spacing.large};
`;

export const TabContainer = styled.div`
	top: ${({ theme }) => theme.spacing.large};
	z-index: ${({ theme }) => theme.zIndex.base};
	background: ${({ theme }) => theme.colors.system.background.system};
`;

export const ButtonWrapper = styled.div<{ hideDeleteButton?: boolean }>`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr ${({ hideDeleteButton }) =>
			hideDeleteButton ? '0' : '1fr'};
	gap: ${({ theme, hideDeleteButton }) =>
		hideDeleteButton ? '0' : theme.spacing.medium};
	padding-top: ${({ theme }) => theme.spacing.medium};
`;
