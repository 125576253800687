/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
// import default tab component
import { SectionTab } from 'polotno/side-panel';

import { Icon } from '@common/design-system/components/atoms';
import { useCreativeEditorContext } from '../../providers/CreativeEditorProvider';

type SectionTabProps = {
	onClick: any;
	active: boolean;
};

const TemplateImport = {
	name: 'loadTemplate',
	Tab: ({ active, onClick }: SectionTabProps) => {
		return (
			<SectionTab name="Load Template" active={active} onClick={onClick}>
				<Icon
					iconName="folder-open"
					size="large"
					weight="fill"
					color={active ? 'primary.text.hover' : 'system.icon.default'}
				/>
			</SectionTab>
		);
	},
	// we need observer to update component automatically on any store changes
	Panel: observer(({ store }: { store: any }) => {
		const { handleLoadTemplate } = useCreativeEditorContext();

		const onLoadTemplate = (template: JSON) => {
			if (!!handleLoadTemplate) {
				handleLoadTemplate(template, store);
			} else {
				store.loadJSON(template);
			}
		};

		const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.[0];

			if (file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					const content = e.target?.result;
					if (typeof content === 'string') {
						onLoadTemplate(JSON.parse(content));
					}
				};
				reader.readAsText(file);
			}
		};
		return (
			<div className="w-100 d-flex flex-wrap gap-2">
				<input type="file" accept=".json" onChange={handleFileUpload} />
			</div>
		);
	}),
};

export default TemplateImport;
