import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '@app/utils/createFetchReducer';
import {
	createStoryTemplateTypes,
	editStoryTemplateTypes,
	filterTemplatesTypes,
	getTemplateOptionsTypes,
} from './action';
import { OptionsType } from '@common/components/interaction/DropdownInput/types';
import { normalize } from 'normalizr';
import entityNormalizer from '@app/utils/entityNormalizer';
import { ToastMessage } from '@app/constants';
import { CreatedTemplateErrorMessage } from './constants';
import { showToast } from '@common/design-system/components/molecules';
import { TemplateType } from '../../types/modules/campaign';

const initialState: GenericEntityState = {
	loading: false,
	error: {},
	response: {
		status: null,
		message: null,
	},
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
		count: null,
	},
	templates: {},
	templatesCount: 0,
	templateOptions: [],
	custom: {},
};

export type storyTemplateStateType = typeof initialState;

const template = entityNormalizer('templates');
const pagination = { templates: [template] };

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			templates,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ templates, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

export const getTemplateOptionsMapper = (
	state: storyTemplateStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	state.templateOptions = response.templates.map((item: TemplateType) => ({
		value: item,
		label: item.name,
	}));
	state.templateOptions.unshift({
		label: 'Default template',
		value: null,
	});
};

export const createStoryTemplateMapper = (
	state: storyTemplateStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	state.templateOptions.push({
		value: response.template,
		label: response.template.name,
	});
};

export const editStoryTemplateMapper = (
	state: storyTemplateStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	state.templateOptions = state.templateOptions.reduce(
		(list: OptionsType[], item: OptionsType) => {
			if (item?.value?._id === response.template._id) {
				item.value = response.template;
				item.label = response.template.name;
			}
			list.push(item);
			return list;
		},
		[],
	);
};

function createStoryErrorMapper(
	state: storyTemplateStateType,
	action: ResponseAction,
) {
	const { message } = action?.error?.response.data;

	if (message?.includes('is required')) {
		const backgroundImageRequired = message?.includes('`backgroundImage`');
		const logoRequired = message?.includes('`logo`');

		if (backgroundImageRequired && logoRequired) {
			showToast({
				message: ToastMessage.backgroundAndLogoRequired,
				type: 'error',
			});
		} else if (backgroundImageRequired) {
			showToast({ message: ToastMessage.backgroundRequired, type: 'error' });
		} else if (logoRequired) {
			showToast({ message: ToastMessage.logoRequired, type: 'error' });
		}
	} else if (message?.includes(CreatedTemplateErrorMessage)) {
		showToast({
			message: ToastMessage.duplicatedTemplateNameError,
			type: 'error',
		});
	} else {
		showToast({ message: ToastMessage.error, type: 'error' });
	}
}

function filterTemplatesMapper(
	state: storyTemplateStateType,
	action: ResponseAction,
) {
	const normalizedData = normalizeResponse(action);
	if (action.response.page === 1) {
		state.templates = normalizedData.entities.templates;
	} else {
		state.templates = {
			...state.templates,
			...normalizedData.entities.templates,
		};
	}
	state.templatesCount = Object.keys(state.templates || {})?.length;
	if (state.pagination) {
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
}

const storyTemplate = createSlice({
	name: 'storyTemplate',
	initialState,
	reducers: {
		CLEAR_TEMPLATES(state: storyTemplateStateType) {
			state.templates = {};
			state.templatesCount = 0;
		},
	},
	extraReducers: {
		...createFetchReducer(getTemplateOptionsTypes, getTemplateOptionsMapper),
		...createFetchReducer(editStoryTemplateTypes, editStoryTemplateMapper),
		...createFetchReducer(
			createStoryTemplateTypes,
			createStoryTemplateMapper,
			createStoryErrorMapper,
		),
		...createFetchReducer(filterTemplatesTypes, filterTemplatesMapper),
	},
});

export default storyTemplate.reducer;
