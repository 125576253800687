import { configureStore, combineReducers } from '@reduxjs/toolkit';

import contact from './contact';
import campaign from './campaign';
import recipe from './recipe';
import cta from './cta';
import story from './stories';
import approval from './approval';
import assigned from './assigned';
import reply from './reply';
import scheduled from './scheduled';
import sent from './sent';
import storyteller from './storyteller';
import storyTemplate from './storyTemplate';
import blackbaud from './blackbaud';
import sharedVideo from './sharedVideo';
import video from './video';
import tag from './tag';
import workspace from './workspace';
import auth from './auth';
import audience from './audience';
import automation from './automations';
import teams from './teams';
import inbox from './inbox';
import chatbot from './chatbot';
import groups from './groups';
import userTemplates from './userTemplates';
import activity from './activity';
import contactAttributes from './contactAttributes';

import fetchMiddleware from './fetch-middleware';
import overlays from './overlays';
import { useAppDispatch } from './types';

export const storeReducers = combineReducers({
	activity,
	approval,
	assigned,
	audience,
	auth,
	automation,
	blackbaud,
	campaign,
	chatbot,
	contact,
	contactAttributes,
	cta,
	groups,
	inbox,
	overlays,
	recipe,
	reply,
	scheduled,
	sent,
	sharedVideo,
	story,
	storyTemplate,
	storyteller,
	tag,
	teams,
	userTemplates,
	video,
	workspace,
});

const CLEAR_ALL_EXCEPT_AUTH = 'CLEAR_ALL_EXCEPT_AUTH';

const rootReducer = (state: any, action: any) => {
	if (action.type === CLEAR_ALL_EXCEPT_AUTH) {
		const copyAuthState = state.auth;
		state = {};
		state.auth = copyAuthState;
	}
	return storeReducers(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware1) =>
		getDefaultMiddleware1({
			serializableCheck: false,
			immutableCheck: false,
		}).concat(fetchMiddleware),
});

export default store;
export { useAppDispatch };

export const clearAllExceptAuth = () => {
	return {
		type: CLEAR_ALL_EXCEPT_AUTH,
	};
};
