import GoodkindDSTheme from '@common/design-system/global/theme';
import styled from 'styled-components';
import { ButtonVariantType } from './Button';
import { evalColorPath } from '@common/design-system/global/utils';

type MapType = {
	default: string;
	active?: string;
};

type ButtonMapsType = {
	primary: MapType;
	outlined: MapType;
	tertiary: MapType;
	success: MapType;
	danger: MapType;
	dangerWeak: MapType;
	ghost: MapType;
};
const getButtonSizeFontStyling = (theme: typeof GoodkindDSTheme, size: any) => {
	switch (size) {
		case 'xs':
		case 'medium':
		case 'small':
		case 'large':
			return `
                font-size: ${theme.fonts.sizes.small};
            `;
	}
};

const getButtonVariantColorStyling = (
	theme: typeof GoodkindDSTheme,
	variant: ButtonVariantType,
	active?: boolean,
	iconColor?: {
		default?: string;
		hover?: string;
		active?: string;
	},
) => {
	const colorMap: ButtonMapsType = {
		primary: { default: theme.colors.system.background.system },
		outlined: {
			default: theme.colors.system.text.default,
			active: theme.colors.primary.text.hover,
		},
		tertiary: { default: theme.colors.system.text.default },
		success: { default: theme.colors.system.background.system },
		danger: { default: theme.colors.system.background.system },
		dangerWeak: { default: theme.colors.error.text.hover },
		ghost: { default: theme.colors.system.text.default },
	};

	const backgroundColorMap: ButtonMapsType = {
		primary: { default: theme.colors.primary.background.strong },
		outlined: {
			default: theme.colors.system.background.system,
			active: theme.colors.primary.background.default,
		},
		tertiary: { default: theme.colors.neutral.background.default },
		success: { default: theme.colors.success.border.default },
		danger: { default: theme.colors.error.background.strong },
		dangerWeak: { default: theme.colors.error.background.default },
		ghost: { default: 'transparent' },
	};

	const borderMap: ButtonMapsType = {
		primary: { default: 'none' },
		outlined: {
			default: `1px solid ${theme.colors.system.border.medium}`,
			active: `1px solid ${theme.colors.primary.border.default}`,
		},
		tertiary: { default: 'none' },
		success: { default: 'none' },
		danger: { default: 'none' },
		dangerWeak: { default: `1px solid ${theme.colors.error.border.default}` },
		ghost: { default: 'none' },
	};

	const backgroundColorHoverMap: ButtonMapsType = {
		primary: { default: theme.colors.primary.background.strongHover },
		outlined: {
			default: theme.colors.system.background.weak,
			active: theme.colors.primary.background.defaultHover,
		},
		tertiary: { default: theme.colors.neutral.background.hover },
		success: { default: theme.colors.success.icon.default },
		danger: { default: theme.colors.error.background.strongHover },
		dangerWeak: { default: theme.colors.error.background.defaultHover },
		ghost: { default: theme.colors.neutral.background.default },
	};

	const backgroundColorDisabledMap: ButtonMapsType = {
		primary: { default: theme.colors.primary.background.strongDisabled },
		outlined: { default: theme.colors.system.background.weak },
		tertiary: { default: theme.colors.neutral.background.default },
		success: { default: theme.colors.success.border.weakHover },
		danger: { default: theme.colors.error.border.weak },
		dangerWeak: { default: theme.colors.system.background.weak },
		ghost: { default: 'initial' },
	};

	const colorDisabledMap: ButtonMapsType = {
		primary: { default: theme.colors.system.background.system },
		outlined: { default: theme.colors.system.text.weak },
		tertiary: { default: theme.colors.system.text.weak },
		success: { default: theme.colors.system.background.system },
		danger: { default: theme.colors.system.background.system },
		dangerWeak: { default: theme.colors.error.text.hover },
		ghost: { default: theme.colors.system.text.weak },
	};

	return `
                background-color: ${
									active
										? backgroundColorMap[variant].active ||
											backgroundColorMap[variant].default
										: backgroundColorMap[variant].default
								};
                color: ${
									active
										? colorMap[variant].active || colorMap[variant].default
										: colorMap[variant].default
								};
				border: ${
					active
						? borderMap[variant].active || borderMap[variant].default
						: borderMap[variant].default
				};

				svg {
					fill: ${
						active && iconColor?.active
							? evalColorPath(theme.colors, iconColor?.active)
							: iconColor?.default
								? evalColorPath(theme.colors, iconColor?.default)
								: undefined
					};
				}

                &:hover {
                	background-color: ${
										active
											? backgroundColorHoverMap[variant].active ||
												backgroundColorHoverMap[variant].default
											: backgroundColorHoverMap[variant].default
									};

									svg {
										fill: ${
											active && iconColor?.active
												? evalColorPath(theme.colors, iconColor?.active)
												: iconColor?.hover
													? evalColorPath(theme.colors, iconColor?.hover)
													: undefined
										}
									}
                }

				&:disabled {
                    background-color: ${
											backgroundColorDisabledMap[variant].default
										};
					color: ${colorDisabledMap[variant].default};

					svg {
						fill: ${colorDisabledMap[variant].default};
					}
				}
            `;
};

export const StyledButton = styled.button<{
	size: string;
	variant: ButtonVariantType;
	iconButton?: boolean;
	iconColor?: {
		default?: string;
		hover?: string;
		active?: string;
	};
	disabled: boolean;
	active?: boolean;
	isFullWidth?: boolean;
	minWidth?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: ${({ theme }) => theme.spacing.xs};
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	pointer-events: ${({ disabled }) => {
		return disabled ? 'none' : 'auto';
	}};
	line-height: ${({ theme }) => theme.fonts.lineHeight.regular};

	border: solid 2px transparent;

	border-radius: ${({ theme, size }) => {
		switch (size) {
			case 'small':
				return theme.shapeRadius.xs;
			case 'medium':
				return theme.shapeRadius.small;
			case 'large':
				return theme.shapeRadius.small;
			default:
				return theme.shapeRadius[6];
		}
	}};

	font-weight: ${({ theme }) => theme.fonts.weights.medium};

	height: ${({ size }) => {
		switch (size) {
			case 'xs':
				return '28px';
			case 'small':
				return '32px';
			case 'medium':
				return '36px';
			case 'large':
				return '40px';
			default:
				return '40px';
		}
	}};

	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};

	width: ${({ size, iconButton, isFullWidth }) => {
		if (isFullWidth) return '100%';

		if (!iconButton) return 'auto';

		switch (size) {
			case 'xs':
				return '28px';
			case 'small':
				return '32px';
			case 'medium':
				return '36px';
			case 'large':
				return '40px';
			default:
				return '40px';
		}
	}};

	padding: ${({ theme, size, iconButton }) => {
		if (iconButton) return '0px';

		switch (size) {
			case 'xs':
				return `0 ${theme.spacing.small}`;
			case 'small':
				return `0 ${theme.spacing.small}`;
			case 'medium':
				return `0 ${theme.spacing.medium}`;
			case 'large':
				return `0 ${theme.spacing.medium}`;
		}
	}};

	${({ theme, variant, active, iconColor }) =>
		getButtonVariantColorStyling(theme, variant, active, iconColor)}
	${({ theme, size }) => getButtonSizeFontStyling(theme, size)}
`;
