import React, { Fragment, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import {
	DragDropContainer,
	DraggableContainer,
} from '@common/design-system/components/layout';
import {
	Icon,
	Text,
	Checkbox,
	Divider,
	Avatar,
	Input,
	Loader,
	StatusIndicator,
} from '@common/design-system/components/atoms';

import * as S from './FloatingMenu.styles';
import useFloatingUi from '@app/hooks/useFloatingUi';
import { FloatingPortal, Placement, Strategy } from '@floating-ui/react';
import SystemMessage from '../SystemMessage/SystemMessage';
import { IconWeight } from '@phosphor-icons/react';
import { FontLineHeightsType } from '@common/design-system/global/types';
import { StatusIndicatorPropsType } from '@common/design-system/components/atoms/StatusIndicator/StatusIndicator';

export type MenuItemType = {
	id?: string;
	_id?: string;
	value?: any;
	icon?: string;
	iconWeight?: IconWeight;
	image?: string;
	text: string;
	type?: 'primary' | 'danger' | 'regular';
	onClick?: (item?: any, list?: any) => void;
	disabled?: boolean | ((item?: any, list?: any) => boolean);
	active?: boolean;
	ariaLabel?: string;
	description?: string;
	labelLineClamp?: boolean;
	status?: {
		color?: string;
		variant?: StatusIndicatorPropsType['variant'];
	};
};

export type MenuGroupsType = {
	menuTitle?: string;
	isCheckbox?: boolean;
	checkboxDirection?: 'left' | 'right';
	showCheckMarkOnActive?: boolean;
	showSeparator?: boolean;
	lineHeight?: FontLineHeightsType;
	menuItems: MenuItemType[];
};

type PropsType = {
	itemCallbackData?: any;
	trigger?: React.ReactNode | ((menuIsOpen: boolean) => React.ReactNode);
	popUpStrategy?: Strategy;
	popUpPlacement?: Placement;
	positionOffset?: number;
	disabled?: boolean;
	searchable?: boolean;
	onSearch?: (value: string) => void;
	searchValue?: string;
	loading?: boolean;
	menus: MenuGroupsType[];
	floatingMenuTitle?: string;
	noOptionsMessage?: {
		title?: string;
		description: string;
		icon: string;
		alt: string;
	};
	minHeight?: string;
	maxHeight?: string;
	minWidth?: string;
	maxWidth?: string;
	floatingConfig?: any;
	onMenuOpen?: (event?: any, reason?: string) => void;
	onMenuClose?: (event?: any, reason?: string) => void;
	initialOpen?: boolean;
	closeOnSelect?: boolean;
	preventFocusTrap?: boolean;
	isListSortable?: boolean;
	onSortedList?: (sortedList: any) => void;
};

function FloatingMenu({
	itemCallbackData,
	trigger,
	popUpPlacement,
	popUpStrategy,
	positionOffset = 0, // Position offset to be set on floating UI, this offset takes effect in all position directions
	disabled,
	searchable,
	onSearch,
	searchValue,
	loading,
	menus,
	floatingMenuTitle, // This is a title to show on top of menu above the search and lists
	noOptionsMessage,
	minHeight,
	minWidth,
	maxWidth,
	maxHeight,
	floatingConfig, //External Floating UI config for controlled cases - useFloatingUi
	onMenuOpen,
	onMenuClose,
	initialOpen = false,
	closeOnSelect = true,
	preventFocusTrap, // Prevent default behavior of focus trapping on the trigger and menu elements
	isListSortable = false,
	onSortedList,
}: PropsType) {
	const [internalSearch, setInternalSearch] = useState('');

	const {
		x: floatingX,
		y: floatingY,
		openingReference,
		floatingReference,
		strategy,
		internalRefs,
		middlewareData,
		getReferenceProps,
		getFloatingProps,
		isOpen,
		setIsOpen,
	} = floatingConfig ||
	useFloatingUi({
		preferredStrategy: popUpStrategy ?? 'absolute',
		preferredPlacement: popUpPlacement ?? 'right',
		clickDisabled: disabled,
		onMenuOpen: onMenuOpen,
		onMenuClose: onMenuClose,
		offset: positionOffset,
		initialOpen: initialOpen,
	});

	const handleClickMenuItem = (
		menuItem: any,
		onClick?: (item?: any, list?: any) => void,
	) => {
		if (onClick) {
			if (itemCallbackData) {
				onClick(itemCallbackData);
			} else {
				onClick(menuItem);
			}
		}

		if (closeOnSelect) {
			setIsOpen(false);

			if (onMenuClose) {
				onMenuClose();
			}
		}
	};

	const getColorPerType = ({
		type,
		active,
		isCheckbox,
	}: {
		type?: string;
		active?: boolean;
		isCheckbox?: boolean;
	}) => {
		if (active && !isCheckbox) {
			return 'primary.text.hover';
		}

		if (type === 'danger') {
			return 'error.icon.default';
		}
		if (type === 'primary') {
			return 'primary.text.default';
		}
		return 'system.text.default';
	};

	const getDisabled = (disabled?: boolean | ((item?: any) => boolean)) => {
		if (typeof disabled === 'function') {
			return disabled(itemCallbackData);
		}
		return disabled;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!searchable) return;

		if (!!onSearch) {
			onSearch(e.target.value);
		} else {
			setInternalSearch(e.target.value);
		}
	};
	const filteredMenus = useMemo(() => {
		if (!internalSearch) {
			return menus;
		}
		// Filter menus based on the internal search value
		return menus.map((menuGroup) => {
			const filteredMenuItems = menuGroup.menuItems.filter((menuItem) =>
				menuItem.text.toLowerCase().includes(internalSearch.toLowerCase()),
			);

			return {
				...menuGroup,
				menuItems: filteredMenuItems,
			};
		});
	}, [internalSearch, menus]);

	const hasMenus = filteredMenus.some((menu) => menu.menuItems.length > 0);

	const handleDragEnd = (result: DropResult, index: number) => {
		const { source, destination } = result;
		const items = filteredMenus[index]?.menuItems || [];
		if (!destination) return;
		// If the item is dropped back in the same position, return early
		if (source.index === destination.index) return;
		// Reorder the items array
		const updatedItems = Array.from(items);
		const [movedItem] = updatedItems.splice(source.index, 1);
		updatedItems.splice(destination.index, 0, movedItem);
		const filterMenuUpdated = [...filteredMenus];
		filterMenuUpdated[index].menuItems = updatedItems;
		onSortedList && onSortedList(filterMenuUpdated);
	};

	const List = ({
		isCheckbox,
		menuItem,
		menuGroup,
		color,
		showCheckMark,
		isListSortable,
		checkboxDirection = 'left',
	}: {
		isCheckbox: boolean;
		menuItem: MenuItemType;
		menuGroup: MenuGroupsType;
		color: string;
		showCheckMark: boolean;
		isListSortable?: boolean;
		checkboxDirection?: 'left' | 'right';
	}) => {
		return (
			<S.MenuItemListWrapper>
				{isListSortable && <Icon color={color} iconName="dotsSixVertical" />}

				{isCheckbox && checkboxDirection === 'left' && (
					<Checkbox
						ariaLabel={`checkbox-${menuItem?.text}`}
						checked={!!menuItem?.active}
						onChange={() => handleClickMenuItem(menuItem, menuItem.onClick)}
						disabled={getDisabled(menuItem?.disabled)}
					/>
				)}

				{(!!menuItem?.status?.color || !!menuItem?.status?.variant) && (
					<StatusIndicator
						color={menuItem?.status?.color}
						variant={menuItem?.status?.variant}
					/>
				)}

				{menuItem?.icon && !menuItem.image && (
					<S.IconContainer>
						<Icon
							iconName={menuItem.icon}
							weight={menuItem.iconWeight}
							color={color}
						/>
					</S.IconContainer>
				)}

				{menuItem?.image && !menuItem?.icon && (
					<Avatar
						size="xs"
						image={menuItem?.image === 'initials' ? '' : menuItem?.image}
						name={menuItem?.text}
					/>
				)}

				<div className="d-flex flex-column gap-2">
					<S.Title
						isEllipsis={menuItem?.labelLineClamp}
						color={color}
						size="small"
						lineHeight={menuGroup?.lineHeight || 'large'}
					>
						{menuItem?.text}
					</S.Title>

					{menuItem?.description && (
						<div>
							<S.Description
								size="small"
								color="system.text.medium"
								lineHeight="regular"
							>
								{menuItem?.description}
							</S.Description>
						</div>
					)}
				</div>

				{isCheckbox && checkboxDirection === 'right' && (
					<div className="ms-auto">
						<Checkbox
							ariaLabel={`checkbox-${menuItem?.text}`}
							checked={!!menuItem?.active}
							onChange={() => handleClickMenuItem(menuItem, menuItem.onClick)}
							disabled={getDisabled(menuItem?.disabled)}
						/>
					</div>
				)}

				{!isCheckbox && showCheckMark && (
					<span className="check-icon-wrapper">
						{menuItem?.active && <Icon iconName="check" color={color} />}
					</span>
				)}
			</S.MenuItemListWrapper>
		);
	};

	const MenuList = ({ isListSortable }: { isListSortable?: boolean }) => {
		return (
			<S.MenuListWrapper>
				{filteredMenus.map((menuGroup, menuGroupIndex) => {
					const isCheckbox = menuGroup?.isCheckbox || false;

					const checkboxDirection = menuGroup?.checkboxDirection || 'left';

					const showCheckMark =
						typeof menuGroup?.showCheckMarkOnActive === 'boolean'
							? menuGroup?.showCheckMarkOnActive
							: true;

					return (
						<Fragment key={`${menuGroupIndex}-menu-group-index`}>
							<div key={`${menuGroupIndex}-menu-item-index`}>
								{menuGroup?.menuTitle && (
									<S.ListTitleWrapper>
										<Text
											fontWeight="semiBold"
											size="xxs"
											letterSpacing="large"
											color="system.text.medium"
											lineHeight={menuGroup?.lineHeight || 'large'}
										>
											{menuGroup?.menuTitle.toUpperCase()}
										</Text>
									</S.ListTitleWrapper>
								)}

								{!isListSortable &&
									menuGroup?.menuItems.map((menuItem, menuItemIndex) => {
										const type = menuItem?.type || 'regular';
										const color = getColorPerType({
											type,
											active: menuItem?.active,
											isCheckbox,
										});
										return (
											<S.MenuItemWrapper
												role="button"
												tabIndex={0}
												title={menuItem?.text}
												aria-label={menuItem?.ariaLabel || 'menu item button'}
												key={`${menuGroupIndex}-${menuItemIndex}-menu-item-index`}
												disabled={getDisabled(menuItem?.disabled)}
												active={menuItem?.active}
												onMouseDown={(e: any) => {
													preventFocusTrap && e.preventDefault();
												}}
												onClick={(e) => {
													e?.stopPropagation();

													handleClickMenuItem(menuItem, menuItem.onClick);
												}}
											>
												<List
													isCheckbox={isCheckbox}
													menuItem={menuItem}
													menuGroup={menuGroup}
													color={color}
													showCheckMark={showCheckMark}
													checkboxDirection={checkboxDirection}
												/>
											</S.MenuItemWrapper>
										);
									})}

								{isListSortable && (
									<DragDropContainer
										droppableId="floatingMenuDrag"
										onDragEnd={(result) =>
											handleDragEnd(result, menuGroupIndex)
										}
									>
										{menuGroup?.menuItems.map((menuItem, menuItemIndex) => {
											const type = menuItem?.type || 'regular';
											const color = getColorPerType({
												type,
												active: menuItem?.active,
												isCheckbox,
											});
											return (
												<DraggableContainer
													key={`${menuGroupIndex}-${menuItemIndex}-menu-item-index`}
													draggableId={`${menuGroupIndex}-${menuItemIndex}-menu-item-index`}
													index={menuItemIndex}
													className="m-0"
												>
													<S.MenuItemWrapper
														role="button"
														tabIndex={0}
														title={menuItem?.text}
														aria-label={
															menuItem?.ariaLabel || 'menu item button'
														}
														key={`${menuGroupIndex}-${menuItemIndex}-menu-item-index`}
														disabled={getDisabled(menuItem?.disabled)}
														active={menuItem?.active}
														onMouseDown={(e: any) => {
															preventFocusTrap && e.preventDefault();
														}}
														onClick={(e) => {
															e?.stopPropagation();

															handleClickMenuItem(menuItem, menuItem.onClick);
														}}
													>
														<List
															isListSortable
															isCheckbox={isCheckbox}
															menuItem={menuItem}
															menuGroup={menuGroup}
															color={color}
															showCheckMark={showCheckMark}
															checkboxDirection={checkboxDirection}
														/>
													</S.MenuItemWrapper>
												</DraggableContainer>
											);
										})}
									</DragDropContainer>
								)}
							</div>

							{menuGroup?.showSeparator && <Divider />}
						</Fragment>
					);
				})}
			</S.MenuListWrapper>
		);
	};

	const MenuComponent = (
		<S.MenuWrapper
			minHeight={minHeight}
			minWidth={minWidth}
			maxHeight={maxHeight}
			maxWidth={maxWidth}
		>
			<>
				{!!searchable && (
					<>
						<S.SearchWrapper>
							<Input
								borderless
								placeholder="Search..."
								iconName="search"
								value={searchValue || internalSearch}
								onChange={handleChange}
								size="small"
							/>

							<Divider />
						</S.SearchWrapper>
					</>
				)}

				{!hasMenus && !loading && (
					<S.FeedbackWrapper>
						<SystemMessage
							title={noOptionsMessage?.title || ''}
							description={noOptionsMessage?.description || 'No results'}
							iconName={noOptionsMessage?.icon || 'search'}
							alt={noOptionsMessage?.alt || 'No results'}
						/>
					</S.FeedbackWrapper>
				)}

				{!!loading && (
					<S.LoaderWrapper>
						<Loader loading size="small" />
					</S.LoaderWrapper>
				)}

				{!loading && hasMenus && !isListSortable && <MenuList />}

				{!loading && hasMenus && isListSortable && (
					<MenuList isListSortable={isListSortable} />
				)}
			</>
		</S.MenuWrapper>
	);

	if (trigger) {
		return (
			<>
				<S.TriggerWrapper
					ref={openingReference}
					role="button"
					tabIndex={0}
					{...getReferenceProps()}
					onMouseDown={(e: any) => {
						preventFocusTrap && e.preventDefault();
					}}
					onClick={(e) => {
						if (disabled) return;

						e.stopPropagation();
						getReferenceProps().onClick(e);
					}}
				>
					{typeof trigger === 'function' ? trigger(isOpen) : <>{trigger}</>}
				</S.TriggerWrapper>
				<FloatingPortal>
					{isOpen && (
						<div
							ref={floatingReference}
							style={{
								position: strategy,
								top: floatingY ?? 0,
								left: floatingX ?? 0,
								visibility: middlewareData.hide?.referenceHidden
									? 'hidden'
									: 'visible',
							}}
							{...getFloatingProps()}
						>
							{MenuComponent}
						</div>
					)}
				</FloatingPortal>
			</>
		);
	}

	return <>{MenuComponent}</>;
}

export default FloatingMenu;
export type { PropsType as FloatingMenuPropsType };
