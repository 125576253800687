type IfProps = {
	condition: boolean;
	children: JSX.Element;
};

/**
 * @deprecated This component is deprecated. This can cause performance issues on react and DOM comparison.
 */
const If = ({ condition, children }: IfProps) => {
	if (condition) return children;
	return null;
};

export default If;
