import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/redux/store';
import { RootState } from '@app/redux/types';

import useDatadog from '@app/hooks/useDatadog';

import { WorkspaceIndicatorToast } from '@common/components/presentation/WorkspaceIndicatorToast';

import TokenStorage from '@app/services/Auth/TokenStorage';
import { checkShouldSetUser } from './utils';

import { PageWrapper } from './PrivateRoute.styles';
import { env } from '@app/env';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { PageRouteEnum } from '@app/constants/pages';
import { WorkspacePlansEnum } from '@app/constants/modules/workspace';
import {
	checkWorkspaceHasChatBotPlan,
	checkWorkspaceHasPostPlan,
	checkWorkspaceHasSMSPlan,
	checkWorkspaceHasVideoMessagingPlan,
} from '@app/utils/modules/workspace';
import { checkIsWorkspaceAdmin } from '@app/utils/modules/storytellers';
import { Loader } from '@common/design-system/components/atoms';

import * as S from './PrivateRoute.styles';
import { useAuth } from '@app/services/Auth/useAuth';
import authConfig from '@app/services/Auth/authConfig';
import LocalStorage from '@app/utils/LocalStorage';

interface PrivateRouteProps {
	children: ReactNode;
	adminRoute?: boolean;
	redirectTo?: string;
	workspacePlans?: WorkspacePlansEnum[];
	pageWrapperOverflowHidden?: boolean;
	setIsShowingNavBar: (isShowingNavBar: boolean) => void;
	noContainer?: boolean;
}

export default function PrivateRoute({
	children,
	adminRoute,
	redirectTo,
	workspacePlans,
	pageWrapperOverflowHidden,
	setIsShowingNavBar,
	noContainer = false,
}: PrivateRouteProps) {
	const dispatch = useAppDispatch();

	const { user: loggedInUser, isLoading, authProvider } = useAuth();

	const navigate = useNavigate();

	const location = useLocation();

	const { setDatadogUser } = useDatadog();

	const growthbook = useGrowthBook();

	const { workspaceInfo } = useSelector(({ auth, workspace }: RootState) => ({
		workspaceInfo: workspace?.workspaceInfo,
	}));

	const workspaceLoading = useSelector(
		({ workspace }: RootState) => workspace.loading,
	);

	const hasPostPlan = checkWorkspaceHasPostPlan(workspaceInfo);

	const hasSMSPlan = checkWorkspaceHasSMSPlan(workspaceInfo);

	const hasChatBotPlan = checkWorkspaceHasChatBotPlan(workspaceInfo);

	const hasVideoMessagingPlan =
		checkWorkspaceHasVideoMessagingPlan(workspaceInfo);

	const hasPlansToMatch = workspacePlans && !R.isEmpty(workspacePlans);

	const isAdmin = checkIsWorkspaceAdmin(loggedInUser);

	if (!isLoading && !authProvider?.isAuthenticated) {
		navigate('/', { state: { fromOther: true } });
	}

	//Redirect user based on plans
	useEffect(() => {
		if (hasPlansToMatch && !workspaceLoading && workspaceInfo._id) {
			if (
				(!hasSMSPlan && workspacePlans?.includes(WorkspacePlansEnum.SMS)) ||
				(!hasChatBotPlan &&
					workspacePlans?.includes(WorkspacePlansEnum.CHATBOT)) ||
				(!hasVideoMessagingPlan &&
					workspacePlans?.includes(WorkspacePlansEnum.VIDEO_MESSAGING)) ||
				(!hasPostPlan && workspacePlans?.includes(WorkspacePlansEnum.POST))
			) {
				navigate(redirectTo || '/');
			}
		}
	}, [
		hasPlansToMatch,
		hasSMSPlan,
		hasChatBotPlan,
		hasVideoMessagingPlan,
		workspacePlans,
		workspaceLoading,
		workspaceInfo,
	]);

	// Redirects unauthorized users
	useEffect(() => {
		if (
			authProvider?.isAuthenticated &&
			TokenStorage.getIdentity() &&
			loggedInUser
		) {
			if (adminRoute && !isAdmin) {
				navigate(redirectTo || '/');
			}
		}
	}, [authProvider?.isAuthenticated, adminRoute, isAdmin, loggedInUser]);

	// Identifies user to Intercom
	useEffect(() => {
		if (!loggedInUser?.id || !workspaceInfo?._id) return;

		if (loggedInUser) {
			(window as any).Intercom('boot', {
				app_id: env.INTERCOM_APP_ID,
				email: loggedInUser.email,
				created_at: loggedInUser.createdAt,
				name: loggedInUser.fullName,
				user_id: loggedInUser.id,
				workspace_name: workspaceInfo.name,
				organization_name: workspaceInfo.organizationName,
				workspace_id: workspaceInfo._id,
			});
		}
	}, [loggedInUser, workspaceInfo?._id]);

	// create ref to avoid rerendering calls & identify user on login
	const loginRef = useRef(false);

	// Identifies user to Datadog
	useEffect(() => {
		if (!loggedInUser?.id || !workspaceInfo?._id) return;

		// Saves workspace and user ids on storage to avoid rerendering calls
		const dataDogWorkspaceId =
			sessionStorage.getItem('dataDogWorkspaceId') || workspaceInfo?._id;
		const dataDogStorytellerId =
			sessionStorage.getItem('dataDogStorytellerId') || loggedInUser?.id;

		// Allows to set datadog user on workspace/user change
		const shouldIdentifyUser = checkShouldSetUser(
			dataDogWorkspaceId,
			dataDogStorytellerId,
			workspaceInfo._id,
			loggedInUser.id,
		);
		if (shouldIdentifyUser || !loginRef.current) {
			const workspaceId = workspaceInfo._id || workspaceInfo.id;
			const storytellerId = loggedInUser.id || loggedInUser._id;

			setDatadogUser();
			sessionStorage.setItem('dataDogWorkspaceId', workspaceId);
			sessionStorage.setItem('dataDogStorytellerId', storytellerId);

			growthbook?.setAttributes({
				workspaceId,
				storytellerId,
			});

			if (!loginRef.current) loginRef.current = true;
		}
	}, [workspaceInfo?._id, loggedInUser?.id]);

	// Pathname Listener for Navbar
	useEffect(() => {
		// Modifications while in Campaign builder
		const isCampaignBuilderRoute = location.pathname.includes(
			PageRouteEnum.campaignCreation,
		);

		const isAuthRoute =
			location.pathname.includes(PageRouteEnum.callback) ||
			location.pathname.includes(PageRouteEnum.changingWorkspace) ||
			location.pathname.includes(PageRouteEnum.changingWorkspaceFallback);

		const isRootRoute = location.pathname === '/';

		if (setIsShowingNavBar) {
			if (
				isCampaignBuilderRoute ||
				isAuthRoute ||
				isRootRoute ||
				!authProvider?.isAuthenticated
			) {
				setIsShowingNavBar(false);
			} else {
				setIsShowingNavBar(true);
			}
		}
	}, [location.pathname, authProvider?.isAuthenticated]);

	const hasIdentityToken = TokenStorage.getIdentity();

	const isLoggedIn = useMemo(() => {
		return authProvider?.isAuthenticated && hasIdentityToken;
	}, [authProvider?.isAuthenticated, hasIdentityToken]);

	useEffect(() => {
		if (!authProvider?.isLoading && !isLoggedIn) {
			LocalStorage.clear();
			authProvider?.logout({ returnTo: authConfig.logoutUrl } as any);
			navigate('/');
		}
	}, [authProvider?.isLoading, isLoggedIn]);

	useEffect(() => {
		const handleReloadOnWorkspaceChange = () => {
			//if the workspace has changed to the localStorage workspaceId then reload the page
			const workspaceId = localStorage.getItem('lastWorkspaceId');
			if (workspaceId !== workspaceInfo?.id) {
				window.location.reload();
			}
		};

		document.addEventListener(
			'visibilitychange',
			handleReloadOnWorkspaceChange,
		);

		return () => {
			document.removeEventListener(
				'visibilitychange',
				handleReloadOnWorkspaceChange,
			);
		};
	}, [workspaceInfo?.id]);

	// Show a loader when this HoC is still making the necessary checks based on the user's data
	if (!loggedInUser?.id || isLoading) {
		return (
			<S.LoaderContainer>
				<Loader />
			</S.LoaderContainer>
		);
	}

	if (noContainer) {
		return <>{children}</>;
	}

	return (
		<PageWrapper
			id="page-wrapper"
			pageWrapperOverflowHidden={pageWrapperOverflowHidden}
		>
			<WorkspaceIndicatorToast
				userEmail={loggedInUser?.email}
				organizationName={workspaceInfo?.organizationName}
			/>
			{children}
		</PageWrapper>
	);
}
