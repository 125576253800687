import styled from 'styled-components';

export const PageWrapper = styled.div<{ pageWrapperOverflowHidden?: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: 100vh;

	${({ pageWrapperOverflowHidden }) =>
		pageWrapperOverflowHidden && `overflow: hidden;`}
`;

export const LoaderContainer = styled.div`
	height: 100vh;
	width: calc(100vw - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 120px;
`;
