import styled, { DefaultTheme } from 'styled-components';
import { ToastPropsType } from './Toast';

const getBorderColor = (type: ToastPropsType['type'], theme: DefaultTheme) => {
	if (type === 'info') {
		return theme.colors.primary.border.default;
	}

	return theme.colors[type].border.default;
};

export const ToastWrapper = styled.div<{
	type: ToastPropsType['type'];
}>`
	position: relative;
	display: flex;
	align-items: flex-start;
	gap: ${({ theme }) => theme.spacing.small};
	padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	background: ${({ theme }) => theme.colors.system.background.system};
	border: 1px solid ${({ theme, type }) => getBorderColor(type, theme)};
	width: 100%;
`;

export const ToastIconWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const ToastContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const ToastTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
	white-space: pre-wrap;
`;
