import styled from 'styled-components';

export const StyledBottomActionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: calc(100% - 24px);
	column-gap: ${({ theme }) => theme.spacing.xs};
	position: absolute;
	bottom: 10px;
	left: 14px;
	z-index: ${({ theme }) => theme.zIndex.base};
`;

export const StyledBottomLeftActionsContainer = styled.div`
	display: flex;
	align-items: center;
`;
export const StyledBottomRightActionsContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.small};
`;

export const StyledEmojiPickerContainer = styled.div`
	position: relative;
	display: inline;
`;

export const StyledTextAreaContainer = styled.div<{
	hasBottomTools?: boolean;
	hasImage?: boolean;
}>`
	position: relative;
	height: 100%; /* Set height to 100% to fill the EditorWrapper */
	display: flex;
	flex-direction: column;

	.tagify--mix .tagify__input {
		min-height: 92px !important;
		padding-bottom: 52px;
		padding-top: ${({ theme }) => theme.spacing.small} !important;
		flex-grow: 1; /* Set 'flex-grow' to 1 to allow the textarea to grow and push the content upwards */

		padding-top: ${({ hasImage }) =>
			hasImage ? '118px !important' : 'initial'};
		position: ${({ hasImage }) =>
			hasImage ? 'relative !important' : 'initial'};
	}
`;

export const Image = styled.img`
	border-radius: 12px;
	height: 88px;
	width: auto;
`;

export const ImageContainer = styled.div`
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	padding: 16px;
	display: flex;
	column-gap: 16px;

	.close-button-wrapper {
		position: absolute;
		right: -6px;
		top: -6px;
	}
`;

export const DisplayModeWrapper = styled.div`
	width: 100%;
	position: relative;
	padding: ${({ theme }) => `${theme.spacing.small} 14px 56px 14px`};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	border: 1px solid ${({ theme }) => theme.colors.system.border.medium};
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	min-height: 80px;
`;

export const ContentWrapper = styled.div`
	padding: ${({ theme }) =>
		`${theme.spacing.xs} ${theme.spacing.xl} ${theme.spacing.xs} ${theme.spacing.xs}`};
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	border: 1px solid ${({ theme }) => theme.colors.system.border.medium};
	border-radius: ${({ theme }) => theme.shapeRadius[6]};
`;

export const CloseButtonWrapper = styled.div`
	position: absolute;
	right: ${({ theme }) => theme.spacing.medium};
	top: ${({ theme }) => theme.spacing.small};
`;
