import styled, { css } from 'styled-components';

export const ChipContainer = styled.div<{
	$clickable?: boolean;
	$deletable?: boolean;
	$bigChip?: boolean;
	$fullWidth?: boolean;
	$isActive?: boolean;
	$hasIcon?: boolean;
	$isDeletable?: boolean;
}>`
	display: inline-flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.xs};
	flex-wrap: wrap;
	background-color: ${({ theme, $isActive }) =>
		$isActive
			? theme.colors.system.background.xWeak
			: theme.colors.system.background.weak};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	padding-left: ${({ $hasIcon, theme }) =>
		$hasIcon ? '10px' : theme.spacing.xs};
	padding-right: ${({ $isDeletable, theme }) =>
		$isDeletable ? theme.spacing.xs : '10px'};
	height: ${({ $bigChip }) => ($bigChip ? '46px' : '32px')};
	border: ${({ theme, $isActive }) =>
		`1px solid ${$isActive ? theme.colors.primary.text.default : theme.colors.neutral.border.default}`};

	width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'max-content')};
	min-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'max-content')};
	flex: 0 0 max-content;
	transition: ${({ theme }) => theme.transitions.ease01};
	box-shadow: ${({ theme, $isActive }) =>
		$isActive ? theme.shadows.controls.selected : 'none'};

	${({ $clickable, $deletable, $isActive, theme }) =>
		($clickable || $deletable) &&
		css`
			cursor: pointer;
			&:hover {
				background-color: ${$isActive
					? theme.colors.system.background.xWeak
					: theme.colors.system.background.medium};
			}
		`}
`;

export const ChipTextsContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${({ theme }) => theme.spacing.xxs};
`;

export const ChipCloseContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	margin-left: auto;
`;
