import styled from 'styled-components';

import { TypographyProps } from '../types';

export const Typography = styled.text<TypographyProps>`
	font-family: ${({ theme }) => theme.fonts.default};
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: ${({ color, theme }) => color || theme.colors.dark[700]};
	text-align: ${({ position }) => position};
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	transition: ${({ theme }) => theme.transition?.typography};
	font-weight: ${({ fontWeight }) => fontWeight};
`;
