import React from 'react';

import { HeaderContainerProps } from './types';

import { HeaderWrapper } from './HeaderContainer.styles';

export default function HeaderContainer({
	children,
	className,
	dataTestId,
}: HeaderContainerProps) {
	return (
		<HeaderWrapper className={className} dataTestId={`${dataTestId}`}>
			{children}
		</HeaderWrapper>
	);
}
