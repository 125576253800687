import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@app/redux/types';

import { replaceMergeToBraceFormat } from '@common/components/interaction/TagsInput/utils';
import { Text } from '../../atoms';
import { TextProps } from '../../atoms/typography/Text/Text';

export type MergeTagsTextContainerProps = {
	mergeTagsText: string;
	children?: React.ReactNode;
} & Partial<TextProps>;

const MergeTagsTextContainer = forwardRef(function MergeTagsTextContainer(
	{ mergeTagsText, ...rest }: MergeTagsTextContainerProps,
	ref,
) {
	const { workspace } = useSelector(({ workspace }: RootState) => ({
		workspace: workspace.workspaceInfo,
	}));

	return (
		<Text ref={ref} {...rest}>
			{replaceMergeToBraceFormat(mergeTagsText, workspace)}
		</Text>
	);
});

export default MergeTagsTextContainer;
