import React from 'react';

import { handleIconByTextSize, handleVariantColor } from './utils';

import { HelpTooltip } from '@common/components/interaction/HelpTooltip';
import { LinkButtonProps } from './types';

import {
	LinkButtonContainer,
	LinkButtonIcon,
	LinkButtonText,
} from './LinkButton.styles';

export default function LinkButton({
	text,
	iconName,
	iconPosition = 'left',
	variant = 'primary',
	disabled = false,
	onClick,
	className,
	textSize = 'regular',
	dataTestId,
	tooltipProps,
}: LinkButtonProps) {
	return (
		<LinkButtonContainer
			tabIndex={0}
			disabled={disabled || false}
			variant={variant}
			className={className}
			size={textSize}
			dataTestId={`${dataTestId}-container`}
			onClick={(e) => !disabled && onClick(e)}
		>
			{iconName && iconPosition === 'left' && (
				<LinkButtonIcon
					color={handleVariantColor(variant)}
					iconName={iconName}
					size={handleIconByTextSize(textSize)}
					dataTestId={`${dataTestId}-icon`}
				/>
			)}

			<LinkButtonText
				size={textSize}
				color={handleVariantColor(variant)}
				dataTestId={`${dataTestId}-text`}
			>
				{text}
			</LinkButtonText>

			{iconName && iconPosition === 'right' && (
				<LinkButtonIcon
					color={handleVariantColor(variant)}
					size={handleIconByTextSize(textSize)}
					iconName={iconName}
					dataTestId={`${dataTestId}-icon`}
				/>
			)}

			{!!tooltipProps?.text && (
				<HelpTooltip
					tooltipProps={tooltipProps}
					color={handleVariantColor(variant)}
					size={handleIconByTextSize(textSize)}
				/>
			)}
		</LinkButtonContainer>
	);
}
