import * as R from 'ramda';

import {
	ContactImportIntegrationNameEnum,
	ContactImportIntegrationDisplayNameEnum,
	WorkspacePlansEnum,
} from '@app/constants/modules/workspace';
import {
	IntegrationListType,
	TemplateType,
	WorkspaceType,
} from '@app/redux/workspace/types';
import { convertToTimezone } from '@app/utils/convertToTimezone';
import { campaignTemplateTypeEnum } from '@app/constants/modules/campaign';

export function getAllWorkspaceTemplate(
	templates: TemplateType[],
	name: string,
) {
	return templates?.filter((item: any) => item?.name === name);
}

export function getWorkspaceTemplatesByType(
	templates: TemplateType[],
	type: campaignTemplateTypeEnum,
) {
	return templates?.filter((template) => template?.type === type);
}

export function getWorkspaceTemplate(templates: TemplateType[], name: string) {
	return templates?.find((item: any) => item?.name === name);
}

export function getSmsTemplate(templates: TemplateType[]) {
	return templates?.find((template) => template?.name === 'sharedVideoSms');
}

export function getEmailTemplate(templates: TemplateType[]): TemplateType {
	return templates?.find(
		(template) => template?.name === 'sharedVideoEmail',
	) as TemplateType;
}

export function getSubjectEmailTitle(templates: TemplateType[]) {
	return getEmailTemplate(templates)?.title;
}

export function checkIsWithinSendingWindow(
	sendingDays: number[],
	sendingHours: [number, number],
	workspaceTimezone: string,
) {
	const workspaceDate = convertToTimezone(new Date(), workspaceTimezone);

	const currentDay = workspaceDate.getDay();
	const currentHour = workspaceDate.getHours();

	if (!sendingDays?.includes(currentDay)) return false;

	return (
		currentHour >= Math.min(...sendingHours) &&
		currentHour < Math.max(...sendingHours)
	);
}

export function checkWhatsAppSetupCompleted(workspace: WorkspaceType) {
	if (!workspace) return false;

	const {
		whatsappSetupCompleted,
		twilioSubaccountAuthToken,
		twilioSubaccountSid,
	} = workspace;

	return (
		!!twilioSubaccountAuthToken &&
		!!twilioSubaccountSid &&
		!!whatsappSetupCompleted
	);
}

export function checkWhatsAppIsEnabled(workspace: WorkspaceType) {
	if (!workspace) return false;

	const { whatsappEnabled } = workspace;

	return !!whatsappEnabled;
}

export function getWorkspaceIntegrationName(
	workspace?: WorkspaceType,
): ContactImportIntegrationNameEnum {
	return (
		workspace?.hotglueSettings?.flows?.contactImport?.integrationName || ''
	);
}

export function getWorkspaceAvailableContactsLists(workspace: WorkspaceType) {
	return (
		workspace?.hotglueSettings?.flows?.contactImport?.availableContactsLists ||
		[]
	);
}

export function getWorkspaceIntegrationDisplayName(
	workspace?: WorkspaceType,
	currentIntegrationName?: ContactImportIntegrationNameEnum,
): ContactImportIntegrationDisplayNameEnum {
	const integrationName = currentIntegrationName
		? currentIntegrationName
		: getWorkspaceIntegrationName(workspace);

	if (integrationName === ContactImportIntegrationNameEnum.HUBSPOT) {
		return ContactImportIntegrationDisplayNameEnum.HUBSPOT;
	}

	if (integrationName === ContactImportIntegrationNameEnum.HUBSPOT_BETA) {
		return ContactImportIntegrationDisplayNameEnum.HUBSPOT;
	}

	if (integrationName === ContactImportIntegrationNameEnum.SFTP) {
		return ContactImportIntegrationDisplayNameEnum.SFTP;
	}

	if (integrationName === ContactImportIntegrationNameEnum.SALESFORCE) {
		return ContactImportIntegrationDisplayNameEnum.SALESFORCE;
	}

	if (integrationName === ContactImportIntegrationNameEnum.DYNAMICS) {
		return ContactImportIntegrationDisplayNameEnum.DYNAMICS;
	}
	if (integrationName === ContactImportIntegrationNameEnum.SALESFORCE_SANDBOX) {
		return ContactImportIntegrationDisplayNameEnum.SALESFORCE_SANDBOX;
	}
	if (integrationName === ContactImportIntegrationNameEnum.BLACKBAUD) {
		return ContactImportIntegrationDisplayNameEnum.BLACKBAUD;
	}
	if (integrationName === ContactImportIntegrationNameEnum.ELLUCIAN_RECRUIT) {
		return ContactImportIntegrationDisplayNameEnum.ELLUCIAN_RECRUIT;
	}
	return '' as ContactImportIntegrationDisplayNameEnum;
}

export function checkWorkspaceHasIntegration(workspace: WorkspaceType) {
	return !!workspace?.hotglueSettings?.flows?.contactImport;
}

export function checkIsSftpIntegration(workspace: WorkspaceType) {
	return (
		getWorkspaceIntegrationName(workspace) ===
		ContactImportIntegrationNameEnum.SFTP
	);
}

export function getCustomAttributesFormatType(
	workspace: WorkspaceType,
	attributeName: string,
) {
	return workspace?.customAttributes?.find(
		(attribute) => attribute.name === attributeName,
	)?.format;
}

export function getIntegrationContactListById(
	integrationContactLists: IntegrationListType[],
	integrationId?: string,
) {
	return integrationContactLists?.find(
		(contactList: IntegrationListType) => contactList.id === integrationId,
	);
}

export function getWorkspaceContactListById(
	workspace: WorkspaceType,
	contactListId?: string,
): IntegrationListType {
	const contactLists = getWorkspaceAvailableContactsLists(workspace);

	return getIntegrationContactListById(
		contactLists,
		contactListId,
	) as IntegrationListType;
}

export function getWorkspacePlans(workspace: WorkspaceType) {
	return workspace?.settings?.plans && !R.isEmpty(workspace?.settings?.plans)
		? workspace?.settings?.plans
		: [WorkspacePlansEnum.VIDEO_MESSAGING];
}

export function checkWorkspaceHasSMSPlan(workspace: WorkspaceType) {
	const workspacePlans = getWorkspacePlans(workspace);
	return workspacePlans.includes(WorkspacePlansEnum.SMS) || false;
}

export function checkWorkspaceHasVideoMessagingPlan(workspace: WorkspaceType) {
	const workspacePlans = getWorkspacePlans(workspace);

	return workspacePlans.includes(WorkspacePlansEnum.VIDEO_MESSAGING) || false;
}

export function checkWorkspaceHasReelsPlan(workspace: WorkspaceType) {
	const workspacePlans = getWorkspacePlans(workspace);

	return workspacePlans.includes(WorkspacePlansEnum.REELS) || false;
}

export function checkWorkspaceHasChatBotPlan(workspace: WorkspaceType) {
	const workspacePlans = getWorkspacePlans(workspace);

	return workspacePlans.includes(WorkspacePlansEnum.CHATBOT) || false;
}

export function checkWorkspaceHasPostPlan(workspace: WorkspaceType) {
	const workspacePlans = getWorkspacePlans(workspace);

	return workspacePlans.includes(WorkspacePlansEnum.POST) || false;
}

export function checkWorkspaceHasOnlySMSPlan(workspace: WorkspaceType) {
	const workspacePlans = getWorkspacePlans(workspace);

	return (
		!R.isEmpty(workspacePlans) &&
		workspacePlans.length === 1 &&
		checkWorkspaceHasSMSPlan(workspace)
	);
}

export function checkWorkspaceHasOnlySMSandChatbotPlans(
	workspace: WorkspaceType,
) {
	const workspacePlans = getWorkspacePlans(workspace);

	return (
		!R.isEmpty(workspacePlans) &&
		workspacePlans.length === 2 &&
		checkWorkspaceHasSMSPlan(workspace) &&
		checkWorkspaceHasChatBotPlan(workspace)
	);
}
