import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import DotLoaderAnimation from '@global/lottie/dot-loader.json';
import { AnimationContainer, ThreeDotsContainer } from './Loader.styles';

export type LoaderProps = {
	loading?: boolean;
	color?: string;
	size?: 'xs' | 'small' | 'medium' | 'large';
	className?: string;
};

function Loader({
	loading = true,
	size = 'medium',
	color = 'neutral.icon.default',
	className,
}: LoaderProps) {
	const animationData = DotLoaderAnimation;

	const animationOptions: any = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMaxYMax slice',
			// I had to slice it because it had a big padding on both animations
			viewBoxSize: '200 200 200 200',
		},
	};

	const width = {
		xs: '35px',
		small: '50px',
		medium: '75px',
		large: '100px',
	}[size];

	const height = {
		xs: '6px',
		small: '50px',
		medium: '75px',
		large: '100px',
	}[size];

	useEffect(() => {
		// uncomment this to track rerenders and check optimizations (campaign is awful and rereders this 125 times)
	}, []);

	return (
		<AnimationContainer
			isLoading={loading || false}
			width={width}
			height={height}
			color={color}
			className={className}
		>
			<ThreeDotsContainer>
				<Lottie options={animationOptions} />
			</ThreeDotsContainer>
		</AnimationContainer>
	);
}

export default React.memo(Loader);
