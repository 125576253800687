import * as Yup from 'yup';

export const VideoDetailsFormValidationSchema = Yup.object().shape({
	captions: Yup.array().nullable(),
	captionsToSave: Yup.string().nullable(),
	changedCues: Yup.array().nullable(),
	favorite: Yup.boolean().nullable(),
	hideCaptions: Yup.boolean().nullable(),
	uploadedBy: Yup.mixed().nullable(),
});
