import React from 'react';
import * as S from './BoxWrapper.styles';

type PropsType = {
	children: React.ReactNode;
	[key: string]: any;
};

function BoxWrapper({ children, ...rest }: PropsType) {
	return <S.BoxWrapper {...rest}>{children}</S.BoxWrapper>;
}

export default BoxWrapper;
