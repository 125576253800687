import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, orderTypes, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import axios from '@global/axios';
import { FilterOptions } from '@app/types';
import { TeamStorytellerType, TeamType } from '@app/types/modules/teams';

const mainType = 'TEAMS';

export const getTeamsTypes = createFetchTypes(`${mainType}_GET_TEAMS`);

function getTeams(FilterOptions?: FilterOptions, custom?: string): fetchAction {
	const defaultFilterOptions = {
		pagination: {
			page: 1,
			pageSize: 50,
		},
		order: { field: 'createdAt', type: orderTypes.ascending },
	};

	const filterString = JSON.stringify({
		...defaultFilterOptions,
		...FilterOptions,
	});

	return {
		type: getTeamsTypes,
		callAPI: () => axios.get(`${apiUrl}/teams?filter=${filterString}`),
		payload: {
			custom,
			errorMessage: ToastMessage.error,
		},
	};
}

export const getTeamsByStorytellerTypes = createFetchTypes(
	`${mainType}_GET_TEAMS_BY_STORYTELLER`,
);

function getTeamsByStoryteller(storytellerId: string): fetchAction {
	return {
		type: getTeamsByStorytellerTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers/${storytellerId}/teams`),
		payload: {
			custom: 'teamsByStoryteller',
			errorMessage: ToastMessage.error,
			storytellerId,
		},
	};
}

export const getTeamByIdTypes = createFetchTypes(`${mainType}_GET_TEAM_BY_ID`);

function getTeamById(teamId: string): fetchAction {
	return {
		type: getTeamByIdTypes,
		callAPI: () => axios.get(`${apiUrl}/teams/${teamId}`),
		payload: {
			errorMessage: ToastMessage.error,
		},
	};
}

export const createTeamTypes = createFetchTypes(`${mainType}_CREATE_TEAM`);

function createTeam(
	teamObject: {
		name: string;
		email: string;
		icon: string;
		storytellers: TeamStorytellerType[] | null;
		phoneNumber: string | null;
	},
	showToast = true,
) {
	return {
		type: createTeamTypes,
		callAPI: () => axios.post(`${apiUrl}/teams`, teamObject),
		payload: {
			successMessage: showToast && ToastMessage.teamCreateSuccess,
		},
	};
}

export const deleteTeamTypes = createFetchTypes(`${mainType}_CREATE_TEAM`);

function deleteTeam(teamId: string) {
	return {
		type: deleteTeamTypes,
		callAPI: () => axios.delete(`${apiUrl}/teams/soft/${teamId}`),
		payload: {
			successMessage: ToastMessage.teamDeleteSuccess,
			errorMessage: ToastMessage.teamDeleteError,
		},
	};
}
export const updateTeamTypes = createFetchTypes(`${mainType}_UPDATE_TEAM`);

function updateTeam(
	teamId: string,
	teamObject: Partial<TeamType>,
	showToast = true,
) {
	return {
		type: updateTeamTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/teams/${teamId}`, {
				team: teamObject,
			}),
		payload: {
			successMessage: showToast && ToastMessage.teamUpdateSuccess,
			errorMessage: showToast && ToastMessage.teamUpdateError,
		},
	};
}

export const updateStorytellerTeamsTypes = createFetchTypes(
	`${mainType}_UPDATE_STORYTELLER_TEAMS`,
);

function updateStorytellerTeams(
	storytellerId: string,
	teams: {
		teamId: string;
		role: TeamStorytellerType['role'];
		notifications: TeamStorytellerType['notifications'];
	}[],
) {
	const payload = {
		storytellerId,
		teams,
	};
	return {
		type: updateStorytellerTeamsTypes,
		callAPI: () => axios.patch(`${apiUrl}/teams/storyteller`, payload),
		payload: {
			custom: 'updateStorytellerTeams',
			successMessage: ToastMessage.storytellerTeamsUpdateSuccess,
			errorMessage: ToastMessage.storytellerTeamsUpdateError,
			storytellerId,
		},
	};
}
export const addStorytellerToTeamTypes = createFetchTypes(
	`${mainType}_ADD_STORYTELLER_TO_TEAM`,
);

function addStorytellerToTeam(
	requestData: {
		team: string;
		storyteller: string;
		teamRole: string;
	},
	showToast = true,
) {
	return {
		type: addStorytellerToTeamTypes,
		callAPI: () => axios.post(`${apiUrl}/teams/storyteller`, requestData),
		payload: {
			custom: 'addStorytellersToTeam',
			successMessage: showToast && ToastMessage.success,
			errorMessage: showToast && ToastMessage.error,
			storytellerId: requestData?.storyteller,
		},
	};
}

export const removeStorytellersFromTeamTypes = createFetchTypes(
	`${mainType}_REMOVE_STORYTELLERS_FROM_TEAM`,
);

function removeStorytellersFromTeam(
	requestData: {
		team: string;
		storytellers: string[];
	},
	showToast = true,
) {
	return {
		type: removeStorytellersFromTeamTypes,
		callAPI: () =>
			axios.delete(`${apiUrl}/teams/storytellers`, { data: requestData }),
		payload: {
			custom: 'removeStorytellersFromTeam',
			successMessage: showToast && ToastMessage.storytellerTeamsDeleteSuccess,
			errorMessage: showToast && ToastMessage.storytellerTeamsDeleteError,
			storytellersId: requestData?.storytellers,
		},
	};
}

export const removeAudienceFromTeamTypes = createFetchTypes(
	`${mainType}_REMOVE_AUDIENCE_FROM_TEAM`,
);

function removeAudienceFromTeam({
	teamId,
	audienceId,
	showToast = true,
}: {
	teamId: string;
	audienceId: string;
	showToast?: boolean;
}) {
	return {
		type: removeAudienceFromTeamTypes,
		callAPI: () => axios.delete(`${apiUrl}/teams/${teamId}/${audienceId}`),
		payload: {
			custom: 'removeAudienceFromTeam',
			successMessage: showToast && ToastMessage.teamUpdateSuccess,
			errorMessage: showToast && ToastMessage.teamUpdateError,
		},
	};
}

function clearTeams() {
	return {
		type: `teams/RESET_TEAMS`,
	};
}

export const getPhoneNumbersType = createFetchTypes(
	`${mainType}_GET_PHONE_NUMBERS`,
);

function getPhoneNumbers(FilterOptions?: FilterOptions) {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getPhoneNumbersType,
		callAPI: () =>
			axios.get(
				`${apiUrl}/phoneNumbers/${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			custom: 'getPhoneNumbers',
		},
	};
}

export const assignPhoneToTeamType = createFetchTypes(
	`${mainType}_ASSIGN_PHONE_NUMBER_TO_TEAM`,
);

function assignPhoneToTeam({
	phoneId,
	teamId,
	showToast = true,
}: {
	phoneId: string;
	teamId: string;
	showToast?: boolean;
}) {
	return {
		type: assignPhoneToTeamType,
		callAPI: () =>
			axios.patch(`${apiUrl}/phoneNumbers/assignToTeam/${phoneId}/${teamId}`),
		payload: {
			custom: 'assignPhoneToTeam',
			successMessage: showToast && ToastMessage.teamUpdateSuccess,
			errorMessage: showToast && ToastMessage.teamUpdateError,
		},
	};
}

function cleanTeamInDetail() {
	return {
		type: 'teams/CLEAN_TEAM_IN_DETAIL',
	};
}

function updateTeamUnreadCount(teamId: string, unreads: number) {
	return {
		type: 'teams/UPDATE_TEAM_UNREAD_COUNT',
		payload: {
			teamId,
			unreads,
		},
	};
}

function updateTotalUnreadsCount(unreads: number) {
	return {
		type: 'teams/UPDATE_TOTAL_UNREADS_COUNT',
		payload: unreads,
	};
}

export {
	getTeams,
	createTeam,
	deleteTeam,
	getTeamById,
	updateTeam,
	updateStorytellerTeams,
	getTeamsByStoryteller,
	addStorytellerToTeam,
	removeStorytellersFromTeam,
	clearTeams,
	removeAudienceFromTeam,
	getPhoneNumbers,
	assignPhoneToTeam,
	cleanTeamInDetail,
	updateTeamUnreadCount,
	updateTotalUnreadsCount,
};
