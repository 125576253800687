export enum NavBarDimension {
	MAX_WIDTH = 224,
	MIN_WIDTH = 72,
}

export enum NavLinkText {
	HOME = 'Home',
	INBOX = 'Inboxes',
	ACTIVITY = 'Activity',
	CAMPAIGNS = 'Campaigns',
	VIDEOS = 'Videos',
	AUDIENCES = 'Audiences',
	ACTIONS = 'Actions',
	SETTINGS = 'Settings',
	TEAMS = 'Teams',
	RECORDERS = 'Recorders',
	REPORT_A_BUG = 'Report a bug',
	SUCCESS_PLAN = 'Success Plan',
	HELP = 'Help',
	CHATBOT = 'Chatbot',
}

export enum NavLinkIconName {
	HOME = 'house',
	INBOX = 'paper-plane-tilt',
	ACTIVITY = 'pulse',
	CAMPAIGNS = 'campaign',
	VIDEOS = 'videos',
	AUDIENCES = 'audiences',
	AUDIENCE = 'audience',
	ACTIONS = 'action',
	SETTINGS = 'settings',
	TEAMS = 'users',
	RECORDERS = 'userFocus',
	SUCCESS_PLAN = 'shooting-start',
	HELP = 'help',
	REPORT_A_BUG = 'bug',
	CHATBOT_ACTIVE = 'aibot',
	CHATBOT_INACTIVE = 'aibotinactive',
}

export enum NavBarState {
	OPEN = 'open',
	CLOSED = 'closed',
}
