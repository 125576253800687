import styled from 'styled-components';

export const Wrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	width: 100%;
	padding: ${({ theme }) => theme.spacing.large};
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	height: 100%;
	section {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xxs};
	}

	.title-wrapper,
	.subtitle-wrapper {
		display: flex;
		align-items: center;
		gap: ${({ theme }) => theme.spacing.xxs};
	}
`;
