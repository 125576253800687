import styled from 'styled-components';

type TagStyledProps = {
	isActive?: boolean;
	margin?: string;
	onClick?: any;
	isLoading?: boolean;
};

export const Tags = styled.div<TagStyledProps>`
	position: relative;
	overflow: hidden;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: ${({ isActive, theme }) =>
		isActive ? `2px solid ${theme.colors.primary[700]}` : '1px solid#e4e4e4'};
	border-radius: 5px;
	padding: 0 12px;
	height: 40px;
	min-width: ${({ isLoading }) => (isLoading ? '100px' : '65px')};
	margin-bottom: 10px;
	margin-right: 5px;
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	margin: ${({ margin }) => margin && margin};

	&:hover {
		background: ${({ onClick }) => onClick && 'rgb(235,235,235, 0.8)'};
	}
`;

export const TagText = styled.span`
	font-size: 13px !important;
	font-family: ${({ theme }) => theme.fonts.default};
	line-height: 0;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.neutral[400]};
`;
