import {
	GetVideoStateInitialValueType,
	VideoStateActionType,
	VideoStateType,
} from './types';

export enum VideoStateEnum {
	captionOn = 'captionOn',
	isMuted = 'isMuted',
	isPlaying = 'isPlaying',
	isBuffering = 'isBuffering',
}

export enum VideoStateActionTypeNameEnum {
	editState = 'editState',
}

export const handleVideoStateReducer = (
	state: VideoStateType,
	action: VideoStateActionType,
) => {
	switch (action.type) {
		case VideoStateActionTypeNameEnum.editState:
			return {
				...state,
				[action.payload.stateName]: action.payload.stateValue,
			};
		default:
			return state;
	}
};

export const getVideoStateInitialValue = ({
	isMuted,
}: GetVideoStateInitialValueType) => {
	const videoState = {
		captionOn: true,
		isMuted: isMuted,
		isPlaying: true,
		isBuffering: false,
	};

	return videoState;
};
