import React from 'react';
import * as S from './NavOption.styles';
import { Icon, Text } from '@common/design-system/components/atoms';
import { FontWeightsType } from '@common/design-system/global/types';

type NavOptionPropsType = {
	focused?: boolean;
	disabled?: boolean;
	text: string;
	textColor?: string;
	textWeight?: FontWeightsType;
	icon?: string;
	iconColor?: string;
	rightIcon?: string;
	onClick?: () => void;
};
function NavOption({
	focused,
	disabled,
	text,
	textColor,
	textWeight = 'regular',
	icon,
	iconColor,
	onClick,
}: NavOptionPropsType) {
	return (
		<S.OptionWrapper
			aria-hidden="true"
			role="button"
			tabIndex={0}
			onKeyDown={(e) => {
				if (e.key === 'Enter') onClick?.();
			}}
			onClick={onClick}
			focused={focused}
			disabled={disabled}
		>
			<S.NavOptions>
				<S.OptionDetails>
					{!!icon && (
						<Icon
							iconName={icon}
							color={iconColor || 'neutral.icon.default'}
							size="medium"
						/>
					)}

					<div className="text-wrapper">
						<Text
							size="small"
							color={textColor || 'system.text.default'}
							fontWeight={textWeight}
							lineHeight="regular"
						>
							{text}
						</Text>
					</div>
				</S.OptionDetails>
			</S.NavOptions>
		</S.OptionWrapper>
	);
}

export default NavOption;
