import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import { FilterOptions } from '@app/types/index';
import axios from '@global/axios';
import { EditStoryOptionsTypes } from '../stories/types';
import { storyStatus } from '../stories/constants';
import { CaptionType } from '@common/components/interaction/CaptionEditor/types';

const mainType = 'APPROVAL';

export const getApprovalsTypes = createFetchTypes(`${mainType}_GET_APPROVALS`);

export const editStoriesTypes = createFetchTypes(`${mainType}_EDIT_STORIES`);

export const bulkApproveTypes = createFetchTypes(`${mainType}_BULK_APPROVE`);
export const bulkDeleteTypes = createFetchTypes(`${mainType}_BULK_DELETE`);
export const bulkRejectTypes = createFetchTypes(`${mainType}_BULK_REJECT`);
export const getSingleStoryTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_STORY`,
);
function getSingleStory(storyId: string, FilterOptions?: FilterOptions) {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getSingleStoryTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories/${storyId}${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			custom: 'getSingleStory',
			errorMessage: ToastMessage.errorStoryNotFound,
		},
	};
}

function getApprovals(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify({
		...FilterOptions,
		filter: [
			...(FilterOptions.filter ?? []),
			{ field: 'status', value: storyStatus.pendingApproval },
		],
	});

	return {
		type: getApprovalsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'getApprovals',
		},
	};
}

export const updateApprovalVideoCaptionsTypes = `approval/UPDATE_VIDEO_CAPTIONS`;

function updateApprovalVideoCaptions(
	approvalVideoId: string,
	captions: CaptionType[],
) {
	return {
		type: updateApprovalVideoCaptionsTypes,
		payload: {
			approvalVideoId,
			captions,
		},
	};
}

export const updateApprovalVideoFieldTypes = `approval/UPDATE_VIDEO_FIELD`;

function updateApprovalVideoField(
	approvalId: string,
	field: string,
	value: any,
) {
	return {
		type: updateApprovalVideoFieldTypes,
		payload: {
			approvalId,
			field,
			value,
		},
	};
}

function editStories(EditOptions: EditStoryOptionsTypes): fetchAction {
	return {
		type: editStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/edit`, {
				...EditOptions,
			}),
		payload: {
			custom: 'editStories',
			successMessage: ToastMessage.editedAssignedStory,
			errorMessage: ToastMessage.error,
		},
	};
}

function bulkApprove(stories: string[]) {
	return {
		type: bulkApproveTypes,
		callAPI: () => axios.patch(`${apiUrl}/stories/bulk/approval`, { stories }),
		payload: {
			custom: 'bulkAction',
			stories,
			successMessage: ToastMessage.success,
			errorMessage: ToastMessage.error,
		},
	};
}

function bulkDelete(storiesId: string[]) {
	return {
		type: bulkDeleteTypes,
		callAPI: () =>
			axios.delete(`${apiUrl}/stories/bulk`, { data: { storiesId } }),
		payload: {
			custom: 'bulkAction',
			storiesId,
			successMessage:
				storiesId.length > 1
					? ToastMessage.severalApprovalStoriesDeleted
					: ToastMessage.singleApprovalStoryDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

function bulkReject(stories: string[], rejectionReason: string) {
	return {
		type: bulkRejectTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/reject`, {
				stories,
				rejectionReason,
			}),
		payload: {
			custom: 'bulkAction',
			stories,
			successMessage: ToastMessage.storyRejected,
			errorMessage: ToastMessage.error,
		},
	};
}

export const clearApprovalsTypes = 'approval/CLEAR_APPROVALS';
function clearApprovals() {
	return {
		type: clearApprovalsTypes,
	};
}

function clearSingleStory() {
	return {
		type: 'approval/CLEAR_SINGLE_STORY',
	};
}

export {
	editStories,
	getApprovals,
	bulkApprove,
	bulkDelete,
	bulkReject,
	getSingleStory,
	updateApprovalVideoCaptions,
	updateApprovalVideoField,
	clearApprovals,
	clearSingleStory,
};
