import styled from 'styled-components';
import { LoaderProps } from './types';

export const AnimationContainer = styled.div<LoaderProps>`
	opacity: ${(props) => (props.isLoading ? 1 : 0)};
	position: ${(props) => (props.isLoading ? 'static' : 'absolute')};
	left: ${(props) => (props.isLoading ? 'auto' : '-9999px')};
	width: ${({ width }) => width};
	height: auto;
	transition: opacity ${({ theme }) => theme.transition?.short};
	path {
		${({ color }) => color && `fill: ${color};`}
		display:${(props) => (props.isLoading ? 'block' : 'none')};
	}
`;

export const ThreeDotsContainer = styled.div<{ width: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: hidden;
	height: ${({ width }) => width};
`;
