/** The keys from this object must be the same as the paths used in
 * the nested routes. Check the CampaignCreation file.
 */
export const BuildTabName = {
	'video-page': 'Video Page*',
	widget: 'Widget*',
};

export enum BuildHeader {
	title = 'Build your campaign',
	text = 'Make them your own and improve conversion rates at each step.',
	collectVideosText = 'Add a personal touch before launching.',
}

export const TemplateValidatorMessages = {
	templateNameRequired: 'Template name is required.',
	primaryColorRequired: 'Buttons color is required.',
	primaryColorInvalid:
		'The buttons color does not exist. Please select a valid hexadecimal color.',
	secondaryColorRequired: 'Background color is required.',
	secondaryColorInvalid:
		'The background color does not exist. Please select a valid hexadecimal color.',
};

export const TemplateCampaignValidatorMessages = {
	widgetDomainRequired: 'Site is required.',
	widgetPathRequired: 'Route is required.',
	widgetPathInvalid:
		'Route is invalid, must start with "/", spaces are not allowed. e.g. "/home".',
};

export const CTAValidatorMessages = {
	internalActionNameRequired: 'Internal action name is required.',
	internalRepliesNameRequired: 'Internal replies name is required.',
	primaryLinkTitleRequired: 'Primary button title is required.',
	primaryLinkUrlRequired: 'Primary button URL is required.',
	primaryLinkUrlInvalid: 'Primary button URL is not a valid URL.',
	secondaryLinkTitleRequired: 'Secondary button title is required.',
	secondaryLinkUrlRequired: 'Secondary button URL is required.',
	secondaryLinkUrlInvalid: 'Secondary button URL is not a valid URL.',
	buttonTitleRequired: 'Button title is required.',
	eventTitleRequired: 'Event title is required.',
	eventDateRequired: 'Event date is required.',
	linkUrlRequired: 'Button URL is required.',
	linkUrlInvalid: 'Please ensure URL is valid including starting with https://',
};

export enum TemplateProviderStates {
	DEFAULT = 'default',
	NEW = 'new',
	SAVED = 'saved',
}

export enum TemplateProviderFieldsSetter {
	TEMPLATE = 'template',
	OVERLAY = 'overlay',
	PLACEMENT = 'placement',
}

export enum TemplateProviderFields {
	HEADER_TEXT = 'headerText',
	BODY_TEXT = 'bodyText',
	PLAY_BUTTON_TEXT = 'playButtonText',
	END_HEADER_TEXT = 'endHeaderText',
	BORDER_RADIUS = 'borderRadius',
	NAME = 'name',
	POSITION = 'position',
	PRIMARY_COLOR = 'primaryColor',
	SECONDARY_COLOR = 'secondaryColor',
	BACKGROUND_IMAGE = 'backgroundImage',
	THUMBNAIL_IMAGE = 'thumbnailImage',
	SNAPCHAT_BACKGROUND_IMAGE = 'snapchatBackgroundImage',
	LOGO = 'logo',
	BUTTON_SIZE = 'buttonSize',
}

export enum TemplateButtonSizesEnum {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

export enum WidgetTemplatePositionEnum {
	BOTTOM_LEFT = 'bottom-left',
	BOTTOM_RIGHT = 'bottom-right',
}

export enum TemplateFieldsValidatorTypeEnum {
	ALL_VIDEO_PAGE = 'all-video-page',
	ALL_WIDGET = 'all-widget',
	WIDGET = 'widget',
}

export const TemplateGenericTextValues = {
	headerText: '{{{storyteller.firstName}}} has a message for you',
	playButtonText: 'Watch video',
	endHeaderText: 'Thanks for watching.',
};

export const TabAlerts = {
	KEEP_EMAIL_SMS_DEFAULT:
		'Do you want to continue using this workspace’s default email and SMS templates?',
	KEEP_EMAIL_DEFAULT:
		'Do you want to continue using this workspace’s default email template?',
	KEEP_SMS_DEFAULT:
		'Do you want to continue using this workspace’s default SMS template?',
};

export enum ScreenDimensionsEnum {
	MOBILE = 'mobile',
	DESKTOP = 'desktop',
}

export const ACCORDION_NAME = {
	LOGO: 'Logo',
	VIDEO_TEXT: 'Video page text',
	WIDGET_TEMPLATE: 'Widget template',
	CAMPAIGN_PLACEMENT: 'Campaign placement*',
	BACKGROUND: 'Background',
	THUMBNAIL_IMAGE: 'Reel Thumbnail',
	LOGO_BACKGROUND: 'Logo & background',
	VIDEO_BACKGROUND: 'Video background', // TODO: Remove this when builder v2 is launched
	COLORS_BORDERS: 'Buttons, colors & borders',
	OVERLAYS: 'Overlays ✨', // TODO: Remove this when builder v2 is launched
	AUGMENTED_REALITY_FILTERS: 'Augmented reality filters',
	DYNAMIC_BACKGROUND: 'Dynamic background',
};

export const LABELS = {
	headerText: 'Preview stage title',
	headerText2: 'Play video message',
	bodyText: 'Preview stage description',
	playButtonText: 'Preview stage button text',
	playButtonText2: 'Play video button text',
	endHeaderText: 'End stage message',
	site: 'Site',
	route: 'Route',
};

export const TEXT = {
	CONTENT: {
		PLAY_STATUS: 'Play',
		PREVIEW_STATUS: 'Preview',
		END_STATUS: 'End',
		POSITION: 'Position',
		RESPONSIVENESS: 'Responsiveness',
		NEXT_STATE: 'Set up next state',
		CONTENT_DESCRIPTION:
			"Customize the video's look before, during and after play.",
		OVERLAYS_DESCRIPTION:
			'Add an Overlay to your videos to create a consistent brand look and to add visual flair.',
		PLACEMENT_DESCRIPTION:
			'Set the website, route and positioning where this campaign will show. You can add websites domains',
		PLACEMENT_ROUTE_DESCRIPTION: 'e.g: /home, /my-cool-path',
	},
	CTA: 'Call to action*',
	TEMPLATE_HEADING: 'Select a template',
	COLORS: {
		BUTTONS: 'Buttons',
		BACKGROUND: 'Background',
	},
	ALERT: {
		TEMPLATE_EDITED:
			'Editing this template will also update it for ongoing campaigns with the same template.',
		CTA_MISSING: 'Add a call to action to continue.',
		DEFAULT_SELECTED:
			'To update the default template, the video page configuration needs to be updated directly on the workspace settings.',
	},
};

export const placeholderVideoIds = {
	QA: 'O7D1hCPy',
	PROD: '6p4gbGMs',
	DEV: 'rRjy6Yyt',
	LOCAL: 'ki1vICTV',
};
