import styled from 'styled-components';

export const MultiLevelFilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	width: 100%;
`;

export const LeadRowContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const FilterMainTriggerContainer = styled.div`
	display: flex;
	margin-left: auto;
	align-items: center;
	justify-content: flex-end;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const FilterSelectedTriggersContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.small};
	flex-wrap: wrap;
`;

export const SearchWrapper = styled.div<{ hasSearch?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: ${({ theme }) => theme.spacing.xs};
	min-width: ${({ hasSearch }) => (hasSearch ? '230px' : '0')};
`;

export const SearchInputWrapper = styled.div<{
	searchWidth: 'medium' | 'large';
}>`
	width: ${({ searchWidth }) => (searchWidth === 'large' ? '300px' : 'auto')};
`;
