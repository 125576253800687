import { AuthConnectionEnum, AuthConnectionNameEnum } from '@app/constants';

export function getStorytellerName(storyteller: any) {
	if (!storyteller) return '';

	return (
		storyteller?.fullName ||
		`${storyteller?.firstName || ''} ${storyteller?.lastName || ''}`
	).trim();
}

export function getStorytellerFirstName(storyteller: any) {
	const firstName = storyteller?.firstName || '';

	return firstName;
}

export function checkAuthConnectionName(auth0User: any) {
	let connectionType = '';
	switch (auth0User?.sub?.split('|')[0]) {
		case AuthConnectionEnum.auth0:
			connectionType = AuthConnectionNameEnum.auth0;
			break;
		case AuthConnectionEnum.google:
			connectionType = AuthConnectionNameEnum.google;
			break;
		case AuthConnectionEnum.apple:
			connectionType = AuthConnectionNameEnum.apple;
			break;
	}

	return connectionType;
}

export function checkIsWorkspaceAdmin(user: any) {
	return user?.role === 'admin';
}
