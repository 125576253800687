import styled, { css, keyframes } from 'styled-components';

import { Textbase } from '../../presentation/NewTypography';

import {
	InputProps,
	InputLabelType,
	InputContainerType,
	InputSizeType,
	InputInfoType,
} from './types';
import {
	handleBackgroundColor,
	handleBorderColor,
	handleTextColor,
} from './utils';

const MessageText = styled(Textbase).attrs({
	size: 'small',
})`
	position: absolute;
	margin: 0;
	top: calc(100% + 4px);
	left: 0px;
`;

export const HelperText = styled(MessageText)`
	display: none;
	color: ${({ theme }) => theme.colors.neutral[300]};
`;

export const InputContainer = styled.div<InputContainerType>`
	position: relative;
	width: 100%;
	pointer-events: none;
	font-family: ${({ theme }) => theme.fonts.default};

	margin-bottom: ${({ error }) => error && '24px'};

	svg {
		color: ${({ theme, error, iconColor }) =>
			iconColor ? iconColor : error && theme.colors.alert[500]} !important;
	}

	&:hover {
		svg {
			color: ${({ theme, disabled, iconColor }) =>
				iconColor
					? iconColor
					: !disabled && theme.colors.neutral[400]} !important;
		}
	}

	&:focus-within {
		${HelperText} {
			display: flex;
		}

		svg {
			color: ${({ theme, iconColor }) =>
				iconColor ? iconColor : theme.colors.primary[700]} !important;
		}
	}
`;

export const IconContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 12px;

	svg {
		transition: none;
	}
`;

export const InputLabel = styled.label<InputLabelType>`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 14px;
	left: ${({ hasIcon }) => (hasIcon ? '40px' : '8px')};
	border-radius: 16px;
	padding: 0 12px 0 8px;
	color: ${({ theme }) => theme.colors.neutral[300]};
	font-weight: 400;
	font-size: 16px;
	transition:
		top 0.2s ease-in,
		left 0.2s ease-in,
		font-size 0.2s ease-in;
	background: ${({ theme, disabled }) =>
		disabled ? 'transparent' : theme.colors.neutral[0]};
`;

function getHeight(size: string, theme: any) {
	return size === 'large'
		? theme.buttonHeight?.large
		: theme.buttonHeight?.small;
}

function getBorderRadius(size: string, theme: any) {
	return size === 'large'
		? theme.borderRadius?.components.large
		: theme.borderRadius?.components.small;
}

function getInputTextIndent(
	inputSize: InputSizeType,
	searchInput?: boolean,
	iconName?: string,
) {
	if (searchInput || iconName) {
		return inputSize === 'large' ? '32px' : '24px';
	}
}

export const StyledInput = styled.input<InputProps>`
	display: flex;
	box-sizing: border-box;
	width: 100%;
	padding: 0 16px;
	text-indent: ${({ searchInput = false, iconName, inputSize = 'small' }) =>
		getInputTextIndent(inputSize, searchInput, iconName)};
	font-size: 16px;
	color: ${({ theme }) => theme.colors.neutral[500]};
	border: 1px solid
		${({ theme, error }) =>
			error ? theme.colors.alert[700] : theme.colors.neutral[200]};
	border-radius: ${({ theme, error, inputSize = 'small' }) => {
		if (error) return;

		return getBorderRadius(inputSize, theme);
	}};

	background: ${({ theme }) => theme.colors.neutral[0]};
	outline: 0;
	caret-color: ${({ theme }) => theme.colors.primary[700]};
	pointer-events: auto;
	transition: ${({ theme }) => theme.transition?.hover};
	transition: border-radius 0.2s ease-in-out;
	min-height: ${({ theme, inputSize = 'small' }) =>
		getHeight(inputSize, theme)};
	max-height: ${({ theme, inputSize = 'small' }) =>
		getHeight(inputSize, theme)};

	${({ theme, error }) =>
		error &&
		css`
			border-top-left-radius: ${theme.borderRadius?.components.large};
			border-top-right-radius: ${theme.borderRadius?.components.large};
			border-bottom: none;
		`}

	&::placeholder {
		color: ${({ theme }) => theme.colors.neutral[300]};
		font-weight: 400;
		font-size: ${({ hasLabel, inputSize }) =>
			hasLabel || inputSize === 'large' ? '16px' : '14px'};
	}

	&:disabled {
		border: 0;
		background: ${({ theme }) => theme.colors.neutral[50]};
	}

	&:not(:disabled):not(:focus):hover {
		border: 1px solid ${({ theme }) => theme.colors.neutral[300]};
		box-shadow: ${({ theme }) => theme.boxShadow?.components.hovered};
	}

	&:focus {
		border: 1px solid ${({ theme }) => theme.colors.primary[700]};
		box-shadow: ${({ theme }) => theme.boxShadow?.components.focused};
		padding-top: ${({ hasLabel }) => hasLabel && '16px'};
	}

	&:not(:placeholder-shown):not(:focus) {
		padding-top: ${({ hasLabel }) => hasLabel && '16px'};
	}

	&:focus::placeholder {
		color: ${({ theme }) => theme.colors.neutral[300]};
	}

	/*   Create space for both label and placeholder */
	${({ hasPlaceholder, hasLabel, searchInput, iconName }) => {
		if (!!hasPlaceholder && !!hasLabel) {
			// Position label on top of placeholder
			return css`
				+ label {
					top: 2px;
					left: ${searchInput || iconName ? '40px' : '8px'};
					font-size: 12px;
					color: ${({ theme }) => theme.colors.primary[700]};
				}
				/* Add space above placeholder text */
				padding-top: 20px;
			`;
		}
	}}

	${({ value, searchInput, iconName }) => {
		if (value) {
			// Position label on top of placeholder when value is present
			return css`
				+ label {
					top: 2px;
					left: ${(searchInput || iconName) && '40px'};
					font-size: 12px;
				}
			`;
		}
	}}
	
	&:focus + label {
		top: 2px;
		left: ${({ searchInput, iconName }) => (searchInput || iconName) && '40px'};
		font-size: 12px;
		color: ${({ theme }) => theme.colors.primary[700]};
	}
`;

export const ClearXContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	height: 100%;
	padding: 0 12px;
	cursor: pointer;
	z-index: 5;
`;

export const InputInfoText = styled(Textbase)<{ type: InputInfoType | null }>`
	color: ${({ type }) => handleTextColor(type)};
`;

const fadeIn = keyframes`
	0% { transform: translateY(-5px); }
	100% { opacity: 1; transform: translateY(0); }
`;

export const InputInfoWrapper = styled.div<{ type: InputInfoType | null }>`
	position: absolute;
	width: 100%;
	opacity: 0;
	min-height: 20px;
	border-radius: 0 0 24px 24px;
	padding: 0 16px;
	background-color: ${({ type }) => handleBackgroundColor(type)};
	border: 1px solid ${({ type }) => handleBorderColor(type)};
	border-top: none;
	animation: ${fadeIn} 0.3s forwards;
	animation-delay: 0.1s;
`;
