import styled, { createGlobalStyle } from 'styled-components';
import { GoodkindTheme } from '@global/theme';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${GoodkindTheme.colors.background} !important;
    font-family: ${GoodkindTheme.fonts.default} !important;
    color: ${GoodkindTheme.colors.dark[700]};
  }

  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /* End of reset */


  button[type=button] {
    cursor: pointer;

    &:disabled {
      cursor: default;
      pointer-events: none;

    }
  }

  /* Reset styling introduced by Blueprint (used by Polotno) */
	a,
	div[role='button'],
	div[role='dialog'] {
		text-decoration: none !important;
	}

	a,
  button,
	div[role='button'],
  div[role='dialog'] {
		&:focus {
      outline: none !important;
      outline-offset: 0px;
		}
		&:active {
			outline: none !important;
		}
	}

  :focus {
      outline: none !important;
      outline-offset: 0px;
		}
	/* End of reset*/

`;

export const Layout = styled.div`
	position: relative;
	display: flex;
	height: 100vh;
	max-height: 100%;
`;
