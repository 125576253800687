import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import { FilterOptions } from '@app/types';
import axios from '@global/axios';
import { EditStoryOptionsTypes } from '../stories/types';
import { storyStatus } from '../stories/constants';
import { CaptionType } from '@common/components/interaction/CaptionEditor/types';

const mainType = 'sent';

export const getSentStoriesTypes = createFetchTypes(
	`${mainType}_GET_SENT_STORIES`,
);

export const editStoriesTypes = createFetchTypes(`${mainType}_EDIT_STORIES`);

export const archiveStoriesTypes = createFetchTypes(
	`${mainType}_ARCHIVE_STORIES`,
);

export const undeleteStoriesTypes = createFetchTypes(
	`${mainType}_UNARCHIVE_STORIES`,
);

export const getSingleStoryTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_STORY`,
);

function getSingleStory(storyId: string, filterOptions: FilterOptions) {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: getSingleStoryTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories/${storyId}${
					filterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			errorMessage: ToastMessage.errorStoryNotFound,
		},
	};
}

//stays
// ### get sent here , we can clean this and not pass an object, just pagination to it , as filters are fixed
function getSentStories(filterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify({
		...filterOptions,
		filter: [
			...(filterOptions.filter ?? []),

			{ field: 'status', value: storyStatus.sent },
		],
	});

	return {
		type: getSentStoriesTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories${filterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'getSentStories',
		},
	};
}

function editStories(EditOptions: EditStoryOptionsTypes): fetchAction {
	return {
		type: editStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/edit`, {
				...EditOptions,
			}),
		payload: {
			custom: 'editStories',
			successMessage: ToastMessage.editedAssignedStory,
			errorMessage: ToastMessage.error,
		},
	};
}

function archiveStories(storiesId: string[]): fetchAction {
	return {
		type: archiveStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/softDelete`, { storiesId }),
		payload: {
			custom: 'bulkArchive',
			storiesId,
			successMessage:
				storiesId.length > 1
					? ToastMessage.severalStoriesArchived
					: ToastMessage.singleStoryArchived,
			errorMessage: ToastMessage.error,
		},
	};
}

function undeleteStories(storiesId: string[]): fetchAction {
	return {
		type: undeleteStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/undelete`, { storiesId }),
		payload: {
			custom: 'bulkUnarchive',
			storiesId,
			successMessage:
				storiesId.length > 1
					? ToastMessage.severalStoriesUnarchived
					: ToastMessage.singleStoryUnarchived,
			errorMessage: ToastMessage.error,
		},
	};
}

export const updateSentVideoCaptionsTypes = `${mainType}/UPDATE_VIDEO_CAPTIONS`;

function updateSentVideoCaptions(sentVideoId: string, captions: CaptionType[]) {
	return {
		type: updateSentVideoCaptionsTypes,
		payload: {
			sentVideoId,
			captions,
		},
	};
}

export const updateSentVideoFieldTypes = `${mainType}/UPDATE_VIDEO_FIELD`;

function updateSentVideoField(sentId: string, field: string, value: any) {
	return {
		type: updateSentVideoFieldTypes,
		payload: {
			sentId,
			field,
			value,
		},
	};
}

export {
	editStories,
	getSentStories,
	archiveStories,
	undeleteStories,
	getSingleStory,
	updateSentVideoCaptions,
	updateSentVideoField,
};
