import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.spacing.small};
	gap: ${({ theme }) => theme.spacing.xl};
	overflow-y: auto;
`;

export const UpperSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	.text-item {
		width: 100%;
		cursor: pointer;

		img {
			width: 100%;
		}
	}
`;

export const LowerSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xl};
	height: 100%;
`;

export const TextCardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: ${({ theme }) => theme.spacing.small};
	row-gap: ${({ theme }) => theme.spacing.xl};
`;

export const TextCard = styled.img`
	width: 100%;
	aspect-ratio: 1;
	cursor: pointer;
`;
