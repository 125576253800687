import styled from 'styled-components';

import { Paragraph } from '@common/components/presentation/NewTypography';

export const ThreeDotsParagraph = styled(Paragraph)`
	max-width: 310px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
