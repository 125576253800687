import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import entityNormalizer from '../../utils/entityNormalizer';
import { normalize } from 'normalizr';

import createFetchReducer from '../../utils/createFetchReducer';
import {
	createGroupTypes,
	deleteGroupTypes,
	getGroupsTypes,
	getSingleGroupTypes,
} from './action';

const initialState: GenericEntityState = {
	loading: false,
	groups: {},
	groupsCount: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
};

export type groupsStateType = typeof initialState;
const group = entityNormalizer('groups');
const pagination = { groups: [group] };

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			groups,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ groups, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function paginationMapper(state: groupsStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);

	if (action.response.page === 1) {
		state.groups = normalizedData.entities.groups;
	} else {
		state.groups = {
			...state.groups,
			...normalizedData.entities.groups,
		};
	}
	state.groupsCount = Object.keys(state.groups || {})?.length;
	if (state.pagination) {
		state.pagination.pages[action.response.page] = normalizedData.result.groups;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
}

function createGroupMapper(state: groupsStateType, action: ResponseAction) {
	const { group } = action.response;

	const newGroup = { [group._id]: group };

	state.groups = { ...newGroup, ...state.groups };
}

function deleteMapperSuccess(state: groupsStateType, action: ResponseAction) {
	const {
		response: { groupId },
	} = action;
	const groupsCopy = { ...state.groups };
	const { [groupId]: deletedGroup, ...restOfGroups } = groupsCopy;
	state.groups = restOfGroups;
}

const groupSlice = createSlice({
	name: 'group',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(getSingleGroupTypes),
		...createFetchReducer(getGroupsTypes, paginationMapper),
		...createFetchReducer(createGroupTypes, createGroupMapper),
		...createFetchReducer(deleteGroupTypes, deleteMapperSuccess),
	},
});

export default groupSlice.reducer;
