import styled from 'styled-components';

export const ButtonContainer = styled.div<{
	focused?: boolean;
	disabled?: boolean;
}>`
	width: 100%;
	min-height: 44px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: ${({ theme }) => theme.spacing.xs} 10px;
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	background: ${({ focused, disabled, theme }) =>
		focused && !disabled && theme.colors.system.background.weak};
	cursor: ${({ disabled }) => !disabled && 'pointer'};

	&:hover {
		background: ${({ disabled, theme }) =>
			!disabled && theme.colors.system.background.weak};
	}
`;
export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xs};
	align-self: stretch;
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
`;

export const ButtonDetails = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;
