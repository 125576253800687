import { useRef, useEffect, useState } from 'react';

type useScrollProp = React.RefObject<Element>;

const useScroll = (ref: useScrollProp) => {
	const scrollTimerRef = useRef<NodeJS.Timeout>();

	const [hasScroll, setHasScroll] = useState<boolean>(false);

	/** Single click scroll */
	const scrollRight = (pixels = 80) => {
		const element = ref?.current;
		if (!element) return;

		element.scrollBy({ top: 0, left: pixels, behavior: 'smooth' });
	};

	const scrollLeft = (pixels = 80) => {
		const element = ref?.current;
		if (!element) return;

		element.scrollBy({ top: 0, left: -pixels, behavior: 'smooth' });
	};

	/** Scroll on mouse hold (use onMouseDown/onMouseUp) */
	const startScrollRight = (pixels = 12) => {
		const element = ref?.current;
		if (!element) return;

		scrollTimerRef.current = setTimeout(() => {
			element.scrollLeft += pixels;
			startScrollRight();
		}, 10);
	};

	const stopScrollRight = () => {
		clearTimeout(scrollTimerRef.current);
	};

	const startScrollLeft = (pixels = 12) => {
		const element = ref?.current;
		if (!element) return;

		scrollTimerRef.current = setTimeout(() => {
			element.scrollLeft -= pixels;
			startScrollLeft();
		}, 10);
	};

	const stopScrollLeft = () => {
		clearTimeout(scrollTimerRef.current);
	};

	/** Constructor to detect scroll */
	const checkHasScroll = () => {
		const element = ref?.current;
		if (!element) return false;

		const hasHorizontalScroll = element.scrollWidth > element.clientWidth;
		const hasVerticalScroll = element.scrollHeight > element.clientHeight;

		setHasScroll(hasHorizontalScroll || hasVerticalScroll);
	};

	useEffect(() => {
		const element = ref?.current;

		if (!element) return;

		const resizeObserver = new ResizeObserver(() => {
			checkHasScroll();
		});

		resizeObserver.observe(element);

		return () => {
			resizeObserver.disconnect();
		};
	}, [ref?.current]);

	return {
		hasScroll,
		scrollRight,
		scrollLeft,
		startScrollRight,
		stopScrollRight,
		startScrollLeft,
		stopScrollLeft,
	};
};

export default useScroll;
