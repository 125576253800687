import styled from 'styled-components';

import { TextbaseTypographyProps, SizeType } from '../types';

import { Typography } from '../styles';

function handleLineHeight(size: SizeType) {
	switch (size) {
		case 'large':
			return '20px';

		case 'regular':
			return '16px';

		case 'small':
			return '14px';

		case 'smaller':
			return '12px';

		default:
			return 'inherit';
	}
}

export const TextbaseTypography = styled(Typography).attrs({
	as: 'span',
})<TextbaseTypographyProps>`
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	line-height: ${({ size = 'regular' }) => handleLineHeight(size)};
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	font-size: ${({ theme, size = 'regular' }) =>
		theme.fontSizes && theme.fontSizes[size]};
	white-space: ${({ lineJump }) => lineJump && 'initial'};
`;
