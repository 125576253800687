import {
	FontFamilyType,
	FontLetterSpacingsType,
	FontLineHeightsType,
	FontSizesType,
	FontWeightsType,
} from '@common/design-system/global/types';
import React, { forwardRef } from 'react';
import * as S from './Text.styles';

type PropTypes = {
	children: React.ReactNode | string | number;
	as?: keyof JSX.IntrinsicElements;
	className?: string;
	color?: string;
	size?: FontSizesType;
	fontWeight?: FontWeightsType;
	lineHeight?: FontLineHeightsType;
	letterSpacing?: FontLetterSpacingsType;
	onClick?: (e: React.MouseEvent) => void;
	htmlFor?: string;
	uppercase?: boolean;
	center?: boolean;
	fontFamily?: FontFamilyType;
	noWrap?: boolean;
	lineClamp?: number;
	isItalic?: boolean;
};

const Text = forwardRef(function Text(
	{
		children,
		color, // eg.: 'system.text.default'
		size,
		fontWeight = 'regular',
		lineHeight = 'regular',
		letterSpacing = 'medium',
		as = 'p',
		htmlFor,
		uppercase,
		onClick,
		center,
		fontFamily = 'default',
		noWrap,
		lineClamp,
		isItalic,
		...rest
	}: PropTypes,
	ref: any,
) {
	return (
		<S.StyledText
			color={color}
			size={size}
			isItalic={isItalic}
			fontWeight={fontWeight}
			lineHeight={lineHeight}
			letterSpacing={letterSpacing}
			as={as}
			htmlFor={htmlFor}
			onClick={onClick}
			uppercase={uppercase}
			center={center}
			fontFamily={fontFamily}
			noWrap={noWrap}
			lineClamp={lineClamp}
			ref={ref}
			{...rest}
		>
			{children}
		</S.StyledText>
	);
});

export default Text;
export type { PropTypes as TextProps };
