import React, { forwardRef, useMemo } from 'react';

import { CustomIcon } from './components/CustomIcon';

import { CustomIconProps } from './components/CustomIcon/CustomIcon';

import {
	Play,
	CircleNotch,
	ChartPieSlice,
	CircleDashed,
	House,
	HouseSimple,
	Users,
	User,
	UsersThree,
	UserList,
	Cube,
	MonitorPlay,
	PuzzlePiece,
	CirclesThree,
	Receipt,
	PaperPlaneTilt,
	PaperPlaneRight,
	UserFocus,
	UserMinus,
	UserPlus,
	Plus,
	ChatText,
	Chats,
	ClosedCaptioning,
	BellRinging,
	MegaphoneSimple,
	Megaphone,
	Lightning,
	LightningSlash,
	GearSix,
	Question,
	List,
	ListPlus,
	CloudArrowUp,
	Upload,
	PencilSimple,
	PencilCircle,
	Check,
	FireSimple,
	Cursor,
	CursorClick,
	CalendarBlank,
	CheckCircle,
	ArrowSquareOut,
	ArrowSquareDown,
	ArrowRight,
	ArrowLeft,
	ArrowBendUpLeft,
	ArrowsClockwise,
	ArrowDownLeft,
	ArrowDownRight,
	PaintBrush,
	PaintBrushBroad,
	PaintBucket,
	Globe,
	ChartBar,
	X,
	Eye,
	EyeSlash,
	Minus,
	Info,
	MicrophoneSlash,
	Warning,
	FileArrowUp,
	TrashSimple,
	Trash,
	PlayCircle,
	MagnifyingGlass,
	MagicWand,
	CaretRight,
	CaretLeft,
	CaretDown,
	CaretUp,
	Circle,
	FileText,
	PlusCircle,
	ChatCircle,
	ChatCircleText,
	Shield,
	ShieldCheck,
	ShieldWarning,
	EnvelopeSimple,
	Clock,
	DotsThree,
	DotsThreeVertical,
	DotsThreeOutline,
	DotsThreeOutlineVertical,
	DotsSix,
	GridNine,
	DotsSixVertical,
	CopySimple,
	ShareNetwork,
	ShareFat,
	HandPointing,
	Tray,
	UsersFour,
	DownloadSimple,
	FloppyDisk,
	Printer,
	Copy,
	Power,
	XCircle,
	ArchiveBox,
	Archive,
	Download,
	WarningCircle,
	Quotes,
	Note,
	Notepad,
	Envelope,
	ChatCenteredText,
	Wrench,
	VideoCamera,
	VideoCameraSlash,
	SpeakerHigh,
	CheckSquareOffset,
	PushPinSimple,
	PushPinSimpleSlash,
	Cake,
	Desktop,
	DeviceMobile,
	Phone,
	IdentificationCard,
	SortAscending,
	Columns,
	IdentificationBadge,
	WhatsappLogo,
	SpinnerGap,
	Article,
	Bug,
	Plugs,
	Smiley,
	BookBookmark,
	HandHeart,
	Certificate,
	Exam,
	MaskHappy,
	Gift,
	Target,
	BookOpenText,
	Trophy,
	Money,
	FaceMask,
	Tree,
	Books,
	FilmReel,
	Atom,
	Toolbox,
	Scroll,
	PottedPlant,
	RainbowCloud,
	Calculator,
	Stamp,
	Flask,
	WheelchairMotion,
	GlobeHemisphereWest,
	Bird,
	ShootingStar,
	Handshake,
	Eyeglasses,
	ChartLineUp,
	ChartLine,
	PersonSimpleThrow,
	Scales,
	Chalkboard,
	Campfire,
	Dna,
	MusicNotes,
	GraduationCap,
	PersonSimpleBike,
	Binoculars,
	HandsPraying,
	Star,
	LightbulbFilament,
	Lightbulb,
	PiggyBank,
	ChalkboardTeacher,
	CubeTransparent,
	Confetti,
	ArrowsDownUp,
	FunnelSimple,
	Funnel,
	Image,
	Link,
	Sparkle,
	NotePencil,
	UserRectangle,
	UserCircle,
	UserCirclePlus,
	UserCircleGear,
	UserCircleDashed,
	UserSwitch,
	Pulse,
	ArrowLineLeft,
	IconWeight,
	Video,
	Hammer,
	Calendar,
	CodeSimple,
	StarHalf,
	Brain,
	PauseCircle,
	ClockCountdown,
	UploadSimple,
	ChatsCircle,
	Clipboard,
	ArchiveTray,
	Gear,
	Record,
	Folder,
	FolderPlus,
	FolderMinus,
	FolderSimple,
	CloudArrowDown,
	Tag,
	ArrowsLeftRight,
	TextAUnderline,
	Hash,
	Subtract,
	CellSignalLow,
	CellSignalMedium,
	CellSignalHigh,
	DoorOpen,
	Microphone,
	Files,
	SquaresFour,
	MoonStars,
	SealWarning,
	ExclamationMark,
	Translate,
	StackSimple,
	File,
	ClockClockwise,
	CalendarCheck,
	ArrowsOut,
	TextAa,
	FolderOpen,
	Swap,
} from '@phosphor-icons/react';

import { evalColorPath } from '@common/design-system/global/utils';
import { useTheme } from 'styled-components';
import { colorRegex, emojiAndSymbolsRegex } from '@app/constants/regex';
import { IconEnum } from '@global/icons/customIconsConstant';

export type IconProps = CustomIconProps & {
	iconName: string;
	size?: 'xxxs' | 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';
	color?: string;
	className?: string;
	weight?: IconWeight;
	customNumberSize?: number | string;
	alt?: string;
};

const Icon = forwardRef(function HandleIcon(
	{
		iconName,
		size = 'medium',
		customNumberSize, // For internal use only, on custom icon sizing for DS components
		color, // e.g. 'primary.text.default'
		weight = 'regular',
		className,
		alt,
	}: IconProps,
	ref: any,
) {
	const iconSizeMap = {
		xxxs: '12px',
		xxs: '14px',
		xs: '16px',
		small: '18px',
		medium: '20px',
		large: '24px',
		xl: '32px',
		xxl: '40px',
	};

	const sizeToUse = customNumberSize
		? `${customNumberSize}px`
		: iconSizeMap[size];

	const theme = useTheme();

	const isColorAHEX =
		(color && colorRegex.test(color)) || color?.startsWith('#');

	const iconColor: string = isColorAHEX
		? color
		: (color && evalColorPath(theme.colors, color)) || 'currentColor';

	const isEmoji = iconName && emojiAndSymbolsRegex().test(iconName);

	const commonProps = useMemo(() => {
		return {
			size: sizeToUse,
			color: iconColor,
			weight,
			className,
			style: {
				flex: `0 0 ${sizeToUse}`,
			},
			alt,
			ref,
		};
	}, [sizeToUse, iconColor, weight, className, ref]);

	if (isEmoji) {
		return (
			<span
				className="d-flex align-items-center justify-content-center"
				style={{ fontSize: sizeToUse }}
			>
				{iconName}
			</span>
		);
	}

	if (!iconName) {
		return <Cube {...commonProps} />;
	}

	switch (iconName) {
		case 'calendar-check':
			return <CalendarCheck {...commonProps} />;
		case 'sealWarning':
			return <SealWarning {...commonProps} />;
		case 'square-four':
			return <SquaresFour {...commonProps} />;

		case 'files':
			return <Files {...commonProps} />;
		case 'door-open':
			return <DoorOpen {...commonProps} />;
		case 'cell-signal-low':
			return <CellSignalLow {...commonProps} />;
		case 'cell-signal-medium':
			return <CellSignalMedium {...commonProps} />;
		case 'cell-signal-high':
			return <CellSignalHigh {...commonProps} />;
		case 'subtract':
			return <Subtract {...commonProps} />;

		case 'hash':
			return <Hash {...commonProps} />;

		case 'text-aa':
			return <TextAa {...commonProps} />;

		case 'text-a-underline':
			return <TextAUnderline {...commonProps} />;
		case 'arrows-left-right':
			return <ArrowsLeftRight {...commonProps} />;

		case 'arrows-up-down':
			return <ArrowsDownUp {...commonProps} />;
		case 'tag':
			return <Tag {...commonProps} />;
		case 'clouddown':
		case 'cloud-arrow-down':
		case 'cloudArrowDown':
			return <CloudArrowDown {...commonProps} />;
		case 'volume-on':
			return <SpeakerHigh {...commonProps} />;
		case 'chart-pie-slice':
			return <ChartPieSlice {...commonProps} />;

		case 'user-rectangle':
			return <UserRectangle {...commonProps} />;
		case 'user-circle':
			return <UserCircle {...commonProps} />;
		case 'user-circle-plus':
			return <UserCirclePlus {...commonProps} />;
		case 'user-circle-gear':
			return <UserCircleGear {...commonProps} />;
		case 'user-circle-dashed':
			return <UserCircleDashed {...commonProps} />;
		case 'user-switch':
			return <UserSwitch {...commonProps} />;
		case 'custom-play':
			return (
				<Play {...commonProps} weight={commonProps?.weight || 'duotone'} />
			);
		case 'loader':
			return (
				<CircleNotch
					{...commonProps}
					weight={commonProps?.weight || 'duotone'}
				/>
			);

		case 'circle-dashed':
			return (
				<CircleDashed
					{...commonProps}
					weight={commonProps?.weight || 'duotone'}
				/>
			);

		case 'spinnerGap':
			return (
				<SpinnerGap
					{...commonProps}
					weight={commonProps?.weight || 'duotone'}
				/>
			);

		case 'image':
			return (
				<Image {...commonProps} weight={commonProps?.weight || 'duotone'} />
			);

		case 'home':
			return <HouseSimple {...commonProps} />;

		case 'house':
			return <House {...commonProps} />;

		case 'campaign':
			return <MegaphoneSimple {...commonProps} />;

		case 'megaphone':
			return <Megaphone {...commonProps} />;

		case 'phone':
			return <Phone {...commonProps} />;

		case 'pauseCircle':
			return <PauseCircle {...commonProps} />;

		case 'audience':
			return <UsersThree {...commonProps} />;

		case 'stackSimple':
			return <StackSimple {...commonProps} />;

		case 'action':
		case 'flash':
			return <Lightning {...commonProps} />;

		case 'lightningSlash':
			return <LightningSlash {...commonProps} />;

		case 'checkSquare':
			return <CheckSquareOffset {...commonProps} />;

		case 'settings':
			return <GearSix {...commonProps} />;

		case 'help':
		case 'question':
			return <Question {...commonProps} />;

		case 'preview':
			return <ArrowSquareOut {...commonProps} />;

		case 'user':
			return <User {...commonProps} />;

		case 'users':
		case 'group':
			return <Users {...commonProps} />;

		case 'user-list':
			return <UserList {...commonProps} />;

		case 'globe':
		case 'language':
			return <Globe {...commonProps} />;

		case 'wrench':
			return <Wrench {...commonProps} />;

		case 'brush':
			return <PaintBrush {...commonProps} />;

		case 'brush-broad':
			return <PaintBrushBroad {...commonProps} />;

		case 'paint-bucket':
			return <PaintBucket {...commonProps} />;

		case 'assessment':
		case 'chart-bar':
			return <ChartBar {...commonProps} />;

		case 'arrowForward':
		case 'arrow-right':
		case 'arrowRight':
			return <ArrowRight {...commonProps} />;

		case 'expandLess':
		case 'arrowUpIndicator':
		case 'chevronUp':
		case 'caret-up':
		case 'caretUp':
			return <CaretUp {...commonProps} />;

		case 'expandMore':
		case 'chevronDown':
		case 'downArrowIndicator':
		case 'caret-down':
		case 'caretDown':
			return <CaretDown {...commonProps} />;

		case 'exclamation':
			return <ExclamationMark {...commonProps} />;
		case 'info':
			return <Info {...commonProps} />;

		case 'sync':
			return <ArrowsClockwise {...commonProps} />;

		case 'chevronRight':
		case 'caretRight':
		case 'caret-right':
			return <CaretRight {...commonProps} />;

		case 'shield':
			return <Shield {...commonProps} />;

		case 'shieldCheck':
		case 'verifiedUser':
			return <ShieldCheck {...commonProps} />;
		case 'shieldWarning':
			return <ShieldWarning {...commonProps} />;

		case 'chevronLeft':
			return <CaretLeft {...commonProps} />;

		case 'reply':
			return <ArrowBendUpLeft {...commonProps} />;

		case 'dash':
			return <Minus {...commonProps} />;

		case 'playCircle':
			return <PlayCircle {...commonProps} />;

		case 'duplicate':
		case 'copy':
			return <Copy {...commonProps} />;

		case 'list':
			return <List {...commonProps} />;

		case 'list-plus':
			return <ListPlus {...commonProps} />;

		case 'fileCopy':
			return <CopySimple {...commonProps} />;

		case 'filter':
			return <FunnelSimple {...commonProps} />;

		case 'filterSimple':
			return <Funnel {...commonProps} />;

		case 'mail':
		case 'email':
			return <EnvelopeSimple {...commonProps} />;

		case 'envelope':
			return <Envelope {...commonProps} />;

		case 'chatCenteredText':
			return <ChatCenteredText {...commonProps} />;

		case 'article':
			return <Article {...commonProps} />;

		case 'accessTime':
			return <Clock {...commonProps} />;

		case 'link':
			return <Link {...commonProps} />;

		case 'moreVert':
			return <DotsThreeVertical {...commonProps} />;

		case 'moreHoriz':
			return <DotsThree {...commonProps} />;

		case 'moreVertOutline':
			return <DotsThreeOutlineVertical {...commonProps} />;

		case 'moreHorizOutline':
			return <DotsThreeOutline {...commonProps} />;

		case 'dotsSix':
			return <DotsSix {...commonProps} />;

		case 'dotsSixVertical':
			return <DotsSixVertical {...commonProps} />;

		case 'close':
			return <X {...commonProps} />;

		case 'closeCircle':
			return <XCircle {...commonProps} />;

		case 'archive':
			return <ArchiveBox {...commonProps} />;
		case 'archiveSimple':
			return <Archive {...commonProps} />;
		case 'fileArrowUp':
		case 'unarchive':
			return <FileArrowUp {...commonProps} />;

		case 'trash':
		case 'delete':
			return <Trash {...commonProps} />;

		case 'trash-simple':
			return <TrashSimple {...commonProps} />;

		case 'addCircle':
		case 'addCircleOutline':
			return <PlusCircle {...commonProps} />;

		case 'systemUpdate':
		case 'systemUpdateAlt':
			return <ArrowSquareDown {...commonProps} />;

		case 'save':
			return <FloppyDisk {...commonProps} />;

		case 'warning':
			return <Warning {...commonProps} />;

		case 'warningCircle':
		case 'warning-circle':
			return <WarningCircle {...commonProps} />;

		case 'comment':
		case 'chat-circle':
			return <ChatCircle {...commonProps} />;
		case 'chat-circle-text':
			return <ChatCircleText {...commonProps} />;

		case 'videos':
		case 'videoPage':
			return <MonitorPlay {...commonProps} />;

		case 'video':
			return <Video {...commonProps} />;

		case 'microphone':
			return <Microphone {...commonProps} />;

		case 'micOff':
			return <MicrophoneSlash {...commonProps} />;

		case 'puzzle':
			return <PuzzlePiece {...commonProps} />;

		case 'identificationBadge':
			return <IdentificationBadge {...commonProps} />;

		case 'identificationCard':
			return <IdentificationCard {...commonProps} />;

		case 'workspace':
			return <CirclesThree {...commonProps} />;

		case 'whatsapp':
		case 'whatsapp-inactive':
			return <WhatsappLogo {...commonProps} />;

		case 'whatsapp-active':
			return <WhatsappLogo {...commonProps} color="#2EB861" />;

		case 'usersFour':
		case 'audiences':
			return <UsersFour {...commonProps} />;

		case 'receipt':
			return <Receipt {...commonProps} />;

		case 'visibility':
			return <Eye {...commonProps} />;

		case 'eye-slash':
			return <EyeSlash {...commonProps} />;

		case 'pin':
			return <PushPinSimple {...commonProps} />;

		case 'grid-nine':
			return <GridNine {...commonProps} />;

		case 'unpin':
			return <PushPinSimpleSlash {...commonProps} />;

		case 'share':
			return <ShareNetwork {...commonProps} />;

		case 'share-fat':
			return <ShareFat {...commonProps} />;

		case 'touchApp':
			return <HandPointing {...commonProps} />;

		case 'arrowLeft':
			return <ArrowLeft {...commonProps} />;

		case 'send':
		case 'paper-plane-tilt':
			return <PaperPlaneTilt {...commonProps} />;

		case 'sendRight':
			return <PaperPlaneRight {...commonProps} />;

		case 'userFocus':
			return <UserFocus {...commonProps} />;

		case 'plus':
			return <Plus {...commonProps} />;

		case 'videoCamera':
			return <VideoCamera {...commonProps} />;

		case 'videoCameraSlash':
			return <VideoCameraSlash {...commonProps} />;

		case 'uploadSimple':
			return <UploadSimple {...commonProps} />;

		case 'unsubscribe':
			return <UserMinus {...commonProps} />;

		case 'personAdd':
			return <UserPlus {...commonProps} />;

		case 'sms':
			return <ChatText {...commonProps} />;

		case 'chats':
			return <Chats {...commonProps} />;
		case 'chats-circle':
			return <ChatsCircle {...commonProps} />;
		case 'subtitles':
			return <ClosedCaptioning {...commonProps} />;

		case 'sparkle':
			return <Sparkle {...commonProps} />;

		case 'notification':
			return <BellRinging {...commonProps} />;

		case 'note':
			return <Note {...commonProps} />;

		case 'notepad':
			return <Notepad {...commonProps} />;

		case 'note-pencil':
			return <NotePencil {...commonProps} />;

		case 'openInNew':
			return <ArrowSquareOut {...commonProps} />;

		case 'uploadCloud':
			return <CloudArrowUp {...commonProps} />;

		case 'upload':
			return <Upload {...commonProps} />;

		case 'export':
			return <DownloadSimple {...commonProps} />;

		case 'download':
			return <Download {...commonProps} />;

		case 'tray':
			return <Tray {...commonProps} />;
		case 'pulse':
			return <Pulse {...commonProps} />;
		case 'edit':
		case 'pencil':
			return <PencilSimple {...commonProps} />;

		case 'check':
			return <Check {...commonProps} />;

		case 'fire':
			return <FireSimple {...commonProps} />;

		case 'click':
			return <Cursor {...commonProps} />;

		case 'cursor-click':
			return <CursorClick {...commonProps} />;

		case 'sort':
			return <SortAscending {...commonProps} />;

		case 'event':
		case 'calendar':
			return <CalendarBlank {...commonProps} />;

		case 'calendarDate':
			return <Calendar {...commonProps} />;
		case 'checkCircle':
			return <CheckCircle {...commonProps} />;

		case 'radioButtonUnchecked':
		case 'circle':
			return <Circle {...commonProps} />;

		case 'search':
			return <MagnifyingGlass {...commonProps} />;

		case 'wand':
			return <MagicWand {...commonProps} />;

		case 'desktop':
			return <Desktop {...commonProps} />;

		case 'mobile':
			return <DeviceMobile {...commonProps} />;

		case 'file-text':
		case 'description':
			return <FileText {...commonProps} />;

		case 'printer':
			return <Printer {...commonProps} />;

		case 'power':
			return <Power {...commonProps} />;
		case 'quotes':
			return <Quotes {...commonProps} />;

		case 'cake':
			return <Cake {...commonProps} />;

		case 'columns':
			return <Columns {...commonProps} />;

		case 'resize':
		case 'arrows-out':
			return <ArrowsOut {...commonProps} />;

		case 'arrow-down-left':
			return <ArrowDownLeft {...commonProps} />;

		case 'arrow-down-right':
			return <ArrowDownRight {...commonProps} />;
		case 'bug':
			return <Bug {...commonProps} />;

		case 'plugs':
			return <Plugs {...commonProps} />;

		case 'smiley':
			return <Smiley {...commonProps} />;
		case 'book-bookmark':
			return <BookBookmark {...commonProps} />;

		case 'hand-heart':
			return <HandHeart {...commonProps} />;

		case 'certificate':
			return <Certificate {...commonProps} />;

		case 'exam':
			return <Exam {...commonProps} />;

		case 'mask':
			return <MaskHappy {...commonProps} />;

		case 'gift':
			return <Gift {...commonProps} />;

		case 'target':
			return <Target {...commonProps} />;

		case 'book-open-text':
			return <BookOpenText {...commonProps} />;

		case 'trophy':
			return <Trophy {...commonProps} />;

		case 'pencil-circle':
			return <PencilCircle {...commonProps} />;

		case 'money':
			return <Money {...commonProps} />;

		case 'face-mask':
			return <FaceMask {...commonProps} />;

		case 'tree':
			return <Tree {...commonProps} />;

		case 'books':
			return <Books {...commonProps} />;

		case 'film-reel':
			return <FilmReel {...commonProps} />;

		case 'atom':
			return <Atom {...commonProps} />;

		case 'toolbox':
			return <Toolbox {...commonProps} />;

		case 'scroll':
			return <Scroll {...commonProps} />;

		case 'potted-plant':
			return <PottedPlant {...commonProps} />;

		case 'rainbow-cloud':
			return <RainbowCloud {...commonProps} />;

		case 'swap':
			return <Swap {...commonProps} />;

		case 'calculator':
			return <Calculator {...commonProps} />;

		case 'stamp':
			return <Stamp {...commonProps} />;

		case 'flask':
			return <Flask {...commonProps} />;

		case 'wheelchair-motion':
			return <WheelchairMotion {...commonProps} />;

		case 'globe-hemisphere-west':
			return <GlobeHemisphereWest {...commonProps} />;

		case 'bird':
			return <Bird {...commonProps} />;

		case 'shooting-start':
			return <ShootingStar {...commonProps} />;

		case 'handshake':
			return <Handshake {...commonProps} />;

		case 'eyeglasses':
			return <Eyeglasses {...commonProps} />;

		case 'chart-line-up':
			return <ChartLineUp {...commonProps} />;

		case 'chart-line':
			return <ChartLine {...commonProps} />;

		case 'person-simple-throw':
			return <PersonSimpleThrow {...commonProps} />;

		case 'person-simple-bike':
			return <PersonSimpleBike {...commonProps} />;

		case 'scales':
			return <Scales {...commonProps} />;

		case 'chalkboard':
			return <Chalkboard {...commonProps} />;

		case 'campfire':
			return <Campfire {...commonProps} />;

		case 'dna':
			return <Dna {...commonProps} />;

		case 'music-notes':
			return <MusicNotes {...commonProps} />;

		case 'graduation-cap':
			return <GraduationCap {...commonProps} />;

		case 'binoculars':
			return <Binoculars {...commonProps} />;

		case 'hands-praying':
			return <HandsPraying {...commonProps} />;

		case 'star':
			return <Star {...commonProps} />;
		case 'star-half':
			return <StarHalf {...commonProps} />;

		case 'lightbulb-filament':
			return <LightbulbFilament {...commonProps} />;
		case 'lightbulb':
			return <Lightbulb {...commonProps} />;
		case 'piggy-bank':
			return <PiggyBank {...commonProps} />;

		case 'chalkboard-teacher':
			return <ChalkboardTeacher {...commonProps} />;

		case 'cube-transparent':
			return <CubeTransparent {...commonProps} />;

		case 'confetti':
			return <Confetti {...commonProps} />;
		case 'arrowLineLeft':
			return <ArrowLineLeft {...commonProps} />;

		case 'hammer':
			return <Hammer {...commonProps} />;

		case 'codeSimple':
			return <CodeSimple {...commonProps} />;

		case 'brain':
			return <Brain {...commonProps} />;

		case 'clock-countdown':
		case 'clockCountdown':
			return <ClockCountdown {...commonProps} />;

		case 'file':
			return <File {...commonProps} />;
		case 'clipboard':
			return <Clipboard {...commonProps} />;
		case 'archiveTray':
			return <ArchiveTray {...commonProps} />;
		case 'gear':
			return <Gear {...commonProps} />;
		case 'record':
			return <Record {...commonProps} />;
		case 'folder':
			return <Folder {...commonProps} />;
		case 'folder-open':
			return <FolderOpen {...commonProps} />;

		case 'folder-simple':
			return <FolderSimple {...commonProps} />;
		case 'dots-three-vertical':
			return <DotsThreeVertical {...commonProps} />;
		case 'folder-plus':
			return <FolderPlus {...commonProps} />;
		case 'folder-minus':
			return <FolderMinus {...commonProps} />;

		case 'moon-stars':
			return <MoonStars {...commonProps} />;

		case 'translate':
			return (
				<Translate
					size={sizeToUse}
					color={iconColor}
					weight={weight}
					className={className}
				/>
			);
		case 'clock-clock-wise':
			return <ClockClockwise {...commonProps} />;

		default:
			if (IconEnum[iconName?.toLowerCase()]) {
				return (
					<CustomIcon
						iconName={iconName}
						color={iconColor}
						className={className}
						width={sizeToUse}
						height={sizeToUse}
					/>
				);
			}

			return <Cube {...commonProps} />;
	}
});

export default React.memo(Icon);
