import React from 'react';
import { TableMapperType } from '../../types';
import { StatusIconProps } from '@common/design-system/components/atoms/StatusIcon/StatusIcon';
import { StatusIcon } from '@common/design-system/components/atoms';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

function StatusIconCell({ mapperField, item }: PropsType) {
	const statusIconVariant =
		typeof mapperField.statusIconVariant === 'function'
			? mapperField.statusIconVariant(item)
			: mapperField.statusIconVariant;

	return (
		<StatusIcon
			variant={statusIconVariant as StatusIconProps['variant']}
			size="small"
		/>
	);
}

export default StatusIconCell;
