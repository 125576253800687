import styled from 'styled-components';

export const ActionFloatingMenuWrapper = styled.div<{
	width?: number;
	position?: 'top' | 'bottom';
}>`
	position: absolute;
	top: ${({ theme, position }) => position === 'top' && theme.spacing.small};
	bottom: ${({ theme, position }) =>
		position === 'bottom' && theme.spacing.small};
	left: ${({ width }) => width && `calc(50% - ${width / 2}px)`};
	display: inline-flex;
	gap: 6px;
	align-items: center;
	justify-content: center;
	padding: 6px;
	border-radius: 14px;
	background-color: ${({ theme }) => theme.colors.system.text.default};
	box-shadow: ${({ theme }) => theme.shadows.elevation.xl};
	z-index: ${({ theme }) => theme.zIndex.modal};
`;

export const ArrowControlsWrapper = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing.xxs};
	align-items: center;
	justify-content: center;
`;
