import styled from 'styled-components';

import { Icon } from '../../../../../common/components/presentation/Icon';
import { Textbase } from '../../../../../common/components/presentation/NewTypography';

export const ResendContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 32px;
`;

export const DescriptionText = styled(Textbase)`
	color: ${({ theme }) => theme.colors.dark[700]};
	text-align: center;
	margin-top: 24px;
`;

export const DescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 48px;
	max-width: 400px;
	text-align: center;
`;

export const SendIcon = styled(Icon)`
	z-index: ${({ theme }) => theme.zIndex.min};
`;

export const SendIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-top: 48px;
`;

export const GoodkindLogo = styled(Icon)``;

export const CheckYourEmailBanner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 80px;
`;
