import styled from 'styled-components';

export const IconContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	column-gap: ${({ theme }) => theme.spacing.large};
`;

export const IconItem = styled.div<{ active?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: ${({ theme }) => theme.shapeRadius.xs};

	background-color: ${({ theme, active }) =>
		active ? theme.colors.primary.background.defaultHover : 'transparent'};

	&:hover {
		background-color: ${({ theme }) =>
			theme.colors.primary.background.defaultHover};
	}
`;
