import styled from 'styled-components';

export const SelectionCardWrapper = styled.div<{
	isActive?: boolean;
	size?: 'xs' | 'regular';
}>`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.large};
	border: 1px solid
		${({ theme, isActive }) =>
			isActive
				? theme.colors.primary.border.default
				: theme.colors.system.border.weak};
	border-radius: ${({ size, theme }) =>
		size === 'xs' ? '10px' : theme.shapeRadius.medium};
	padding: ${({ size, theme }) =>
		size === 'xs' ? `` : `${theme.spacing.medium} ${theme.spacing.large}`};
	background-color: ${({ theme, isActive }) =>
		isActive
			? theme.colors.primary.background.default
			: theme.colors.system.background.system};
	cursor: pointer;

	&:hover {
		border: 1px solid
			${({ theme, isActive }) =>
				isActive
					? theme.colors.primary.border.default
					: theme.colors.system.border.medium};
	}
`;

export const TextContainer = styled.div<{ centered?: boolean }>`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
`;
export const ThumbnailWrapper = styled.div`
	width: ${({ theme }) => theme.spacing['xxxl']};
	height: ${({ theme }) => theme.spacing['xxxl']};
	border-radius: ${({ theme }) => theme.shapeRadius['50']};
	overflow: hidden;
	border: 1px solid ${({ theme }) => theme.colors.neutral.border.default};
	background-color: ${({ theme }) => theme.colors.system.background.weak};

	img {
		height: 100%;
		width: 100%;
		display: block;
		object-fit: contain;
	}
`;
