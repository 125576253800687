import { MergedTheme } from '@common/design-system/global/theme';
import styled from 'styled-components';

const getPadding = (
	theme: MergedTheme,
	size?: 'small' | 'medium' | 'large' | 'xl',
) => {
	switch (size) {
		case 'small':
			return `${theme.spacing.small} ${theme.spacing.small}`;
		case 'medium':
			return `${theme.spacing.medium} ${theme.spacing.medium}`;
		case 'large':
			return `${theme.spacing.large} ${theme.spacing.medium}`;
		case 'xl':
			return `${theme.spacing.xl} ${theme.spacing.xl}`;
	}
};

export const CardButton = styled.div<{
	active?: boolean;
	disabled?: boolean;
	variant?: 'horizontal' | 'vertical';
	size?: 'small' | 'medium' | 'large' | 'xl';
	centered?: boolean;
	activeWhite?: boolean;
}>`
	position: relative;
	display: flex;
	flex-direction: ${({ variant }) =>
		variant === 'horizontal' ? 'row' : 'column'};
	align-items: ${({ centered, variant }) =>
		centered || (!centered && variant === 'horizontal') //Horizontal need to align-items vertically still
			? 'center'
			: 'flex-start'};
	justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
	align-self: stretch;
	width: 100%;
	height: auto;
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	gap: ${({ theme }) => theme.spacing.xs};
	padding: ${({ theme, size }) => getPadding(theme, size)};
	border-radius: ${({ theme, size }) =>
		size === 'small' ? theme.shapeRadius.small : theme.shapeRadius.medium};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	transition: ${({ theme }) => theme.transitions.ease01};
	background-color: ${({ theme, active, activeWhite, disabled }) =>
		disabled
			? theme.colors.system.background.weak
			: active && !activeWhite
				? theme.colors.primary.background.default
				: theme.colors.system.background.system};
	border: 1px solid
		${({ theme, active }) =>
			active
				? theme.colors.primary.border.default
				: theme.colors.system.border.weak};

	&:hover {
		border-color: ${({ theme, active }) =>
			active
				? theme.colors.primary.border.default
				: theme.colors.system.border.medium};
		box-shadow: ${({ theme, disabled }) =>
			disabled ? 'none' : theme.shadows.elevation.small};
	}

	.texts-container {
		display: flex;
		flex-direction: column;
		align-items: ${({ variant }) =>
			variant === 'horizontal' ? 'flex-start' : 'center'};
		justify-content: center;
		row-gap: ${({ theme }) => theme.spacing.xxs};
	}

	& > :nth-child(3) {
		margin-left: ${({ variant }) => variant === 'horizontal' && 'auto'};
	}
`;

export const CardButtonsContainer = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: center;
	width: 100%;
	gap: ${({ theme }) => theme.spacing.small};
`;

export const TextContainer = styled.div<{ textAlignment?: string }>`
	${({ textAlignment }) => textAlignment && `text-align: ${textAlignment}`};
`;
