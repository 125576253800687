import emojiRegex from 'emoji-regex';

export const UrlRegex =
	/(^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$)|(^mailto:(\/\/)?[\w-\.]+@([\w-]+\.)+[\w-]{2,4}(\?subject=[^?&]+)?)$/;

export const UrlWithoutProtocolRegex =
	/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

export const DomainWithProtocolRegex =
	/https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

export const RouteRegex = /^\/[^\/][a-z0-9-_~!?$&'()*+,;=:@./]+$|\/$/;

export const colorRegex = /#([a-f0-9]{3}){1,2}\b/i;

export const MergeTagRegex = /{{{(.*?)}}}/;

export const MergeTagSpaceAroundRegex = /(^|\S){{{(.*?)}}}(\S|$)/;

export const emailRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BASE_64_REGEX =
	/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const MongoObjectIdRegex = /^[0-9a-fA-F]{24}$/;

export const emojiAndSymbolsRegex = emojiRegex;
