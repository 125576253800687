/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
// import default tab component
import { SectionTab } from 'polotno/side-panel';

import { Divider, Icon } from '@common/design-system/components/atoms';

import * as S from './TextSection.styles';
import { TYPOGRAPHY_TEMPLATES } from '@app/constants/modules/postcard';

type SectionTabProps = {
	onClick: any;
	active: boolean;
};

const TextSection = {
	name: 'text',
	Tab: ({ active, onClick }: SectionTabProps) => {
		return (
			<SectionTab name="Text" active={active} onClick={onClick}>
				<Icon
					iconName="text-aa"
					size="large"
					weight="fill"
					color={active ? 'primary.text.hover' : 'system.icon.default'}
				/>
			</SectionTab>
		);
	},
	// we need observer to update component automatically on any store changes
	Panel: observer(({ store }: { store: any }) => {
		const basicTexts = TYPOGRAPHY_TEMPLATES?.filter(
			(template) => template.type === 'basic',
		);

		const customTexts = TYPOGRAPHY_TEMPLATES?.filter(
			(template) => template.type === 'custom',
		);

		const handleSelectTemplate = (json: any) => {
			// This flow is gotten directly from the polotno guy himself
			const pos = {
				x: store.width / 2,
				y: store.height / 2,
			};

			// what if all pages are removed while loading?
			// or no pages at all?
			if (!store.activePage) {
				return;
			}

			const baseSize = 1080 + 1080;
			const currentSize = store.width + store.height;
			const scale = currentSize / baseSize;

			const dX = pos
				? pos.x - (json.width / 2) * scale
				: store.width / 2 - (json.width / 2) * scale;
			const dY = pos
				? pos.y - (json.height / 2) * scale
				: store.height / 2 - (json.height / 2) * scale;
			store.history.transaction(() => {
				const texts = json.pages[0].children;
				const ids: string[] = [];
				texts.forEach((text: any) => {
					delete text.id;
					const { id } = store.activePage?.addElement({
						...text,
						fontSize: text.fontSize * scale,
						x: text.x * scale + dX,
						y: text.y * scale + dY,
						width: text.width * scale,
						height: text.height * scale,
					}) as any;
					ids.push(id);
				});
				store.selectElements(ids);
			});
		};

		return (
			<S.Container>
				<S.UpperSection>
					{basicTexts.map(({ image, json }, index) => {
						return (
							<div
								aria-hidden
								className="text-item"
								key={`${image}-${index}`}
								onClick={() => handleSelectTemplate(json)}
							>
								<img alt="Text template" src={image} />
							</div>
						);
					})}
				</S.UpperSection>

				<Divider />

				<S.LowerSection>
					<S.TextCardContainer>
						{customTexts.map(({ image, json }, index) => {
							return (
								<S.TextCard
									key={index}
									src={image}
									onClick={() => handleSelectTemplate(json)}
								/>
							);
						})}
					</S.TextCardContainer>
				</S.LowerSection>
			</S.Container>
		);
	}),
};

export default TextSection;
