import styled, { css } from 'styled-components';
import { InputPropsType } from '../Input/Input';
import { InputSizes } from '../Input/Input.styles';

export const TagWrapper = styled.div<{
	isOpenButtonVisible?: boolean;
	textArea?: boolean;
	textAreaRows?: number;
	hasFocus: boolean;
	hasValue: boolean;
	hasLabel: boolean;
	hasError?: boolean;
	disabled?: boolean;
	size?: InputPropsType['size'];
}>`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};

	.tagify {
		--tag-pad: 0.1rem; // https://github.com/yairEO/tagify#css-variables
		/* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		border: none;

		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}

		&[disabled] {
			background: none;
			opacity: 1;
		}
	}

	//Font styles
	.tagify--mix .tagify__input {
		display: ${({ textArea }) => (!textArea ? 'flex' : 'block')};
		align-items: center;
		width: 100%;
		padding-top: ${({ theme }) => theme.spacing.xs};
		padding-bottom: ${({ theme }) => theme.spacing.xs};
		padding-left: 14px;
		padding-right: ${({ isOpenButtonVisible }) =>
			isOpenButtonVisible ? `2.5rem` : `1.5rem`};

		height: ${({ textArea, size }) =>
			textArea ? `auto` : size && `${InputSizes[size]}px`};
		min-height: ${({ textArea, textAreaRows, size }) =>
			textArea && textAreaRows && size
				? `${textAreaRows * InputSizes[size]}px`
				: size && `${InputSizes[size]}px`};
		font-size: ${({ theme }) => theme.fonts.sizes.small};
		line-height: ${({ theme }) => theme.fonts.lineHeight.large};
		background-color: ${({ theme, disabled }) =>
			disabled
				? theme.colors.system.background.weak
				: theme.colors.system.background.system};
		border: 1px solid
			${({ theme, hasError }) =>
				hasError
					? theme.colors.error.border.default
					: theme.colors.system.border.medium};
		border-radius: ${({ theme }) => theme.shapeRadius.small};

		box-shadow: ${({ theme, hasError }) =>
			hasError && theme.shadows.controls.error};

		pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

		&:hover {
			box-shadow: ${({ theme, hasError }) =>
				!hasError && theme.shadows.controls.hover};
		}

		&:focus,
		&:active {
			box-shadow: ${({ theme, hasError }) =>
				!hasError && theme.shadows.controls.selected};
			border: 1px solid
				${({ theme, hasError }) =>
					hasError ? 'initial' : theme.colors.primary.border.default};
			outline: none;
		}

		&::placeholder {
			color: ${({ theme }) => theme.colors.system.text.weak};
		}
	}

	//Fix wrap in long tags
	.tagify__tag-text {
		white-space: nowrap;
		font-size: ${({ theme }) => theme.fonts.sizes.small} !important;
	}

	// Remove the default margin of the remove button
	.tagify__tag__removeBtn {
		margin-right: 0px;
	}

	// Change default tag padding
	.tagify__tag {
		padding: 3px;
	}

	// Logic to make the tag to behave as an input
	// - Don't break the text when in too long
	// - Hide the text when is overlapping and it's scroll bar
	// - Reduce the width so don't overlap the button when is an input
	${({ textArea }) => {
		const isAnInput = !textArea;

		return (
			isAnInput &&
			css`
				.tagify__input {
					white-space: nowrap;
					overflow: auto;
				}

				.tagify__input br {
					display: none;
				}

				.tagify__input div,
				.tagify__input p {
					display: inline-block;
					white-space: nowrap;
					overflow: auto;
					overflow-y: hidden;
				}

				.tagify__input {
					&::-webkit-scrollbar {
						display: none !important;
					}

					&::-webkit-scrollbar-track {
						display: none !important;
					}

					&::-webkit-scrollbar-thumb {
						display: none !important;
					}

					&::-webkit-scrollbar-thumb:hover {
						display: none !important;
					}
					&::-webkit-scrollbar-thumb:active {
						display: none !important;
					}
				}
			`
		);
	}}
`;

export const ButtonContainer = styled.div<{
	textArea?: boolean;
	inputSize?: InputPropsType['size'];
}>`
	position: absolute;
	right: 1px;
	padding-right: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	top: ${({ inputSize }) => (inputSize === 'small' ? '2px' : '4px')};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	z-index: ${({ theme }) => theme.zIndex.base};
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
`;

export const LabelWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const InputAndButtonWrapper = styled.div`
	position: relative;
`;
