import { useAnalytics } from '@segment/analytics-react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/redux/types';
import { getStorytellerName } from '@app/utils/modules/storytellers';
import { useAuth } from '@app/services/Auth/useAuth';

const useSegement = () => {
	const workspace = useSelector(
		({ workspace }: RootState) => workspace.workspaceInfo,
	);

	const { user } = useAuth();

	const { group, page, identify, track } = useAnalytics();
	const currentUserName = getStorytellerName(user);

	function pageTracker(category: string, eventTitle: string, properties: any) {
		if (workspace?.name) {
			const userInfo = {
				Id: user?._id,
				name: currentUserName,
				email: user?.email,
				location: properties?.pathname,
				workspace: workspace?.id,
				salesforceAccountID: workspace?.csTrackerId,
			};
			page(category, eventTitle, userInfo);
		}
	}
	function eventTracker(event: string) {
		if (workspace?.id) {
			const properties = {
				Id: user.id,
				name: currentUserName,
				email: user.email,
				workspace: workspace?.id,
				salesforceAccountID: workspace?.csTrackerId,
			};

			track(event, properties);
		}
	}
	function identifyUser() {
		if (user?._id) {
			const userIdentity = {
				id: user?._id,
				name: currentUserName,
				email: user?.email,
				workspace: workspace?.id,
				salesforceAccountID: workspace?.csTrackerId,
			};
			identify(userIdentity, user?._id);
		}
	}

	function setWorkspceGroup() {
		if (workspace?.id && workspace?.csTrackerId) {
			const workpsaceInfo = {
				workspaceID: workspace?.id,
				workspaceName: workspace?.name,
				salesforceAccountID: workspace?.csTrackerId,
			};
			group(workspace?.csTrackerId, workpsaceInfo);
		}
	}

	return {
		pageTracker,
		identifyUser,
		setWorkspceGroup,
		eventTracker,
	};
};

export default useSegement;
