import styled from 'styled-components';
import { SmallH1Props } from './types';

export const StyledH1 = styled.h1<SmallH1Props>`
	font-size: 28px;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: -0.09px;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	margin: 0;
`;
