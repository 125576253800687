import styled from 'styled-components';
import { InputSizes } from '../Input/Input.styles';
import { InputPropsType } from '../Input/Input';

const LEADING_WIDTH = 76;

export const PhoneNumberInputContainer = styled.div<{
	$size?: InputPropsType['size'];
	$hasError?: boolean;
}>`
	.PhoneInput {
		position: relative;
		min-height: ${({ $size }) => $size && `${InputSizes[$size]}px`};
	}

	.PhoneInputInput {
		padding-left: ${({ theme }) =>
			`calc(${LEADING_WIDTH}px + ${theme.spacing.small})`};
	}

	.PhoneInputCountry {
		z-index: ${({ theme }) => theme.zIndex.base};
		position: absolute;
		top: ${({ $size }) => ($size ? `${InputSizes[$size]}px` : '50%')};
		transform: ${({ $size }) =>
			$size ? `translateY(-${InputSizes[$size]}px)` : `translateY(-50%)`};
		height: 100%;
		max-height: ${({ $size }) => ($size ? `${InputSizes[$size]}px` : '100%')};

		display: flex;
		align-items: center;
		justify-content: center;
		gap: ${({ theme }) => theme.spacing.xs};
		padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
		background-color: ${({ theme }) => theme.colors.system.background.weak};
		border: 1px solid ${({ theme }) => theme.colors.system.border.medium};
		min-width: ${LEADING_WIDTH}px;
		border-top-left-radius: ${({ theme }) => theme.shapeRadius.regular};
		border-bottom-left-radius: ${({ theme }) => theme.shapeRadius.regular};
	}
`;
