import styled from 'styled-components';

export const MenuIconWrapper = styled.div`
	position: absolute;
	right: ${({ theme }) => theme.spacing.medium};
	top: ${({ theme }) => theme.spacing.medium};
	border-radius: ${({ theme }) => theme.shapeRadius[6]};
`;

export const VideoList = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: ${({ theme }) => theme.spacing.xs};
	overflow: hidden;
	cursor: pointer;
	position: relative;
`;

export const ImageWrapper = styled.div`
	img {
		width: 100%;
		height: 214px;
		object-fit: cover;
	}
`;

export const VideoTextWrapper = styled.div`
	height: 140px;
	padding: ${({ theme }) => theme.spacing.medium};
	border-radius: ${({ theme }) => theme.spacing.xs};
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border: 1px solid;
	border-color: ${({ theme }) => theme.colors.system.background.xStrong};
	border-top: none;
	background: ${({ theme }) => theme.colors.system.background.system};
	overflow: hidden;
	gap: ${({ theme }) => theme.spacing.medium};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const AuthorWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.small};
`;

export const Div = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const RadioIconWrapper = styled.div`
	position: absolute;
	left: ${({ theme }) => theme.spacing.medium};
	top: ${({ theme }) => theme.spacing.medium};
	border-radius: ${({ theme }) => theme.shapeRadius[6]};
`;

export const VideoListContainer = styled.div<{ column?: number }>`
	display: grid;
	grid-template-columns: ${({ column = 6 }) => `repeat(${column}, 1fr)`};
	gap: ${({ theme }) => theme.spacing.large};

	@media (max-width: 1620px) {
		grid-template-columns: ${({ column = 5 }) => `repeat(${column}, 1fr)`};
	}

	@media (max-width: 1400px) {
		grid-template-columns: ${({ column = 4 }) => `repeat(${column}, 1fr)`};
	}

	@media (max-width: 1200px) {
		grid-template-columns: ${({ column = 3 }) => `repeat(${column}, 1fr)`};
	}

	@media (max-width: 960px) {
		grid-template-columns: ${({ column = 2 }) => `repeat(${column}, 1fr)`};
	}

	@media (max-width: 768px) {
		grid-template-columns: ${({ column = 1 }) => `repeat(${column || 1}, 1fr)`};
	}
`;

export const VideoIdWrapper = styled.div`
	position: absolute;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	top: 47%;
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	z-index: ${({ theme }) => theme.zIndex.base};
	right: ${({ theme }) => theme.spacing.small};
	padding: ${({ theme }) => theme.spacing.small};
`;
