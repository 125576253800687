export enum storyStatus {
	unassigned = 'unassigned',
	assigned = 'assigned',
	videoCompleted = 'videoCompleted',
	pendingApproval = 'pendingApproval',
	sent = 'sent',
	saved = 'saved',
	scheduled = 'scheduled',
	pendingSchedule = 'pendingSchedule',
}
