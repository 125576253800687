import React from 'react';
import { TableMapperType } from '../../types';
import { Badge } from '@common/design-system/components/atoms';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

function BadgeCell({ mapperField, item }: PropsType) {
	const badgeIconName =
		typeof mapperField.badge?.icon === 'function'
			? mapperField.badge?.icon(item)
			: mapperField.badge?.icon;

	const badgeVariant =
		typeof mapperField.badge?.variant === 'function'
			? mapperField.badge?.variant(item)
			: mapperField.badge?.variant;

	const badgeText =
		typeof mapperField.badge?.text === 'function'
			? mapperField.badge?.text(item)
			: mapperField.badge?.text;

	const badgeIconWeight =
		typeof mapperField.badge?.iconWeight === 'function'
			? mapperField.badge?.iconWeight(item)
			: mapperField.badge?.iconWeight;

	return (
		<Badge
			iconBadge={!badgeText}
			text={badgeText}
			size={mapperField.badge?.size}
			iconName={badgeIconName}
			variant={badgeVariant}
			iconWeight={badgeIconWeight}
			squared={mapperField.badge?.squared}
			onClick={mapperField.badge?.onClick}
			onRemove={mapperField.badge?.onRemove}
		/>
	);
}

export default BadgeCell;
