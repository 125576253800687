import styled from 'styled-components';
import { evalColorPath } from '@common/design-system/global/utils';

const getBulletColor = (theme: any, variant?: string, color?: string) => {
	if (color) {
		return evalColorPath(theme.colors, color);
	} else if (variant) {
		if (variant === 'neutral') {
			return theme.colors[variant].border.hover;
		}

		return theme.colors[variant].icon.default;
	} else {
		return '';
	}
};

const getFrameColor = (theme: any, variant?: string, color?: string) => {
	if (color) {
		return evalColorPath(theme.colors, color);
	} else if (variant) {
		if (variant === 'neutral') {
			return theme.colors.system.background.medium;
		}

		return theme.colors[variant].background.defaultHover;
	} else {
		return '';
	}
};

export const StatusIndicatorFrame = styled.div<{
	variant?: string;
	frameColor?: string;
}>`
	width: 23px;
	height: 23px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme, variant, frameColor }) =>
		getFrameColor(theme, variant, frameColor)};
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
`;

export const StatusIndicator = styled.div<{ variant?: string; color?: string }>`
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
	background-color: ${({ theme, variant, color }) =>
		getBulletColor(theme, variant, color)};
`;
