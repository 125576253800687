import React from 'react';

import * as S from './StatusIcon.styles';
import { Icon } from '../Icon';

type PropsType = {
	variant?: 'error' | 'warning' | 'success' | 'neutral';
	size?: 'small' | 'medium';
	round?: boolean;
};

function StatusIcon({
	variant = 'success',
	size = 'medium',
	round = true,
}: PropsType) {
	const iconNameAndColorMap = {
		error: {
			iconName: 'close',
			color: 'error.icon.default',
		},
		warning: {
			iconName: '',
			color: 'warning.icon.default',
		},
		success: {
			iconName: 'check',
			color: 'success.icon.default',
		},
	};

	const isWarning = variant === 'warning';
	const isNeutral = variant === 'neutral';

	return (
		<S.Wrapper size={size} variant={variant} round={round}>
			{!isWarning && !isNeutral && (
				<Icon
					iconName={iconNameAndColorMap[variant].iconName}
					customNumberSize={11}
					weight="bold"
					color={iconNameAndColorMap[variant].color}
				/>
			)}

			{isWarning && <>!</>}
			{isNeutral && <></>}
		</S.Wrapper>
	);
}

export default StatusIcon;
export type { PropsType as StatusIconProps };
