import React from 'react';

import * as S from './StatusIndicator.styles';

type PropsType = {
	variant?: 'primary' | 'error' | 'warning' | 'success' | 'accent' | 'neutral';
	color?: string;
	frameColor?: string;
	withFrame?: boolean;
};

function StatusIndicator({ variant, color, frameColor, withFrame }: PropsType) {
	if (withFrame) {
		return (
			<S.StatusIndicatorFrame frameColor={frameColor} variant={variant}>
				<S.StatusIndicator color={color} variant={variant} />
			</S.StatusIndicatorFrame>
		);
	}

	return <S.StatusIndicator color={color} variant={variant} />;
}

export default StatusIndicator;
export type { PropsType as StatusIndicatorPropsType };
