import styled from 'styled-components';

export const StyledH2 = styled.h2`
	font-size: 28px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	margin: 0;
`;
