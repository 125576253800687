import styled from 'styled-components';

export const TriggerWrapper = styled.div`
	display: inline-block;
	width: fit-content;
	cursor: pointer;
`;

export const PopoverContainer = styled.div<{ withNoPadding?: boolean }>`
	padding: ${({ withNoPadding, theme }) =>
		!withNoPadding && theme.spacing.medium};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	box-shadow: ${({ theme }) => theme.shadows.elevation.large};
	z-index: ${({ theme }) => theme.zIndex.popover};
	background-color: ${({ theme }) => theme.colors.system.background.system};
`;

export const CloseContainer = styled.div`
	position: absolute;
	top: ${({ theme }) => theme.spacing.xxs};
	right: ${({ theme }) => theme.spacing.xxs};
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;
