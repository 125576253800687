import styled from 'styled-components';

import { EngagementBarProps } from './types';

export const HiddenPercentage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	> * {
		font-size: 10px;
		line-height: 10px;
		color: ${({ theme }) => theme.colors.neutral[400]};
	}
`;

export const EngagementProgress = styled.div<EngagementBarProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: ${({ engagement }) => engagement}%;
	height: 100%;
	border-radius: ${({ theme }) => theme.borderRadius?.subtle};
	background: ${({ theme }) => theme.colors.primary[500]};
`;

export const EngagementBarContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	max-width: 144px;
	min-width: 80px;
	height: 12px;
	border-radius: ${({ theme }) => theme.borderRadius?.subtle};
	background: ${({ theme }) => theme.colors.neutral[50]};
	z-index: ${({ theme }) => theme.zIndex.min};

	&:hover {
		${HiddenPercentage} {
			display: flex;
		}
	}
`;
