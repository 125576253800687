import React, {
	useState,
	useEffect,
	useMemo,
	createContext,
	ReactNode,
	useContext,
} from 'react';
import { decode, encode } from 'js-base64';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/redux/types';
import { CTA } from '@app/redux/cta/types';
import { CTAListEnum } from '@app/constants/cta';
import { mapObjectToFormData } from '@app/utils/mapObjectToFormData';
import { createCta, updateCta } from '@app/redux/cta/action';
import { getCampaignsPerCTA } from '@app/redux/campaign/action';

import {
	checkIsCTAUsedType,
	createCTAType,
	setCTASelectedFieldsType,
	updateCTAType,
} from '@app/containers/CampaignCreation/types';
import { CTATypeEnum } from '@app/constants/modules/ctas';
import { getActionFromCallToActions } from '@app/utils/modules/actions';
import { BASE_64_REGEX } from '@app/constants/regex';
import { CTAFieldsValidator } from '../utils';

type CallToActionContextType = {
	isCTAMissing: boolean;
	CTAFieldsErrors: { [key: string]: string };
	isInvalid: boolean;
	setCTAFields: (cta: Partial<CTA> | null, changedAll: boolean) => void;
	setCTAMissing: any;
	storedCTAFields: any;
	getUpdatedCta: (type: string, fields: Partial<CTA>) => Partial<CTA>;
	validateCTAFields: (
		fields: Partial<CTA>,
		callback?: () => void,
	) => Promise<boolean>;
	setCTASelected: any;
	CTASelected: any;
	setCTASelectedFields: (props: setCTASelectedFieldsType) => void;
	isDirty: boolean;
	createCTA: (props: createCTAType) => void;
	updateCTA: (props: updateCTAType) => void;
	checkIsCTABeingUsed: (props: checkIsCTAUsedType) => void;
	clearErrors: () => void;
	isSavingWhenChangingStep: boolean;
	handleSaveWhenChangingStep: (isSaving: boolean) => void;
};

const builderCTAContext = createContext<CallToActionContextType>(
	{} as CallToActionContextType,
);

function getUpdatedCta(type: string, fields: Partial<CTA>) {
	let updatedCta = fields;
	switch (type) {
		case CTAListEnum.doubleLink:
			updatedCta = {
				...updatedCta,
				primaryLinkTitle: fields.primaryLinkTitle,
				primaryLinkUrl: fields.primaryLinkUrl,
				secondaryLinkTitle: fields.secondaryLinkTitle,
				secondaryLinkUrl: fields.secondaryLinkUrl,
			};
			break;

		case CTAListEnum.link:
			updatedCta = {
				...updatedCta,
				linkButtonTitle: fields.linkButtonTitle,
				linkUrl: fields.linkUrl,
			};
			break;

		case CTAListEnum.code:
			updatedCta = {
				...updatedCta,
				buttonText: fields.buttonText,
			};
			break;

		default:
			break;
	}

	return updatedCta;
}

export default function BuilderCTAProvider({
	children,
}: {
	children: ReactNode;
	rest?: any;
}) {
	const dispatch = useDispatch();
	/**
	 * Handles the outside save
	 * **/
	const [isSavingWhenChangingStep, setIsSavingWhenChangingStep] =
		useState<boolean>(true);

	const handleSaveWhenChangingStep = (value: boolean) => {
		setIsSavingWhenChangingStep(value);
	};
	// Init selector and support methods used in the provider config

	const callToActions = useSelector(
		({ campaign }: RootState) => campaign?.savedCampaign?.callToActions,
	);

	/**
	 * - Provider states -
	 * storedCTAFields is the CTA fields that are saved in the campaign
	 *
	 * CTASelected is a temporal state, so we can make changes, edit, select, without saving
	 * and discard the changes without affecting the savedCTAFields.
	 *
	 * When the CTA is 'Saved' we update the storedCTAFields with the CTASelected
	 * */

	// Saved CTA
	const [storedCTAFields, setStoredCTAFields] = useState<Partial<CTA> | null>();

	const [CTASelected, setCTASelected] = useState<Partial<CTA>>();

	const [CTAFieldsErrors, setCTAFieldsErrors] = useState({});

	const hasCTAFieldsErrors = useMemo(
		() => Object.keys(CTAFieldsErrors || {}).length > 0,
		[CTAFieldsErrors],
	);

	const isDirty = useMemo(() => {
		//These are the initial states for the saved and temporal CTA
		if (CTASelected === null && JSON.stringify(CTAFieldsErrors) === '{}')
			return false;

		return JSON.stringify(storedCTAFields) !== JSON.stringify(CTASelected);
	}, [storedCTAFields, CTASelected]);

	const [isCTAMissing, setCTAMissing] = useState<boolean>(false);

	const handleEncodeScript = (cta: Partial<CTA>) => {
		cta.script = encode(cta.script || '');
	};

	const clearErrors = () => {
		setCTAFieldsErrors({});
	};

	/** Provider validators: Templates and CTA */

	const validateCTAFields = async (
		fields: Partial<CTA>,
		callback?: () => void,
	) => {
		try {
			await CTAFieldsValidator(fields);
			clearErrors();
			if (callback) {
				callback();
			}

			return true;
		} catch (errors: any) {
			setCTAFieldsErrors(errors);
			return false;
		}
	};

	/** Provider setters - Saved CTA and temporal state */

	// Saved CTA
	const setCTAFields = (cta: Partial<CTA> | null, changedAll: boolean) => {
		if (changedAll) {
			setStoredCTAFields(cta);

			if (cta) {
				validateCTAFields(cta);
			}
		} else {
			setStoredCTAFields((prev) => {
				const fieldsFormat = { ...prev, ...cta };
				validateCTAFields(fieldsFormat);
				return fieldsFormat;
			});
		}
	};

	//Temporally set CTA fields
	const setCTASelectedFields = ({
		cta,
		changedAll,
		validate = true,
	}: setCTASelectedFieldsType) => {
		if (changedAll) {
			setCTASelected(cta);
			if (validate) {
				validateCTAFields(cta);
			} else {
				clearErrors();
			}
		} else {
			setCTASelected((prev) => {
				const fieldsFormat = { ...prev, ...cta };

				if (validate) {
					validateCTAFields(fieldsFormat);
				} else {
					clearErrors();
				}

				return fieldsFormat;
			});
		}
	};

	const createCTA = async ({ onFail, onSuccess, cta }: createCTAType) => {
		const editedCTA = { ...cta };

		if (editedCTA?.type === CTATypeEnum.CODE) handleEncodeScript(editedCTA);

		const createCTAForm = new FormData();
		mapObjectToFormData(createCTAForm, editedCTA);
		try {
			await validateCTAFields(cta);
			const createResponse = await dispatch(createCta(createCTAForm));
			if (createResponse) {
				onSuccess && onSuccess(createResponse);
			}
		} catch (e) {
			onFail && onFail(e);
		}
	};

	const updateCTA = async ({ onFail, onSuccess, cta }: updateCTAType) => {
		const editedCTA = { ...cta };

		if (editedCTA?.type === CTATypeEnum.CODE) handleEncodeScript(editedCTA);

		const updateCTAForm = new FormData();
		mapObjectToFormData(updateCTAForm, editedCTA);
		const ctaId = cta?._id as string;
		try {
			await validateCTAFields(cta);
			const updateResponse = await dispatch(
				updateCta({ form: updateCTAForm, id: ctaId, isV2: true }),
			);
			if (updateResponse) {
				onSuccess && onSuccess(updateResponse);
			}
		} catch (e) {
			onFail && onFail(e);
		}
	};

	const checkIsCTABeingUsed = async ({
		ctaId,
		onSuccess,
		onFail,
	}: checkIsCTAUsedType) => {
		try {
			const checkIsCTABeingUsed: any = await dispatch(
				getCampaignsPerCTA(ctaId),
			);
			if (checkIsCTABeingUsed) {
				const isBeingUsed = Boolean(checkIsCTABeingUsed?.campaign);
				onSuccess && onSuccess(isBeingUsed);
			}
		} catch (e) {
			onFail && onFail(e);
		}
	};

	// Clean CTA error when CTA fields change
	useEffect(() => {
		if (storedCTAFields) {
			setCTAMissing(false);
		}
	}, [storedCTAFields]);

	// Validate fields on component mount
	useEffect(() => {
		if (storedCTAFields) {
			validateCTAFields(storedCTAFields);
		}

		// Allow saving when changing step
		handleSaveWhenChangingStep(true);
	}, []);

	/*** Get the CTA from the campaign
	 * CTA script is decoded  in 3 places:
	 * 1. When we create or update a CTA on CTAManagerModal.tsx
	 * 2. When we list the CTAs on SelectCTA.tsx
	 * 3. When we get the CTA from the campaign on the builderCTAProvider.tsx
	 *
	 * CTA script is encoded:
	 * 1. When we create or update a CTA on BuilderCTAProvider.tsx
	 * ***/
	useEffect(() => {
		if (callToActions?.length) {
			const cta = getActionFromCallToActions(callToActions);
			const ctaCopy: Partial<CTA> = !!cta ? { ...cta } : {};
			if (ctaCopy?.type === CTATypeEnum.CODE) {
				const isBase64 = BASE_64_REGEX.test(ctaCopy?.script || '');
				ctaCopy.script = isBase64
					? decode(ctaCopy?.script || '')
					: ctaCopy?.script;
			}
			setStoredCTAFields(ctaCopy);
			setCTASelected(ctaCopy);
		}
	}, [callToActions]);

	return (
		<builderCTAContext.Provider
			value={{
				isCTAMissing,
				CTAFieldsErrors,
				isInvalid: isCTAMissing || hasCTAFieldsErrors,
				setCTAFields,
				setCTAMissing,
				storedCTAFields,
				getUpdatedCta,
				validateCTAFields,
				isSavingWhenChangingStep,
				handleSaveWhenChangingStep,
				setCTASelected,
				CTASelected,
				setCTASelectedFields,
				isDirty,
				createCTA,
				updateCTA,
				checkIsCTABeingUsed,
				clearErrors,
			}}
		>
			{children}
		</builderCTAContext.Provider>
	);
}

export function useBuilderCTAContext() {
	return useContext(builderCTAContext);
}
