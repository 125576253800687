import {
	postcardImageUrls,
	PostcardSizeEnum,
	postcardSizeMap,
} from '@app/constants/modules/postcard';
import { WorkspaceFontType } from '@app/redux/workspace/types';
import { StoreType } from 'polotno/model/store';

export function getPostcardOverlay(
	page: 'front' | 'back',
	size: PostcardSizeEnum,
) {
	if (page === 'front') {
		if (size === PostcardSizeEnum.FOUR_BY_SIX) {
			return postcardImageUrls['4x6_overlay_front'];
		}
		if (size === PostcardSizeEnum.SIX_BY_NINE) {
			return postcardImageUrls['6x9_overlay_front'];
		}
		if (size === PostcardSizeEnum.SIX_BY_ELEVEN) {
			return postcardImageUrls['6x11_overlay_front'];
		}
	}

	if (page === 'back') {
		if (size === PostcardSizeEnum.FOUR_BY_SIX) {
			return postcardImageUrls['4x6_overlay_back'];
		}
		if (size === PostcardSizeEnum.SIX_BY_NINE) {
			return postcardImageUrls['6x9_overlay_back'];
		}
		if (size === PostcardSizeEnum.SIX_BY_ELEVEN) {
			return postcardImageUrls['6x11_overlay_back'];
		}
	}
}

export function getPostcardWidthAndHeight(size: PostcardSizeEnum) {
	return postcardSizeMap[size]
		? postcardSizeMap[size]
		: postcardSizeMap[PostcardSizeEnum.FOUR_BY_SIX];
}

export function removeColorDuplicates(array: string[]) {
	const unique: string[] = [];

	array.forEach((color) => {
		color = color.toLowerCase();
		if (!unique.includes(color)) {
			unique.push(color);
		}
	});

	return unique;
}

export function handleLoadFontsToStore(
	store: StoreType,
	fonts: WorkspaceFontType[],
) {
	fonts?.forEach((font: any) => {
		store.addFont({
			fontFamily: font?.name,
			url: font?.url,
		});
	});
}
