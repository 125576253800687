import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
	padding-bottom: 16px;
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	background: ${({ theme }) => theme.colors.system.background.system};
	box-shadow: ${({ theme }) => theme.shadows.elevation.medium};
	width: fit-content;

	.DayPicker {
		font-family: ${({ theme }) => theme.fonts.family.default};
	}

	.DayPicker-wrapper {
		padding-bottom: 0;
	}

	.DayPicker-NavButton {
		top: 15px;
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.14277 14.6035C5.9475 14.7987 5.63092 14.7987 5.43566 14.6035L4.72855 13.8964C4.53329 13.7011 4.53329 13.3845 4.72855 13.1893L9.81434 8.10348C10.0096 7.90822 10.0096 7.59163 9.81434 7.39637L4.72855 2.31059C4.53329 2.11532 4.53329 1.79874 4.72855 1.60348L5.43566 0.896371C5.63092 0.701109 5.9475 0.70111 6.14277 0.896372L12.6428 7.39637C12.838 7.59163 12.838 7.90822 12.6428 8.10348L6.14277 14.6035Z" fill="%23627084"/></svg>');
		width: 32px;
	}

	.DayPicker-NavButton--prev {
		left: 20px;
		transform: rotate(180deg);
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.14277 14.6035C5.9475 14.7987 5.63092 14.7987 5.43566 14.6035L4.72855 13.8964C4.53329 13.7011 4.53329 13.3845 4.72855 13.1893L9.81434 8.10348C10.0096 7.90822 10.0096 7.59163 9.81434 7.39637L4.72855 2.31059C4.53329 2.11532 4.53329 1.79874 4.72855 1.60348L5.43566 0.896371C5.63092 0.701109 5.9475 0.70111 6.14277 0.896372L12.6428 7.39637C12.838 7.59163 12.838 7.90822 12.6428 8.10348L6.14277 14.6035Z" fill="%23627084"/></svg>');
		width: 32px;
	}

	.DayPicker-Caption {
		margin-bottom: 16px;
		padding: 0;
		margin-top: 3px;
	}

	.DayPicker-Caption > div {
		text-align: center;
		font-size: ${({ theme }) => theme.fonts.sizes.regular};
		font-weight: ${({ theme }) => theme.fonts.weights.medium};
		color: ${({ theme }) => theme.colors.system.text.default};
	}

	.DayPicker-Month {
		margin: 16px;
		margin-bottom: 0;
	}

	.DayPicker-Weekdays {
		font-size: ${({ theme }) => theme.fonts.sizes.small};
		font-weight: ${({ theme }) => theme.fonts.weights.semiBold};
	}

	.DayPicker-Day {
		font-size: ${({ theme }) => theme.fonts.sizes.small};
		font-weight: ${({ theme }) => theme.fonts.weights.regular};
		width: 30px;
		height: 30px;
		padding: 5px;

		&.DayPicker-Day--disabled {
			color: ${({ theme }) => theme.colors.system.text.weak};
		}

		&.DayPicker-Day--today {
			background-color: ${({ theme }) =>
				theme.colors.primary.background.defaultActive};
			color: ${({ theme }) => theme.colors.system.text.default};
		}
	}
	.DayPicker-Day--outside {
		background: #eef3f7;
		border-radius: 0px;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
			.DayPicker-Day--outside
		) {
		background: ${({ theme }) => theme.colors.primary.icon.default};
		color: ${({ theme }) => theme.colors.system.background.system};
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
			.DayPicker-Day--outside
		):hover {
		background: ${({ theme }) => theme.colors.primary.background.default};
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	padding: 8px;
	column-gap: 8px;

	button {
		outline: 0 !important;
	}
`;
