import React from 'react';

import { FontLineHeightsType } from '../../../../global/types';

import { Icon, Text } from '../../../atoms';
import { Tooltip } from '../../Tooltip/Tooltip';

import * as S from './Label.styles';

type LabelSizeType = 'xs' | 'small' | 'regular';

type PropsType = {
	children?: React.ReactNode;
	text?: string;
	size?: LabelSizeType;
	semiBold?: boolean;
	mediumBold?: boolean;
	regularWeight?: boolean;
	color?: string;
	onClick?: (e: React.MouseEvent) => void;
	htmlFor?: string;
	lineHeight?: FontLineHeightsType;
	tooltipInfo?: string;
	className?: string;
};

function Label({
	children,
	text,
	size = 'regular',
	color = 'system.text.default',
	semiBold = false,
	mediumBold = true,
	regularWeight = false,
	htmlFor,
	onClick,
	lineHeight = 'large',
	tooltipInfo,
	className,
	...rest
}: PropsType) {
	const getFontWeight = () => {
		if (regularWeight) {
			return 'regular';
		}

		if (semiBold) {
			return 'semiBold';
		}

		if (mediumBold) {
			return 'medium';
		}

		return 'regular';
	};

	const fontWeight = getFontWeight();

	return (
		<S.LabelContainer>
			<Text
				as="label"
				letterSpacing="medium"
				size={size}
				color={color}
				fontWeight={fontWeight}
				lineHeight={lineHeight}
				htmlFor={htmlFor}
				onClick={onClick}
				className={className}
				{...rest}
			>
				{children || text}
			</Text>
			{tooltipInfo && (
				<Tooltip content={tooltipInfo} placement="right">
					<span className="d-flex">
						<Icon iconName="help" color="neutral.icon.default" />
					</span>
				</Tooltip>
			)}
		</S.LabelContainer>
	);
}

export default Label;

export type { LabelSizeType };
