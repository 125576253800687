import styled from 'styled-components';

import { TextTooltipOrientationType } from './types';

import { TooltipContainer, TooltipBlock } from './WrapperTooltip.styles';

export const Tooltip = styled(TooltipBlock)<{
	orientation: TextTooltipOrientationType;
}>`
	text-decoration: none;
	text-transform: none;
	font-weight: 400;
	max-width: 100%;

	word-break: ${({ orientation }) =>
		(orientation === 'top' || orientation === 'bottom') && 'break-word'};
`;

export const TextTooltipContainer = styled(TooltipContainer)<{
	orientation: TextTooltipOrientationType;
	width?: number;
}>`
	justify-content: ${({ orientation }) =>
		(orientation === 'top' || orientation === 'bottom') && 'center'};
	cursor: text;

	width: ${({ width }) => `${width || 280}px`};
	max-width: ${({ orientation }) =>
		(orientation === 'left' || orientation === 'right') && '280px'};
	white-space: pre-wrap;
`;
