import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as ReactBootstrapThemeProvider } from 'react-bootstrap';

import { GlobalStyle } from './globalStyle';
import GoodkindDSTheme from './theme';
import type { MergedTheme } from '@common/design-system/global/theme';

import './styles/custom-bootstrap.scss';

type PropsType = {
	children: React.ReactNode;
	withGlobalStyle?: boolean;
};

function GoodkindDSProvider({ children, withGlobalStyle = true }: PropsType) {
	return (
		<ThemeProvider theme={GoodkindDSTheme as MergedTheme}>
			<ReactBootstrapThemeProvider
				breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
				minBreakpoint="xs"
			>
				<Helmet>
					<link rel="preconnect" href="https://fonts.googleapis.com" />
					<link
						rel="preconnect"
						href="https://fonts.gstatic.com"
						crossOrigin=""
					/>
					<link
						href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
						rel="stylesheet"
					/>
					<link
						href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
						rel="stylesheet"
					/>
				</Helmet>
				{withGlobalStyle && <GlobalStyle />}
				{children}
			</ReactBootstrapThemeProvider>
		</ThemeProvider>
	);
}

export default GoodkindDSProvider;
