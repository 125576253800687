import styled from 'styled-components';
import { StyledInitialTagProps } from './types';

export const Initials = styled.div<StyledInitialTagProps>`
	display: grid;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	background-color: ${({ theme, background }) =>
		background || theme.colors.neutral[0]};
	color: ${({ color }) => (color ? color : '#9013fe')};
	font-size: ${({ fontSize }) => fontSize || '12px'};
	border-radius: 50%;
	border: 1px solid #979797;
	width: ${({ size }) => size || '35px'};
	height: ${({ size }) => size || '35px'};
	padding: ${({ padding }) => (padding ? padding : '5px')};
	line-height: ${({ lineHeight }) => lineHeight && lineHeight};
`;
