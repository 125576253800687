import styled from 'styled-components';

export const TextContainer = styled.div`
	display: flex;
	width: fit-content;
	max-width: 100%;

	> * {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

export const EllipsisTooltipContainer = styled.div<{ height: number }>`
	position: relative;
	display: flex;
	max-width: 100%;
	width: 100%;
	height: ${({ height }) => `${height}px`};

	> * {
		position: absolute;
		top: 0;
		left: 0;
	}
`;
