import styled from 'styled-components';

export const InfoAndChildrenContainer = styled.div<{
	hasSupportText: boolean;
}>`
	display: flex;
	flex-direction: column;
	gap: ${({ hasSupportText, theme }) =>
		!hasSupportText ? theme.spacing.xxxs : theme.spacing.small};
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.small};

	.texts-wrapper {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xxxs};
	}
`;
