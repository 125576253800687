import styled from 'styled-components';

export const StyledP = styled.p`
	font-size: 10px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	margin: 0;
`;
