import styled from 'styled-components';

import { ParagraphTypographyProps, ParagraphSizeType } from '../types';

import { Typography } from '../styles';

function handleLineHeight(size: ParagraphSizeType) {
	switch (size) {
		case 'large':
			return '24px';

		case 'regular':
			return '20px';
	}
}

export const ParagraphTypography = styled(Typography).attrs({
	as: 'p',
})<ParagraphTypographyProps>`
	line-height: ${({ size = 'regular' }) => handleLineHeight(size)};
	font-size: ${({ theme, size = 'regular' }) =>
		theme.fontSizes &&
		theme.fontSizes[size]}; // Large: 16px | Regular: 14px | Small: 12px
`;
