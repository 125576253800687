export const mapObjectToFormData = (
	form: FormData,
	obj: { [key: string]: any },
) => {
	Object.keys(obj).forEach((ctaKey) => {
		if (Array.isArray(obj[ctaKey])) {
			obj[ctaKey].forEach((value: string) => {
				form.append(`${ctaKey}[]`, value);
			});
		} else {
			form.append(ctaKey, obj[ctaKey]);
		}
	});
};
