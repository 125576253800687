import styled from 'styled-components';

export const ColorPickerContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const LabelWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const PopupComponent = styled.div`
	z-index: ${({ theme }) => theme.zIndex.popover};
	padding: 16px 0;

	> div {
		box-shadow: ${({ theme }) => theme.shadows.elevation.medium} !important;
	}
`;
