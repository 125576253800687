import {
	goodkindHQEmail,
	goodkindOnlyEmail,
	weAreGoodkindEmail,
} from '@app/constants';

export function checkIsGKAdmin(userEmail: string): boolean {
	if (!userEmail) return false;

	const isWeAreGoodkindUser = userEmail.includes(weAreGoodkindEmail);
	const isGoodkindHQUser = userEmail.includes(goodkindHQEmail);
	const isGoodkindUser = userEmail.includes(goodkindOnlyEmail);

	return isWeAreGoodkindUser || isGoodkindHQUser || isGoodkindUser;
}

export default checkIsGKAdmin;
