import styled from 'styled-components';

export const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.large};
`;

export const HeaderControlsWrapper = styled.div`
	display: flex;
	gap: 10px; //TODO: review 10 gaps usage out of DS
	align-items: center;
	justify-content: space-between;
`;

export const ChipsContainer = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing.xs};
	align-items: center;
	flex-wrap: wrap;
`;

export const HeaderInputWrapper = styled.div`
	width: 345px;
`;

export const HeaderItems = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
