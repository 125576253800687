import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { datadogRum } from '@datadog/browser-rum';

import 'react-toastify/dist/ReactToastify.css';

import store from './redux/store';

import Routes from './routes';
import { APP_VERSION, ToastPosition, segmentWriteKey } from '@app/constants';

import GoodkindTheme from '@global/theme';

import { GlobalStyle } from './app.styles';
import { sentryKey } from './constants/Sentry';

import useGrowthBookFeatures from '@app/hooks/useGrowthbook';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { createClient, AnalyticsProvider } from '@segment/analytics-react';
import AuthProvider from './services/Auth';
import { env } from './env';
import { MergedTheme } from '@common/design-system/global/theme';
import { StyledToastContainer } from '@common/design-system/components/layout';

export default function App(props: AppProps) {
	/** GrowthBook **/
	const growthbook = useGrowthBookFeatures();

	// explictly have to enable it to avoid local metrics
	// by default case
	if (env.ENABLE_DATADOG === 'on') {
		datadogRum.init({
			applicationId: '3d61d234-172e-4fb7-90b3-df082f74dd6c',
			clientToken: 'pub4dbf744de6ec327d735d607a2cf091af',
			site: 'datadoghq.com',
			service: 'webapp',
			env: env.NODE_ENV,
			// Specify a version number to identify the deployed version of your application in Datadog
			version: APP_VERSION,
			sampleRate: env.NODE_ENV === 'prod' ? 100 : 0,
			trackInteractions: env.NODE_ENV === 'prod',
			trackFrustrations: env.NODE_ENV === 'prod',
			// disabling session replay
			defaultPrivacyLevel: 'mask-user-input',
		});
		// disabling session replay
		if (env.NODE_ENV === 'prod') {
			datadogRum.startSessionReplayRecording();
			/* 
		TODO, 
		when a user has a session, add attributes to the session
		https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm
		Some useful attributes to bring in from workspace:
			a. Country
			b. Workspace ID
			c. Company Type
			d. Storyteller ID
			e. IDs from Mixpanel, Keen, Datadog to cross reference events 
			f. Workspace name
		*/
		}
	}

	Sentry.init({
		dsn: sentryKey,
		integrations: [new BrowserTracing()],
		release: `goodkind-frontend@${APP_VERSION}`,
		environment: env.NODE_ENV,
		tracesSampleRate: 1.0,
	});

	//segment
	const segmentClient = createClient({
		writeKey: segmentWriteKey || '',
	});

	return (
		<>
			<GrowthBookProvider growthbook={growthbook}>
				<AnalyticsProvider client={segmentClient}>
					<ThemeProvider theme={GoodkindTheme as MergedTheme}>
						<GlobalStyle />

						<Provider store={store}>
							<AuthProvider>
								<Routes />
								<StyledToastContainer hideProgressBar style={ToastPosition} />
							</AuthProvider>
						</Provider>
					</ThemeProvider>
				</AnalyticsProvider>
			</GrowthBookProvider>
		</>
	);
}

type AppProps = {
	/** This property is used for a personalized greeting  */
	// name: string;
};
