import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import DotLoaderAnimation from '@global/lottie/dot-loader.json';
import { LoaderProps } from './types';
import { AnimationContainer, ThreeDotsContainer } from './Loader.styles';
import GoodkindTheme from '@global/theme';

function Loader({
	loading = true,
	width = '75px',
	color = GoodkindTheme.colors.neutral[200],
	className,
}: LoaderProps) {
	const animationData = DotLoaderAnimation;

	const animationOptions: any = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMaxYMax slice',
			// I had to slice it because it had a big padding on both animations
			viewBoxSize: '200 200 200 200',
		},
	};
	useEffect(() => {
		// uncomment this to track rerenders and check optimizations (campaign is awful and rereders this 125 times)
	}, []);

	return (
		<AnimationContainer
			isLoading={loading || false}
			width={width}
			color={color}
			className={className}
		>
			<ThreeDotsContainer width={width}>
				<Lottie options={animationOptions} />
			</ThreeDotsContainer>
		</AnimationContainer>
	);
}

export default React.memo(Loader);
