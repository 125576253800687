import styled from 'styled-components';

export const CloseButton = styled.div`
	position: absolute;
	top: 4px;
	right: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	margin-left: auto;
	cursor: pointer;

	> svg {
		color: ${({ theme }) => theme.colors.neutral[400]} !important;
	}

	&:hover {
		> svg {
			color: ${({ theme }) => theme.colors.neutral[700]} !important;
		}
	}
`;

export const ToastContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 360px;
	height: 32px;
	padding: 0 56px;

	border-radius: 0 0 8px 8px;
	background: ${({ theme }) => theme.colors.tags[500]};
`;

export const ToastWrapper = styled.div<{ isVisible: boolean }>`
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
	justify-content: center;
	z-index: ${({ theme }) => theme.zIndex.max};
`;
