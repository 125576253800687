import React from 'react';

import { CustomIconProps } from './types';

import { StyledImg } from './CustomIcon.styles';
import { IconEnum } from '@global/icons/customIconsConstant';

export default function Icon({
	className,
	iconName,
	width,
	height,
}: CustomIconProps) {
	return (
		<StyledImg
			className={className || ''}
			src={IconEnum[iconName?.toLowerCase()] || iconName}
			width={width}
			height={height}
		/>
	);
}
