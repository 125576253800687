import React from 'react';
import StyledP from './Explainer.styles';
import { ExplainerProps } from './types';

export default function Explainer({
	centered = false,
	children,
	className,
}: ExplainerProps) {
	return (
		<StyledP centered={centered} className={className}>
			{children}
		</StyledP>
	);
}
