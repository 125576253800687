import styled from 'styled-components';

export const PreviewButton = styled.button`
	display: flex;
	align-items: center;
	height: 24px;
	padding: 0 8px;
	background: ${({ theme }) => theme.colors.neutral[50]};
	border-radius: ${({ theme }) => theme.borderRadius?.rounded};
	border: 0;
	cursor: pointer;
	column-gap: 8px;

	&:not(:disabled):hover {
		background: ${({ theme }) => theme.colors.neutral[100]};
	}
	&:not(:disabled):active {
		background: ${({ theme }) => theme.colors.neutral[200]};
	}

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}
`;
