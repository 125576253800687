export function capitalize(text: string): string {
	if (typeof text !== 'string') return '';

	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function transformTextToCamelCase(text: string): string {
	return text
		.trim() // remove spaces
		.replace(/[^a-zA-Z0-9 ]/g, '') // remove special characters
		.replace(/\s+/g, ''); // remove spaces
}

export function preventBlankSpaces(text: string): string {
	if (!text || text === ' ') return '';

	if (text.includes('  ')) return text.replace('  ', ' ');

	return text;
}

export function limitNumber(number: number, limit: number): string {
	if (!number) return '0';

	if (number > limit) {
		return `${limit}+`;
	}

	return `${number}`;
}

export function handleTextLength(text: string, maxLength = 100): string {
	if (maxLength && text.length > maxLength) {
		return text.substr(0, maxLength - 3).trim() + '...';
	}

	return text;
}

export function kFormatThousandNumbers(number: number) {
	if (number < 999) return number;

	// 1500 => 1.5k
	if (number % 100 === 0) return number / 1000 + 'k';

	return number;
}
