import React, { useMemo } from 'react';

import * as S from './AvatarList.styles';

import { Avatar } from '@common/design-system/components/atoms';
import { AvatarProps } from '../Avatar/Avatar';

type PropTypes = {
	avatars: AvatarProps[];
	size?: AvatarProps['size'];
	displayAmount?: number;
	className?: string;
};

function AvatarList({
	avatars = [],
	size = 'medium',
	displayAmount = 5,
	className,
}: PropTypes) {
	const numberDisplaySizingMap = {
		xxxl: 80,
		xxl: 40,
		xl: 36,
		large: 32,
		medium: 28,
		small: 24,
		xs: 20,
	};

	const numberDisplaySize = numberDisplaySizingMap[size] as number;

	const hasHiddenAvatars = useMemo(
		() => avatars.length > displayAmount,
		[avatars, displayAmount],
	);

	const displayItems = useMemo(() => {
		if (hasHiddenAvatars) {
			const displayedAvatars = [...avatars].slice(0, displayAmount);

			return displayedAvatars;
		}

		return avatars;
	}, [avatars, displayAmount, hasHiddenAvatars]);

	return (
		<S.AvatarListContainer className={className}>
			{displayItems.length > 0 &&
				displayItems.map((avatar, index) => {
					return (
						<Avatar
							name={avatar.name}
							image={avatar.image}
							size={size as AvatarProps['size']}
							key={avatar.id || avatar._id || index}
							withBorder
						/>
					);
				})}

			{hasHiddenAvatars && (
				<S.AmountDisplayer size={numberDisplaySize} withBorder>
					+{avatars.length - displayAmount}
				</S.AmountDisplayer>
			)}
		</S.AvatarListContainer>
	);
}

export default AvatarList;
