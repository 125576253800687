import React from 'react';

import Loader from '@common/components/presentation/Loader';

import { ButtonProps } from './types';

import { GoodkindTheme } from '@global/theme';

import {
	PrimaryButton,
	SecondaryButton,
	TertiaryButton,
	QuaternaryButton,
	AlertButton,
	LoaderContainer,
	NeutralButton,
} from './Button.styles';

export default function Button({
	children,
	variant = 'primary',
	disabled = false,
	className,
	ariaLabel,
	loading,
	isCircular,
	type = 'button',
	size = 'regular',
	onClick,
}: ButtonProps) {
	const handleClick = (e: React.MouseEvent) => {
		if (!loading && onClick) {
			e.stopPropagation();
			onClick(e);
		}
	};

	function handleLoaderColor() {
		switch (variant) {
			case 'primary':
				return GoodkindTheme.colors.neutral[0];

			case 'secondary':
				return GoodkindTheme.colors.primary[700];

			case 'tertiary':
				return GoodkindTheme.colors.secondary[700];

			case 'quaternary':
				return GoodkindTheme.colors.neutral[400];

			case 'neutral':
				return GoodkindTheme.colors.neutral[400];

			case 'alert':
				return GoodkindTheme.colors.alert[700];

			default:
				return GoodkindTheme.colors.primary[700];
		}
	}

	function HandleButton() {
		switch (variant) {
			case 'primary':
				return (
					<PrimaryButton
						size={size}
						className={className}
						onClick={handleClick}
						isCircular={isCircular}
						variant={variant}
						aria-label={ariaLabel}
						disabled={disabled}
						loading={loading}
						type={type}
					>
						{loading && (
							<LoaderContainer variant={variant}>
								<Loader
									width={GoodkindTheme.buttonHeight[size]}
									loading={loading}
									color={handleLoaderColor()}
								/>
							</LoaderContainer>
						)}

						{children}
					</PrimaryButton>
				);

			case 'secondary':
				return (
					<SecondaryButton
						size={size}
						className={className}
						onClick={handleClick}
						isCircular={isCircular}
						variant={variant}
						aria-label={ariaLabel}
						disabled={disabled}
						loading={loading}
						type={type}
					>
						{loading && (
							<LoaderContainer variant={variant}>
								<Loader
									width={GoodkindTheme.buttonHeight[size]}
									loading={loading}
									color={handleLoaderColor()}
								/>
							</LoaderContainer>
						)}

						{children}
					</SecondaryButton>
				);

			case 'tertiary':
				return (
					<TertiaryButton
						size={size}
						className={className}
						onClick={handleClick}
						isCircular={isCircular}
						variant={variant}
						aria-label={ariaLabel}
						disabled={disabled}
						loading={loading}
						type={type}
					>
						{loading && (
							<LoaderContainer variant={variant}>
								<Loader
									width={GoodkindTheme.buttonHeight[size]}
									loading={loading}
									color={handleLoaderColor()}
								/>
							</LoaderContainer>
						)}

						{children}
					</TertiaryButton>
				);

			case 'quaternary':
				return (
					<QuaternaryButton
						size={size}
						className={className}
						onClick={handleClick}
						isCircular={isCircular}
						variant={variant}
						aria-label={ariaLabel}
						disabled={disabled}
						loading={loading}
						type={type}
					>
						{loading && (
							<LoaderContainer variant={variant}>
								<Loader
									width={GoodkindTheme.buttonHeight[size]}
									loading={loading}
									color={handleLoaderColor()}
								/>
							</LoaderContainer>
						)}

						{children}
					</QuaternaryButton>
				);

			case 'neutral':
				return (
					<NeutralButton
						size={size}
						className={className}
						onClick={handleClick}
						isCircular={isCircular}
						variant={variant}
						aria-label={ariaLabel}
						disabled={disabled}
						loading={loading}
						type={type}
					>
						{loading && (
							<LoaderContainer variant={variant}>
								<Loader
									width={GoodkindTheme.buttonHeight[size]}
									loading={loading}
									color={handleLoaderColor()}
								/>
							</LoaderContainer>
						)}

						{children}
					</NeutralButton>
				);

			case 'alert':
				return (
					<AlertButton
						size={size}
						className={className}
						onClick={handleClick}
						isCircular={isCircular}
						variant={variant}
						aria-label={ariaLabel}
						disabled={disabled}
						loading={loading}
						type={type}
					>
						{loading && (
							<LoaderContainer variant={variant}>
								<Loader
									width={GoodkindTheme.buttonHeight[size]}
									loading={loading}
									color={handleLoaderColor()}
								/>
							</LoaderContainer>
						)}

						{children}
					</AlertButton>
				);
		}
	}

	return <HandleButton />;
}
