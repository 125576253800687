import authConfig from '@app/services/Auth/authConfig';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider as PropelAuthProvider } from '@propelauth/react';

import React from 'react';
import history from '../../utils/history';

type AuthProviderProps = {
	children: React.ReactNode;
};

export default function AuthProvider(props: AuthProviderProps) {
	const { children } = props;

	const onRedirectCallback = (appState: any) => {
		history.push(
			appState && appState.targetUrl
				? appState.targetUrl
				: window.location.pathname + '/home',
		);
	};

	return (
		<Auth0Provider
			useRefreshTokens={true} // this automatically adds the `offline_access` scope
			cacheLocation="localstorage"
			domain={authConfig?.auth0.domain || ''}
			clientId={authConfig?.auth0.clientId || ''}
			redirectUri={authConfig?.redirectUri}
			onRedirectCallback={onRedirectCallback}
			audience={authConfig?.auth0.audience}
			scope={authConfig?.auth0.scope}
		>
			<PropelAuthProvider authUrl={authConfig?.propelAuth.authUrl}>
				{children}
			</PropelAuthProvider>
		</Auth0Provider>
	);
}
