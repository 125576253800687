import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl } from '@app/constants/index';
import { fetchAction } from '../types';
import { FilterOptions } from '@app/types/index';
import axios from '@global/axios';
const mainType = 'recipe';

export const filterRecipesTypes = createFetchTypes(
	`${mainType}_FILTER_RECIPES`,
);

function filterRecipes(
	FilterOptions: FilterOptions,
	options?: { isFilter: boolean },
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: filterRecipesTypes,
		payload: { isFilter: options?.isFilter },
		callAPI: () =>
			axios.get(
				`${apiUrl}/recipes${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

export { filterRecipes };
