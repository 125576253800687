import styled from 'styled-components';
import { ProgressBarProps } from './types';

export const ProgressBarBass = styled.div<ProgressBarProps>`
	width: 100%;
	height: 9px;
	background: ${({ theme }) => theme.colors.neutral[100]};
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: ${(props) => (props.rounded ? '30px' : '0')};
	margin: ${({ margin }) => margin && margin};
`;
// border-radius: ${({ theme }) => theme.borderRadius?.default};

export const Progress = styled.div<ProgressBarProps>`
	width: ${(props) => props.width};
	height: 9px;
	background: ${({ theme }) => theme.colors.gradients.primary};
	border-bottom-right-radius: ${({ theme }) => theme.borderRadius?.default};
	border-top-right-radius: ${({ theme }) => theme.borderRadius?.default};
	transition: width ${({ theme }) => theme.transition?.oneSecond};
	border-radius: ${(props) => (props.rounded ? '30px' : '0')};
`;
