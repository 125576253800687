import type { MergedTheme } from '../../../@types/styled';

const tokenColors = {
	primary: {
		background: {
			default: '#EBF7FF',
			defaultHover: '#D9EEFF',
			defaultActive: '#CCE9FF',
			strong: '#0074E8',
			strongHover: '#0266CA',
			strongDisabled: '#85CEFF',
		},
		text: {
			default: '#0074E8',
			hover: '#0074E8',
			strong: '#0266CA',
			strongHover: '#174CA1',
		},
		icon: {
			default: '#0099FF',
			hover: '#0074E8',
			strong: '#0266CA',
			strongHover: '#174CA1',
		},
		border: {
			default: '#0099FF',
			hover: '#0074E8',
			weak: '#CCE9FF',
			weakHover: '#B8E0FF',
		},
	},
	success: {
		background: {
			default: '#EDFDF3',
			defaultHover: '#D4F7E0',
		},
		text: {
			default: '#0D7245',
			hover: '#0A613A',
		},
		icon: {
			default: '#119155',
		},
		border: {
			default: '#1FB269',
			hover: '#119155',
			weak: '#BEEFCE',
			weakHover: '#89E6AE',
		},
	},
	warning: {
		background: {
			default: '#FFF7EB',
			defaultHover: '#FFF3DB',
		},
		text: {
			default: '#A5520D',
			hover: '#813F08',
		},
		icon: {
			default: '#DD7B12',
		},
		border: {
			default: '#FA9B1E',
			hover: '#DD7B12',
			weak: '#FFDF9A',
			weakHover: '#FFD378',
		},
	},
	error: {
		background: {
			default: '#FFEFF0',
			defaultHover: '#FDD8DC',
			strong: '#E22837',
			strongHover: '#D61F2E',
		},
		text: {
			default: '#D61F2E',
			hover: '#B81422',
		},
		icon: {
			default: '#D61F2E',
		},
		border: {
			default: '#E22837',
			hover: '#D61F2E',
			weak: '#FFB8BE',
			weakHover: '#FF9EA6',
		},
	},
	accent: {
		background: {
			default: '#F0EBFF',
			defaultHover: '#E1D4FF',
		},
		text: {
			default: '#553286',
			hover: '#3F265E',
		},
		icon: {
			default: '#7C45D7',
			hover: '#6A3CAE',
		},
		border: {
			default: '#8C4FFF',
			hover: '#7C45D7',
			weak: '#D3BEFF',
			weakHover: '#C6A9FF',
		},
	},
	highlight1: {
		background: {
			default: '#EEFAFA',
			strong: '#E2F1DF',
		},
		text: {
			default: '#357A82',
		},
		icon: {
			default: '#1D7D89',
		},
	},
	highlight2: {
		background: {
			default: '#FFF2FC',
			strong: '#FCE3F6',
		},
		text: {
			default: '#994185',
		},
		icon: {
			default: '#AB3491',
		},
	},
	highlight3: {
		background: {
			default: '#FFF8E1',
			strong: '#FCF2CF',
		},
		text: {
			default: '#8D640F',
		},
		icon: {
			default: '#9D6800  ',
		},
	},
	highlight4: {
		background: {
			default: '#F0EBFF',
		},
		text: {
			default: '#8D640F',
		},
		icon: {
			default: '#5D368D  ',
		},
	},
	neutral: {
		background: {
			default: '#EEF2F6',
			hover: '#DBE2EB',
		},
		border: {
			default: '#B2C1D1',
			hover: '#9DABBE',
		},
		icon: {
			default: '#627084',
			hover: '#3D495C',
		},
	},
	system: {
		background: {
			system: '#FFFFFF',
			xWeak: '#FBFBFC',
			weak: '#F4F7FA',
			medium: '#EEF2F6',
			strong: '#E5EAF0',
			xStrong: '#DBE2EB',
			modal: '#1B1E22',
		},
		text: {
			default: '#1B1E22',
			medium: '#627084',
			weak: '#9DABBE',
		},
		border: {
			weak: '#E5EAF0',
			medium: '#CBD5E1',
			strong: '#B2C1D1',
		},
		// This is just to avoid type issues when using indexes in the theme
		// It's not used anywhere
		icon: {
			default: undefined,
		},
		gradients: {
			orangePink: 'linear-gradient(147deg, #FA9B1E 6.95%, #F45AC1 93.05%)',
		},
	},
};

const fonts = {
	family: {
		default:
			'Inter, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif',
		title: 'Plus Jakarta Sans, sans-serif',
		arial: 'Arial, sans-serif',
		poppins: 'Poppins, sans-serif',
		playfair: 'Playfair Display, serif',
		garamond: 'EB Garamond, serif',
		oswald: 'Oswald, sans-serif',
	},
	sizes: {
		xxxs: `0.5rem`, //8px
		xxs: `0.688rem`, //11px
		xs: `0.75rem`, //12px
		small: `0.875rem`, //14px
		regular: '1rem', //16px
		medium: `1.125rem`, //18px
		large: `1.250rem`, //20px
		xl: `1.5rem`, //24px
		xxl: `2rem`, //32px
		xxxl: `2.5rem`, //40px
		xxxxl: `3rem`, //48px
	},
	weights: {
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
	},
	lineHeight: {
		regular: 1,
		medium: 1.2,
		large: 1.5,
	},
	letterSpacing: {
		xs: '-0.02em',
		small: '-0.01em',
		regular: '0',
		medium: '0.01em', //0.16px
		large: '0.022em', //0.35px
		xl: '0.04em', //0.64px
	},
};

const spacing = {
	0: 0,
	xxxs: `0.125rem`, //2px
	xxs: `0.25rem`, //4px
	xs: `0.5rem`, //8px
	small: `0.75rem`, //12px
	medium: `1rem`, //16px
	large: `1.5rem`, //24px
	xl: `2rem`, //32px
	xxl: `3rem`, //48px
	xxxl: `4rem`, //64px
	xxxxl: `5rem`, //80px
};

const shapeRadius = {
	0: 0,
	6: `0.375rem`, //6px
	xxs: `0.25rem`, //4px
	xs: `0.5rem`, //8px
	small: `0.625rem`, //10px
	regular: `0.75rem`, //12px
	medium: `1rem`, //16px
	large: `1.5rem`, //24px
	xl: `2rem`, //32px
	40: `2.5rem`, //40px
	xxl: `3.5rem`, //56px
	50: `50%`,
};

//TODO: Review inset and control shadows in the design system file
const shadows = {
	elevation: {
		base: `0px 0px 1px rgba(31, 51, 71, 0.15), 0px 1px 2px rgba(31, 51, 71, 0.1)`,
		xxs: `0px 0.5px 1.5px 0px rgba(31, 51, 71, 0.15), 0px 0px 1px 0px rgba(31, 51, 71, 0.10);`,
		xs: `0px 0px 1px rgba(31, 51, 71, 0.2), 0px 1px 2px rgba(31, 51, 71, 0.15);`,
		small: `0px 4px 4px 0px rgba(31, 51, 71, 0.05), 0px 1px 2px 0px rgba(31, 51, 71, 0.15), 0px 0px 1px 0px rgba(31, 51, 71, 0.20);`,
		medium: `0px 0px 1px rgba(31, 51, 71, 0.2), 0px 0px 2px rgba(31, 51, 71, 0.15), 0px 2px 4px rgba(31, 51, 71, 0.1), 0px 6px 8px rgba(31, 51, 71, 0.05);`,
		large: `0px 0px 1px rgba(31, 51, 71, 0.2), 0px 0px 2px rgba(31, 51, 71, 0.1), 0px 2px 4px rgba(31, 51, 71, 0.1), 0px 6px 8px rgba(31, 51, 71, 0.05), 0px 10px 12px rgba(31, 51, 71, 0.05);`,
		xl: `0px 14px 16px 0px rgba(31, 51, 71, 0.05), 0px 6px 8px 0px rgba(31, 51, 71, 0.10), 0px 2px 4px 0px rgba(31, 51, 71, 0.10), 0px 0px 2px 0px rgba(31, 51, 71, 0.10), 0px 0px 1px 0px rgba(31, 51, 71, 0.20);`,
		container: `0px 2px 4px 0px rgba(41, 48, 61, 0.1);`,
	},
	inset: {
		xs: `inset 0px 0px 1px rgba(31, 51, 71, 0.15), inset 0px 1px 2px rgba(31, 51, 71, 0.15);`,
		small: `inset 0px 0px 1px rgba(31, 51, 71, 0.15), inset 0px 1px 2px rgba(31, 51, 71, 0.15), inset 0px 4px 4px rgba(31, 51, 71, 0.05);`,
		medium: `inset 0px 0px 1px rgba(31, 51, 71, 0.15), inset 0px 0px 2px rgba(31, 51, 71, 0.15), inset 0px 2px 4px rgba(31, 51, 71, 0.1), inset 0px 4px 6px rgba(31, 51, 71, 0.05);`,
		large: `inset 0px 0px 1px rgba(31, 51, 71, 0.15), inset 0px 0px 2px rgba(31, 51, 71, 0.15), inset 0px 2px 4px rgba(31, 51, 71, 0.1), inset 0px 4px 6px rgba(31, 51, 71, 0.05), inset 0px 6px 8px rgba(31, 51, 71, 0.05);`,
		xl: `inset 0px 0px 1px rgba(31, 51, 71, 0.15), inset 0px 0px 2px rgba(31, 51, 71, 0.15), inset 0px 2px 4px rgba(31, 51, 71, 0.15), inset 0px 4px 6px rgba(31, 51, 71, 0.05), inset 0px 8px 10px rgba(31, 51, 71, 0.05);`,
	},
	controls: {
		'hover-small': `0px 0px 0px 3px #EEF3F7;`,
		hover: `0px 0px 0px 3px #EEF2F6;`,
		selected: `0px 0px 0px 3px #D1EBFF;`,
		error: `0px 0px 0px 3px #FEF0F5;`,
	},
};

const zIndex = {
	base: 1000,
	sticky: 1020,
	fixed: 1030,
	modalBackdrop: 1040,
	modal: 1050,
	popover: 1060,
	tooltip: 1070,
};

const transitions = {
	//DS
	ease01: 'all 0.1s ease',
	easeInOut15: 'all 0.15s ease-in-out',

	//Legacy
	all: 'all 0.2s ease-in-out',
	short: '0.1s cubic-bezier(0.645, 0.045, 0.355, 1)',
	long: '0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
	oneSecond: '1s cubic-bezier(0.645, 0.045, 0.355, 1)',
	cubicBezier: 'cubic-bezier(0.645, 0.045, 0.355, 1)',

	fade: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
	expand: 'height 0.3s ease-in-out, width 0.3s ease-in-out',
	transform: 'transform 0.3s ease-in-out',
	translate:
		'top 0.3s ease-in-out, right 0.3s ease-in-out, bottom 0.3s ease-in-out, left 0.3s ease-in-out',
	hover:
		'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out',
	typography:
		'color 0.3s ease-in-out, transform 0.3s ease-in-out, fill 0.3s ease-in-out',
};

const breakpoint = {
	width: {
		xs: 720,
		s: 1080,
		m: 1280,
		l: 1440,
		xl: 1680,
	},
	height: {
		s: 768,
	},
};

export const GoodkindDSTheme = {
	name: 'Goodkind Design System',
	colors: tokenColors,
	fonts,
	spacing,
	shapeRadius,
	shadows,
	zIndex,
	transitions,
	breakpoint,
};

export default GoodkindDSTheme;

export type { MergedTheme };
