import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import LocalStorage from '@app/utils/LocalStorage';
import Loader from '@common/components/presentation/Loader/Loader';
import { RootState, useAppDispatch } from '@app/redux/types';
import { getWorkspaceByEmail } from '@app/redux/workspace/action';
import { useSelector } from 'react-redux';
import {
	grantAccess,
	setAuthProviderInfo,
	uploadProfilePicture,
} from '@app/redux/auth/action';
import { LoaderContainer } from './AuthCallback.styles';
import { showToast } from '@common/design-system/components/molecules';
import { ToastMessage } from '@app/constants';
import authConfig from '@app/services/Auth/authConfig';
import { PageRouteEnum } from '@app/constants/pages';
import { CheckYourEmail } from './components';
import { useQueryParams } from '@app/hooks/useQueryParams';
import { NOT_VERIFIED_MESSAGE } from './constants';
import { useAuth } from '@app/services/Auth/useAuth';
import TokenStorage from '@app/services/Auth/TokenStorage';

function AuthCallback() {
	const { user: loggedInUser, authProvider, authProvidersSchemas } = useAuth();

	const { queryParams } = useQueryParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { workspaces, workspaceError } = useSelector(
		({ workspace: { custom, error } }: RootState) => ({
			workspaces: custom?.getWorkspaceByEmailResponse?.workspaces,
			workspaceError: error,
		}),
	);

	const { loadingAccessGrant, authError } = useSelector(
		({ auth: { loading, error } }: RootState) => ({
			loadingAccessGrant: loading,
			authError: error,
		}),
	);

	const shouldUserCheckEmail = useMemo(() => {
		const params = queryParams.get('error_description');

		return params?.includes(NOT_VERIFIED_MESSAGE);
	}, []);

	const storeToken = async () => {
		try {
			let currentProvider = authProvider;

			if (!currentProvider) {
				const hasPropelParam = window.location.search === '?propel=true';
				if (hasPropelParam) {
					currentProvider = authProvidersSchemas?.propelAuth;
					LocalStorage.setItemInLocalStorage('checkAuthProvider', 'propelAuth');
				} else {
					currentProvider = authProvidersSchemas?.auth0;
					LocalStorage.setItemInLocalStorage('checkAuthProvider', 'auth0');
				}
			}

			if (currentProvider && currentProvider?.user) {
				const accessToken = await currentProvider?.getAccessTokenSilently();

				if (accessToken) {
					TokenStorage.setAccessToken(accessToken);
				}

				const lastWorkspaceId = localStorage.getItem('lastWorkspaceId');
				if (!workspaces?.length && currentProvider?.user?.email) {
					dispatch(getWorkspaceByEmail(currentProvider?.user.email));
				} else {
					let workspaceIdToSelect = (workspaces[0] as any).id;
					if (lastWorkspaceId) {
						workspaceIdToSelect = lastWorkspaceId;
					} else {
						localStorage.setItem('lastWorkspaceId', workspaceIdToSelect);
					}
					dispatch(
						grantAccess(
							workspaceIdToSelect,
							currentProvider?.user.email as string,
						),
					);
					dispatch(
						setAuthProviderInfo({
							logout: currentProvider?.logout,
							loginWithRedirect: currentProvider?.loginWithRedirect,
							user: currentProvider?.user,
						}),
					);
					// (window as any).auth0Logout = logout;
					dispatch(
						uploadProfilePicture({
							email: currentProvider?.user.email as string,
							pictureUrl: (currentProvider?.user as any).picture,
							workspaceId: workspaceIdToSelect,
						}),
					);
				}
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (!loadingAccessGrant && loggedInUser?.email) {
			navigate(`/${PageRouteEnum.home}`);
		}
	}, [loadingAccessGrant, loggedInUser?.email]);

	useEffect(() => {
		if (workspaceError?.message) {
			showToast({
				message: ToastMessage.errorEmailAddressWorkspaceSelection,
				type: 'error',
			});
			LocalStorage.clear();
			authProvider?.logout({ returnTo: authConfig.logoutUrl } as any);
		}
	}, [workspaceError]);

	useEffect(() => {
		if (authError?.message) {
			showToast({ message: ToastMessage.errorPermissionDenied, type: 'error' });
			LocalStorage.clear();
			authProvider?.logout({ returnTo: authConfig.logoutUrl } as any);
		}
	}, [authError]);

	useEffect(() => {
		const accessToken = TokenStorage.getAccessToken();

		if (!authProvider?.isLoading || !accessToken) {
			storeToken();
		}
	}, [authProvider?.isLoading, workspaces]);

	return (
		<>
			{!shouldUserCheckEmail && (
				<LoaderContainer>
					<Loader loading width="75px" />
				</LoaderContainer>
			)}

			{shouldUserCheckEmail && <CheckYourEmail />}
		</>
	);
}

export default AuthCallback;
