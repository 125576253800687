import createFetchTypes from '@app/utils/createFetchTypes';
import { fetchAction } from '../types';
import axios from '@global/axios';
import { apiUrl, ToastMessage } from '@app/constants';
import { CaptionOverride } from '@app/types';
import { AutomationTypes, ContactExportPayload } from '../automations/types';
import { TrustScoreDocType } from './types';

const mainType = 'workspace';

export type Workspace = {
	name: string;
	logo: string;
	_id: string;
	__v: number;
	id: string;
};

export const getWorkspaceByEmailTypes = createFetchTypes(
	`${mainType}_GET_WORKSPACES_BY_EMAIL`,
);

export const WorkspaceInfoTypes = createFetchTypes(
	`${mainType}_WORKSPACE_INFO`,
);
export const zapierKeyTypes = createFetchTypes(`${mainType}_ZAPIER_KEY`);

export const disconnectWorkspaceIntegrationTypes = createFetchTypes(
	`${mainType}_DISCONNECT_INTEGRATION`,
);
export const updateWorkspaceIntegrationTypes = createFetchTypes(
	`${mainType}_WORKSPACE_INTEGRATION`,
);
export const updateHotglueCommunicationPreferenceTypes = createFetchTypes(
	`${mainType}_HOTGLUE_COMMUNICATION_PREFRENCE`,
);
export const updateHotglueSyncingScheduleTypes = createFetchTypes(
	`${mainType}_HOTGLUE_SYNCING_SCHEDULE`,
);
export const uploadLogoTypes = createFetchTypes(`${mainType}_UPLOAD_LOGO`);
export const updateVideoPageSettingsTypes = createFetchTypes(
	`${mainType}_UPDATE_VIDEO_PAGE_SETTINGS`,
);
export const updateWorkspaceTypes = createFetchTypes(`${mainType}_UPDATE`);
export const verifyWorkspaceDomainDataTypes = createFetchTypes(
	`${mainType}_SET_DOMAIN_DATA`,
);
export const verifyDomainTypes = createFetchTypes(`${mainType}_VERIFY_DOMAIN`);

export const setZapierIntegrationTypes = createFetchTypes(
	`${mainType}_SET_ZAPIER_INTEGRATION`,
);

export const disconnectHotglueIntegrationTypes = createFetchTypes(
	`${mainType}_DISCONNECT_HOTGLUE_INTEGRATION`,
);

export const updateCustomAttributesTypes = createFetchTypes(
	`${mainType}_UPDATE_CUSTOM_ATTRIBUTES`,
);

export const connectHotglueFlowTypes = createFetchTypes(
	`${mainType}_CONNECT_HOTGLUE_FLOW`,
);

export const getSendDataOutTargetFieldsTypes = createFetchTypes(
	`${mainType}_GET_SEND_DATA_OUT_TARGET_FIELD`,
);

export const createCustomAttributeTypes = createFetchTypes(
	`${mainType}_CREATE_CUSTOM_ATTRIBUTE`,
);
export const pinCustomAttributeTypes = createFetchTypes(
	`${mainType}_PIN_CUSTOM_ATTRIBUTE`,
);
export const getCaptionOverrideTypes = createFetchTypes(
	`${mainType}_GET_CAPTION_OVERRIDE`,
);

export const createOverrideTypes = createFetchTypes(
	`${mainType}_CREATE_CAPTION_OVERRIDE`,
);

export const deleteOverrideTypes = createFetchTypes(
	`${mainType}_DELETE_CAPTION_OVERRIDE`,
);

export const createWorkspaceDataExportTypes = createFetchTypes(
	`${mainType}_CREATE_DATA_EXPORT`,
);

export const editWorkspaceContactExportTypes = createFetchTypes(
	`${mainType}_EDIT_CONTACT_EXPORT`,
);

export const getExportableFieldsTypes = createFetchTypes(
	`${mainType}_EXPORTABLE_FIELDS`,
);

export const addTrustScoreDocTypes = createFetchTypes(
	`${mainType}_ADD_TRUST_SCORE_DOC`,
);

export const updateTrustScoreDocTypes = createFetchTypes(
	`${mainType}_EDIT_TRUST_SCORE_DOC`,
);

export const deleteTrustScoreDocTypes = createFetchTypes(
	`${mainType}_DELETE_TRUST_SCORE_DOC`,
);

export const updateMMSOverlayTypes = createFetchTypes(
	`${mainType}_UPDATE_MMS_OVERLAY`,
);

export const sendMMSThumbnailPreviewTypes = createFetchTypes(
	`${mainType}_SEND_MMS_THUMBNAIL_PREVIEW`,
);

export const syncExportJobTypes = createFetchTypes(
	`${mainType}_SYNC_EXPORT_JOB`,
);

function setZapierIntegration(): fetchAction {
	return {
		type: setZapierIntegrationTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/integration/zapier`),
		payload: {
			successMessage: ToastMessage.integrationConnected,
		},
	};
}

function getWorkspaceByEmail(email: string): fetchAction {
	return {
		type: getWorkspaceByEmailTypes,
		callAPI: () => axios.get(`${apiUrl}/auth/workspaces?e=${email}`),
		payload: {
			custom: 'getWorkspaceByEmail',
		},
	};
}

// function getWorkspaceInfo(): fetchAction {
//     return {
//         type: WorkspaceInfoTypes,
//         callAPI: () => axios.get(`${apiUrl}/auth/verify`)
//     }
// }

function updateWorkspaceIntegration(obj: any): fetchAction {
	const data: { [key: string]: string } = {
		integrationId: obj.integrationId,
		integrationName: obj.integrationName || 'blackbaud',
		integrationBaseUrl: obj.integrationBaseUrl || obj.constituent_id,
		integrationOn: obj.integrationOn || true,
	};
	if (obj.refreshToken) {
		data.refreshToken = obj.refreshToken || '';
	}

	return {
		type: updateWorkspaceIntegrationTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/${obj.workspaceId}`, data),
		payload: {
			successMessage: ToastMessage.integrationConnected,
		},
	};
}

function connectHotglueFlow(data: { [key: string]: any }): fetchAction {
	return {
		type: connectHotglueFlowTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/workspaces/integration/hotglue/connect`, data),
		payload: {
			successMessage: ToastMessage.integrationConnected,
		},
	};
}

function disconnectWorkspaceIntegration(obj: any): fetchAction {
	const data = {
		integrationId: obj.integrationId,
		integrationName: obj.integrationName || 'blackbaud',
		integrationOn: false,
	};
	return {
		type: disconnectWorkspaceIntegrationTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/${obj.workspaceId}`, data),
		payload: {
			successMessage: ToastMessage.integrationDisconnected,
		},
	};
}

function updateHotglueCommunicationPreference(obj: any): fetchAction {
	const data = {
		flowName: 'contactImport',
		flow: {
			defaultCommunicationPreference: obj.communicationPreference,
		},
	};

	return {
		type: updateHotglueCommunicationPreferenceTypes,
		callAPI: () => axios.put(`${apiUrl}/workspaces/integration/hotglue`, data),
		payload: {
			successMessage: ToastMessage.integrationUpdated,
		},
	};
}

function updateHotglueSyncingSchedule(data: {
	[key: string]: any;
}): fetchAction {
	return {
		type: updateHotglueSyncingScheduleTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/hotglue/scheduleJob`, data),
		payload: {
			successMessage: ToastMessage.integrationUpdated,
		},
	};
}

function getZapierApiKey(): fetchAction {
	return {
		type: zapierKeyTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/integration/zapier`),
	};
}

function getWorkspaceInfo(custom?: string): fetchAction {
	return {
		type: WorkspaceInfoTypes,
		callAPI: () => axios.get(`${apiUrl}/workspaces/current`),
		payload: {
			...(custom ? { custom } : {}),
		},
	};
}

function updateWorkspace(
	fields: any,
	workspaceId: string,
	successMessage?: string,
	errorMessage?: string,
): fetchAction {
	return {
		type: updateWorkspaceTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/${workspaceId}`, {
				workspace: fields,
			}),
		payload: {
			custom: 'updateWorkspace',
			...(successMessage ? { successMessage } : {}),
			...(errorMessage ? { errorMessage } : {}),
		},
	};
}

function uploadLogo(logoForm: FormData) {
	return {
		type: uploadLogoTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/uploadLogo`, logoForm),
	};
}

function updateVideoPageSettings(workspaceForm: FormData, custom?: string) {
	return {
		type: updateVideoPageSettingsTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/workspaces/logoAndBackground`, workspaceForm),
		payload: {
			custom,
			successMessage: ToastMessage.videoPageSettingsUpdated,
			errorMessage: ToastMessage.errorUpdatingSettings,
		},
	};
}

function verifyWorkspaceDomainData() {
	return {
		type: verifyWorkspaceDomainDataTypes,
		callAPI: () => axios.get(`${apiUrl}/workspaces/domain`),
		payload: { custom: 'verifyDomain' },
	};
}

function resetWorkspace() {
	return {
		type: `${mainType}/RESET_WORKSPACE`,
	};
}

function disconnectHotglueIntegration(
	integrationId: string,
	flowName: string,
	custom?: string,
) {
	return {
		type: disconnectHotglueIntegrationTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/hotglue/disconnect`, {
				integrationId,
				flowName,
			}),
		payload: {
			...(custom ? { custom } : {}),
			successMessage: ToastMessage.integrationDisconnected,
		},
	};
}

export type CustomAttributeProp = {
	id?: string;
	mappedTo: string;
	listType?: string;
};

function updateCustomAttributes(
	customAttributes?: CustomAttributeProp,
	attributeIndex?: number,
) {
	return {
		type: updateCustomAttributesTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/customAttributes`, customAttributes),
		payload: {
			custom: 'updateCustomAttributes',
			attributeIndex: attributeIndex,
		},
	};
}
/**
 * create new custom attribute
 */

function createCustomAttribute(customAttributes: any): fetchAction {
	return {
		type: createCustomAttributeTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/workspaces/customAttributes`, customAttributes),
		payload: {
			custom: 'createNewcustomAttribute',
		},
	};
}
/**
 * pin/unpin custom attribute
 *
 */
function pinCustomAttribute(
	customAttributeId: string,
	customAttributeIndex: number,
): fetchAction {
	return {
		type: pinCustomAttributeTypes,
		callAPI: () =>
			axios.put(
				`${apiUrl}/workspaces/customAttributes/${customAttributeId}/togglePin`,
			),
		payload: {
			custom: 'pinCustomAttribute',
			customAttributeIndex: customAttributeIndex,
		},
	};
}

function getSendDataOutTargetFields(
	integrationName: string,
	activityType?: string,
) {
	return {
		type: getSendDataOutTargetFieldsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/workspaces/hotglue/sendDataOut/targets?targetName=${integrationName}${
					activityType ? `&activityType=${activityType}` : ''
				}`,
			),
		payload: { custom: 'getSendDataOutTargetFields' },
	};
}

/**
 * Caption Overrides
 */
function getOverrides(): fetchAction {
	const filterString = JSON.stringify({
		search: '',
		pagination: { page: 1, pageSize: 100 },
	});
	return {
		type: getCaptionOverrideTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/workspaces/settings/captionOverrides${
					filterString ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			custom: 'getOverrides',
		},
	};
}
/**
 * Create Caption Override
 */

function createOverrides(captionOverrides: CaptionOverride) {
	return {
		type: createOverrideTypes,
		callAPI: () =>
			axios.post(
				`${apiUrl}/workspaces/settings/captionOverrides`,
				captionOverrides,
			),
		payload: {
			custom: 'createOverrides',
			successMessage: ToastMessage.overrideCreated,
			errorMessage: ToastMessage.overrideCreatedError,
		},
	};
}

/**
 * delete Caption Override
 * */
function deleteOverrides(captionOverridesId: string) {
	return {
		type: deleteOverrideTypes,
		callAPI: () =>
			axios.delete(
				`${apiUrl}/workspaces/settings/captionOverrides/${captionOverridesId}`,
			),
		payload: {
			custom: 'deleteOverrides',
			successMessage: ToastMessage.overrideDeleted,
			captionOverridesId,
		},
	};
}

// wrokspace data export automation

function createWorksapceDataExport(
	automation: AutomationTypes,
	successMessage?: string,
): fetchAction {
	return {
		type: createWorkspaceDataExportTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/workspaces/dataExport/settings`, {
				...automation,
			}),
		payload: {
			custom: 'createAutomation',
			successMessage:
				successMessage ||
				(automation?.id
					? ToastMessage.editAutomation
					: ToastMessage.createAutomation),
			errorMessage: ToastMessage.error,
		},
	};
}

function editWorkspaceContactExport(
	payload: ContactExportPayload,
	successMessage?: string,
): fetchAction {
	return {
		type: editWorkspaceContactExportTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/workspaces/contactExport/settings`, {
				...payload,
			}),
		payload: {
			custom: 'editContactExport',
			successMessage: successMessage || ToastMessage.editAutomation,
			errorMessage: ToastMessage.error,
		},
	};
}

//  Trust Score
type addTrustScoreDocType = {
	type: TrustScoreDocType['type'];
	url: TrustScoreDocType['url'];
	publicId?: TrustScoreDocType['publicId'];
	fileName?: TrustScoreDocType['fileName'];
};

function getExportableFields(
	isContactExportFields: boolean = false,
): fetchAction {
	return {
		type: getExportableFieldsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/workspaces/settings/exportableFields${
					isContactExportFields ? '?isContactExportFields=true' : ''
				}`,
			),
		payload: {
			custom: 'exportableFields',
		},
	};
}

function addTrustScoreDoc(doc: addTrustScoreDocType): fetchAction {
	return {
		type: addTrustScoreDocTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/workspaces/trustScoreDoc`, {
				type: doc.type,
				url: doc.url,
				publicId: doc.publicId,
				fileName: doc.fileName,
			}),
		payload: {
			custom: 'addTrustScoreDoc',
			successMessage: ToastMessage.trustScoreDocAdded,
			errorMessage: ToastMessage.errorAddingTrustScoreDoc,
		},
	};
}

function updateTrustScoreDoc(docId: string, url: string): fetchAction {
	return {
		type: updateTrustScoreDocTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/trustScoreDoc/${docId}`, {
				url,
			}),
		payload: {
			custom: 'updateTrustScoreDoc',
			successMessage: ToastMessage.trustScoreDocUpdated,
			errorMessage: ToastMessage.errorUpdatingTrustScoreDoc,
		},
	};
}

function deleteTrustScoreDoc(docId: string): fetchAction {
	return {
		type: deleteTrustScoreDocTypes,
		callAPI: () => axios.delete(`${apiUrl}/workspaces/trustScoreDoc/${docId}`),
		payload: {
			custom: 'deleteTrustScoreDoc',
			successMessage: ToastMessage.trustScoreDocDeleted,
			errorMessage: ToastMessage.errorDeletingTrustScoreDoc,
		},
	};
}

// MMS Thumbnail logo
function updateMMSOverlay(data: any): fetchAction {
	return {
		type: updateMMSOverlayTypes,
		callAPI: () => axios.patch(`${apiUrl}/workspaces/mmsOverlay`, data),
		payload: {
			custom: 'updateMMSOverlay',
		},
	};
}

type sendMMSThumbnailPreviewType = {
	phoneNumbers: string[];
};

function sendMMSThumbnailPreview(
	payload: sendMMSThumbnailPreviewType,
): fetchAction {
	return {
		type: sendMMSThumbnailPreviewTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/sendMmsPreview`, payload),
		payload: {
			custom: 'sendMMSThumbnailPreview',
			successMessage: ToastMessage.mmsThumbnailPreviewSent,
			errorMessage: ToastMessage.errorSendingMmsThumbnailPreview,
		},
	};
}

//trigger data export job manually

function syncExportJob(): fetchAction {
	return {
		type: syncExportJobTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/exportJob`),
		payload: {
			custom: 'syncExportJob',
			successMessage: ToastMessage.dataExportJobTriggered,
			errorMessage: ToastMessage.error,
		},
	};
}

export {
	getWorkspaceByEmail,
	getWorkspaceInfo,
	updateWorkspaceIntegration,
	updateHotglueCommunicationPreference,
	updateHotglueSyncingSchedule,
	updateWorkspace,
	disconnectWorkspaceIntegration,
	uploadLogo,
	verifyWorkspaceDomainData,
	updateVideoPageSettings,
	getZapierApiKey,
	setZapierIntegration,
	resetWorkspace,
	disconnectHotglueIntegration,
	updateCustomAttributes,
	connectHotglueFlow,
	getSendDataOutTargetFields,
	createCustomAttribute,
	pinCustomAttribute,
	getOverrides,
	createOverrides,
	deleteOverrides,
	createWorksapceDataExport,
	editWorkspaceContactExport,
	getExportableFields,
	addTrustScoreDoc,
	updateTrustScoreDoc,
	deleteTrustScoreDoc,
	updateMMSOverlay,
	sendMMSThumbnailPreview,
	syncExportJob,
};
