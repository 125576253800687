import styled, { css } from 'styled-components';

import { TabComponentType } from './types';

export const ScrollButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 100%;
	border: 0;
	padding: 0 16px;
	background: ${({ theme }) => theme.colors.system.background.system};

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
`;

export const TextContainer = styled.div<{
	active?: boolean;
	disabled?: boolean;
}>`
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.xs};

	color: ${({ theme, active, disabled }) =>
		disabled
			? theme.colors.system.text.weak
			: active
				? theme.colors.primary.text.default
				: theme.colors.system.text.medium};
`;

export const Tab = styled.a<TabComponentType>`
	padding: ${({ theme }) => `${theme.spacing.small}`};
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};

	${({ active, theme }) =>
		!active &&
		css`
			&:hover {
				.tab-text {
					color: ${theme.colors.system.text.default};
				}

				::after {
					opacity: 1;
					background: ${({ theme }) => theme.colors.neutral.border.default};
				}
			}
		`}

	::after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		right: 0;
		border-radius: 1px;
		background: ${({ theme, active, disabled }) =>
			disabled
				? theme.colors.neutral.border.default
				: active && theme.colors.primary.text.default};
		opacity: ${({ active }) => (active ? 1 : 0)};
		transition: ${({ theme }) => theme.transition?.fade};
	}
`;

export const TabsWrapper = styled.div`
	display: flex;
	column-gap: 14px;
	width: 100%;
	overflow-x: auto;
	border-bottom: 1px solid ${({ theme }) => theme.colors.system.border.weak};

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	::-webkit-scrollbar {
		display: none;
	}
`;

export const TabsContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;
