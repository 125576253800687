import styled, { css } from 'styled-components';

function handleCircleRight(toggle: boolean) {
	return toggle ? '2px' : 'calc(100% - 19px)';
}

export const ToggleWrapper = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.small};

	& > * {
		cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
		pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	}
`;

export const Toggle = styled.button<{ toggle?: boolean; disabled?: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	width: 38px;
	height: 20px;

	justify-content: ${({ toggle }) => (toggle ? 'flex-end' : 'flex-start')};

	border-radius: ${({ theme }) => theme.shapeRadius.medium};

	transition: ${({ theme }) => theme.transition?.all};

	border: none;

	background: ${({ theme, toggle, disabled }) =>
		disabled
			? theme.colors.system.background.medium
			: toggle
				? theme.colors.primary.text.default
				: theme.colors.neutral.background.hover};

	&:focus {
		outline-color: ${({ theme }) => theme.colors.primary.background.default};
	}
`;

export const ToggleCircle = styled.div<{
	toggle?: boolean;
	disabled?: boolean;
}>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 16px;
	height: 16px;
	border-radius: 50%;

	right: ${({ toggle }) => handleCircleRight(toggle ?? false)};

	box-shadow: ${({ theme }) => theme.shadows.elevation.xs};
	background: ${({ theme }) => theme.colors.system.background.system};

	${({ theme }) => css`
		transition: ${theme.transition?.all};
	`}
`;
