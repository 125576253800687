import styled from 'styled-components';

export type StyledImgProps = {
	className: string;
	src: string;
	width?: string;
	height?: string;
};

export const StyledImg = styled.img<StyledImgProps>`
	display: inline-block;
	width: ${({ width }) => (width ? width : '24px')};
	min-width: ${({ width }) => (width ? width : '24px')};
	height: ${({ height }) => (height ? height : '24px')};
`;
