import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/redux/types';
import { CTATypeEnum } from '@app/constants/modules/ctas';
import { CTA } from '@app/redux/cta/types';
import {
	createCTAType,
	updateCTAType,
} from '@app/containers/CampaignCreation/types';
import { mapObjectToFormData } from '@app/utils/mapObjectToFormData';
import { createCta, updateCta } from '@app/redux/cta/action';
import { CTAFieldsValidator } from '@app/containers/CampaignCreationV2/containers/BuildStep/utils';

type ReplyContextType = {
	storedRepliesFields: any;
	replySelected: any;
	setRepliesFields: (replyFields: Partial<CTA>, setAll: boolean) => void;
	setReplySelectedFields: (replyFields: Partial<CTA>, setAll?: boolean) => void;
	setReplySelected: any;
	replyFieldsErrors: { [key: string]: string };
	createReply: (props: createCTAType) => void;
	updateReply: (props: updateCTAType) => void;
	clearReplyFieldsErrors: () => void;
};

const builderRepliesProvider = createContext<ReplyContextType>(
	{} as ReplyContextType,
);

export default function BuilderRepliesProvider({
	children,
}: {
	children: ReactNode;
	rest?: any;
}) {
	const dispatch = useDispatch();

	const callToActions = useSelector(
		({ campaign }: RootState) => campaign?.savedCampaign?.callToActions,
	);

	const getReplyFields = (callToActions: CTA[]) => {
		if (callToActions?.length) {
			const replyCTA = callToActions.find(
				(cta: CTA) => cta?.type === CTATypeEnum.SUGGESTED_REPLY,
			);

			return replyCTA ?? null;
		}
		return null;
	};

	/**
	 * storedRepliesFields: comes from the campaign callToActions
	 * replySelected: comes from the selected reply (temporary state)
	 * **/

	const [storedRepliesFields, setStoredRepliesFields] =
		useState<Partial<CTA> | null>(getReplyFields(callToActions));

	const [replySelected, setReplySelected] = useState<Partial<CTA>>();

	const [replyFieldsErrors, setReplyFieldsErrors] = useState({});
	const clearReplyFieldsErrors = () => {
		setReplyFieldsErrors({});
	};

	const validateReplyFields = async (
		fields: Partial<CTA>,
		callback?: () => void,
	) => {
		try {
			await CTAFieldsValidator(fields);
			clearReplyFieldsErrors();
			if (callback) {
				callback();
			}
		} catch (errors: any) {
			setReplyFieldsErrors(errors);
			return false;
		}
	};

	const setRepliesFields = (replyFields: Partial<CTA>, setAll: boolean) => {
		if (setAll) {
			setStoredRepliesFields(replyFields);
			validateReplyFields({
				...replyFields,
				type: CTATypeEnum.SUGGESTED_REPLY,
			});
		} else {
			setStoredRepliesFields((prevState) => {
				const replyCopy = { ...prevState, ...replyFields };
				validateReplyFields(replyCopy);
				return replyCopy;
			});
		}
	};

	//Set temp state
	const setReplySelectedFields = (
		replyFields: Partial<CTA>,
		setAll?: boolean,
	) => {
		if (setAll) {
			setReplySelected(replyFields);
			validateReplyFields({
				...replyFields,
				type: CTATypeEnum.SUGGESTED_REPLY,
			});
		} else {
			setReplySelected((prevState) => {
				const replyCopy = { ...prevState, ...replyFields };
				validateReplyFields(replyCopy);
				return replyCopy;
			});
		}
	};

	const createReply = async ({ onFail, onSuccess, cta }: createCTAType) => {
		const editedCTA = { ...cta };
		const createCTAForm = new FormData();
		mapObjectToFormData(createCTAForm, editedCTA);
		try {
			// await validateCTAFields(cta);
			const createResponse = await dispatch(createCta(createCTAForm));
			if (createResponse) {
				onSuccess && onSuccess(createResponse);
			}
		} catch (e) {
			onFail && onFail(e);
		}
	};

	const updateReply = async ({ onFail, onSuccess, cta }: updateCTAType) => {
		const editedCTA = { ...cta };
		const updateCTAForm = new FormData();
		mapObjectToFormData(updateCTAForm, editedCTA);
		const ctaId = cta?._id || '';
		try {
			// await validateCTAFields(cta);
			const updateResponse = await dispatch(
				updateCta({ form: updateCTAForm, id: ctaId, isV2: true }),
			);
			if (updateResponse) {
				onSuccess && onSuccess(updateResponse);
			}
		} catch (e) {
			onFail && onFail(e);
		}
	};

	useEffect(() => {
		const suggestedReply = getReplyFields(callToActions);
		if (suggestedReply) {
			setStoredRepliesFields(suggestedReply);
		}
	}, [callToActions]);

	return (
		<builderRepliesProvider.Provider
			value={{
				storedRepliesFields,
				replySelected,
				setReplySelected,
				setRepliesFields,
				setReplySelectedFields,
				replyFieldsErrors,
				createReply,
				updateReply,
				clearReplyFieldsErrors,
			}}
		>
			{children}
		</builderRepliesProvider.Provider>
	);
}

export function useBuilderRepliesContext() {
	return useContext(builderRepliesProvider);
}
