import {
	FontLineHeightsType,
	FontWeightsType,
} from '@common/design-system/global/types';
import { evalColorPath } from '@common/design-system/global/utils';
import styled from 'styled-components';

const getLinkSizing = (size: string) => {
	switch (size) {
		case 'small':
			return `xs`;
		case 'medium':
			return `small`;
		case 'large':
			return `regular`;
		default:
			return `small`;
	}
};

export const StyledLink = styled.a<{
	size: string;
	iconButton?: boolean;
	disabled: boolean;
	color?: string;
	weight?: FontWeightsType;
	lineHeight?: FontLineHeightsType;
}>`
	display: inline-flex;
	align-items: center;
	text-align: left;
	column-gap: ${({ theme }) => theme.spacing.xs};
	cursor: pointer;
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	width: fit-content;
	line-height: ${({ theme, lineHeight }) =>
		theme.fonts.lineHeight[lineHeight || 'regular']};

	color: ${({ theme, disabled, color }) =>
		disabled
			? theme.colors.system.text.medium
			: evalColorPath(theme.colors, color || 'primary.text.default')};

	font-weight: ${({ theme, weight }) =>
		theme.fonts.weights[weight || 'medium']};

	font-size: ${({ theme, size }) => theme.fonts.sizes[getLinkSizing(size)]};
	text-decoration: none;
	position: relative;
	white-space: pre-wrap;

	&:hover {
		color: ${({ theme, disabled, color }) =>
			disabled
				? theme.colors.system.text.medium
				: evalColorPath(theme.colors, color || 'primary.text.hover')};

		text-decoration: underline !important;
	}
`;
