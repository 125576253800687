import { CampaignBuilderType } from '../types';

export enum campaignScreens {
	basics = 'basics',
	actions = 'action',
	storyteller = 'storyteller',
	instructions = 'instructions',
	audience = 'audience',
	automation = 'automation',
	storyLibrary = 'video-library',
	summary = 'summary',
	templates = 'templates',
}

export enum templateBuilderScreens {
	sms = 'sms',
	email = 'email',
	'video-page' = 'video-page',
	'widget' = 'widget',
	'whatsapp' = 'whatsapp',
}

export enum SummaryRouteEnum {
	COMPLETED = 'completed',
}

export const campaignStepper: CampaignBuilderType = {
	oneToOne: {
		totalSteps: 5,
		basics: {
			back: null,
			next: campaignScreens.audience,
			step: 1,
		},
		audience: {
			back: campaignScreens.basics,
			next: campaignScreens.storyteller,
			step: 2,
		},
		storyteller: {
			back: campaignScreens.audience,
			next: campaignScreens.templates,
			step: 3,
		},
		templates: {
			back: campaignScreens.storyteller,
			next: campaignScreens.summary,
			step: 4,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	oneToAll: {
		totalSteps: 5,
		basics: {
			back: null,
			next: campaignScreens.audience,
			step: 1,
		},
		audience: {
			back: campaignScreens.basics,
			next: campaignScreens.storyLibrary,
			step: 2,
		},
		storyLibrary: {
			back: campaignScreens.audience,
			next: campaignScreens.templates,
			step: 3,
		},
		templates: {
			back: campaignScreens.storyLibrary,
			next: campaignScreens.summary,
			step: 4,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	oneToAllNewStory: {
		totalSteps: 5,
		basics: {
			back: null,
			next: campaignScreens.audience,
			step: 1,
		},
		audience: {
			back: campaignScreens.basics,
			next: campaignScreens.storyteller,
			step: 2,
		},
		storyteller: {
			back: campaignScreens.audience,
			next: campaignScreens.templates,
			step: 3,
		},
		templates: {
			back: campaignScreens.storyteller,
			next: campaignScreens.summary,
			step: 4,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	oneToWorld: {
		totalSteps: 4,
		basics: {
			back: null,
			next: campaignScreens.storyLibrary,
			step: 1,
		},
		storyLibrary: {
			back: campaignScreens.basics,
			next: campaignScreens.templates,
			step: 3,
		},
		templates: {
			back: campaignScreens.storyLibrary,
			next: campaignScreens.summary,
			step: 4,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	oneToWorldNewStory: {
		totalSteps: 4,
		basics: {
			back: null,
			next: campaignScreens.storyteller,
			step: 1,
		},
		storyteller: {
			back: campaignScreens.basics,
			next: campaignScreens.templates,
			step: 3,
		},
		templates: {
			back: campaignScreens.storyteller,
			next: campaignScreens.summary,
			step: 4,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	collectVideos: {
		totalSteps: 4,
		basics: {
			back: null,
			next: campaignScreens.storyteller,
			step: 1,
		},
		storyteller: {
			back: campaignScreens.basics,
			next: campaignScreens.templates,
			step: 2,
		},
		templates: {
			back: campaignScreens.storyteller,
			next: campaignScreens.summary,
			step: 3,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	collectVideosSkipTemplates: {
		totalSteps: 3,
		basics: {
			back: null,
			next: campaignScreens.storyteller,
			step: 1,
		},
		storyteller: {
			back: campaignScreens.basics,
			next: campaignScreens.summary,
			step: 2,
		},
		summary: {
			back: campaignScreens.storyteller,
			next: null,
		},
	},
	/** These are the flows skipping the audience step */
	oneToOneChildSkipAudience: {
		totalSteps: 4,
		basics: {
			back: null,
			next: campaignScreens.storyteller,
			step: 1,
		},
		storyteller: {
			back: campaignScreens.basics,
			next: campaignScreens.templates,
			step: 2,
		},
		templates: {
			back: campaignScreens.storyteller,
			next: campaignScreens.summary,
			step: 3,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	oneToAllChildSkipAudience: {
		totalSteps: 4,
		basics: {
			back: null,
			next: campaignScreens.storyLibrary,
			step: 1,
		},
		storyLibrary: {
			back: campaignScreens.basics,
			next: campaignScreens.templates,
			step: 2,
		},
		templates: {
			back: campaignScreens.storyLibrary,
			next: campaignScreens.summary,
			step: 3,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
	oneToAllNewStoryChildSkipAudience: {
		totalSteps: 4,
		basics: {
			back: null,
			next: campaignScreens.storyteller,
			step: 1,
		},
		storyteller: {
			back: campaignScreens.basics,
			next: campaignScreens.templates,
			step: 2,
		},
		templates: {
			back: campaignScreens.storyteller,
			next: campaignScreens.summary,
			step: 3,
		},
		summary: {
			back: campaignScreens.templates,
			next: null,
		},
	},
};

export type stepperCampaignType =
	| 'oneToOne'
	| 'oneToAll'
	| 'oneToAllNewStory'
	| 'oneToWorld'
	| 'oneToWorldNewStory'
	| 'collectVideos'
	| 'collectVideosSkipTemplates'
	| 'oneToOneChildSkipAudience'
	| 'oneToAllChildSkipAudience'
	| 'oneToAllNewStoryChildSkipAudience';

export enum campaignTypeEnum {
	oneToOne = 'oneToOne',
	oneToAll = 'oneToAll',
	oneToAllNewStory = 'oneToAllNewStory',
	oneToWorld = 'oneToWorld',
	oneToWorldNewStory = 'oneToWorldNewStory',
	collectVideos = 'collectVideos',
	collectVideosSkipTemplates = 'collectVideosSkipTemplates',
	/** These are the flows skipping the audience step */
	oneToOneChildSkipAudience = 'oneToOneChildSkipAudience',
	oneToAllChildSkipAudience = 'oneToAllChildSkipAudience',
	oneToAllNewStoryChildSkipAudience = 'oneToAllNewStoryChildSkipAudience',
}

export const InstructionsPlaceholder =
	'Give some best practices and tips to your recorders, this information will be visible to your recorders when they record your videos.';

export enum PersonalizationEnum {
	oneToOne = 'oneToOne',
	oneToAll = 'oneToAll',
	oneToWorld = 'oneToWorld',
	collectVideos = 'collectVideos',
	collectVideosSkipTemplates = 'collectVideosSkipTemplates',
	sms = 'sms',
}

export enum CreationMethod {
	createToDos = 'createToDos',
	videoFromLibrary = 'videoFromLibrary',
	collectVideos = 'collectVideos',
	noVideo = 'noVideo',
}

export type campaignCreationType = {
	personalization: PersonalizationEnum | null;
	creationMethod: CreationMethod | null;
};

/** Here is where the campaigns are created from the dropdown */

export const oneToOneCampaign: campaignCreationType = {
	personalization: PersonalizationEnum.oneToOne,
	creationMethod: CreationMethod.createToDos,
};

export const oneToManyCampaign: campaignCreationType = {
	personalization: PersonalizationEnum.oneToAll,
	creationMethod: null,
};

export const collectVideosCampaign: campaignCreationType = {
	personalization: null,
	creationMethod: CreationMethod.collectVideos,
};

export const basicFormInitialState = {
	id: '',
	campaignName: '',
	creationMethod: '',
	strictApproval: true,
	scheduleDate: '',
	hideReply: false,
};

/** All the one to many campaigns*/

export const OneToManyCampaignTypes: string[] = [
	campaignTypeEnum.oneToAll,
	campaignTypeEnum.oneToAllNewStory,
	campaignTypeEnum.oneToAllChildSkipAudience,
	campaignTypeEnum.oneToAllNewStoryChildSkipAudience,
];

export const OneToOneCampaignTypes: string[] = [
	campaignTypeEnum.oneToOneChildSkipAudience,
	campaignTypeEnum.oneToOne,
];

export const uploadAudienceHelpArticle =
	'https://intercom.help/goodkind/en/articles/5164336-uploading-contacts-with-a-file-into-goodkind';

export enum stepsName {
	campaignType = 'Setup',
	action = 'Action',
	videoPage = 'Video page',
	templates = 'Templates',
	storyteller = 'Recorders',
	instructions = 'Instructions',
	audience = 'Audience',
	automation = 'Automation',
	summary = 'Summary',
	selectVideo = 'Select video',
}

export const isCompleteStep = {
	[campaignScreens.basics]: false,
	[campaignScreens.actions]: false,
	[campaignScreens.templates]: false,
	[campaignScreens.storyteller]: false,
	[campaignScreens.instructions]: false,
	[campaignScreens.audience]: false,
	[campaignScreens.automation]: false,
	[campaignScreens.storyLibrary]: false,
	[campaignScreens.summary]: false,
};

export enum campaignResponseMessage {
	duplicatedName = 'One or several campaigns with this name already exist.',
	updatedSuccessfully = 'Campaign updated successfully',
}

export const UntitledCampaignName = 'Untitled Campaign';

export enum HotglueJobStatusEnum {
	STARTED = 'started',
	STOPPED = 'stopped',
	QUEUED = 'queued',
	COMPLETED = 'completed',
	CANCELLED = 'cancelled',
	PENDING_APPROVAL = 'pendingApproval',
	FAILED = 'failed',
	IN_PROGRESS = 'inProgress',
}
