import { useLocation } from 'react-router-dom';

export function useQueryParams() {
	const location = useLocation();

	const { search } = location;

	const queryParams = new URLSearchParams(search);

	/** To get the specific param, use queryParams.get('PARAM_NAME'); */
	return { queryParams };
}
