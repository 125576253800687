import React from 'react';

import { StyledImg } from './CustomIcon.styles';
import { IconEnum } from '@global/icons/customIconsConstant';

export type CustomIconProps = {
	iconName: string;
	width?: string;
	height?: string;
	className?: string;
	color?: string;
};

export default function Icon({
	className,
	iconName,
	width,
	height,
}: CustomIconProps) {
	return (
		<StyledImg
			className={className || ''}
			src={IconEnum[iconName?.toLowerCase()] || iconName}
			width={width}
			height={height}
		/>
	);
}
