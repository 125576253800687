import React from 'react';

import * as S from './Badge.styles';

import { Icon, Text } from '@common/design-system/components/atoms';
import { IconWeight } from '@phosphor-icons/react';
import StatusIndicator, {
	StatusIndicatorPropsType,
} from '../StatusIndicator/StatusIndicator';

type BadgeSizeType = 'small' | 'medium' | 'large';

type PropsType = {
	text?: string;
	variant?:
		| 'success'
		| 'warning'
		| 'error'
		| 'info'
		| 'accent'
		| 'neutral'
		| 'outline'
		| 'highlight1'
		| 'highlight2'
		| 'highlight3';
	size?: BadgeSizeType;
	iconName?: string;
	iconPosition?: 'left' | 'right';
	iconBadge?: boolean;
	iconWeight?: IconWeight;
	dropdownBadge?: boolean;
	statusColor?: string;
	statusVariant?: StatusIndicatorPropsType['variant'];
	squared?: boolean;
	className?: string;
	onRemove?: (id?: string | number) => void;
	onClick?: (id?: string | number) => void;
	id?: string | number;
};

function Badge({
	id,
	text,
	iconName,
	iconPosition = 'left',
	size = 'medium',
	variant = 'info',
	iconBadge = false,
	iconWeight,
	dropdownBadge = false,
	statusVariant,
	statusColor,
	squared,
	className,
	onRemove,
	onClick,
}: PropsType) {
	const iconSizeMap: {
		[key in BadgeSizeType]: 'xs' | 'small' | 'medium';
	} = {
		small: 'xs',
		medium: 'small',
		large: 'medium',
	};

	const isIconLeft = iconName && iconPosition === 'left';
	const isIconRight = iconName && iconPosition === 'right';

	// Undefined for the icons the inherit the text color
	const removeIconColor = {
		neutral: undefined,
		primary: undefined,
		secondary: undefined,
		tertiary: undefined,
		success: undefined,
		warning: undefined,
		error: undefined,
		info: undefined,
		accent: undefined,
		highlight1: undefined,
		highlight2: undefined,
		highlight3: undefined,
		outline: 'system.text.weak',
	};

	const handleBadgeClick = () => {
		onClick && onClick(id || text);
	};

	return (
		<S.BadgeContainer
			variant={variant}
			size={size}
			iconBadge={iconBadge}
			dropdownBadge={dropdownBadge}
			hasIcon={!!isIconLeft || !!isIconRight}
			squared={squared}
			className={className}
			onClick={onClick ? handleBadgeClick : undefined}
		>
			{iconBadge && iconName ? (
				<Icon
					iconName={iconName}
					size={iconSizeMap[size]}
					weight={iconWeight}
				/>
			) : (
				<>
					{isIconLeft && (
						<Icon
							iconName={iconName}
							size={iconSizeMap[size]}
							weight={iconWeight}
						/>
					)}

					{(statusColor || statusVariant) && (
						<StatusIndicator variant={statusVariant} color={statusColor} />
					)}

					{text && <Text>{text}</Text>}

					{isIconRight && (
						<Icon
							iconName={iconName}
							size={iconSizeMap[size]}
							weight={iconWeight}
						/>
					)}

					{dropdownBadge && (
						<Icon iconName="chevronDown" weight="fill" size="xxs" />
					)}

					{!!onRemove && (
						<S.RemoveIconContainer
							onClick={() => onRemove(id)}
							className="remove"
						>
							<Icon
								iconName="close"
								weight="bold"
								size="xxxs"
								color={removeIconColor[variant]}
								alt="Remove"
							/>
						</S.RemoveIconContainer>
					)}
				</>
			)}
		</S.BadgeContainer>
	);
}

export default Badge;
export type { PropsType as BadgePropsType };
