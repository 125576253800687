import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@app/redux/types';
import { useLocation } from 'react-router-dom';
import useSegement from '@app/hooks/useSegment';
import { SegemntEvents } from '@app/constants';

import { getPageNameFromPath } from '@app/utils/getPageName';
import { useAuth } from '../Auth/useAuth';

export default function Segment() {
	const [workspace] = useSelector(({ workspace }: RootState) => [
		workspace.workspaceInfo,
	]);

	const { user } = useAuth();

	const loginRef = useRef(false);
	const location = useLocation();
	const { pageTracker, setWorkspceGroup, identifyUser, eventTracker } =
		useSegement();

	useEffect(() => {
		if (workspace?.name) {
			const page = getPageNameFromPath(location.pathname);
			pageTracker(SegemntEvents.viewedPage, page, location);
		}
	}, [workspace.name, location.pathname]);

	useEffect(() => {
		if (workspace?.name && user?.email) {
			setWorkspceGroup();
			if (!loginRef.current) {
				identifyUser();
				eventTracker(SegemntEvents.login);
				loginRef.current = true;
			}
		}
	}, [workspace?.name, user?.email]);

	return <></>;
}
