//Hook to unify auth stuff, useAuth hook
import { RootState } from '@app/redux/types';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	useRedirectFunctions,
	useAuthInfo,
	useLogoutFunction,
} from '@propelauth/react';
import LocalStorage from '@app/utils/LocalStorage';

export const useAuth = () => {
	// Auth0 provider hooks
	const {
		isLoading: auth0IsLoading,
		user: auth0User,
		isAuthenticated: auth0IsAuthenticated,
		getAccessTokenSilently: getAccessTokenSilentlyAuth0,
		logout: auth0Logout,
		loginWithRedirect: auth0LoginWithRedirect,
	} = useAuth0();

	// Propel provider hooks
	const {
		loading: propelIsLoading,
		user: propelUser,
		isLoggedIn: propelIsLoggedIn,
		accessToken: propelAccessToken,
	} = useAuthInfo();

	const { redirectToLoginPage } = useRedirectFunctions();
	const propelLogout = useLogoutFunction();

	// Grant access / identity information
	const {
		user: accessUser,
		loading: grantAccessLoading,
		error: accessError,
	} = useSelector(({ auth }: RootState) => ({
		user: auth.user,
		loading: !!auth.loading,
		error: auth.error,
	}));

	const selectedAuthProviderName = LocalStorage.getFromLocalStorage(
		'checkAuthProvider',
	) as 'auth0' | 'propelAuth';

	const isProviderLoading = auth0IsLoading || propelIsLoading;
	const isAccessLoading = grantAccessLoading;

	const isLoading = isProviderLoading || isAccessLoading;

	const providersSchema = useMemo(() => {
		return {
			auth0: {
				name: 'auth0',
				user: auth0User,
				isAuthenticated: auth0IsAuthenticated,
				isLoading: auth0IsLoading,
				getAccessTokenSilently: getAccessTokenSilentlyAuth0,
				logout: auth0Logout,
				loginWithRedirect: auth0LoginWithRedirect,
			},
			propelAuth: {
				name: 'propelAuth',
				user: propelUser,
				isAuthenticated: propelIsLoggedIn || false,
				isLoading: propelIsLoading,
				getAccessTokenSilently: () => Promise.resolve(propelAccessToken),
				logout: propelLogout,
				loginWithRedirect: redirectToLoginPage,
			},
		};
	}, [
		auth0IsLoading,
		auth0IsAuthenticated,
		getAccessTokenSilentlyAuth0,
		auth0Logout,
		auth0LoginWithRedirect,
		auth0User,
		propelIsLoading,
		propelIsLoggedIn,
		propelAccessToken,
		propelLogout,
		redirectToLoginPage,
		propelUser,
	]);

	const authProvider = useMemo(() => {
		if (!selectedAuthProviderName) {
			return null;
		}

		return providersSchema[selectedAuthProviderName];
	}, [providersSchema, selectedAuthProviderName]);

	return {
		authProvider,
		authProvidersSchemas: providersSchema,
		selectedAuthProviderName,
		isLoading,
		isProviderLoading,
		isAccessLoading,
		accessError,
		user: accessUser,
		propelAccessToken,
	};
};
