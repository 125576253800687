import styled from 'styled-components';

import { OrientationType } from '@app/types';

export const StyledButton = styled.button<{ active: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	border: 0;
	background: ${({ theme, active }) =>
		active ? theme.colors.system.background.system : 'none'};
	column-gap: 8px;
	transition: ${({ theme }) => theme.transitions.hover};
	box-shadow: ${({ active, theme }) => active && theme.shadows.elevation.base};
	border-radius: ${({ theme }) => theme.shapeRadius.small};
	z-index: 1;
`;

export const StyledContainer = styled.div<{
	disabled?: boolean;
	isInvalid?: boolean;
	orientation: OrientationType;
}>`
	width: 100%;
	padding: 2px;
	min-height: 40px;
	flex-shrink: 0;
	display: inline-flex;
	flex-direction: ${({ orientation }) =>
		orientation === 'vertical' && 'column'};
	border-radius: ${({ theme }) => theme.shapeRadius.small};
	background: ${({ theme }) => theme.colors.system.background.weak};
	overflow: hidden;
	box-shadow: ${({ theme, disabled }) =>
		disabled && theme.shadows.elevation.base};
	border: ${({ isInvalid, theme }) =>
		isInvalid && `1px solid ${theme.colors.error.border.default}`};
	pointer-events: ${({ disabled }) => disabled && 'none'};
	opacity: ${({ disabled }) => disabled && 0.6};
`;
