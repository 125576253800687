import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
	// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
	&&&.Toastify__toast-container {
		height: auto;
		padding: 0;
	}
	.Toastify__toast {
		padding: 0;
		min-height: 45px;
		background: none !important;
	}
	.Toastify__toast-body {
		padding: 0;
		margin: 0;
	}
`;

export default StyledToastContainer;
