import styled from 'styled-components';

export const StyledH3 = styled.h3`
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	margin: 0;
`;
