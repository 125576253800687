import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  from { 
		opacity: 0;
	 }

  to { 
		opacity: 1; 
	}
`;

export const TagsInputTooltipContainer = styled.div<{
	height?: number;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	animation-name: ${fade};
	animation-duration: 0.2s;
	animation-timing-function: ease-in-out;
	z-index: ${({ theme }) => theme.zIndex.popover};
	max-height: 380px;
`;

export const EmojiAndOptionsWrapper = styled.div`
	min-width: 300px;
	max-width: 300px;
	padding: 6px 8px;
	background: ${({ theme }) => theme.colors.system.background.system};
	/* box-shadow: ${({ theme }) => theme.boxShadow?.medium}; */
	border-radius: ${({ theme }) => theme.shapeRadius.xxs};
	border: 1px solid ${({ theme }) => theme.colors.neutral.border.default};

	& > *:not(:last-child) {
		margin-bottom: 8px;
	}
`;

export const OptionsContainer = styled.div`
	min-height: 280px;
	max-height: 280px;
	width: 100%;
	overflow-y: auto;
`;

export const Option = styled.div`
	padding: ${({ theme }) => theme.spacing.xs};
	border-radius: 5px;
	cursor: pointer;
	white-space: wrap;

	&:hover {
		background: ${({ theme }) => theme.colors.system.background.weak};
	}
`;
