export enum InboxPresenceEvents {
	OPEN_CONVERSATION = 'client-open-conversation',
	CLOSE_CONVERSATION = 'client-close-conversation',
	TYPING_IN_CONVERSATION = 'client-typing-in-conversation',
	STOPPED_TYPING_IN_CONVERSATION = 'client-typing-in-conversation-stopped',
	TOGGLE_STARRED_CONVERSATION = 'client-toggleStarred-conversation',
	TOGGLE_ARCHIVED_CONVERSATION = 'client-toggleArchived-conversation',
	OPTED_OUT_CONVERSATION = 'client-optOut-conversation',
}

export enum InboxPrivateEvents {
	MESSAGE_RECEIVED = 'message-received',
	MESSAGE_CREATED = 'message-created',
	MESSAGE_STATUS_UPDATED = 'message-status-updated',
	UNHIDE_CONVERSATION = 'unhide-conversation',
}
