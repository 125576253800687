import { useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';

import { RootState } from '@app/redux/types';
import { useAuth } from '@app/services/Auth/useAuth';

function useDatadog() {
	const { workspace } = useSelector(({ workspace }: RootState) => ({
		workspace: workspace.workspaceInfo,
	}));

	const { user } = useAuth();

	function setDatadogUser() {
		const {
			_id: workspaceId,
			country,
			location,
			workspaceVertical: vertical,
			name: workspaceName,
		} = workspace;

		const { id: storytellerId, fullName: name, email } = user;

		const dataDogUserData = {
			storytellerId,
			workspaceId,
			workspaceName,
			name,
			email,
			id: storytellerId,
			vertical,
			location,
			country,
		};

		datadogRum.setUser(dataDogUserData);
	}

	function unsetDatadogUser() {
		datadogRum.removeUser();
	}

	return {
		setDatadogUser,
		unsetDatadogUser,
	};
}

export default useDatadog;
