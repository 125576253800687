import styled from 'styled-components';

import {
	FontFamilyType,
	FontLetterSpacingsType,
	FontLineHeightsType,
	FontSizesType,
	FontWeightsType,
} from '@common/design-system/global/types';
import { evalColorPath } from '@common/design-system/global/utils';

export const StyledText = styled.div<{
	color?: string;
	size?: FontSizesType;
	fontWeight: FontWeightsType;
	lineHeight: FontLineHeightsType;
	letterSpacing: FontLetterSpacingsType;
	uppercase?: boolean;
	center?: boolean;
	fontFamily: FontFamilyType;
	noWrap?: boolean;
	lineClamp?: number;
	isItalic?: boolean;
}>`
	font-family: ${({ theme, fontFamily }) => theme.fonts.family[fontFamily]};
	font-size: ${({ theme, size }) =>
		size ? theme.fonts.sizes[size] : 'inherit'};
	font-weight: ${({ theme, fontWeight }) =>
		fontWeight ? theme.fonts.weights[fontWeight] : 'inherit'};
	font-style: ${({ isItalic }) => (isItalic ? 'italic' : 'normal')};
	color: ${({ theme, color }) =>
		color ? evalColorPath(theme.colors, color) : 'inherit'};
	line-height: ${({ theme, lineHeight }) => theme.fonts.lineHeight[lineHeight]};
	letter-spacing: ${({ theme, letterSpacing }) =>
		theme.fonts.letterSpacing[letterSpacing]};
	margin: 0;
	padding: 0;
	text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
	gap: ${({ theme }) => theme.spacing.xxs};
	display: -webkit-box;
	text-overflow: ellipsis;
	overflow: ${({ noWrap, lineClamp }) =>
		noWrap || lineClamp ? 'hidden' : 'initial'};
	word-break: ${({ noWrap }) => (noWrap ? 'keep-all' : 'break-word')};
	text-align: ${({ center }) => (center ? 'center' : 'inherit')};

	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${({ lineClamp, noWrap }) =>
		noWrap ? 1 : lineClamp || 'none'};
	cursor: inherit;
`;
