/**
 * Input +13505551234
 * Output (350) 555-1234
 * */
export function formatPhoneNumberWithCountryCode(phoneNumber: string) {
	if (!phoneNumber) return;
	if (phoneNumber?.length !== 12) {
		return phoneNumber;
	}
	const areaCode = phoneNumber.substring(2, 5);
	const firstPart = phoneNumber.substring(5, 8);
	const secondPart = phoneNumber.substring(8, 12);

	return `(${areaCode}) ${firstPart}-${secondPart}`;
}

/**
 * Input 1234567890
 * Output 123-456-7890
 * */
export function formatPhoneNumberWithoutCountryCode(phoneNumber?: string) {
	if (!phoneNumber) return;

	if (phoneNumber?.length !== 10) {
		return phoneNumber;
	}

	const areaCode = phoneNumber.slice(0, 3);
	const firstPart = phoneNumber.slice(3, 6);
	const secondPart = phoneNumber.slice(6);

	return `${areaCode}-${firstPart}-${secondPart}`;
}
