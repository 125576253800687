import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants/index';
import { fetchAction } from '../types';
import { FilterOptions } from '@app/types/index';

import axios from '@global/axios';
import { CTADropdownFilter } from '../constants';
const mainType = 'cta';
type cta = {
	[key: string]: { [key: string]: any };
};

export const filterCtaTypes = createFetchTypes(`${mainType}_FILTER_CTA`);
export const deleteCtaTypes = createFetchTypes(`${mainType}_DELETE_CTA`);
export const createCtaTypes = createFetchTypes(`${mainType}_CREATE_CTA`);
export const updateCtaTypes = createFetchTypes(`${mainType}_UPDATE_CTA`);
export const softBulkTypes = createFetchTypes(`${mainType}_SOFT_BULK_CTA`);

export const getCtaOptionsTypes = createFetchTypes(`${mainType}_GET_OPTIONS`);

function getCtaOptions(options?: { excludeAll?: boolean }): fetchAction {
	return {
		type: getCtaOptionsTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/cta?filter=${JSON.stringify(CTADropdownFilter)}`),
		payload: {
			excludeAll: options?.excludeAll,
		},
	};
}

function filterCtas(filterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: filterCtaTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/cta${filterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

function deleteCtas(DeleteOptions: string[]): fetchAction {
	const deleteString = JSON.stringify(DeleteOptions);
	return {
		type: deleteCtaTypes,
		callAPI: () =>
			axios.delete(
				`${apiUrl}/cta${DeleteOptions ? `?filter=${deleteString}` : ``}`,
			),
	};
}

function createCta(CreateOptions: FormData, custom?: string): fetchAction {
	return {
		type: createCtaTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/cta`, CreateOptions, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		payload: {
			...(custom ? { custom } : {}),
			successMessage: custom !== 'createPreview' && ToastMessage.createCTA,
		},
	};
}

type updateCTAType = {
	form: FormData;
	id: string;
	settings?: {
		successMessage?: string;
		errorMessage?: string;
	};
	isV2?: boolean;
};

function updateCta({ isV2, id, form, settings }: updateCTAType): fetchAction {
	const url = isV2 ? `${apiUrl}/cta/v2/${id}` : `${apiUrl}/cta/${id}`;
	return {
		type: updateCtaTypes,
		callAPI: () =>
			axios.patch(url, form, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		payload: {
			errorMessage: settings?.errorMessage && settings?.errorMessage,
		},
	};
}

function softBulk(ctaIds: string[]) {
	return {
		type: softBulkTypes,
		callAPI: () =>
			axios.delete(`${apiUrl}/cta/soft/bulk`, {
				data: { ctaIds: ctaIds },
			}),
		payload: { ctaIds: ctaIds },
	};
}

function resetCTAs() {
	return {
		type: `${mainType}/RESET`,
	};
}

export {
	filterCtas,
	deleteCtas,
	createCta,
	updateCta,
	softBulk,
	getCtaOptions,
	resetCTAs,
};
