import styled from 'styled-components';

import { HorizontalType, VerticalType } from '@app/types';
import { MenuProps, OptionVariantType } from './types';

import { Button } from '..';

import { GoodkindTheme } from '@global/theme';

export const StyledDropdownButton = styled(Button)`
	padding: 0 1px 0 8px;
`;

export const Separator = styled.div`
	content: '';
	display: inline-block;
	margin: 0 0 0px 8px;
	background: ${({ theme }) => theme.colors.neutral[0]};
	height: 18px;
	width: 1px;
	vertical-align: top;
`;

function handleVariant(variant: string) {
	switch (variant) {
		case 'primary':
			return GoodkindTheme.colors.primary[700];

		case 'neutral':
			return GoodkindTheme.colors.neutral[400];

		case 'alert':
			return GoodkindTheme.colors.alert[700];

		default:
			return GoodkindTheme.colors.neutral[400];
	}
}

function handleHoverVariant(variant: string) {
	switch (variant) {
		case 'primary':
			return GoodkindTheme.colors.primary[900];

		case 'neutral':
			return GoodkindTheme.colors.neutral[700];

		case 'alert':
			return GoodkindTheme.colors.alert[900];

		default:
			return GoodkindTheme.colors.neutral[700];
	}
}

export const MenuContainer = styled.div<MenuProps>`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: ${({ size }) => `${size}px`};
	min-height: ${({ size }) => `${size}px`};
	cursor: pointer;

	&:hover {
		> svg {
			color: ${({ theme }) => theme.colors.neutral[700]} !important;
		}
	}
`;

export const PopUpMenu = styled.div<{
	horizontalOrientation: HorizontalType;
	verticalOrientation: VerticalType;
}>`
	position: absolute;
	display: flex;
	flex-direction: column;
	min-width: 120px;
	box-shadow: ${({ theme }) => theme.boxShadow?.strong};
	background: ${({ theme }) => theme.colors.neutral[0]};
	border-radius: ${({ theme }) => theme.borderRadius?.default};
	overflow: hidden;
	opacity: 1 !important;
	z-index: 10;

	${({ verticalOrientation }) =>
		verticalOrientation === 'down'
			? 'top: calc(100% + 8px);'
			: 'bottom: calc(100% + 8px);'}

	${({ horizontalOrientation }) =>
		horizontalOrientation === 'left' ? 'right: 0;' : 'left: 0;'}

	> * + * {
		margin-top: 4px;
	}
`;

export const Option = styled.div<{
	variant: OptionVariantType;
	disabled?: boolean;
}>`
	display: flex;
	align-items: center;
	padding: 8px;
	background: ${({ disabled, theme }) => disabled && theme.colors.neutral[50]};
	opacity: ${({ disabled }) => disabled && '0.5'};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

	p {
		white-space: nowrap;
		color: ${({ variant }) => handleVariant(variant)};
	}

	svg {
		fill: ${({ variant }) => handleVariant(variant)} !important;
	}

	> * + * {
		margin-left: 8px;
	}

	&:hover {
		svg {
			fill: ${({ variant, disabled }) =>
				!disabled && handleHoverVariant(variant)} !important;
		}

		p {
			color: ${({ variant, disabled }) =>
				!disabled && handleHoverVariant(variant)} !important;
		}
	}
`;
