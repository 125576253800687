import React from 'react';

import { TypographyProps } from '../types';

import { H1Typography } from './H1.styles';

export default function H1({
	children,
	position = 'left',
	color,
	className,
}: TypographyProps) {
	return (
		<H1Typography color={color} position={position} className={className}>
			{children}
		</H1Typography>
	);
}
