import styled from 'styled-components';

import { HorizontalType } from '@app/types';

import { TooltipContainer } from './WrapperTooltip.styles';

export const ContentTooltipContainer = styled(TooltipContainer)<{
	orientation?: HorizontalType;
	height?: number | null;
	horizontallyAligned?: boolean;
}>`
	width: fit-content;

	top: ${({ height, horizontallyAligned }) =>
		horizontallyAligned && height ? `calc(50% - ${height / 2}px)` : '8px'};

	left: ${({ orientation }) => orientation === 'right' && '100%'};
	padding-left: ${({ orientation }) => orientation === 'right' && '8px'};

	right: ${({ orientation }) => orientation === 'left' && '100%'};
	padding-right: ${({ orientation }) => orientation === 'left' && '8px'};
`;
