import React, { useMemo } from 'react';

import If from '@common/components/layout/If';

import { Icon } from '@common/components/presentation/Icon';

import { InputProps } from './types';

import { GoodkindTheme } from '@global/theme';
import {
	InputContainer,
	IconContainer,
	StyledInput,
	InputLabel,
	HelperText,
	InputInfoText,
	InputInfoWrapper,
} from './Input.styles';
import { RequiredAsterisk } from '@common/components/presentation';

function Input({
	value,
	name = 'input-component',
	onChange,
	className,
	disabled,
	isRequired,
	type,
	autoFocus,
	readOnly,
	maxLength,
	labelText,
	helperText,
	placeholder,
	error,
	errorText,
	searchInput,
	iconName,
	iconColor,
	onKeyDown,
	onKeyUp,
	inputSize = 'large',
	autoComplete,
	onFocus,
	min,
	...rest
}: InputProps) {
	const handleChange = (event: any) => {
		event.persist();

		const inputValue = event.target.value;

		if (onChange) {
			onChange(inputValue as string);
		}
	};

	const handleFocus = (e: MouseEvent) => {
		if (readOnly) {
			(e.target as HTMLElement).blur();
			return;
		}

		if (onFocus) {
			onFocus(e);
		}
	};

	const isShowingErrorMessage = useMemo(
		() => !!errorText && !!error,
		[errorText, error],
	);

	return (
		<InputContainer
			disabled={disabled}
			error={error}
			iconColor={iconColor}
			className={className}
		>
			{(searchInput || iconName) && (
				<IconContainer>
					<Icon
						iconName={searchInput ? 'search' : iconName + ''}
						size={inputSize === 'large' ? 24 : 16}
						weight="regular"
						color={iconColor || GoodkindTheme.colors.neutral[300]}
					/>
				</IconContainer>
			)}

			<StyledInput
				id={name}
				name={name}
				type={type}
				value={value}
				disabled={disabled}
				readOnly={readOnly}
				maxLength={maxLength}
				aria-invalid={error}
				aria-labelledby={`${name}-label`}
				onChange={handleChange}
				searchInput={searchInput}
				iconName={iconName}
				autoComplete={autoComplete}
				onKeyDown={onKeyDown}
				error={error}
				hasLabel={!!labelText}
				hasPlaceholder={!!placeholder}
				placeholder={placeholder}
				onFocus={(e: any) => handleFocus(e)}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={autoFocus}
				inputSize={inputSize}
				onKeyUp={onKeyUp}
				min={min}
				{...rest}
			/>

			<If condition={!!labelText}>
				<InputLabel
					hasIcon={searchInput || !!iconName}
					htmlFor={name}
					disabled={disabled}
					error={error}
				>
					{labelText}
					{isRequired && <RequiredAsterisk />}
				</InputLabel>
			</If>

			<If condition={!!helperText && !isShowingErrorMessage}>
				<HelperText>{helperText}</HelperText>
			</If>

			<If condition={isShowingErrorMessage}>
				<InputInfoWrapper type="alert">
					<InputInfoText size="small" type="alert">
						{errorText}
					</InputInfoText>
				</InputInfoWrapper>
			</If>
		</InputContainer>
	);
}

export default React.forwardRef<HTMLInputElement, InputProps>(Input);
