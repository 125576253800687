import { isEmpty } from 'ramda';
import * as yup from 'yup';

export const normalizeYupErrors = (error: any) => {
	const validationErrors: Record<string, string> = {};
	error.inner.forEach((e: yup.ValidationError) => {
		if (e.path && !isEmpty(e.path)) {
			validationErrors[e.path] = e.message;
		} else {
			validationErrors._error = e.message;
		}
	});

	return validationErrors;
};
