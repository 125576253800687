import React from 'react';

import { Icon } from '@common/design-system/components/atoms';

import { CheckboxContainer } from './Checkbox.styles';

type PropsType = {
	checked: boolean;
	onChange: (checked: boolean, e?: any) => void;
	partiallyChecked?: boolean;
	ariaLabel: string;
	disabled?: boolean;
	className?: string;
};

function Checkbox({
	checked,
	partiallyChecked,
	ariaLabel,
	onChange,
	disabled,
	className,
}: PropsType) {
	return (
		<CheckboxContainer
			role="checkbox"
			tabIndex={0}
			checked={checked}
			disabled={disabled}
			partiallyChecked={partiallyChecked}
			onClick={(e: React.MouseEvent) => {
				e.stopPropagation();
				e.persist();

				!disabled && onChange(!checked, e);
			}}
			onKeyDown={(e: React.KeyboardEvent) => {
				const isEnterKey = e.key === 'Enter';
				const isSpaceKey = e.key === ' ';

				if (isEnterKey || isSpaceKey) {
					e.preventDefault();
					onChange(!checked, e);
				}
			}}
			aria-label={ariaLabel}
			aria-checked={checked}
			className={className}
		>
			{/* // Ghost icon to avoid UI flashing */}
			{!checked && !partiallyChecked && (
				<Icon
					iconName="dash"
					// color="system.background.system"
					weight="bold"
					customNumberSize={12}
				/>
			)}

			{(checked || partiallyChecked) && (
				<Icon
					iconName={checked ? 'check' : 'dash'}
					color="system.background.system"
					weight="bold"
					customNumberSize={12}
				/>
			)}
		</CheckboxContainer>
	);
}

export default Checkbox;
