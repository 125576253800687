import styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { Text } from '@common/design-system/components/atoms';

const disabledStyles = () => css`
	pointer-events: none;
	background-color: ${({ theme }) => theme.colors.system.background.weak};
	color: ${({ theme }) => theme.colors.system.text.medium};
`;

const nonDisabledStyles = () => css<{
	$isAccordionHeader?: boolean;
	$isActivePage?: boolean;
}>`
	&:hover {
		background-color: ${({ $isAccordionHeader, $isActivePage, theme }) =>
			!$isAccordionHeader &&
			!$isActivePage &&
			theme.colors.system.background.weak};
		color: ${({ $isActivePage }) => !$isActivePage && 'initial'};
	}

	${({ $isActivePage }) =>
		typeof $isActivePage === 'boolean'
			? css`
					background-color: ${({ theme }) =>
						$isActivePage
							? theme.colors.primary.background.default
							: 'initial'};
					color: ${({ theme }) =>
						$isActivePage ? theme.colors.primary.text.strong : 'initial'};
				`
			: css`
					&.active {
						background-color: ${({ theme }) =>
							theme.colors.primary.background.default};
						color: ${({ theme }) => theme.colors.primary.text.strong};
					}
				`};
`;

const mainLinkStyles = () => css<{
	$isNavbarCollapsed?: boolean;
	$isDisabled?: boolean;
	$isAccordionHeader?: boolean;
	$isSubMenusThreaded?: boolean;
	$isActivePage?: boolean;
}>`
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding-left: ${({ $isAccordionHeader, $isSubMenusThreaded, theme }) =>
		$isAccordionHeader && !$isSubMenusThreaded ? '0px' : theme.spacing.xs};
	padding-right: ${({ theme }) => theme.spacing.xs};
	height: 34px;
	width: ${({ $isNavbarCollapsed }) => ($isNavbarCollapsed ? '34px' : 'auto')};
	margin: ${({ $isNavbarCollapsed }) =>
		$isNavbarCollapsed ? '0px auto' : '0px'};
	gap: ${({ theme }) => theme.spacing.xs};
	cursor: pointer;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.system.text.default};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	position: relative;
	transition: ${({ theme }) => theme.transitions.ease01};

	${({ $isDisabled }) => ($isDisabled ? disabledStyles : nonDisabledStyles)};

	justify-content: ${({ $isNavbarCollapsed }) =>
		$isNavbarCollapsed && 'center'};
`;

export const NavLinkButtonContainer = styled(NavLink)`
	${mainLinkStyles}
`;

export const LinkButtonContainer = styled(Link)`
	${mainLinkStyles}
`;

export const NavLinkButtonWrapper = styled.div`
	margin-left: auto;
`;

export const NavLinkButtonIconWrapper = styled.button`
	display: contents;
`;

export const RedirectLinkIconWrapper = styled.div`
	display: flex;
	justify-content: end;
	flex: 1;
`;
export const BadgeWrapper = styled.span`
	margin-left: auto;
`;

export const StatusBulletWrapper = styled.span`
	margin-left: auto;
	position: absolute;
	right: 4px;
	top: 5px;
	display: inline-flex;
`;

//If the icon changes its size, we should modify the min-width
export const IconContainer = styled.div<{ isActivePage?: boolean | null }>`
	height: 34px;
	color: ${({ isActivePage, theme }) =>
		isActivePage ? 'inherit' : theme.colors.neutral.icon.hover};
	display: flex;
	justify-content: center;
	align-items: center;

	.active-icon {
		opacity: ${({ isActivePage }) => (isActivePage ? 1 : 0)};
		position: ${({ isActivePage }) => (isActivePage ? 'relative' : 'absolute')};
	}

	.inactive-icon {
		opacity: ${({ isActivePage }) => (isActivePage ? 0 : 1)};
		position: ${({ isActivePage }) => (isActivePage ? 'absolute' : 'relative')};
	}
`;

export const NavText = styled(Text)`
	overflow: hidden;
	display: inline-block;
`;
