import React from 'react';
import { FloatingMenu } from '@common/design-system/components/molecules';
import { Badge } from '@common/design-system/components/atoms';
import { TableMapperType } from '../../types';
import { FloatingMenuPropsType } from '@common/design-system/components/molecules/FloatingMenu/FloatingMenu';

type PropsType = {
	item: any;
	mapperField: TableMapperType;
};

function BadgeFloatingMenuCell({ item, mapperField }: PropsType) {
	const badgeIconName =
		typeof mapperField.badge?.icon === 'function'
			? mapperField.badge?.icon(item)
			: mapperField.badge?.icon;

	const badgeVariant =
		typeof mapperField.badge?.variant === 'function'
			? mapperField.badge?.variant(item)
			: mapperField.badge?.variant;

	const badgeText =
		typeof mapperField.badge?.text === 'function'
			? mapperField.badge?.text(item)
			: mapperField.badge?.text;

	const badgeIconWeight =
		typeof mapperField.badge?.iconWeight === 'function'
			? mapperField.badge?.iconWeight(item)
			: mapperField.badge?.iconWeight;

	const menuItemsResult =
		typeof mapperField.menuItems === 'function'
			? mapperField.menuItems(item)
			: mapperField.menuItems;

	const menuItems = menuItemsResult?.map(
		(
			menuItem: FloatingMenuPropsType['menus'][number]['menuItems'][number],
		) => ({
			...menuItem,
			disabled:
				typeof menuItem.disabled === 'function'
					? menuItem.disabled(item)
					: menuItem.disabled,
		}),
	) as FloatingMenuPropsType['menus'][number]['menuItems'];

	return (
		<>
			{badgeVariant && badgeText && (
				<FloatingMenu
					menus={[{ menuItems }]}
					popUpPlacement="bottom-end"
					itemCallbackData={item}
					trigger={
						<Badge
							text={badgeText}
							dropdownBadge
							iconName={badgeIconName}
							iconWeight={badgeIconWeight}
							size={mapperField?.badge?.size}
							variant={badgeVariant}
						/>
					}
				/>
			)}
		</>
	);
}

export default BadgeFloatingMenuCell;
