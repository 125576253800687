import { showToast } from '@common/design-system/components/molecules';

import { ToastMessage } from '@app/constants';

export const copyToClipboard = async (
	text: string,
	successMessage?: string,
	errorMessage?: string,
) => {
	if (typeof text !== 'undefined') {
		try {
			await navigator.clipboard.writeText(text);
			showToast({
				message: successMessage || ToastMessage.copied,
				type: 'success',
			});
		} catch (err) {
			showToast({
				message: errorMessage || ToastMessage.failedToCopy,
				type: 'error',
			});
		}
	} else {
		showToast({ message: ToastMessage.error, type: 'error' });
	}
};
