import React, { useEffect, useState } from 'react';
import { TableMapperType } from '../../types';
import { SquareIconButton } from '@common/design-system/components/atoms';

type PinButtonCellProps = {
	mapperField: TableMapperType;
	item: any;
};

function PinButtonCell({ item, mapperField }: PinButtonCellProps) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isPinned, setIsPinned] = useState(false);

	useEffect(() => {
		setIsPinned(mapperField.key && item[mapperField.key]);
	}, [item]);

	useEffect(() => {
		if (isLoading) {
			setIsLoading(false);
		}
	}, [item]);

	const handlePin = () => {
		setIsLoading(true);
		typeof mapperField.onPin === 'function' && mapperField.onPin(item);
	};

	return (
		<SquareIconButton
			variant="neutral"
			ariaLabel="Icon button"
			size="medium"
			icon={isPinned ? 'pin' : 'unpin'}
			onClick={handlePin}
			disabled={isLoading}
		/>
	);
}

export default PinButtonCell;
