import styled from 'styled-components';

export const FloatWrapper = styled.div`
	overflow-y: auto;
	box-shadow: ${({ theme }) => theme.shadows.elevation.medium};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	z-index: ${({ theme }) => theme.zIndex.popover};
	position: relative;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.medium};

	padding: ${({ theme }) =>
		`${theme.spacing.xs} ${theme.spacing.medium} ${theme.spacing.medium}`};
`;
