import React from 'react';

import { CollapsableContainerProps } from './types';

import { CollapsableWrapper } from './CollapsableContainer.styles';

export default function CollapsableContainer({
	children,
	isOpen = true,
	maxWidth,
	minWidth,
	maxHeight,
	minHeight,
	className,
}: CollapsableContainerProps) {
	return (
		<CollapsableWrapper
			open={isOpen}
			maxWidth={maxWidth}
			minWidth={minWidth}
			maxHeight={maxHeight}
			minHeight={minHeight}
			className={className}
		>
			{children}
		</CollapsableWrapper>
	);
}
