import createFetchTypes from '@app/utils/createFetchTypes';
import { fetchAction } from '../types';
import axios from '@global/axios';
import { ToastMessage, apiUrl } from '@app/constants';

const mainType = 'AUTH';

export const grantAccessTypes = createFetchTypes(`${mainType}_GRANT_ACCESS`);

export const resetPasswordTypes = createFetchTypes(
	`${mainType}_RESET_PASSWORD`,
);

export const updateAuthUserTypes = createFetchTypes(
	`${mainType}_UPDATE_AUTH_USER`,
);

export const emailCheckTypes = createFetchTypes(`${mainType}_EMAIL_CHECK`);

function grantAccess(
	workspaceId: string,
	email: string,
	reload = false,
): fetchAction {
	return {
		type: grantAccessTypes,
		payload: { reload },
		callAPI: () => axios.get(`${apiUrl}/auth/webappAccess?w=${workspaceId}`),
	};
}

export const uploadProfilePictureTypes = createFetchTypes(
	`${mainType}_UPLOAD_PROFILE_PICTURE`,
);

function uploadProfilePicture({
	email,
	workspaceId,
	pictureUrl,
}: {
	workspaceId: string;
	email: string;
	pictureUrl?: string;
}): fetchAction {
	return {
		type: uploadProfilePictureTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/storytellers/uploadProfilePictureLink`, {
				email,
				pictureUrl,
				workspaceId,
			}),
	};
}

export const setAuthProviderInfoType = 'auth/SET_AUTH_PROVIDER_INFO';

function setAuthProviderInfo(provider: any) {
	return {
		type: setAuthProviderInfoType,
		payload: {
			provider,
		},
	};
}

function resetPassword(email: string): fetchAction {
	return {
		type: resetPasswordTypes,
		callAPI: () => axios.post(`${apiUrl}/auth/passwordResetEmail`, { email }),
		payload: {
			custom: 'resetPassword',
			successMessage: ToastMessage.resetPassword,
		},
	};
}

function updateAuthUser(data: { [key: string]: any }): fetchAction {
	const { id, ...storyteller } = data;
	return {
		type: updateAuthUserTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/storytellers`, {
				id,
				storyteller,
			}),
		payload: {
			custom: 'updateAuthUser',
			successMessage: ToastMessage.userUpdated,
			errorMessage: ToastMessage.errorUpdatingUser,
		},
	};
}

function emailCheck(email: string): fetchAction {
	return {
		type: emailCheckTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers/check?e=${email}`),
		payload: {
			custom: 'emailCheck',
		},
	};
}

export {
	grantAccess,
	setAuthProviderInfo,
	uploadProfilePicture,
	updateAuthUser,
	resetPassword,
	emailCheck,
};
