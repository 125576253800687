export const capitalizeString = (string: string): string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const handleVerbPlural = (
	amount: number,
	capitalize?: boolean,
): string => {
	const result = amount === 1 ? 'is' : 'are';

	return capitalize ? capitalizeString(result) : result;
};

const vowels = ['a', 'e', 'i', 'o', 'u'];

// Lookup table of irregular plural forms
const irregularForms: { [key: string]: string } = {
	child: 'children',
	foot: 'feet',
	person: 'people',
	tooth: 'teeth',
	woman: 'women',
};

export function handlePlural(
	word: string,
	count: number,
	capitalize?: boolean,
) {
	let result = '';
	word = word.toLowerCase();

	if (count === 1) {
		result = word;
	} else {
		// Check for irregular form
		if (irregularForms[word]) {
			result = irregularForms[word];
		}

		// Check for consonant + y ending
		else if (word.endsWith('y') && !vowels.includes(word[word.length - 2])) {
			result = word.slice(0, -1) + 'ies';
		}
		// ------------------------------
		else if (
			word.endsWith('ss') ||
			word.endsWith('ch') ||
			word.endsWith('sh')
		) {
			result = word + 'es';
		}
		// Add "s" for regular plural form
		else result = word + 's';
	}

	return capitalize ? capitalizeString(result) : result;
}
