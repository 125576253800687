import { createSlice } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '@app/utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	filterCampaignsTypes,
	updateCampaignTypes,
	launchDraftCampaignTypes,
	exportCampaignsTypes,
	exportSingleCampaignTypes,
	addContactsToCampaignTypes,
	emailPreviewTypes,
	sendPostcardPreviewTypes,
	resendStoriesCounttypes,
	resendCampaignStoriesTypes,
	getCampaignOptionsTypes,
	getCampaignTypes,
	getCampaignsCountTypes,
	saveCampaignTypes,
	launchCampaignTypes,
	saveCurrentChildCampaignTypes,
	saveAndUpdateCurrentChildCampaignTypes,
	deactivateCampaignTypes,
	getEmailTemplatesTypes,
	duplicateCampaignTypes,
	bulkDeleteDraftCampaignsTypes,
	getVerifiedStorytellersTypes,
	pinCampaignTypes,
	getEmailTemplateHTMLTypes,
	getHotglueJobTypes,
	getCampaignPerCTAType,
	getCTAsInCampaignsTypes,
	editEmailTemplateTypes,
	acceptPendingCampaignContactsTypes,
	approvePendingStoriesTypes,
	setLiveCampaignTypes,
	updateCampaignV2Types,
} from './action';
import entityNormalizer from '@app/utils/entityNormalizer';
import objectToOptions from '@app/utils/ObjectToOptions';

import { ToastMessage } from '@app/constants';
import { recipesStateType } from '../recipe';
import { CampaignStateEnum, CampaignType } from './types';
import { setStatusChangeInCampaigns } from './utils';
import { showToast } from '@common/design-system/components/molecules';
import { FIRST_PAGE } from '../constants';
import { capitalize } from '@app/utils/formatString';
import { storiesStateType } from '../approval';

const campaign = entityNormalizer('campaigns', {}, { idAttribute: '_id' });
const ctas = entityNormalizer('ctas', {}, { idAttribute: '_id' });

const pagination = { campaigns: [campaign] };
const ctaPagination = { ctas: [ctas] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	campaign: {},
	campaigns: {},
	campaignCount: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: null,
	},
	paginationOptions: {
		pages: {},
		currentPage: 1,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	updateResponse: {
		status: null,
		message: null,
	},
	launchResponse: {
		status: null,
		message: null,
	},
	custom: {},
	campaignOptions: [],
	savedCampaign: {},
	campaignTree: {},
	campaignName: 'Untitled Campaign',
	totalCampaigns: {},
	verifiedStorytellers: [],
	activeCtas: {
		activeCTACount: 0,
		activeCTAs: {},
		activeCTAPagination: {
			currentPage: 1,
			totalPages: null,
			count: null,
		},
	},
};

export type campaignsStateType = typeof initialState;

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			campaigns,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ campaigns, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function paginationMapper(state: campaignsStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);

	// to make the infinite scroll works
	if (action.response.page === FIRST_PAGE) {
		state.campaigns = {
			...normalizedData.entities.campaigns,
		};
	} else {
		state.campaigns = {
			...state.campaigns,
			...normalizedData.entities.campaigns,
		};
	}

	state.campaignCount = Object.keys(state.campaigns || {})?.length;
	// Options for the dropdown menu
	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.campaigns;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
}

function activeCTAsPaginationMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	const { count, page, pageSize, totalPages, ctas } = action.response.result;
	const normalizedActiveCTAs = normalize(
		{ count, page, pageSize, totalPages, ctas },
		ctaPagination,
	);

	if (action.response.result.page === FIRST_PAGE) {
		state.activeCtas.activeCTAs = { ...normalizedActiveCTAs.entities.ctas };
	} else {
		state.activeCtas.activeCTAs = {
			...state.activeCtas.activeCTAs,
			...normalizedActiveCTAs.entities.ctas,
		};
	}

	state.activeCtas.activeCTACount = Object.keys(
		state.activeCtas.activeCTAs || {},
	)?.length;
	state.activeCtas.activeCTAPagination.currentPage =
		action.response.result.page;
	state.activeCtas.activeCTAPagination.totalPages =
		action.response.result.totalPages;
	state.activeCtas.activeCTAPagination.count = action.response.result.count;
}

function getCampaign(state: campaignsStateType, action: ResponseAction) {
	const { campaign, sharedVideoStats, contactsByPreference } = action.response;
	state.campaign = {
		...campaign,
		sharedVideoStats,
		contactsByPreference,
	};
}

function optionsMapper(state: campaignsStateType, action: ResponseAction) {
	const normalizedData: any = normalizeResponse(action);
	const campaignCopy = objectToOptions(
		normalizedData.entities.campaigns,
		'id',
		'name',
	);
	if (!action.payload?.excludeAll && !action.payload.includeBoth) {
		campaignCopy.unshift({
			value: '',
			label: 'All',
		});
	}

	if (action?.response?.page > 1) {
		state.campaignOptions = [...state.campaignOptions, ...campaignCopy];
	} else {
		state.campaignOptions = campaignCopy;
	}
	if (action.payload.includeBoth) {
		state.campaignOptions = campaignCopy;
		state.campaignOptionsAll = campaignCopy;
		state.campaignOptionsAll = [
			{
				value: '',
				label: 'All',
			},
			...campaignCopy,
		];
	}
	state.paginationOptions.pages[action.response.page] =
		normalizedData.result.campaigns;
	state.paginationOptions.currentPage = action.response.page;
	state.paginationOptions.totalPages = action.response.totalPages;
	state.paginationOptions.count = action.response.count;
}

function getCampaignsCountMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.totalCampaigns = action.response.campaigns;
}

function updateCampaignMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.campaign = { ...state.campaign, ...action.response.campaign };
	state.updateResponse = action.response;

	if (action.payload?.removeFromCampaignsList) {
		//TODO: Fix this state mutation
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
		delete state.campaigns[action.response.campaign._id];
	}
}

function pinCampaignMapper(state: campaignsStateType, action: ResponseAction) {
	const { _id, pinned } = action.response?.campaign;

	if (state.campaignCount === 0 && !state.campaigns[_id]) return;

	state.campaigns[_id].pinned = pinned;

	if (state.campaign?._id === _id) state.campaign.pinned = pinned;
}

function deactivateCampaignMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	const deactivatedCampaign = action.response.campaign;
	state.campaign.state = deactivatedCampaign.state;
	state.campaign.pendingStories = deactivatedCampaign.pendingStories;
	state.campaign.pendingStoriesCount = deactivatedCampaign.pendingStoriesCount;
	state.campaign.assignedStories = deactivatedCampaign.assignedStories;
	state.campaign.assignedStoriesCount =
		deactivatedCampaign.assignedStoriesCount;

	//Remove campaign from state.campaigns

	//TODO: Fix this state mutation
	// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
	delete state.campaigns[deactivatedCampaign._id];
}

function bulkDeleteDraftCampaignsMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	const { campaignIds } = action.response;

	const result = campaignIds.reduce(
		(objToReturn: any, id: string) => {
			const { [id]: deletedCampaign, ...rest } = objToReturn;
			return { ...rest };
		},
		{ ...state.campaigns },
	);

	state.campaigns = result;

	const newCampaignsCount = state.campaignCount - campaignIds.length;
	state.campaignCount = newCampaignsCount > 0 ? newCampaignsCount : 0;
}

function launchDraftCampaignMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.launchResponse = action.response;
	state.launchResponse.error = action.error;
}

export const exportCampaignsSuccessMapper = (
	state: campaignsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	fileDownload(response, 'campaigns.csv');
	state.type = action.type;
};

export const exportSingleCampaignSuccessMapper = (
	state: campaignsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	const { campaignName } = action.payload;
	fileDownload(response, `${campaignName}.csv`);
	state.type = action.type;
};

export const addContactToCampaignSuccessMapper = (
	state: campaignsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
};

function addContactsToCampaignErrorMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const errorCode = action?.error?.response?.data?.errorCode;

	const isNoValidContactsInboxCampaign = 103;
	const isCollectCampaign = 31;

	if (errorCode === 60) {
		return showToast({
			message: ToastMessage.overApprovedContactLimit,
			type: 'error',
		});
	}

	if (errorCode === isCollectCampaign) {
		return showToast({
			message: ToastMessage.assignContactsToCollectVideoCampaignError,
			type: 'error',
		});
	}

	if (errorCode === isNoValidContactsInboxCampaign) {
		return showToast({
			message: ToastMessage.assignContactsInboxCampaignError,
			type: 'error',
		});
	}

	return showToast({
		message: ToastMessage.contactsToCampaignError,
		type: 'error',
	});
}

function updateTreeCampaign(state: recipesStateType, action: ResponseAction) {
	if (!!state.campaignTree[action?.response?.campaign?._id]) {
		state.campaignTree = {
			...state.campaignTree,
			[action.response.campaign._id]: action.response.campaign,
		};
	}
}

function saveCampaignMapper(state: recipesStateType, action: ResponseAction) {
	updateTreeCampaign(state, action);
	state.savedCampaign = {
		...state.savedCampaign,
		...action?.response?.campaign,
		stories: action?.response?.stories ?? state.savedCampaign.stories,
		verifiedStorytellers: [],
	};
	state.campaignName = action?.response?.campaign.name;
}

function normalizeCampaignTree(campaigns: CampaignType[]) {
	const entityNormalizer = normalize({ campaigns }, pagination);
	return { ...entityNormalizer.entities.campaigns };
}

function saveCurrentChildCampaignMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	updateTreeCampaign(state, action);
}

function launchCampaignMapper(state: recipesStateType, action: ResponseAction) {
	const currentCampaign = state.savedCampaign;
	const campaignTree = state.campaignTree;

	if (campaignTree[currentCampaign?._id]) {
		const campaignTreeUpdated = setStatusChangeInCampaigns(
			campaignTree,
			CampaignStateEnum.active,
		);

		state.campaignTree = normalizeCampaignTree(campaignTreeUpdated);
	}

	state.savedCampaign = {
		...state.savedCampaign,
		state: CampaignStateEnum.active,
	};
}

function launchCampaignErrorMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const errorMessage = action?.error?.response?.data?.message;
	const errorCode = action?.error?.response?.data?.errorCode;
	const haveMaxNumberOfContacts =
		errorMessage?.includes('Maximun number of contacts exceed.') ||
		errorMessage?.includes('Maximum number of contacts exceed.');
	const videoIsPendingApproval = errorCode === 16;
	const templateIsNotAssignedToTeam = errorCode === 2202;
	const workspacePlanIsNotEnabled = errorCode === 514;
	const campaignHasUnverifiedAudience = errorCode === 1906;

	if (campaignHasUnverifiedAudience) {
		return showToast({
			message: ToastMessage.audienceNotVerifiedError,
			type: 'error',
		});
	}

	if (templateIsNotAssignedToTeam) {
		return showToast({
			message: ToastMessage.templateNotAssignedToTeamError,
			type: 'error',
		});
	}

	if (workspacePlanIsNotEnabled) {
		const plan = action?.error?.response?.data?.metadata?.plan;
		return showToast({
			message: `${capitalize(plan)} plan is not enabled for this workspace yet.`,
			type: 'error',
		});
	}
	if (haveMaxNumberOfContacts) {
		return showToast({
			message: ToastMessage.audienceMaxContactError,
			type: 'error',
		});
	} else if (videoIsPendingApproval) {
		return showToast({
			message: ToastMessage.videoIsPendingApproval,
			type: 'error',
		});
	} else {
		return showToast({ message: ToastMessage.error, type: 'error' });
	}
}

function emailPreviewErrorMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const errorResponse = action?.error?.response;
	const errorMessage = errorResponse?.data?.message || errorResponse?.data;

	const isCtaNotDefined = errorMessage?.includes('cta must be defined');
	const isPhoneNumberInvalid = errorMessage?.includes(
		'is not a valid phone number',
	);

	if (isCtaNotDefined) {
		return showToast({ message: ToastMessage.ctaNotSelected, type: 'error' });
	} else if (isPhoneNumberInvalid) {
		return showToast({
			message: ToastMessage.invalidPhoneNumber,
			type: 'error',
		});
	} else {
		return showToast({ message: ToastMessage.error, type: 'error' });
	}
}

function postcardPreviewErrorMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const errorResponse = action?.error?.response;
	const errorCode = errorResponse?.data?.errorCode;
	const errorMessage = errorResponse?.data?.message || errorResponse?.data;

	const isCtaNotDefined = errorMessage?.includes('cta must be defined');
	const workspacePlanIsNotEnabled = errorCode === 514;

	if (workspacePlanIsNotEnabled) {
		const plan = action?.error?.response?.data?.metadata?.plan;
		return showToast({
			message: `${capitalize(plan)} plan is not enabled for this workspace yet.`,
			type: 'error',
		});
	}

	if (isCtaNotDefined) {
		return showToast({ message: ToastMessage.ctaNotSelected, type: 'error' });
	}

	return showToast({ message: ToastMessage.error, type: 'error' });
}
function saveCampaignErrorMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const errorCode = action?.error?.response?.data?.errorCode;
	const errorMessage = action?.error?.response?.data?.message;

	const campaignNotFound = errorCode === 5;

	const haveMaxNumberOfContacts =
		errorMessage?.includes('Maximun number of contacts exceed.') ||
		errorMessage?.includes('Maximum number of contacts exceed.');

	const IsMissingFallbackCampaign =
		campaignNotFound && errorMessage?.includes('Campaign not found');

	if (haveMaxNumberOfContacts) {
		return showToast({
			message: ToastMessage.audienceMaxContactError,
			type: 'error',
		});
	}

	if (IsMissingFallbackCampaign) {
		return showToast({
			message: ToastMessage.fallbackCampaignMissing,
			type: 'error',
		});
	}

	return showToast({ message: ToastMessage.error, type: 'error' });
}

function approvePendingStoriesMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const {
		response: { stories },
	} = action;

	const campaignToUpdate: any = Object.values(state.campaigns).find(
		(campaign: any) =>
			campaign?.storiesPendingApproval?.some((story: any) =>
				stories.includes(story._id),
			),
	);

	if (campaignToUpdate) {
		const filteredStoriesPendingApproval =
			campaignToUpdate?.storiesPendingApproval?.filter(
				(story: any) => !stories.includes(story._id),
			);
		const updatedStoriesPendingApprovalCount =
			campaignToUpdate?.storiesPendingApprovalCount -
			filteredStoriesPendingApproval.length;

		if (campaignToUpdate?.storiesPendingApprovalCount === 1) {
			const actionsRequired = campaignToUpdate?.actionsRequired.filter(
				(action: string) => action !== 'approveVideos',
			);
			state.campaigns[campaignToUpdate._id] = {
				...campaignToUpdate,
				actionsRequired,
				storiesPendingApproval: filteredStoriesPendingApproval,
				storiesPendingApprovalCount: updatedStoriesPendingApprovalCount,
			};
		} else {
			state.campaigns[campaignToUpdate._id] = {
				...campaignToUpdate,
				storiesPendingApproval: filteredStoriesPendingApproval,
				storiesPendingApprovalCount: updatedStoriesPendingApprovalCount,
			};
		}
	}
}
function verifiedStorytellersMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const {
		response: { sendingAddresses },
	} = action;
	state.verifiedStorytellers = sendingAddresses;
}

function setCampaignLiveSuccessMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const {
		response: { campaign },
		payload: { removeFromCampaignsList },
	} = action;

	state.campaigns[campaign._id] = campaign;

	if (removeFromCampaignsList) {
		//TODO: Fix this state mutation
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
		delete state.campaigns[campaign._id];
	}
}

const campaignSlice = createSlice({
	name: 'campaign',
	initialState,
	reducers: {
		RESET(state: campaignsStateType) {
			state.campaignCount = 0;
			state.campaigns = {};
			state.savedCampaign = initialState.savedCampaign;
			state.campaignName = '';
			state = initialState;
		},
		EDIT_CAMPAIGN(state: campaignsStateType, action: ResponseAction) {
			state.savedCampaign = {
				...action.payload.campaign,
				isDraft: !!action.payload.campaign._id,
				activeCampaignEditMode: action.payload.activeCampaignEditMode,
			};
			state.campaignName = action.payload?.campaign.name;
		},
		SET_CAMPAIGN_TREE(state: campaignsStateType, action: ResponseAction) {
			const { payload } = action;
			state.campaignTree = normalizeCampaignTree(payload);
		},
		SET_CAMPAIGN_NAME(state: campaignsStateType, action: { payload: string }) {
			state.campaignName = action.payload;
		},
		RESET_ACTIVE_CTAS(state: campaignsStateType) {
			state.activeCtas.activeCTACount = 0;
			state = initialState;
		},
	},
	extraReducers: {
		...createFetchReducer(getCampaignTypes, getCampaign),
		...createFetchReducer(getCampaignsCountTypes, getCampaignsCountMapper),
		...createFetchReducer(filterCampaignsTypes, paginationMapper),

		...createFetchReducer(launchDraftCampaignTypes, launchDraftCampaignMapper),

		...createFetchReducer(updateCampaignTypes, updateCampaignMapper),
		...createFetchReducer(pinCampaignTypes, pinCampaignMapper),

		...createFetchReducer(deactivateCampaignTypes, deactivateCampaignMapper),
		...createFetchReducer(exportCampaignsTypes, exportCampaignsSuccessMapper),
		...createFetchReducer(
			exportSingleCampaignTypes,
			exportSingleCampaignSuccessMapper,
		),
		...createFetchReducer(
			addContactsToCampaignTypes,
			addContactToCampaignSuccessMapper,
			addContactsToCampaignErrorMapper,
		),
		...createFetchReducer(acceptPendingCampaignContactsTypes),
		...createFetchReducer(emailPreviewTypes, () => {}, emailPreviewErrorMapper),
		...createFetchReducer(
			sendPostcardPreviewTypes,
			() => {},
			postcardPreviewErrorMapper,
		),
		...createFetchReducer(getEmailTemplatesTypes),
		...createFetchReducer(getEmailTemplateHTMLTypes),
		...createFetchReducer(editEmailTemplateTypes),
		...createFetchReducer(
			getVerifiedStorytellersTypes,
			verifiedStorytellersMapper,
		),
		...createFetchReducer(resendStoriesCounttypes),
		...createFetchReducer(resendCampaignStoriesTypes),
		...createFetchReducer(getCampaignOptionsTypes, optionsMapper),
		...createFetchReducer(
			saveCampaignTypes,
			saveCampaignMapper,
			saveCampaignErrorMapper,
		),
		...createFetchReducer(
			launchCampaignTypes,
			launchCampaignMapper,
			launchCampaignErrorMapper,
		),
		...createFetchReducer(
			saveCurrentChildCampaignTypes,
			saveCurrentChildCampaignMapper,
		),
		...createFetchReducer(
			saveAndUpdateCurrentChildCampaignTypes,
			saveCampaignMapper,
		),
		...createFetchReducer(duplicateCampaignTypes),
		...createFetchReducer(
			bulkDeleteDraftCampaignsTypes,
			bulkDeleteDraftCampaignsMapper,
		),
		...createFetchReducer(getHotglueJobTypes),
		...createFetchReducer(getCampaignPerCTAType),
		...createFetchReducer(getCTAsInCampaignsTypes, activeCTAsPaginationMapper),

		...createFetchReducer(
			approvePendingStoriesTypes,
			approvePendingStoriesMapper,
		),

		...createFetchReducer(setLiveCampaignTypes, setCampaignLiveSuccessMapper),
		...createFetchReducer(updateCampaignV2Types),
	},
});

export default campaignSlice.reducer;
