import styled from 'styled-components';

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const PageTitleWrapper = styled.div<{
	titleOrientation: 'horizontal' | 'vertical';
}>`
	display: flex;
	align-items: ${({ titleOrientation }) => {
		if (titleOrientation === 'horizontal') return 'center';
		return 'flex-start';
	}};
	flex-direction: ${({ titleOrientation }) => {
		if (titleOrientation === 'horizontal') return 'row';
		return 'column';
	}};
	height: 100%;
`;

export const PageTitle = styled.h1`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: ${({ theme }) => theme.fonts.family.title};
	font-size: ${({ theme }) => theme.fonts.sizes.medium};
	font-weight: ${({ theme }) => theme.fonts.weights.bold};
	line-height: ${({ theme }) => theme.fonts.lineHeight.large};
`;

export const PageSubTitle = styled.p<{ hasMargin: boolean }>`
	margin-left: ${({ hasMargin, theme }) => hasMargin && theme.spacing.medium};
`;

export const PageHeaderContainer = styled.div<{ size?: string }>`
	position: sticky;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	padding: ${({ theme, size }) => {
		switch (size) {
			case 'small':
				return `${theme.spacing.xs} ${theme.spacing.medium}`;

			case 'regular':
				return `0 ${theme.spacing.large}`;
			default:
				return `0 ${theme.spacing.large}`;
		}
	}};
	min-height: 56px;
	max-height: 56px;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	z-index: ${({ theme }) => theme.zIndex.fixed};
	column-gap: 16px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.system.border.weak};
`;
