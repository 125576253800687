import styled, { keyframes } from 'styled-components';

const FOOTER_HEIGHT = {
	regular: 88,
	small: 56,
};

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;
export const ModalScreenBackground = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => `${theme.colors.system.background.modal}`};
	opacity: 0.65;
	z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
`;

export const ModalScreenCover = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	/* height: 100%; */
	min-height: 100vh;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
	animation: ${fadeIn} 0.15s linear normal;
`;

export const ModalContainer = styled.div<{
	minWidth: string;
	maxWidth: string;
	maxHeight: string;
	minHeight?: string;
	isShowingFooter?: boolean;
	smallFooter?: boolean;
}>`
	display: flex;
	position: relative;
	background: ${({ theme }) => theme.colors.system.background.system};
	border-radius: ${({ theme }) => theme.shapeRadius.medium};
	box-shadow: ${({ theme }) => theme.shadows.elevation.xl};
	z-index: ${({ theme }) => theme.zIndex.modal};
	width: ${({ minWidth }) => minWidth};
	min-width: ${({ minWidth }) => minWidth};
	max-width: ${({ maxWidth }) => maxWidth};
	max-height: ${({ maxHeight }) => maxHeight};
	min-height: ${({ minHeight }) => minHeight};
	height: auto;
	overflow: hidden;
	padding-bottom: ${({ isShowingFooter, smallFooter }) =>
		isShowingFooter
			? smallFooter
				? FOOTER_HEIGHT.small
				: FOOTER_HEIGHT.regular
			: 0}px;

	.header-title-link {
		text-align: start;
		&:hover {
			text-decoration: underline !important;
		}
	}
`;

export const ModalHeader = styled.div<{
	$modalHasContent?: boolean;
	$hasBorder?: boolean;
}>`
	display: flex;
	align-items: center;
	width: 100%;
	height: fit-content;
	gap: ${({ theme }) => theme.spacing.xs};

	border-bottom: ${({ theme, $hasBorder }) =>
		$hasBorder ? `1px solid ${theme.colors.system.border.weak}` : 'none'};

	padding: ${({ theme }) => theme.spacing.large};
	padding-bottom: ${({ theme, $hasBorder }) =>
		$hasBorder ? theme.spacing.large : theme.spacing.medium};
`;

export const ModalTitleContainer = styled.div<{ $modalHasContent?: boolean }>`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const ModalContent = styled.span<{ $hasContentPadding?: boolean }>`
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: ${({ theme, $hasContentPadding }) =>
		$hasContentPadding ? theme.spacing.large : 0};
	line-height: ${({ theme }) => theme.fonts.lineHeight.large};
	overflow-y: auto;
	overflow-x: hidden;
`;

export const ModalFooter = styled.div<{
	$hasBorder?: boolean;
	$hasBackButton?: boolean;
	$hasCustomElement?: boolean;
	$smallFooter?: boolean;
}>`
	width: -webkit-fill-available;
	display: flex;
	align-items: center;
	justify-content: ${({ $hasBackButton, $hasCustomElement }) =>
		$hasBackButton || $hasCustomElement ? 'space-between' : 'flex-end'};
	column-gap: ${({ theme }) => theme.spacing.xs};
	padding: ${({ theme }) => theme.spacing.large};
	border-top: ${({ theme, $hasBorder }) =>
		$hasBorder ? `1px solid ${theme.colors.system.border.weak}` : 'none'};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	position: absolute;
	bottom: 0;
	max-height: ${({ $smallFooter }) =>
		$smallFooter ? FOOTER_HEIGHT.small : FOOTER_HEIGHT.regular}px;

	.buttons-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: ${({ theme }) => theme.spacing.xs};
	}
`;

export const CloseIconContainer = styled.div`
	margin-left: auto;
`;

export const DescriptionContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xxs};
`;
