import styled from 'styled-components';

export const Wrapper = styled.div<{ controlsPosition?: string }>`
	display: inline-flex;
	flex-direction: column;
	max-width: 90px;

	align-items: center;

	row-gap: ${({ theme }) => theme.spacing.xs};
	column-gap: ${({ theme }) => theme.spacing.medium};

	flex-direction: ${({ controlsPosition }) =>
		controlsPosition === 'bottom' ? 'column' : 'row'};
`;

export const ControlsWrapper = styled.div<{ controlsPosition?: string }>`
	display: flex;
	column-gap: ${({ theme }) => theme.spacing.xs};
	align-items: center;
`;
