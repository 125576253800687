import { CampaignStateEnum, CampaignType } from '../types';

export function setStatusChangeInCampaigns(
	campaigns: { [key: string]: CampaignType },
	state: CampaignStateEnum,
) {
	return Object.values(campaigns)?.map((campaign) => ({
		...campaign,
		state,
	}));
}
