import styled, { css } from 'styled-components';

import { ToggleButtonType } from './types';

function handleCircleRight(toggle: boolean) {
	return toggle ? '2px' : 'calc(100% - 22px)';
}

export const Toggle = styled.button<ToggleButtonType>`
	position: relative;
	display: flex;
	align-items: center;
	width: 44px;
	height: 24px;

	justify-content: ${({ toggle }) => (toggle ? 'flex-end' : 'flex-start')};

	border: 1px solid ${({ theme }) => theme.colors.neutral[100]};

	border-radius: ${({ theme }) => theme.borderRadius?.round};

	transition: ${({ theme }) => theme.transition?.all};

	background: ${({ theme, toggle }) =>
		toggle ? theme.colors.primary[700] : theme.colors.neutral[100]};

	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

	&:focus {
		outline-color: ${({ theme }) => theme.colors.primary[700]};
	}
`;

export const ToggleCircle = styled.div<ToggleButtonType>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	border-radius: 50%;

	right: ${({ toggle }) => handleCircleRight(toggle)};

	background: ${({ theme }) => theme.colors.neutral[0]};

	${({ theme }) => css`
		transition: ${theme.transition?.all};
		-webkit-transition: ${theme.transition?.all};
		-moz-transition: ${theme.transition?.all};
		-o-transition: ${theme.transition?.all};
		-ms-transition: ${theme.transition?.all};
	`}
`;
