import { fetchAction } from '@app/redux/types';
import axios from '@global/axios';
import { ToastMessage, apiUrl, operators, orderTypes } from '@app/constants';
import createFetchTypes from '@app/utils/createFetchTypes';
import {
	FilterOptions,
	filterObject,
	filterObjectArray,
	orderObject,
	paginationObject,
} from '@app/types';

const mainType = 'CHATBOT';

export const getWorkspaceAiBotsTypes = createFetchTypes(
	`${mainType}_GET_WORKSPACE_AI_BOTS`,
);

function getWorkspaceAiBots(): fetchAction {
	const computedFilter = {
		pagination: {
			page: 1,
			pageSize: 200,
			//On almost all production workspaces we will only have 1 aiBot per workspace (so this pageSize will not hurt performance at all)
			//But on dev/qa and 1 prod workspace we will need to render all the aiBots available (which at most will be a couple hundred)
		},
		order: {
			field: 'updatedAt',
			type: orderTypes.descending,
		},
	};

	return {
		type: getWorkspaceAiBotsTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/aiBot?filter=${JSON.stringify(computedFilter)}`),
		payload: {
			custom: 'getWorkspaceAiBots',
		},
	};
}

export const getAiBotByIdTypes = createFetchTypes(
	`${mainType}_GET_AI_BOT_BY_ID`,
);

function getAiBotById({ aiBotId }: { aiBotId: string }): fetchAction {
	return {
		type: getAiBotByIdTypes,
		callAPI: () => axios.get(`${apiUrl}/aiBot/${aiBotId}`),
		payload: {
			custom: 'getAiBotById',
		},
	};
}

export const updateAiBotTypes = createFetchTypes(`${mainType}_UPDATE_AI_BOT`);

function updateAiBot({
	id,
	name,
	type,
	widgetConfig,
	generationConfig,
}: {
	id: string;
	name: string;
	type: string;
	widgetConfig: {
		logo: string;
		previewMessage: string;
		greetingMessage: string;
		primaryColor: string;
		secondaryColor?: string;
		demoBackgroundImage?: string;
		mobileDemoBackgroundImage?: string;
	};
	generationConfig?: {
		schoolName?: string;
	};
}): fetchAction {
	const aiBotData = {
		id,
		name,
		type,
		widgetConfig,
		generationConfig,
	};

	return {
		type: updateAiBotTypes,
		callAPI: () => axios.put(`${apiUrl}/aiBot`, aiBotData),
		payload: {
			custom: 'updateAiBot',
			successMessage: ToastMessage.updateAiBotSuccess,
			errorMessage: ToastMessage.updateAiBotFailed,
		},
	};
}

export const getAiBotConversationTypes = createFetchTypes(
	`${mainType}_GET_AI_BOT_CONVERSATION`,
);

function getAiBotConversations({
	aiBotId,
	paginationFilter,
	orderFilter,
	additionalFilters,
	searchQuery,
}: {
	aiBotId?: string;
	paginationFilter?: paginationObject;
	orderFilter?: orderObject;
	additionalFilters?: filterObject[];
	searchQuery?: string;
}): fetchAction {
	const computedFilter = {
		//TODO: CHECK HOW WE CAN POPULATE MESSAGES ON THIS CALL FOR EASY MANIPULATION
		filter: [
			...(additionalFilters ?? []),
			{
				field: 'aiBot',
				value: aiBotId,
			},
		],
		pagination: paginationFilter ?? {
			page: 1,
			pageSize: 5,
		},
		order: orderFilter ?? { field: 'updatedAt', type: 'desc' },
		...(searchQuery && {
			search: {
				fields: ['stringId', 'geoLocationDisplay', 'onPages.0'],
				value: searchQuery,
			},
		}),
	};

	return {
		type: getAiBotConversationTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/aiConversation?filter=${JSON.stringify(computedFilter)}`,
			),
	};
}

export const getAiBotKnowledgeBaseDocsType = createFetchTypes(
	`${mainType}_GET_AI_BOT_KNOWLEDGE_BASE_DOCS`,
);

function getAiBotKnowledgeBaseDocs({
	aiBotId,
	paginationFilter,
}: {
	aiBotId: string;
	paginationFilter?: {
		page: number;
		pageSize: number;
	};
}): fetchAction {
	const filterOptions = {
		filter: [
			{ field: 'type', value: ['webSiteAIDoc', 'fileAIDoc'] },
			{ field: 'aiBot', value: aiBotId },
		],
		order: {
			field: 'createdAt',
			type: orderTypes.descending,
		},
		pagination: paginationFilter ?? { page: 1, pageSize: 15 },
	};

	return {
		type: getAiBotKnowledgeBaseDocsType,
		callAPI: () =>
			axios.get(`${apiUrl}/aiDoc?filter=${JSON.stringify(filterOptions)}`),
	};
}

export const getAiBotQADocsToReviewType = createFetchTypes(
	`${mainType}_GET_AIL_BOT_QA_DOCS_TO_REVIEW`,
);

function getAiBotQADocsToReview({
	aiBotId,
	topicId,
	paginationFilter,
	questionSearchQuery,
	additionalFilters,
}: {
	aiBotId: string;
	topicId?: string;
	paginationFilter?: {
		page: number;
		pageSize: number;
	};
	questionSearchQuery?: string;
	additionalFilters?: filterObjectArray;
}): fetchAction {
	const filterOptions: FilterOptions = {
		filter: [
			{ field: 'aiBot', value: aiBotId },
			{ field: 'type', value: 'qaAIDoc' },
			{ field: 'approvedBy', value: false, operator: operators.exists },
			{
				field: 'isWebDocPromptExample',
				value: false,
				operator: operators.exists,
			},
			...(additionalFilters ?? []),
		],
		order: {
			field: 'createdAt',
			type: orderTypes.descending,
		},
		...(questionSearchQuery && {
			search: {
				fields: ['question', 'answer'],
				value: questionSearchQuery,
			},
		}),
		pagination: paginationFilter ?? { page: 1, pageSize: 25 },
	};

	// If we are filtering by subtopic, there is no need to pass in parent topic filter
	// and backend does not support multiple filters per field
	if (topicId && !additionalFilters?.find((f) => f.field === 'topics')) {
		filterOptions.filter?.push({
			field: 'topics',
			value: [topicId],
			operator: operators.in,
		});
	}

	return {
		type: getAiBotQADocsToReviewType,
		callAPI: () =>
			axios.get(`${apiUrl}/aiDoc?filter=${JSON.stringify(filterOptions)}`),
		payload: {
			custom: 'getAiBotQADocsToReview',
		},
	};
}

export const getAiBotQADocsApprovedType = createFetchTypes(
	`${mainType}_GET_AIL_BOT_QA_DOCS_APPROVED`,
);

function getAiBotQADocsApproved({
	aiBotId,
	topicId,
	paginationFilter,
	questionSearchQuery,
	additionalFilters,
}: {
	aiBotId: string;
	topicId?: string;
	paginationFilter?: {
		page: number;
		pageSize: number;
	};
	questionSearchQuery?: string;
	additionalFilters?: filterObjectArray;
}): fetchAction {
	const filterOptions: FilterOptions = {
		filter: [
			{ field: 'aiBot', value: aiBotId },
			{ field: 'type', value: 'qaAIDoc' },
			{ field: 'approvedBy', value: true, operator: operators.exists },
			{
				field: 'isWebDocPromptExample',
				value: false,
				operator: operators.exists,
			},
			...(additionalFilters ?? []),
		],
		populate: [
			{
				field: 'approvedBy',
				select: ['firstName', 'lastName', 'fullName', 'id'],
			},
		],
		...(questionSearchQuery && {
			search: {
				fields: ['question', 'answer'],
				value: questionSearchQuery,
			},
		}),
		// as approving a doc does not update createdAt field, so sorting by updatedAt
		order: {
			field: 'updatedAt',
			type: orderTypes.descending,
		},
		pagination: paginationFilter ?? { page: 1, pageSize: 25 },
	};

	// If we are filtering by subtopic, there is no need to pass in parent topic filter
	// and backend does not support multiple filters per field
	if (topicId && !additionalFilters?.find((f) => f.field === 'topics')) {
		filterOptions.filter?.push({
			field: 'topics',
			value: [topicId],
			operator: operators.in,
		});
	}

	return {
		type: getAiBotQADocsApprovedType,
		callAPI: () =>
			axios.get(`${apiUrl}/aiDoc?filter=${JSON.stringify(filterOptions)}`),
		payload: {
			custom: 'getAiBotQADocsApproved',
		},
	};
}

export const getAiBotQADocsUpdateSuggestionsType = createFetchTypes(
	`${mainType}_GET_AIL_BOT_QA_DOCS_UPDATE_SUGGESTIONS`,
);

function getAiBotQADocsUpdateSuggestions({
	aiBotId,
	paginationFilter,
	additionalFilters,
}: {
	aiBotId: string;
	paginationFilter?: {
		page: number;
		pageSize: number;
	};
	additionalFilters?: filterObjectArray;
}): fetchAction {
	const filterOptions: FilterOptions = {
		pagination: paginationFilter ?? { page: 1, pageSize: 25 },
	}; //THERE IS ONLY PAGINATION FOR NOW ON THIS ENDPOINT
	// If you want to add more filters, you can need to edit backend

	return {
		type: getAiBotQADocsUpdateSuggestionsType,
		callAPI: () =>
			axios.get(
				`${apiUrl}/aiDoc/${aiBotId}/suggestions?filter=${JSON.stringify(filterOptions)}`,
			),
		payload: {
			custom: 'getAiBotQADocsUpdateSuggestions',
		},
	};
}

export const createNewAiDocType = createFetchTypes(
	`${mainType}_CREATE_NEW_AI_DOC`,
);

function createNewAiDoc({
	aiBotId,
	aiDoc,
	selectedTopicId,
	successMessage,
	skipQaDocValidation,
	showErrorToast = true,
}: {
	aiBotId: string;
	aiDoc: {
		type: 'qaAIDoc' | 'webSiteAIDoc' | 'fileAIDoc';
		url?: string;
		convertedTextUrl?: string;
		startingUrl?: string;
		includeUrls?: string[];
		excludeUrls?: string[];
		question?: string;
		answer?: string;
		sources?: string[];
		approvedBy?: string;
		apifyDatasetId?: string;
		topics?: string[];
	};
	selectedTopicId?: string;
	successMessage?: string;
	skipQaDocValidation?: boolean;
	showErrorToast?: boolean;
}): fetchAction {
	return {
		type: createNewAiDocType,
		callAPI: () =>
			axios.post(`${apiUrl}/aiDoc`, {
				aiBot: aiBotId,
				...aiDoc,
				...(skipQaDocValidation && {
					skipQaDocValidation,
				}),
			}),
		payload: {
			custom: 'createNewAiDoc',
			successMessage: successMessage
				? successMessage
				: ToastMessage.createAiDocSuccess,
			...(showErrorToast && {
				errorMessage: ToastMessage.createAiDocFailed,
			}),
			type: aiDoc.type,
			selectedTopicId,
		},
	};
}

export const updateAiDocType = createFetchTypes(
	`${mainType}_UPDATE_NEW_AI_DOC`,
);

function updateAiDoc({
	aiDoc,
	skipQaDocValidation,
	userAction,
	successMessage,
}: {
	aiDoc: {
		type: 'qaAIDoc' | 'webSiteAIDoc' | 'fileAIDoc';
		url?: string;
		startingUrl?: string;
		includeUrls?: string[];
		excludeUrls?: string[];
		question?: string;
		answer?: string;
		sources?: string[];
		approvedBy?: string;
		id?: string;
		apifyDatasetId?: string;
	};
	skipQaDocValidation?: boolean;
	userAction?: 'approved' | 'update';
	successMessage?: string;
}): fetchAction {
	return {
		type: updateAiDocType,
		callAPI: () =>
			axios.put(`${apiUrl}/aiDoc`, {
				...aiDoc,
				...(skipQaDocValidation && {
					skipQaDocValidation,
				}),
			}),
		payload: {
			custom: 'updateAiDoc',
			successMessage: successMessage
				? successMessage
				: ToastMessage.updateAiDocSuccess,
			type: aiDoc.type,
			userAction: userAction,
		},
	};
}

export const bulkUseCachedQuestionSuggestionsType = createFetchTypes(
	`${mainType}_BULK_USE_CACHED_QUESTION_SUGGESTIONS`,
);

function bulkUseCachedQuestionSuggestions({
	aiBotId,
	aiDocIds,
	successMessage,
}: {
	aiBotId: string;
	aiDocIds: string[];
	successMessage: ToastMessage;
}): fetchAction {
	return {
		type: bulkUseCachedQuestionSuggestionsType,
		callAPI: () =>
			axios.put(`${apiUrl}/aiDoc/bulkUseSuggestedAnswer`, {
				aiBot: aiBotId,
				aiDocIds,
			}),
		payload: {
			custom: 'bulkUseCachedQuestionSuggestions',
			successMessage,
			errorMessage: ToastMessage.updateAiDocFailed,
			aiDocIds,
		},
	};
}

export const bulkDismissCachedQuestionSuggestionsType = createFetchTypes(
	`${mainType}_BUlK_DISMISS_CACHED_QUESTION_SUGGESTIONS`,
);

function bulkDismissCachedQuestionSuggestions({
	aiBotId,
	aiDocIds,
}: {
	aiBotId: string;
	aiDocIds: string[];
}): fetchAction {
	return {
		type: bulkDismissCachedQuestionSuggestionsType,
		callAPI: () =>
			axios.put(`${apiUrl}/aiDoc/bulkUseSuggestedAnswer`, {
				aiBot: aiBotId,
				aiDocIds,
				shouldDismiss: true,
			}),
		payload: {
			custom: 'bulkDismissCachedQuestionSuggestions',
			successMessage:
				aiDocIds.length > 1
					? ToastMessage.dismissedAnswerSuggestions
					: ToastMessage.dismissedAnswerSuggestion,
			errorMessage: ToastMessage.updateAiDocFailed,
			aiDocIds,
		},
	};
}

export const deleteAiDocType = createFetchTypes(`${mainType}_DELETE_AI_DOC`);

function deleteAiDoc({
	aiDocId,
	type,
	userAction,
}: {
	aiDocId: string;
	type: 'qaAIDoc' | 'webSiteAIDoc' | 'fileAIDoc';
	userAction?: 'dismiss' | 'delete';
}): fetchAction {
	return {
		type: deleteAiDocType,
		callAPI: () => axios.delete(`${apiUrl}/aiDoc/${aiDocId}`),
		payload: {
			custom: 'deleteAiDoc',
			successMessage: ToastMessage.deleteAiDocSuccess,
			errorMessage: ToastMessage.deleteAiDocFailed,
			aiDocId,
			type,
			userAction,
		},
	};
}
export const getAiBotConversationThreadTypes = createFetchTypes(
	`${mainType}_GET_AI_BOT_CONVERSATION_THREAD`,
);
function getAiBotConversationThread({
	conversationId,
}: {
	conversationId?: string;
}): fetchAction {
	return {
		type: getAiBotConversationThreadTypes,
		callAPI: () => axios.get(`${apiUrl}/aiConversation/${conversationId}`),
		payload: {
			custom: 'getAiBotConversationThread',
		},
	};
}

export const getAiBotStatsTypes = createFetchTypes(
	`${mainType}_GET_AI_BOT_STATS`,
);
function getAiBotStats({ aiBotId }: { aiBotId: string }): fetchAction {
	return {
		type: getAiBotStatsTypes,
		callAPI: () => axios.get(`${apiUrl}/aiBot/stats/${aiBotId}`),
		payload: {
			custom: 'getAiBotStats',
		},
	};
}

export const createAiChatBotTypes = createFetchTypes(
	`${mainType}_CREATE_AI_CHATBOT`,
);
function createAiChatBot({
	aiBot,
}: {
	aiBot: {
		name: string;
		type: string;
	};
}): fetchAction {
	return {
		type: createAiChatBotTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/aiBot`, {
				...aiBot,
				isDemoMode: true, // Creation of chatbot from web app will always be a demo bot. Creation of chatbot from templateEngine will always be a non-demo bot
			}),
		payload: {
			custom: 'createAiChatBot',
			successMessage: ToastMessage.createAiBotSuccess,
			errorMessage: ToastMessage.createAiBotFailed,
		},
	};
}

export const getAiBotTopicsTypes = createFetchTypes(
	`${mainType}_GET_AI_BOT_TOPICS`,
);
function getAiBotTopics({ aiBotId }: { aiBotId: string }): fetchAction {
	const filterOptions = {
		filter: [{ field: 'aiBot', value: aiBotId }],
		order: {
			field: 'updatedAt',
			type: orderTypes.descending,
		},
		pagination: { page: 1, pageSize: 200 },
	};
	return {
		type: getAiBotTopicsTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/topic?filter=${JSON.stringify(filterOptions)}`),
		payload: {
			custom: 'getAiBotTopics',
		},
	};
}

export const getAiBotUnansweredQuestionsType = createFetchTypes(
	`${mainType}_GET_AI_BOT_UNANSWERED_QUESTIONS`,
);

function getAiBotUnansweredQuestions({
	aiBotId,
	topicId,
	paginationFilter,
	questionSearchQuery,
	additionalFilters,
}: {
	aiBotId: string;
	topicId?: string;
	paginationFilter?: {
		page: number;
		pageSize: number;
	};
	questionSearchQuery?: string;
	additionalFilters?: filterObjectArray;
}): fetchAction {
	const filterOptions: FilterOptions = {
		filter: [
			{ field: 'aiBot', value: aiBotId },
			{ field: 'humanMessageWasUnanswered', value: true },
			{ field: 'aiDocQa', value: false, operator: operators.exists },
			{
				field: 'isWebDocPromptExample',
				value: false,
				operator: operators.exists,
			},
			{ field: 'source', value: false, operator: operators.exists },
			...(additionalFilters ?? []),
		],
		order: {
			field: 'createdAt',
			type: orderTypes.descending,
		},
		...(questionSearchQuery && {
			search: {
				fields: ['standaloneHumanEnglishMessage'],
				value: questionSearchQuery,
			},
		}),
		pagination: paginationFilter ?? { page: 1, pageSize: 25 },
	};

	// If we are filtering by subtopic, there is no need to pass in parent topic filter
	// and backend does not support multiple filters per field
	if (topicId && !additionalFilters?.find((f) => f.field === 'topics')) {
		filterOptions.filter?.push({
			field: 'topics',
			value: [topicId],
			operator: operators.in,
		});
	}
	return {
		type: getAiBotUnansweredQuestionsType,

		callAPI: () =>
			axios.get(`${apiUrl}/aiMessage?filter=${JSON.stringify(filterOptions)}`),
		payload: {
			custom: 'getAiBotUnansweredQuestions',
		},
	};
}

export const removeAiBotUnansweredType = createFetchTypes(
	`${mainType}_REMOVE_AI_BOT_UNANSWERED`,
);

function removeAiBotUnanswered({
	id,
	successMessage,
}: {
	id: string;
	successMessage?: string;
}): fetchAction {
	return {
		type: removeAiBotUnansweredType,
		callAPI: () =>
			axios.put(`${apiUrl}/aiMessage/removeUnanswered`, {
				id,
			}),
		payload: {
			custom: 'removeAiBotUnanswered',
			successMessage:
				successMessage || successMessage === ''
					? successMessage
					: ToastMessage.removeUnansweredSuccess,
			errorMessage: ToastMessage.removeUnansweredFailed,
			aiMessageId: id,
			userAction: 'removeUnanswered',
		},
	};
}

export const updateAiBotTopicType = createFetchTypes(
	`${mainType}_UPDATE_AI_BOT_TOPIC`,
);

function updateTopic({
	topic,
	aiBotId,
	userAction,
	successMessage,
}: {
	topic: {
		id?: string;
		restrictedToUrls?: string[] | null;
		customInstructions?: string | null;
		defaultResponse?: {
			answer: string;
			sources?: string[];
			createdBy: string;
		} | null;
		forceDefaultResponse?: boolean | null;
		extraContext?: string | null;
	};
	aiBotId: string;
	userAction?: 'approved' | 'update';
	successMessage?: string;
}): fetchAction {
	return {
		type: updateAiBotTopicType,
		callAPI: () =>
			axios.put(`${apiUrl}/topic?aiBotId=${aiBotId}`, {
				...topic,
			}),
		payload: {
			custom: 'updateAiBotTopic',
			successMessage: successMessage
				? successMessage
				: ToastMessage.updateAiDocSuccess,
			errorMessage: ToastMessage.updateAiDocFailed,
			userAction: userAction,
		},
	};
}

export const getAiBotUrlSourcesType = createFetchTypes(
	`${mainType}_GET_AI_BOT_URL_SOURCE_LIST`,
);

function getAiBotUrlSources({
	aiBotId,
	search,
	paginationFilter,
	discludeList,
}: {
	aiBotId: string;
	search?: string;
	paginationFilter?: {
		page: number;
		pageSize: number;
	};
	discludeList?: string[];
}): fetchAction {
	const filterOptions = {
		search: {
			fields: ['url'],
			value: search || '',
		},
		pagination: paginationFilter ?? { page: 1, pageSize: 15 },
		filter: [{ field: 'url', value: discludeList, operator: operators.notIn }],
	};

	return {
		type: getAiBotUrlSourcesType,
		callAPI: () =>
			axios.get(
				`${apiUrl}/aiDoc/uniqueUrls?aiBotId=${aiBotId}&filter=${JSON.stringify(filterOptions)}`,
			),
	};
}

export const getAiBotFileSourcesType = createFetchTypes(
	`${mainType}_GET_AI_BOT_FILE_SOURCE_LIST`,
);

function getAiBotFileSources({
	aiBotId,
	search,
	paginationFilter,
	discludeList,
}: {
	aiBotId: string;
	search?: string;
	paginationFilter?: {
		page: number;
		pageSize: number;
	};
	discludeList?: string[];
}): fetchAction {
	const filterOptions = {
		filter: [
			{ field: 'type', value: 'fileAIDoc' },
			{ field: 'aiBot', value: aiBotId },
			{ field: 'fileName', value: discludeList, operator: operators.notIn },
		],
		search: {
			fields: ['fileName'],
			value: search || '',
		},
		order: {
			field: 'fileName',
			type: orderTypes.ascending,
		},
		pagination: paginationFilter ?? { page: 1, pageSize: 15 },
	};

	return {
		type: getAiBotFileSourcesType,
		callAPI: () =>
			axios.get(`${apiUrl}/aiDoc?filter=${JSON.stringify(filterOptions)}`),
	};
}

export const getAiBotMessageTypes = createFetchTypes(
	`${mainType}_GET_AI_BOT_MESSAGE`,
);

function getAiBotMessage({
	aiBotId,
	messageId,
}: {
	aiBotId: string;
	messageId: string;
}) {
	const filterOptions = {
		filter: [
			{ field: 'aiBot', value: aiBotId },
			{ field: 'id', value: messageId },
		],
	};

	return {
		type: getAiBotMessageTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/aiMessage?filter=${JSON.stringify(filterOptions)}`),
	};
}

export {
	getWorkspaceAiBots,
	getAiBotById,
	updateAiBot,
	getAiBotConversations,
	createNewAiDoc,
	getAiBotKnowledgeBaseDocs,
	getAiBotQADocsApproved,
	getAiBotQADocsToReview,
	updateAiDoc,
	deleteAiDoc,
	getAiBotConversationThread,
	getAiBotStats,
	createAiChatBot,
	getAiBotTopics,
	getAiBotUnansweredQuestions,
	removeAiBotUnanswered,
	updateTopic,
	getAiBotUrlSources,
	getAiBotFileSources,
	bulkUseCachedQuestionSuggestions,
	bulkDismissCachedQuestionSuggestions,
	getAiBotMessage,
	getAiBotQADocsUpdateSuggestions,
};
