import styled from 'styled-components';

export const SideNavigationContainer = styled.div<{ noPadding?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	padding: ${({ theme, noPadding }) =>
		noPadding
			? '0'
			: ` ${theme.spacing.large} 0 ${theme.spacing.medium} ${theme.spacing.large}`};
	overflow-y: auto;
	overflow-x: hidden;
`;

export const NavigationsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.medium};
`;
