import createFetchTypes from '../../utils/createFetchTypes';
import { fetchAction } from '../types';
import { axiosForExternal } from '../../../global/axios';
import { apiUrl } from '../../constants';
import qs from 'querystring';

const mainType = 'BLACKBAUD';
type user = {
	name: string;
};
type ResponseType = {
	users: user[];
};

export const BBAuthTypes = createFetchTypes(`${mainType}_AUTH`);
export const BBRefreshTypes = createFetchTypes(`${mainType}_REFRESH`);
export const BBListTypes = createFetchTypes(`${mainType}_LIST`);
export const BBFundTypes = createFetchTypes(`${mainType}_FUND`);
export const BBCampaignTypes = createFetchTypes(`${mainType}_CAMPAIGN`);
export const BBAppealTypes = createFetchTypes(`${mainType}_APPEAL`);
export const BBMSAccountTypes = createFetchTypes(`${mainType}_BBMS`);
export const BBPublicKeyTypes = createFetchTypes(`${mainType}_PUBLIC_KEY`);
export const GetConstituentOfListsTypes = createFetchTypes(
	`${mainType}_CONSTITUENT_LISTS`,
);

const CORS_URL = `https://cors-enable.herokuapp.com`;
const AUTH_URL = `https://oauth2.sky.blackbaud.com`;
const BASE_URL = `https://api.sky.blackbaud.com`;
const list_type = 'Constituent';
const Token = `OWZmODQzMjQtZDc3OS00MGY4LTgxZWYtZGJlNjIzYjNjMGQ2OlEwUVJ4ZVhpQXZwMTg4V3oyVFdUNUlWY3ZwMDd0WUt0NGpkQ0pHbDBYbEk9`;
const redirect_url = `${apiUrl}/redirect`;
const subscriptionKey = 'd2a803e2305246028e2ecd95a4d4d7d1';
const bbGatewayKey = '43617ffdcee648d5a673f57b384b0376';

const bbHeaders = {
	'Content-Type': 'application/x-www-form-urlencoded',
	'Access-Control-Allow-Origin': '*',
	Authorization: `Basic ${Token}`,
};

function getBBAuthToken(code: string, type: string): fetchAction {
	const body = {
		grant_type: 'authorization_code',
		code,
		redirect_uri: `${redirect_url}/${type}`,
	};

	return {
		type: BBAuthTypes,
		payload: { type },
		callAPI: () =>
			axiosForExternal.post(
				`${CORS_URL}/${AUTH_URL}/token`,
				qs.stringify(body),
				{
					headers: bbHeaders,
				},
			),
	};
}

async function getLists(access_token: string, id: string[]) {
	const bbHeaders = {
		'Bb-Api-Subscription-Key': `${subscriptionKey}`,
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${access_token}`,
	};
	const arr: any = [];
	const data = await Promise.all(
		id.map((item) => {
			return axiosForExternal
				.get(
					`${CORS_URL}/${BASE_URL}/constituent/v1/constituents?list_id=${item}&limit=5000`,
					{ headers: bbHeaders },
				)
				.then((res) => {
					if (res && res.data) {
						arr.push(...res.data.value);
					}
				});
		}),
	);

	if (data) {
		return arr;
	}
}

async function getConstituentsOfLists(access_token: string, id: string[]) {
	const lists = await getLists(access_token, id);
	return lists;
}

function getRefreshToken(refresh_token: string, type: string): fetchAction {
	const body = {
		grant_type: 'refresh_token',
		refresh_token,
		preserve_refresh_token: true,
	};

	return {
		type: BBRefreshTypes,
		payload: { type },
		callAPI: () =>
			axiosForExternal.post(
				`${CORS_URL}/${AUTH_URL}/token`,
				qs.stringify(body),
				{
					headers: bbHeaders,
				},
			),
	};
}

function getBlackbaudLists(access_token: string): fetchAction {
	const bbHeaders = {
		'Bb-Api-Subscription-Key': `${subscriptionKey}`,
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${access_token}`,
	};

	return {
		type: BBListTypes,
		callAPI: () =>
			axiosForExternal.get(
				`${CORS_URL}/${BASE_URL}/list/v1/lists?list_type=${list_type}`,
				{ headers: bbHeaders },
			),
	};
}

function getCampaign(access_token: string) {
	const bbHeaders = {
		'Bb-Api-Subscription-Key': `${subscriptionKey}`,
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${access_token}`,
	};

	return {
		type: BBCampaignTypes,
		callAPI: () =>
			axiosForExternal.get(`${CORS_URL}/${BASE_URL}/fundraising/v1/campaigns`, {
				headers: bbHeaders,
			}),
	};
}

function getAppeal(access_token: string) {
	const bbHeaders = {
		'Bb-Api-Subscription-Key': `${subscriptionKey}`,
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${access_token}`,
	};

	return {
		type: BBAppealTypes,
		callAPI: () =>
			axiosForExternal.get(`${CORS_URL}/${BASE_URL}/fundraising/v1/appeals`, {
				headers: bbHeaders,
			}),
	};
}

function getFund(access_token: string) {
	const bbHeaders = {
		'Bb-Api-Subscription-Key': `${subscriptionKey}`,
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${access_token}`,
	};

	return {
		type: BBFundTypes,
		callAPI: () =>
			axiosForExternal.get(`${CORS_URL}/${BASE_URL}/fundraising/v1/funds`, {
				headers: bbHeaders,
			}),
	};
}

function getBBMSAcounts(access_token: string) {
	const bbHeaders = {
		'Bb-Api-Subscription-Key': `${bbGatewayKey}`,
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${access_token}`,
	};

	return {
		type: BBMSAccountTypes,
		callAPI: () =>
			axiosForExternal.get(
				`${CORS_URL}/${BASE_URL}/payments/v1/paymentconfigurations?include_inactive=${false}`,
				{ headers: bbHeaders },
			),
	};
}

function getPublicKey(access_token: string) {
	const bbHeaders = {
		'Bb-Api-Subscription-Key': `${bbGatewayKey}`,
		'Access-Control-Allow-Origin': '*',
		Authorization: `Bearer ${access_token}`,
	};

	return {
		type: BBPublicKeyTypes,
		callAPI: () =>
			axiosForExternal.get(
				`${CORS_URL}/${BASE_URL}/payments/v1/checkout/publickey`,
				{
					headers: bbHeaders,
				},
			),
	};
}

export {
	getBBAuthToken,
	getRefreshToken,
	getBlackbaudLists,
	getAppeal,
	getFund,
	getCampaign,
	getBBMSAcounts,
	getPublicKey,
	getConstituentsOfLists,
};
