import styled from 'styled-components';

import { Typography } from '../styles';

export const H1Typography = styled(Typography).attrs({
	as: 'h1',
})`
	line-height: 32px;
	font-size: ${({ theme }) => theme.fontSizes?.h1}; // 24px
`;
