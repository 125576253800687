import styled, { css } from 'styled-components';
import { PreviewFloatingWrapper } from '../../CampaignCreation.styles';

export const LoaderContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.background};
`;

export const IframeContainer = styled.div<{
	width: string;
	isMobile: boolean;
}>`
	display: flex;
	width: 100%;
	max-width: ${({ width }) => width};
	overflow: ${({ isMobile }) => isMobile && 'hidden'};
	transform: ${({ isMobile }) => isMobile && 'scale(0.9)'};
	margin: ${({ isMobile }) => isMobile && '0 auto'};
	${({ isMobile }) => {
		if (isMobile)
			return css`
				aspect-ratio: 9/16;
			`;

		return css`
			height: 100%;
		`;
	}};
	border-radius: ${({ theme, isMobile }) =>
		isMobile && theme.borderRadius?.components.large};

	@media (max-height: ${({ theme }) => `${theme.breakpoint.height.s}px`}) {
		height: ${({ isMobile }) => isMobile && '640px'};
		transform: ${({ isMobile }) => isMobile && 'scale(0.8)'};
	}
`;

export const IframeWrapper = styled(PreviewFloatingWrapper)`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;
