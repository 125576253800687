export const getTimeFromDate = (dateSelected: string) => {
	const startTimeSelected = new Date(dateSelected);

	let hour: number | string = startTimeSelected.getHours() % 12;
	hour = hour ? hour : 12;
	hour = hour.toString();

	const minutes = startTimeSelected.getMinutes().toString().padStart(2, '0');

	const period = startTimeSelected.getHours() >= 12 ? 'pm' : 'am';

	return `${hour}:${minutes}${period}`;
};
