import { MergedTheme } from '@common/design-system/global/theme';
import styled from 'styled-components';

const getBgColor = (variant: string, theme: MergedTheme) => {
	const { colors } = theme;

	switch (variant) {
		case 'outlined':
			return colors.system.background.system;
		case 'system':
			return colors.system.background.weak;
		case 'warning':
			return colors.warning.background.default;
		default:
			return colors.system.background.system;
	}
};

const getBorderColor = (variant: string, theme: MergedTheme) => {
	const { colors } = theme;

	switch (variant) {
		case 'warning':
			return colors.warning.border.weak;
		default:
			return colors.system.border.medium;
	}
};

export const ActionCardContainer = styled.div<{
	variant: string;
}>`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme, variant }) => getBgColor(variant, theme)};
	border: 1px solid ${({ theme, variant }) => getBorderColor(variant, theme)};
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	overflow: hidden;

	&:hover .floating-menu-container {
		opacity: 1;
		transition: ${({ theme }) => theme.transitions.easeInOut15};
	}
`;

export const FloatingMenuContainer = styled.div`
	opacity: 0;
	position: absolute;
	top: 16px;
	right: 16px;
`;

export const ActionCardContentContainer = styled.div<{
	padding?: 'small' | 'medium' | 'large';
}>`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	padding: ${({ padding, theme }) => theme.spacing[padding || 'large']};
	gap: ${({ theme }) => theme.spacing.large};
`;

export const ActionCardTitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const ActionCarBadgesContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const ActionCardContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const ActionCardFooterContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: ${({ theme }) => theme.spacing.xs};

	.texts-container {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xxs};

		.main-text-container {
			display: flex;
			flex: 1;
			align-items: center;
			gap: ${({ theme }) => theme.spacing.xs};
		}
	}
`;

export const LeadingContainer = styled.div`
	padding-top: ${({ theme }) => theme.spacing.xs};
`;

export const LeftContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.small};
`;

export const RightContainer = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing.xs};
	align-items: center;
	justify-content: center;
`;

export const ImageContainer = styled.div<{
	imageHeight?: number;
}>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${({ theme }) => theme.colors.system.border.weak};

	img {
		width: 100%;
		height: ${({ imageHeight }) => imageHeight && `${imageHeight}px`};
	}
`;

export const InnerCardContainer = styled.div`
	padding: ${({ theme }) => theme.spacing.large};
	padding-top: 0;
`;
