import React from 'react';

import * as S from './BadgeCount.styles';

import { Text } from '@common/design-system/components/atoms';

type BadgeSizes = 'xs' | 'small' | 'medium' | 'large';

export type BadgeCountPropsType = {
	variant?:
		| 'default'
		| 'success'
		| 'warning'
		| 'error'
		| 'accent'
		| 'neutral'
		| 'filled'
		| 'outline';
	size?: BadgeSizes;
	count: number;
	leadingSign?: '+' | '-' | '>' | '<' | '';
};

function BadgeCount({
	size = 'medium',
	variant = 'default',
	count = 0,
	leadingSign = '',
}: BadgeCountPropsType) {
	const textSizeMap: {
		[key in BadgeSizes]: 'xxs' | 'xs' | 'small';
	} = {
		xs: 'xs',
		small: 'small',
		medium: 'small',
		large: 'small',
	};

	return (
		<S.BadgeCountContainer variant={variant} size={size}>
			<Text size={textSizeMap[size]}>{`${leadingSign}${count}`}</Text>
		</S.BadgeCountContainer>
	);
}

export default BadgeCount;
