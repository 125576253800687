import styled from 'styled-components';
import { evalColorPath } from '@common/design-system/global/utils';

const getSize = (size?: string) => {
	switch (size) {
		case 'small':
			return '20px';
		case 'medium':
			return '24px';
		default:
			return '24px';
	}
};

const getBgColor = (variant?: string) => {
	switch (variant) {
		case 'success':
			return 'success.background.defaultHover';
		case 'warning':
			return 'warning.background.defaultHover';
		case 'error':
			return 'error.background.defaultHover';
		case 'neutral':
			return 'neutral.background.default';
		default:
			return 'neutral.background.hover';
	}
};

const getColor = (variant?: string) => {
	switch (variant) {
		case 'success':
			return 'success.icon.default';
		case 'warning':
			return 'warning.icon.default';
		case 'error':
			return 'error.icon.default';
		default:
			return 'neutral.icon.hover';
	}
};

export const Wrapper = styled.div<{
	variant?: string;
	size?: string;
	round?: boolean;
}>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: ${({ size }) => getSize(size)};
	height: ${({ size }) => getSize(size)};
	border-radius: ${({ theme, round }) => round && theme.shapeRadius[50]};
	background-color: ${({ theme, variant }) =>
		evalColorPath(theme.colors, getBgColor(variant))};
	color: ${({ theme, variant }) =>
		evalColorPath(theme.colors, getColor(variant))};
`;
