import React, { useState, useMemo, useEffect } from 'react';

import { Paragraph, Bold } from '../NewTypography';
import { Icon } from '../Icon';

import checkIsGKAdmin from '@app/utils/checkIsGKAdmin';
import { WORKSPACE_INDICATOR_TOAST_FIELD } from './constants';

import { WorkspaceIndicatorToastProps } from './types';

import {
	ToastWrapper,
	ToastContainer,
	CloseButton,
} from './WorkspaceIndicatorToast.styles';

export default function WorkspaceIndicatorToast({
	userEmail,
	organizationName,
}: WorkspaceIndicatorToastProps) {
	const [isOpened, setIsOpened] = useState<boolean>(
		JSON.parse(
			sessionStorage.getItem(WORKSPACE_INDICATOR_TOAST_FIELD) || 'true',
		),
	);

	const handleClose = () => {
		setIsOpened(false);
		sessionStorage.setItem(WORKSPACE_INDICATOR_TOAST_FIELD, 'false');
	};

	const handleOpen = () => {
		setIsOpened(true);
		sessionStorage.setItem(WORKSPACE_INDICATOR_TOAST_FIELD, 'true');
	};
	const isGoodkindAdmin = useMemo(() => {
		const isGKAdmin = checkIsGKAdmin(userEmail);
		return isGKAdmin;
	}, [userEmail]);

	useEffect(() => {
		const lastOrganizationName = localStorage.getItem('lastOrganizationName');

		if (organizationName && organizationName !== lastOrganizationName) {
			handleOpen();
			localStorage.setItem('lastOrganizationName', organizationName);
		}
	}, [organizationName]);
	return (
		<ToastWrapper isVisible={isOpened && isGoodkindAdmin}>
			<ToastContainer>
				<Paragraph>
					<Bold>Viewing {organizationName} Workspace</Bold>
				</Paragraph>

				<CloseButton onClick={handleClose}>
					<Icon iconName="close" size={14} />
				</CloseButton>
			</ToastContainer>
		</ToastWrapper>
	);
}
