import React from 'react';
import * as S from './WorkspaceButton.styles';
import { Icon, Text } from '@common/design-system/components/atoms';

type WorkspaceButtonPropsType = {
	focused?: boolean;
	workspaceName: string;
	canSwitchWorkspace: boolean;
};
function WorkspaceButton({
	focused,
	workspaceName,
	canSwitchWorkspace,
	...rest
}: WorkspaceButtonPropsType) {
	return (
		<S.ButtonContainer
			focused={focused}
			disabled={!canSwitchWorkspace}
			role="button"
			tabIndex={0}
			{...rest}
		>
			<S.ButtonWrapper>
				<S.ButtonDetails>
					<Text size="small" color="system.text.default">
						Workspace
					</Text>

					<Text
						size="xs"
						lineHeight="medium"
						color="system.text.medium"
						lineClamp={1}
						noWrap
					>
						{workspaceName || 'Loading...'}
					</Text>
				</S.ButtonDetails>
				{canSwitchWorkspace && (
					<Icon
						iconName="chevronRight"
						weight="regular"
						color="system.border.medium"
						size="xs"
					/>
				)}
			</S.ButtonWrapper>
		</S.ButtonContainer>
	);
}

export default React.forwardRef(WorkspaceButton);
