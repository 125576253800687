import styled from 'styled-components';

export const AvatarListContainer = styled.div`
	display: flex;
	align-items: center;

	> * + * {
		margin-left: -5px;
	}
`;

export const AmountDisplayer = styled.div<{
	size?: number;
	onClick?: () => void;
	withBorder?: boolean;
}>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.shapeRadius.xxl};
	height: ${({ size }) => `${size}px`};
	min-width: ${({ size }) => `${size}px`};
	font-size: ${({ theme }) => theme.fonts.sizes.xs};
	font-weight: ${({ theme }) => theme.fonts.weights.medium};
	cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'default')};
	background: ${({ theme }) => theme.colors.neutral.background.default};
	padding: 5.5px 7px 5.5px 5px;
	z-index: ${({ theme }) => theme.zIndex.base};
	color: ${({ theme }) => theme.colors.neutral.icon.hover};

	${({ withBorder, theme }) =>
		withBorder &&
		`border: 1.5px solid ${theme.colors.system.background.system}`};
`;
