import styled from 'styled-components';

export const StyledGrid = styled.div`
	width: 100vw;
	height: 100%;
	margin: 0 !important;
	padding: 0 !important;
	&.column {
		padding: 0 !important;
	}
`;

export const StyledColumn = styled.div`
	margin: 0 !important;
	padding: 0 !important;
	position: relative;
	width: 100%;
`;

export const LoginLoaderContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20%;
	height: 100%;
`;
