import styled from 'styled-components';

export const ThumbnailEditorContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${({ theme }) => theme.spacing.large};
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${({ theme }) => theme.spacing.xxxs};
`;
