import styled from 'styled-components';
import { LoadingProp, VideoCardContainerType } from './types';
import { Tiny, Explainer, H4 } from '../typography';
import { Icon } from '../Icon';

export const VideoCardContainer = styled.div<VideoCardContainerType>`
	/* margin: 5px; */
	padding: 0;
	width: 280px;
	min-width: 240px;
	height: ${(props) => (props.video ? '265px' : '365px')};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${({ theme }) => theme.borderRadius?.default};
	background: ${({ theme }) => theme.colors.neutral[0]};
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	z-index: 0;
	outline: none;
	border: none;
	cursor: ${(props) => (props.isSelectable ? 'pointer' : 'default')};
	:before {
		transition: opacity ${({ theme }) => theme.transition?.short};
		content: '';
		position: absolute;
		width: 103%;
		height: 103%;
		opacity: ${(props) => (props.selected ? '1' : '0')};
		background: ${(props) =>
			props.loading
				? `${props.theme.colors.neutral[0]}`
				: `${props.theme.colors.gradients.secondary}`};
		border-radius: ${({ theme }) => theme.borderRadius?.default};
		z-index: -1;
		top: -1.5%;
		left: -1.5%;
	}
	&:hover,
	:focus {
		:before {
			opacity: ${(props) => (props.isSelectable ? '1' : '0')};
		}
	}
`;

export const StyledImg = styled.div<LoadingProp & { src: string }>`
	background-image: url('${(props) => props.src}');
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: ${(props) => (props.loading ? 0 : 1)};
	transition: opacity ${({ theme }) => theme.transition?.short};
`;

export const Banner = styled.div`
	height: 35px;
	width: 100%;
	background: ${({ theme }) => theme.colors.neutral[0]};
	border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${({ theme: { borderRadius: { smooth } = {} } }) =>
		`${smooth} ${smooth} 0 0`};
`;

export const VideoBanner = styled(Banner)`
	justify-content: space-between;
	padding: 0 10px;
`;

export const BannerExplainer = styled(Explainer)<LoadingProp>`
	opacity: ${(props) => (props.loading ? 0 : 1)};
	position: ${(props) => (props.loading ? 'absolute' : 'static')};
	transition: opacity ${({ theme }) => theme.transition?.short};
	font-size: 12px;
`;

export const BannerAnchor = styled.a`
	color: ${({ theme }) => theme.colors.secondary[700]};
	transition:
		color ${({ theme }) => theme.transition?.short},
		opacity ${({ theme }) => theme.transition?.short};
`;

export const VideoLibraryCard = styled.div<{ hasFixedSize: boolean }>`
	position: relative;
	width: ${(props) => (props.hasFixedSize ? '260px' : '100%')};
`;

export const VideoSection = styled.div`
	position: relative;
	width: 100%;
`;

export const VideoContainer = styled.div`
	width: 100%;
	background: white;
	height: 179px;
	max-height: 179px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.colors.neutral[50]};
`;

export const CtaSection = styled.div<LoadingProp>`
	height: 85px;
	padding: 10px;
	background: ${({ theme }) => theme.colors.neutral[0]};
	border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
	text-align: left;

	> * {
		transition: opacity ${({ theme }) => theme.transition?.short};
		opacity: ${(props) => (props.loading ? 0 : 1)};
	}
`;

export const UploadedBySection = styled.div<LoadingProp>`
	height: 55px;
	padding: 10px;
	background: ${({ theme }) => theme.colors.neutral[0]};
	border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
	text-align: left;
	display: flex;
	align-items: center;

	> * {
		transition: opacity ${({ theme }) => theme.transition?.short};
		opacity: ${(props) => (props.loading ? 0 : 1)};
	}
`;

export const CtaAnchor = styled.a`
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	font-family: ${({ theme }) => theme.fonts.default};
	color: ${({ theme }) => theme.colors.neutral[700]};
	margin: 0;
	margin-top: 10px;
	display: inline-block;
	text-decoration: none;
	transition:
		color ${({ theme }) => theme.transition?.short},
		opacity ${({ theme }) => theme.transition?.short};
`;

export const StyledTiny = styled(Tiny)`
	color: ${({ theme }) => theme.colors.neutral[200]};
	text-transform: ${(props) => (props.uppercase ? `uppercase` : `default`)};
`;

export const TagContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 10px;
`;

export const StyledH4 = styled(H4)`
	color: ${({ theme }) => theme.colors.neutral[200]};
`;

export const StyledIcon = styled(Icon)`
	margin-left: 10px;
`;

export const StatSection = styled.div<LoadingProp>`
	height: ${(props) => (props.video ? '55px' : '65px')};
	width: 100%;
	display: flex;
	justify-content: ${(props) => (props.video ? 'center' : 'space-evenly;')};
	align-items: center;
	border-radius: ${({ theme: { borderRadius: { smooth } = {} } }) =>
		`0 0 ${smooth} ${smooth}`};
	background: ${({ theme }) => theme.colors.neutral[50]};

	> * {
		transition: opacity ${({ theme }) => theme.transition?.short};
		opacity: ${(props) => (props.loading ? 0 : 1)};
	}
`;

export const Stat = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const StatExplainer = styled(Explainer)`
	color: ${({ theme }) => theme.colors.neutral[700]};
	margin-bottom: 5px;
`;

export const StatTiny = styled(Tiny)`
	color: ${({ theme }) => theme.colors.neutral[200]};
`;

export const LottieContainer = styled.div`
	position: absolute;
	z-index: 2;
	background: ${({ theme }) => theme.colors.neutral[50]};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	margin-top: 10px;
`;

export const LottieContainerVideo = styled(LottieContainer)`
	height: 93%;
`;
