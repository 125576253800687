import React from 'react';

import * as S from './CubeToggle.styles';
import { OrientationType } from '@app/types';
import { Icon, Text } from '../';

export type SwitchOptionType = {
	iconName?: string;
	iconSize?: 'regular' | 'small';
	isActive: boolean;
	text?: string;
	onClick: () => void;
};

type PropTypes = {
	switchOptions: SwitchOptionType[];
	orientation?: OrientationType;
	isInvalid?: boolean;
	disabled?: boolean;
};
const CubeToggle = ({
	switchOptions,
	orientation = 'horizontal',
	isInvalid,
	disabled,
}: PropTypes) => {
	return (
		<S.StyledContainer
			orientation={orientation}
			disabled={disabled}
			isInvalid={isInvalid}
		>
			{switchOptions.map((switchOption, index) => (
				<S.StyledButton
					type="button"
					key={`${switchOption.text}-${index}`}
					active={switchOption.isActive}
					onClick={switchOption.onClick}
				>
					{!!switchOption.iconName && (
						<Icon
							iconName={switchOption.iconName}
							weight="regular"
							color={
								switchOption.isActive
									? 'primary.text.default'
									: 'system.text.weak'
							}
							size={switchOption?.iconSize === 'small' ? 'small' : 'large'}
						/>
					)}

					{!!switchOption.text && (
						<Text
							color={
								switchOption.isActive
									? 'primary.text.default'
									: 'system.text.weak'
							}
							size="small"
							fontWeight="medium"
						>
							{switchOption.text}
						</Text>
					)}
				</S.StyledButton>
			))}
		</S.StyledContainer>
	);
};

export default CubeToggle;

export type { PropTypes as CubeToggleProps };
