import styled, { css } from 'styled-components';

import { THUMB_DIMENSION } from './constants';

const getProgressWidth = (progressRatio: number, offset = 0) => {
	// Calculates full width of the slider minus the thumb size and then make it
	// proportional to the current progress
	return `calc((100% - ${THUMB_DIMENSION - offset}px) * ${progressRatio})`;
};

export const Progress = styled.div<{ progressRatio: number }>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: ${({ progressRatio }) => getProgressWidth(progressRatio, 10)};
	background: ${({ theme }) => theme.colors.primary.background.strongDisabled};
	z-index: ${({ theme }) => theme.zIndex.base};
`;

export const SliderComponent = styled.input``;

export const SliderThumbReference = styled.div`
	height: ${THUMB_DIMENSION}px;
	width: ${THUMB_DIMENSION}px;
	border-radius: 50%;

	color: ${({ theme }) => theme.colors.primary.text.hover};
`;

export const TooltipReference = styled.div<{ progressRatio: number }>`
	position: absolute;
	top: -6px;
	left: ${({ progressRatio }) => getProgressWidth(progressRatio)};
	height: ${THUMB_DIMENSION}px;
	width: ${THUMB_DIMENSION}px;
`;

const thumbBaseStyles = css<{ progressRatio: number }>`
	-webkit-appearance: none;
	height: ${THUMB_DIMENSION}px;
	width: ${THUMB_DIMENSION}px;
	margin-top: -6px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.primary.text.hover};
	z-index: ${({ theme }) => theme.zIndex.sticky};
	position: absolute !important;
	left: ${({ progressRatio }) => getProgressWidth(progressRatio)} !important;

	cursor: grab;

	&:active {
		cursor: grabbing;
	}
`;

export const SliderWrapper = styled.div<{
	progressRatio: number;
	disabled?: boolean;
}>`
	display: flex;
	width: 100%;
	position: relative;
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	&:hover {
		${TooltipReference} {
			display: flex;
		}
	}

	input[type='range'] {
		-webkit-appearance: none;
		width: 100%;
		height: 4px;
		background: ${({ theme }) => theme.colors.system.background.xStrong};
	}

	input[type='range']::-webkit-slider-thumb,
	input[type='range']::-moz-range-thumb {
		${thumbBaseStyles}
	}

	// Chrome changed how thumbs are styled on pseudo elements, anyway for some reason it doesn't work with css selectors + comma
	input[type='range' i]::-webkit-slider-thumb {
		${thumbBaseStyles}
	}

	input[type='range']::-webkit-slider-runnable-track {
		-webkit-appearance: none;
		border: none;
		height: 4px;
		background: ${({ theme }) => theme.colors.system.background.xStrong};
		color: ${({ theme }) => theme.colors.primary.text.hover};
	}

	input[type='range']::-ms-fill-lower {
		border: 1px solid ${({ theme }) => theme.colors.primary.text.hover};
	}
`;
