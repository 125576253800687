import styled from 'styled-components';

export const MobileFrameContainer = styled.div`
	width: 310.675px;
	height: 656px;
	background: ${({ theme }) => theme.colors.system.background.xStrong};
	border: 4px solid ${({ theme }) => theme.colors.system.border.strong};
	box-shadow: ${({ theme }) => theme.shadows.elevation.large};
	border-radius: ${({ theme }) => theme.shapeRadius[40]};
	display: flex;
	flex-direction: column;
	overflow: auto;
`;

export const MobileFrameScreen = styled.div<{ whiteBg?: boolean }>`
	position: relative;
	background: ${({ theme, whiteBg }) =>
		whiteBg
			? theme.colors.system.background.system
			: theme.colors.system.background.medium};
	border-radius: 37px;
	border: 8px solid ${({ theme }) => theme.colors.system.text.default};
	overflow: auto;

	width: 100%;
	height: 100%;

	& > *:not(:last-child) {
		margin-bottom: 8px;
	}
`;

export const MobileFrameHeader = styled.div`
	display: flex;
	position: absolute;
	width: 100%;
	height: auto;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	background-color: ${({ theme }) => theme.colors.system.background.system};
	padding: ${({ theme }) => theme.spacing.medium};
`;
