import { FloatingMenu } from '@common/design-system/components/molecules';
import { FloatingMenuPropsType } from '@common/design-system/components/molecules/FloatingMenu/FloatingMenu';
import GoodkindDSTheme from '@common/design-system/global/theme';
import { FloatingPortal } from '@floating-ui/react';
import React from 'react';

type WorkspaceSelectorPropsType = {
	isLoading: boolean;
	isDisabled: boolean;
	searchValue: string;
	handleSearchValue: (value: string) => void;
	popupMenuHeight: number;
	floatingConfig: any;
	workspacesOptions: FloatingMenuPropsType['menus'][number]['menuItems'];
};
function WorkspaceSelector({
	isLoading,
	isDisabled,
	searchValue,
	handleSearchValue,
	popupMenuHeight,
	floatingConfig,
	workspacesOptions,
}: WorkspaceSelectorPropsType) {
	return (
		<FloatingPortal>
			<div
				ref={floatingConfig?.floatingReference}
				style={{
					position: floatingConfig?.strategy,
					top: (floatingConfig?.y || 0) + 79,
					left: floatingConfig?.x ?? 0,
					zIndex: GoodkindDSTheme.zIndex.fixed,
				}}
				{...floatingConfig?.getFloatingProps()}
			>
				<FloatingMenu
					searchable
					disabled={isDisabled}
					searchValue={searchValue}
					onSearch={(value) => handleSearchValue(value)}
					loading={isLoading}
					popUpPlacement="right"
					minHeight={popupMenuHeight + 'px'}
					maxHeight={popupMenuHeight + 'px'}
					minWidth="280px"
					maxWidth="280px"
					menus={[
						{
							lineHeight: 'large',
							showCheckMarkOnActive: true,
							menuItems: workspacesOptions,
						},
					]}
				/>
			</div>
		</FloatingPortal>
	);
}

export default WorkspaceSelector;
