import React from 'react';

import { TypographyProps } from '../types';

import { H3Typography } from './H3.styles';

export default function H3({
	children,
	position = 'left',
	color,
	className,
}: TypographyProps) {
	return (
		<H3Typography color={color} position={position} className={className}>
			{children}
		</H3Typography>
	);
}
