import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { devtools } from 'zustand/middleware';

import { createWithEqualityFn } from 'zustand/traditional';
import { CampaignType } from '@app/redux/campaign/types';

type StoreType = {
	campaign: {
		name?: string;
		currentStep?: string;
		overlay?: CampaignType['overlay'] | null;

		//Settings configuration edited on the build step
		settings?: CampaignType['settings'];

		//Widget data saved com campaign level
		widgetDomain?: string;
		widgetPath?: string;
		widgetHideOnMobile?: boolean;
	};
	setCampaignData: (data: Partial<StoreType['campaign']>) => void;
	setInitialValues: (data: Partial<StoreType['campaign']>) => void;
	reset: () => Promise<void>;
};

const useBuildSaveStore = createWithEqualityFn(
	devtools(
		immer((set) => ({
			campaign: {},
			errors: {},
			setCampaignData: async (data: Partial<StoreType['campaign']>) => {
				set(
					(state: StoreType) => {
						state.campaign = {
							...state.campaign,
							...data,
						};
					},
					false,
					'buildSaveStore/setCampaignData',
				);
			},
			setInitialValues: async (data: Partial<StoreType['campaign']>) => {
				set(
					(state: StoreType) => {
						state.campaign = {
							settings: data.settings || state.campaign.settings,
							overlay: data.overlay || state.campaign.overlay,
						};
					},
					false,
					'buildSaveStore/setInitialValues',
				);
			},
			reset: async () => {
				set(
					(state: StoreType) => {
						state.campaign = {};
					},
					true,
					'buildSaveStore/reset',
				);
			},
		})),
		{ name: 'buildSaveStore' },
	),
	shallow,
);

export default useBuildSaveStore;
export type { StoreType as BuildSaveStoreType };
